<template>
  <!-- Practice information -->
  <div class="wrap-table border-table">
    <div class="row row-group">
      <div class="col-sm-4">
        <span class="text-up"><strong>Practice Email Address</strong></span>
      </div>
      <div class="col-sm-8">
        <div
          v-if="!edits.email.show"
          class="row-large p-email-edition pr-0"
        >
          <div class="col-sm-9 p-email-info">
            <span>{{ practice.email }}</span>
          </div>
          <div class="col-md-3 action-box p-email-info">
            <a
              v-if="canUserEdit"
              class="link_action"
              data-block="email"
              href="#"
              @click.prevent="_edit_prac"
            >Edit</a>
          </div>
        </div>
        <ValidationObserver
          v-else
          ref="observer"
          v-slot="{ invalid }"
          tag="form"
          class="row-large p-email-edition pr-0"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Email"
            rules="email|required"
            class="col-md-12 p-email-edit pr-0"
          >
            <input
              id="email"
              v-model="practice.email"
              placeholder="contact@practice.com"
              type="email"
              name="email"
              :class="errors.length ? 'error' : ''"
            >
            <p class="validation error mt-2">
              {{ errors[0] }}
            </p>
            <div class="text-right mt-3">
              <a
                href="#"
                data-action="cancel"
                class="btn btn-bordered right-space"
                @click.prevent="_edit_prac_email"
              >Cancel</a>
              <a
                href="#"
                class="btn btn-primary autowidth"
                data-action="save"
                @click.prevent="_edit_prac_email"
              >Save</a>
            </div>
          </validation-provider>
        </ValidationObserver>
      </div>
    </div>
    <div class="row row-group">
      <div class="col-sm-4">
        <span class="text-up"><strong>Practice Website Link</strong></span>
      </div>
      <div class="col-sm-8">
        <div
          v-if="!edits.link.show"
          class="row-large p-website-edition"
        >
          <div class="col-sm-9 p-website-info">
            <span>{{ practice.url }}</span>
          </div>
          <div class="col-md-3 action-box p-website-info">
            <a
              v-if="canUserEdit"
              data-block="link"
              href="#"
              class="link_action"
              @click.prevent="_edit_prac"
            >Edit</a>
          </div>
        </div>
        <ValidationObserver
          v-else
          ref="observer"
          v-slot="{ invalid }"
          tag="form"
          class="row-large p-website-edition" 
        >
          <validation-provider
            v-slot="{ errors }"
            name="Website"
            rules="url|required"
            class="col-md-12 p-website-edit pr-0"
          >
            <div class="form-group">
              <input
                id="link"
                v-model="practice.url"
                placeholder="www.practicewebsite.com"
                type="text"
                name="link"
                :class="errors.length ? 'error' : ''"
              >
              <p class="validation error mt-2">
                {{ errors[0] }}
              </p>
              <div class="text-right mt-3">
                <a
                  href=""
                  data-action="cancel"
                  class="btn btn-bordered right-space"
                  @click.prevent="_edit_prac_link"
                >Cancel</a>
                <a
                  href="#"
                  class="btn btn-primary autowidth"
                  data-action="save"
                  @click.prevent="_edit_prac_link"
                >Save</a>
              </div>
            </div>
          </validation-provider>
        </ValidationObserver>
      </div>
    </div>
    <div class="row row-group">
      <div class="col-sm-4">
        <span class="text-up"><strong>Practice Number</strong></span>
      </div>
      <div class="col-sm-8">
        <div
          v-if="!edits.numbers.show"
          class="row-large p-number-edition"
        >
          <div class="col-sm-9 p-number-info">
            <span
              v-for="phone in practice.phones"
              :key="phone.id"
              class="text-soft"
            >
              {{ phone.number }}<span v-if="phone.ext"> ext.{{ phone.ext }}</span>
              <br>
            </span>
          </div>
          <div class="col-md-3 action-box">
            <a
              v-if="canUserEdit"
              href="#"
              class="link_action"
              data-block="numbers"
              @click.prevent="_edit_prac"
            >Edit</a>
          </div>
        </div>
        <div
          v-else
          class="row-large p-number-edition-res pr-0"
        >
          <ValidationObserver
            ref="observer"
            v-slot="{ invalid }"
            tag="form"
            class="p-number-edit"
          >
            <div
              v-for="(phoneObj, index) in practice.phones"
              :key="index"
              class="row align-items-start"
            >
              <div class="col-9">
                <label
                  v-if="index < 1"
                  for="main_practice_num"
                >Main Practice Number</label>
                <input
                  v-model="phoneObj.number"
                  placeholder="(555) 555 - 5555"
                  type="text"
                  name="link"
                  :class="addPhoneError[index] ? 'error' : ''"
                  @input="enterNumber(index)"
                  @change="changeNumber(index)"
                >
                <p 
                  v-if="addPhoneError[index]"
                  class="validation error mt-2"
                >
                  {{ addPhoneError[index] }}
                </p>
              </div>
              <div :class="['col-3', index == 0 ? 'pr-0':'']">
                <label
                  v-if="index < 1"
                  for="practice_num_ext"
                >Ext. (Optional)</label>
                <input
                  v-model="phoneObj.ext"
                  placeholder="555"
                  type="text"
                  maxlength="3"
                >
                <a
                  v-if="index > 0"
                  class="remove-rw"
                  @click="removePhone(index)"
                >
                  <i class="fas fa-minus" />
                </a>
              </div>
            </div>
            <div class="p-number-edit-submit">
              <div class="row">
                <div class="col-md-12 form-options">
                  <a
                    href="#"
                    class="addition-number" 
                    @click.prevent="addPhone"
                  >
                    <i class="fas fa-plus" /> add additional number
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-right pr-0">
                  <a
                    href="#"
                    class="btn btn-bordered right-space"
                    data-action="cancel"
                    @click.prevent="_edit_prac_numbers"
                  >Cancel</a>
                  <a
                    href="#"
                    class="btn btn-primary autowidth"
                    data-action="save"
                    @click.prevent="_edit_prac_numbers"
                  >Save</a>
                </div>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <div class="row row-group">
      <div class="col-sm-4">
        <span class="text-up"><strong>Practice Logo</strong></span>
      </div>
      <div class="col-sm-8">
        <div
          v-if="!edits.logo.show"
          class="row-large"
        >
          <div class="col-md-9">
            <span class="text-soft">
              <img :src="practice.logo.url">
            </span>
          </div>
          <div class="col-md-3 action-box">
            <a
              v-if="canUserEdit"
              href="#"
              class="link_action"
              data-block="logo"
              @click.prevent="_edit_prac"
            >Edit</a>
          </div>
        </div>
        <div
          v-else
          class="row-large"
        >
          <ValidationObserver
            ref="observer"
            v-slot="{ invalid }"
            tag="form"
            class="col-md-12"
          >
            <ImagePicker
              :image="practice.logo"
              :image-preview="imagePreview"
              :filename="filename"
              :bordered="false"
              :cropper-options="{
                aspectRatio: '',
                autoCropArea: 1,
                viewMode: 1,
                movable: false,
                zoomable: true
              }"
              :can-remove="false"
              @image="val => practice.logo = val"
              @image-preview="val => imagePreview = val"
              @filename="val => filename = val"
            />
            <p 
              v-if="practiceLogoError" 
              class="validation error mt-2"
            >
              {{ practiceLogoError }}
            </p>
            <div class="col-md-12 action-box mt-4">
              <div class="text-right">
                <a
                  href
                  class="btn btn-bordered right-space"
                  data-action="cancel"
                  @click.prevent="_edit_prac_logo"
                >Cancel</a>
                <a
                  href
                  class="btn btn-primary autowidth"
                  data-action="save"
                  @click.prevent="_edit_prac_logo"
                >Save</a>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>

    <div class="row row-group">
      <div class="col-sm-4">
        <span class="text-up">
          <strong>Social Media Links</strong></span>
      </div>
      <div class="col-sm-8">
        <div
          v-if="!edits.socials.show"
          class="row-large p-social-edition"
        >
          <div class="col-sm-9">
            <span
              v-for="social in practice.social_media"
              :key="social.id"
              :data-url="social.url"
            >
              {{ social.url }}<br>
            </span>
            <span v-if="emptySocial()">None<br></span>
          </div>
          <div class="col-md-3 action-box">
            <a
              v-if="canUserEdit"
              class="link_action"
              href="#"
              data-block="socials"
              @click.prevent="_edit_prac"
            >Edit</a>
          </div>
        </div>
        <div
          v-else
          class="row-large p-social-edit"
        >
          <ValidationObserver
            ref="observer"
            v-slot="{ invalid }"
            tag="form"
            class="container-socials"
          >
            <div
              v-for="(socialItem, index) in practice.social_media"
              :key="index"
              :class="['social-option', 'social-'+socialItem.type]"
            >
              <div>
                <input
                  :id="'social_' + socialItem.type"
                  type="checkbox"
                  :name="'social_' + socialItem.type"
                  :checked="socialItem.url"
                  :data-index="index"
                  @change="socialsOpen"
                >
                <label
                  :for="'social_' + socialItem.type"
                >
                  {{ capitalize(socialItem.type) }}</label>
                <input
                  v-model="socialItem.url"
                  type="url"
                  :placeholder="`www.${socialItem.type}.com/practice`"
                >
              </div>
            </div>
          </ValidationObserver>
        </div>
        <div
          v-if="edits.socials.show"
          class="row-large save-socials p-social-edit"
        >
          <div class="col-md-12 text-right pr-0">
            <a
              href="#"
              class="btn btn-bordered right-space"
              data-action="cancel"
              @click.prevent="_edit_prac_socials"
            >Cancel</a>
            <a
              href="#"
              class="btn btn-primary autowidth"
              data-action="save"
              @click.prevent="_edit_prac_socials"
            >Save</a>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!isRole('group_admin')"
      class="row row-group last-row"
    >
      <div class="col-sm-4">
        <span class="text-up">
          <strong>Future Orders</strong>
        </span>
      </div>
      <div class="col-sm-8 d-flex align-items-center">
        <div 
          class="slideThree mr-2"
          :class="practice.future_order ? 'active' : ''"
        >
          <input
            id="slideThree"
            v-model="practice.future_order"
            type="checkbox"
          >
          <label
            :class="futureOrderLoading ? 'loading' : ''"
            @click="toggleFutureOrder"
          />
        </div>
        <label
          class="mb-0 hover-btn"
          @click="toggleFutureOrder"
        >
          Enable future order date ability
        </label>
      </div>
    </div>

    <Modal
      :show-modal="showCancelModal"
      modal-id="before"
      class="modal fade dashboard-modal" 
      @hide-modal-window="showCancelModal = false"
    >
      <img
        src="../../../../images/warning.png"
        alt="Warning!"
        class="warning-icon"
      >
      <h4 class="modal-title text-center">
        Before you go ...
      </h4>
      <p class="text-center">
        You have unsaved changes. Are you sure you want to continue?
      </p>
      <div class="mb-3">
        <a
          href="#"
          class="btn btn-primary w-100"
          @click.prevent="hideModal"
        >Continue Without Saving</a>
      </div>
      <div class>
        <a
          href="#"
          data-dismiss="modal"
          class="btn btn-bordered w-100"
          @click.prevent="showCancelModal = false"
        >Cancel</a>
      </div>
    </Modal>
    <div
      v-if="showCancelModal"
      class="modal-backdrop fade show"
    />
  </div>
  <!-- End Practice information -->
</template>
<style scoped lang="scss">
  input[type="checkbox"]~input{ display: none; }
  input[type="checkbox"]:checked~input{ display: block; }

  /* .slideThree */
  .slideThree {
    width: 66px;
    height: 30px;
    border: 2px solid #a989de;
    position: relative;
    border-radius: 50px;
    background: #fff;
    transition: all 0.4s ease;
    &.active {
      background: #a989de;
    }
    &:after {
      content: 'OFF';
      color: #a989de;
      position: absolute;
      right: 4px;
      top: 4px;
      z-index: 0;
      font-size: 11px;
      font-weight: bold;
    }
    &:before {
      content: 'ON';
      color: #fff;
      position: absolute;
      left: 6px;
      top: 4px;
      z-index: 0;
      font-size: 11px;
      font-weight: bold;
    }
    label {
      display: block;
      width: 24px;
      height: 24px;
      cursor: pointer;
      position: absolute;
      top: 1px;
      left: 2px;
      z-index: 1;
      background: #fcfff4;
      border-radius: 50px;
      transition: all 0.4s ease;
      border: 1px solid #a989de;
      &.loading:after {
        content: '';
        display: block;
        position: absolute;
        top: 3.5px;
        left: 3.5px;
        background: transparent;
        border-radius: 100%;
        height: 15px;
        width: 15px;
        border: 1px solid #442a70;
        border-top: 1px solid transparent;
        animation: spinAround 500ms infinite linear;
      }
      &:before {
        display: none;
      }
    }
    input[type=checkbox] {
      visibility: hidden;
      &:checked + label {
        left: 38px;
      }
    }
  }
  @keyframes spinAround {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }
  /* end .slideThree */
</style>
<script>
import {
  mapGetters, mapActions
} from 'vuex';
import Modal from '../partials/Modal';
import {
  ValidationProvider,
  ValidationObserver,
  extend
} from 'vee-validate';
import {
  required,
  email
} from 'vee-validate/dist/rules';
import {
  isURL,
  capitalize,
  validPhone
} from '@/helper';
import {
  AsYouType
} from 'libphonenumber-js';
import {
  UPDATE_PRACTICE_FORM,
  UPDATE_PRACTICE
} from '@/store/practice/actions.types';
import {
  socials
} from '@/store/practice/initialObject';
import ImagePicker from '@/views/components/ImagePicker';
import { get } from 'lodash';

// Install rules
extend('required', required);
extend('url', {
  validate(value) {
    return isURL(value);
  },
  message: 'Please enter a valid website link '
});

email['message'] = 'Please enter a valid email address';
extend('email', email);

// Component to show and update practice information
// This page includes email, website link, practice phone number, 
// logo, social links, future order switcher
// @group Practice
export default {
  name: 'Information',
  components: {
    Modal,
    ValidationProvider,
    ValidationObserver,
    ImagePicker
  },
  props: {
    // Property that show is current user can edit practice information data
    canUserEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edits: {
        current: '',
        email: {
          show: false,
          e_addr: ''
        },
        link: {
          show: false,
          w_link: ''
        },
        numbers: {
          show: false,
          t_numbers: [{}]
        },
        logo: {
          show: false,
          logo: ''
        },
        socials: {
          show: false,
          s_links: [{}]
        }
      },
      sending: false,
      practiceLogoError: '',
      imagePreview: null,
      filename: '',
      showCancelModal: false,
      addPhoneError: [],
      futureOrderLoading: false,
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'practice'])
  },
  watch: {
    practice: function (newPractice, oldPractice) {
      if (newPractice.logo && newPractice.logo.url)
        this.imagePreview = newPractice.logo.url;

      oldPractice.social_media.forEach(soc => {
        const newSocial = newPractice.social_media.find(item => item.type == soc.type);
        if (newSocial) soc.url = newSocial.url;
      });

      this.practice.social_media = oldPractice.social_media;
    }
  },
  beforeMount() {
    if (this.practice.logo && this.practice.logo.url)
      this.imagePreview = this.practice.logo.url;

    const socialsArr = [...socials];
    socialsArr.forEach(soc => {
      const newSocial = this.practice.social_media.find(item => item.type == soc.type);
      if (newSocial) soc.url = newSocial.url;
    });

    this.practice.social_media = socialsArr;
  },

  methods: {
    ...mapActions({
      'updatePractice': `${UPDATE_PRACTICE}`
    }),
    // @vuese
    // Check if phone valid
    // @arg Phone number
    validPhone,
    // Used to check if social input visible
    socialsOpen(){
      let index = event.target.dataset.index;
      if(!event.target.checked){
        this.practice.social_media[index].url = '';
      }
    },

    // @vuese
    // Used to enable or disable future order option
    toggleFutureOrder() {
      if (this.futureOrderLoading) return;
      this.futureOrderLoading = true;

      this.updatePractice({
        practice: {
          future_order: !this.practice.future_order
        }
      })
        .then(() => {})
        .catch(() => {
          this.flashError('Error updating Future Order');
        })
        .finally(() => {
          this.futureOrderLoading = false;
        });
    },

    // @vuese
    // Used to show edit form and save old data in case if we do not want to save it
    // @arg param that show which form we editing
    _edit_prac(event) {
      switch (event.target.getAttribute('data-block')) {
      case 'email':
        this.edits.email.e_addr = this.practice.email;
        this.edits.email.show = true;
        break;
      case 'link':
        this.edits.link.w_link = this.practice.url;
        this.edits.link.show = true;
        break;
      case 'numbers':
        this.edits.numbers.t_numbers = [];
        for (let i = 0; i < this.practice.phones.length; i++) {
          this.edits.numbers.t_numbers.push({ ...this.practice.phones[i] });
        }
        this.edits.numbers.show = true;
        break;
      case 'logo':
        this.edits.logo.logo = this.imagePreview;
        this.edits.logo.show = true;
        break;
      case 'socials':
        this.edits.socials.s_links = [];
        for (let i = 0; i < this.practice.social_media.length; i++) {
          this.edits.socials.s_links.push({ ...this.practice.social_media[i] });
        }
        this.edits.socials.show = true;
        break;
      }
    },

    // @vuese
    // Used to close email form and also save data or cancel editing form
    // in case if data was changed and we cancel editing it will trigger modal
    // that tell that you will loose data
    // @arg event
    async _edit_prac_email() {
      switch (event.target.getAttribute('data-action')) {
      case 'save':
        if (await this.isValid()) {
          this.sending = true;

          let formData = new FormData();
          formData.append('practice[email]', this.practice.email);

          this.$store
            .dispatch(UPDATE_PRACTICE_FORM, formData)
            .then(resp => {
              this.edits.email.show = false;
              this.flashSuccess('Practice email address updated');
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {
              this.sending = false;
            });
        }
        break;
      case 'cancel':
        if (this.edits.email.e_addr == this.practice.email) {
          this.edits.email.show = false;
        } else {
          this.edits.current = 'email';
          this.showCancelModal = true;
        }
        break;
      }
    },

    // @vuese
    // Used to close website link form and also save data or cancel editing form
    // in case if data was changed and we cancel editing it will trigger modal
    // that tell that you will loose data
    // @arg event
    async _edit_prac_link() {
      switch (event.target.getAttribute('data-action')) {
      case 'save':
        if (await this.isValid()) {
          this.sending = true;

          let formData = new FormData();
          formData.append('practice[url]', this.practice.url);

          this.$store
            .dispatch(UPDATE_PRACTICE_FORM, formData)
            .then(resp => {
              this.edits.link.show = false;
              this.flashSuccess('Practice website link updated');
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {
              this.sending = false;
            });
        }
        break;
      case 'cancel':
        if (this.edits.link.w_link == this.practice.url) {
          this.edits.link.show = false;
        } else {
          this.edits.current = 'link';
          this.showCancelModal = true;
        }
        break;
      }
    },

    // @vuese
    // Used to close practice phone number form and also save data or cancel editing form
    // in case if data was changed and we cancel editing it will trigger modal
    // that tell that you will loose data
    // @arg event
    async _edit_prac_numbers() {
      switch (event.target.getAttribute('data-action')) {
      case 'save':
        if ((await this.isValid()) && this.validPhones()) {
          this.sending = true;

          let formData = new FormData();

          this.practice.phones.forEach(phone => {
            if (!phone.number) return;
            formData.append('practice[phones][][number]', phone.number);
            formData.append('practice[phones][][ext]', phone.ext);
          });

          this.$store
            .dispatch(UPDATE_PRACTICE_FORM, formData)
            .then(resp => {
              this.edits.numbers.show = false;
              this.flashSuccess('Practice numbers updated');
            })
            .catch(err => {
              if (get(err, 'response.data.errors.phones[0]') == 'is invalid') {
                this.validPhones();
              }
            })
            .finally(() => {
              this.sending = false;
            });
        }

        break;
      case 'cancel':
        if (
          JSON.stringify(this.edits.numbers.t_numbers) ==
          JSON.stringify(this.practice.phones)
        ) {
          this.edits.numbers.show = false;
        } else {
          this.edits.current = 'numbers';
          this.showCancelModal = true;
        }
        break;
      }
    },

    // @vuese
    // Used to close practice logo form and also save data or cancel editing form
    // in case if data was changed and we cancel editing it will trigger modal
    // that tell that you will loose data
    // @arg event
    async _edit_prac_logo() {
      switch (event.target.getAttribute('data-action')) {
      case 'save':
        if (await this.isValid() && this.validLogo()) {
          this.sending = true;

          let formData = new FormData();
          if (!this.practice.logo.url)
            formData.append('practice[logo]', this.practice.logo, this.filename);

          this.$store.dispatch(UPDATE_PRACTICE_FORM, formData)
            .then(resp => {
              this.edits.logo.show = false;
              this.flashSuccess('Practice logo updated');
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {
              this.sending = false;
            });
        }
        break;
      case 'cancel':
        if (this.edits.logo.logo == this.imagePreview) {
          this.edits.logo.show = false;
        } else {
          this.edits.current = 'logo';
          this.showCancelModal = true;
        }
        break;
      }
    },

    // @vuese
    // Used to close practice socials form and also save data or cancel editing form
    // in case if data was changed and we cancel editing it will trigger modal
    // that tell that you will loose data
    // @arg event
    async _edit_prac_socials() {
      switch (event.target.getAttribute('data-action')) {
      case 'save':
        if (await this.isValid()) {
          this.sending = true;

          let formData = new FormData();
          this.practice.social_media.forEach(soc => {
            formData.append('practice[social_media][][type]', soc.type);
            formData.append('practice[social_media][][url]', soc.url);
          });

          this.$store.dispatch(UPDATE_PRACTICE_FORM, formData)
            .then(resp => {
              this.edits.socials.show = false;
              this.flashSuccess('Practice social media links updated');
              this.$forceUpdate();
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {
              this.sending = false;
            });
        }
        break;
      case 'cancel':
        if (JSON.stringify(this.edits.socials.s_links) == JSON.stringify(this.practice.social_media)) {
          this.edits.socials.show = false;
        } else {
          this.edits.current = 'socials';
          this.showCancelModal = true;
        }
        break;
      }
    },

    // @vuese
    // Used to hide modal and close current edit form
    hideModal() {
      switch (this.edits.current) {
      case 'email':
        this.practice.email = this.edits.email.e_addr;
        this.showCancelModal = false;
        this.edits.email.show = false;
        break;
      case 'link':
        this.practice.url = this.edits.link.w_link;
        this.showCancelModal = false;
        this.edits.link.show = false;
        break;
      case 'numbers':
        this.practice.phones = [];
        for (let i = 0; i < this.edits.numbers.t_numbers.length; i++) {
          this.practice.phones.push({ ...this.edits.numbers.t_numbers[i] });
        }
        this.showCancelModal = false;
        this.edits.numbers.show = false;
        break;
      case 'logo':
        this.practice.logo = {
          url: this.edits.logo.logo
        };
        this.showCancelModal = false;
        this.edits.logo.show = false;
        break;
      case 'socials':
        this.practice.social_media = [];
        for (let i = 0; i < this.edits.socials.s_links.length; i++) {
          this.practice.social_media.push({ ...this.edits.socials.s_links[i] });
        }
        this.showCancelModal = false;
        this.edits.socials.show = false;
        break;
      }
    },

    // @vuese
    // Used to check if form valid
    isValid() {
      return this.$refs.observer.validate();
    },
    // @vuese
    // Used to add empty phone row to the end 
    addPhone() {
      this.practice.phones.push({ number: '', ext: '' });
    },
    // @vuese
    // Used to remove phone on click
    // @arg row index
    removePhone(index) {
      this.practice.phones.splice(index, 1);
    },
    // @vuese
    // Used to enter number, validate, and convert it
    // @arg row index
    enterNumber(index) {
      let count_numbers = 0, last_number = 0, max_numbers = 10;
      if(this.practice.phones[index].number[0] == 1)
        max_numbers += 1;

      for (let i = 0; i < this.practice.phones[index].number.length; i++) {
        if(/^\d+$/.test(this.practice.phones[index].number[i])){
          count_numbers +=1;
          if(count_numbers > max_numbers && last_number == 0)
            last_number = i;
        }          
      }

      if(last_number != 0){
        this.practice.phones[index].number = this.practice.phones[index].number.slice(0,last_number);
      }

      if(event.inputType.indexOf('deleteContent') == -1){
        if(this.validPhone(this.practice.phones[index].number)){
          this.practice.phones[index].number = new AsYouType('US').input(
            this.practice.phones[index].number
          );
          this.addPhoneError[index] = '';
        }else{
          this.addPhoneError[index] = 'Please enter valid phone number';
        }
      }
    },
    // @vuese
    // Used to change number and convert it
    changeNumber(index) {
      if (!this.validPhone(this.practice.phones[index].number)) {
        this.addPhoneError[index] = 'Please enter valid phone number';
      } else {
        this.addPhoneError[index] = '';

        this.practice.phones[index].number = new AsYouType('US').input(
          this.practice.phones[index].number
        );
      }
    },
    // @vuese 
    // Used to Capitalize string
    // @arg String value
    capitalize(val) {
      return capitalize(val);
    },
    // @vuese
    // Use to check if logo valid
    validLogo() {
      const logo = this.practice.logo;
      if ((logo.hasOwnProperty('url') && !this.practice.logo.url) || !this.practice.logo) {
        this.practiceLogoError = 'Please add practice logo';
        return false;
      }
      const fileSize = Math.round((logo.size / 1024));
      if (fileSize > 3072){
        this.practiceLogoError = 'Image upload failed, The maximum file size allowed is 3MB. Please try again.';
        return false;
      }
      return true;
    },
    // @vuese
    // Used to validate all phone numbers
    validPhones() {
      let valid = true;
      this.practice.phones.forEach((phone, idx) => {
        if (!this.validPhone(phone.number)) {
          valid = false;
          this.addPhoneError[idx] = 'Please enter valid phone number';
        }
      });
      return valid;
    },
    // @vuese
    // Used to check if socials is empty
    emptySocial() {
      return this.practice.social_media.filter(soc => soc.url != '').length == 0;
    }
  }
};
</script>