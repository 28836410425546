<template>
  <div class="text-center">
    <img 
      :src="warning" 
      alt="Warning!" 
      class="warning-icon"
    >
    <h4 
      class="modal-title"
    >
      Remove Client
    </h4>
    <p>
      Are you sure you want to remove {{ selected.length }} clients(s) from your practice?
    </p>
    <div class="bottom-cta mb-3">
      <a 
        class="btn btn-primary btn-cta"
        @click="removePatients()"
      >Remove</a>
    </div>
    <div class="bottom-cta">
      <a 
        class="btn btn-bordered w-100" 
        data-dismiss="modal"
        @click="hideModal" 
      >Cancel</a>
    </div>
  </div>
</template>

<script>
import warning from '@images/warning.png';
import { REMOVE_PATIENTS } from '@/store/patients/actions.types';

// Component to remove clients, but actualy they will be reassigned 
// to Super Admin practice
// @group Patinets
export default {
  name: 'RemovePatiensModal',
  props: {
    // @vuese
    // Array of selected users
    selected: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      sending: false,
      warning,
    };
  },
  methods: {
    // @vuese
    // Used to sent request to delete selected users
    removePatients() {
      if (this.sending) return;

      this.sending = true;
      this.$store.dispatch(REMOVE_PATIENTS, {
        'patients[ids]': this.selected.map(patient => patient.id)
      })
        .then(() => {
          this.flashSuccess(`${this.selected.length} clients have been removed`);
        })
        .catch(() => {
          this.flashError('Error happend. Please reload and try again.');
        })
        .finally(() => {
          // Tell parent component that we done with removing clients
          this.$emit('patiens-removed');
          this.sending = false;
        });
    },
    // @vuese
    // Used to hide modal
    hideModal() {
      // Close modal
      this.$emit('hide-modal-window');
    }
  }
};
</script>