<template>
  <div class="content-wrap">
    <h2>Reports</h2>

    <div
      v-if="!superAdminShowReports()"
      class="wrap-table border-table"
    >
      Please choose a practice first.
    </div>
    <div
      v-if="superAdminShowReports()"
    >
      <sales-overview />

      <sales-detail
        :practice-created-at="practiceCreatedAt"
      />

      <hr>

      <order-placed
        :practice-created-at="practiceCreatedAt"
      />

      <hr>

      <royalties-overview />

      <royalties-detail
        :practice-created-at="practiceCreatedAt"
      />

      <hr class="mt-1">

      <patients
        :practice-created-at="practiceCreatedAt"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SalesOverview from './SalesOverview';
import SalesDetail from './SalesDetail';
import OrderPlaced from './OrderPlaced';
import RoyaltiesOverview from './RoyaltiesOverview';
import RoyaltiesDetail from './RoyaltiesDetail';
import Patients from './Patients';

import ApiService from '@/services/api.service';

// @group Reports
export default {
  name: 'Reports',

  components: {
    SalesOverview,
    SalesDetail,
    OrderPlaced,
    RoyaltiesOverview,
    RoyaltiesDetail,
    Patients
  },

  props: {
    // @vuese
    // Array of all practices when Super Admin user logged in
    practices: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      practiceCreatedAt: ''
    };
  },

  computed: {
    ...mapGetters([
      'practice',
    ])
  },

  watch: {
    practice: {
      deep: true,
      handler() {
        if (this.isRole('super_admin')) {
          const practice = this.findPractice();
          this.practiceCreatedAt = practice ? practice.created_at : '';
        } else {
          this.practiceCreatedAt = this.practice.created_at;
        }
      }
    }
  },

  beforeMount() {
    if (this.isRole('super_admin')) {
      const practice = this.findPractice();
      this.practiceCreatedAt = practice ? practice.created_at : '';
    } else {
      this.practiceCreatedAt = this.practice.created_at;
    }
  },

  methods: {
    // @vuese
    // Used to check if we can show reports page.
    // If used is super admin then we check if practice selected, if yes
    // then we show report page for selected practice.
    // If it is any other used we show report page
    superAdminShowReports() {
      return this.isRole('super_admin') ? ApiService.practiceId : true;
    },
    // @vuese
    // Used to get practice object, by selected practice id
    findPractice() {
      return this.practices.find(practice => practice.id == ApiService.practiceId.replace('/', ''));
    }
  }
};
</script>