import Vue from 'vue';
import App from '@/app';

import ApiService from '@/services/api.service';
import router from '@/router';
import store from '@/store';
import AppNotifications from '@/mixins/notifications';
import AppRole from '@/mixins/role';
import Notifications from '@/views/dashboard/partials/Notifications';
import '@src/application.scss';
import { promiseFinallyPolyfill, addFromatToDate } from '@/helper';
import filters from '@/helper/filters';

promiseFinallyPolyfill();
addFromatToDate();

Vue.mixin(AppNotifications);
Vue.mixin(AppRole);

Vue.component('Notifications', Notifications);

ApiService.init();

window.onload = () => {
  const props = Object.assign({}, document.body.dataset);
  document.body.removeAttribute('data-stripe-public-key');
  document.body.removeAttribute('data-shop-url');
  document.body.removeAttribute('data-shopify-storefront-api-key');

  Vue.mixin({
    data() {
      return {
        shopUrl: props.shopUrl,
        employeeDiscount: props.employeeDiscount
      };
    }
  });

  Vue.shopifyStorefrontApiKey = props.shopifyStorefrontApiKey;

  const app = new Vue({
    router,
    store,
    created() {
      ApiService.setInterceptors.bind(this)();
    },
    render: h => h(App, { props })
  }).$mount();
  document.body.appendChild(app.$el);
};
