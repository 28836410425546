<template>
  <div class="content-wrap">
    <Notifications />
    <h2>{{ patient.first_name }} {{ patient.last_name }}</h2>
    <ul class="breadcrumb-page">
      <li class="crumb">
        <router-link
          :to="{
            name: 'Patients'
          }"
        >
          All Clients
        </router-link>
      </li>
      <li class="crumb active">
        Client: {{ patient.first_name }} {{ patient.last_name }}
      </li>
    </ul>
    <div class="row row-layout-370">
      <div class="col-xl-8">
        <PatientViewInfo
          :patient="patient"
        />
        <!-- /. Table Patient Information  -->

        <PatientViewOrder
          :patient="patient"
        />

        <PatientViewRegimen
          :patient="patient"
        />

        <PatientViewQuizResults
            :patient_id="patient.id"
            :patient="patient"
          />

        <!-- <PatientViewRegimen
          :patient="patient"
          :allowEdit="false"
          :allowDelete="false"
          :useSkinCareProfessionalRecommendation="false"
        /> -->
        <!-- /. Table Orders  -->
      </div>
      <div class="col-xl-4 aside-sticky">
        <div v-if="patient.status == 'completed' && ( this.isRole('super_admin') || (patient.practice && patient.practice.can_create_orders))" class="aside-box text-center">
          <h4 class="mb-3">
            Place Order For Patient
          </h4>
          <p>Order products in their regimen or find other products on RegimenPro<sup>®</sup> to purchase.</p>
          <router-link
            :to="{
              name: 'PatientCreateOrder',
              params: {
                id: patient.id
              }
            }"
            class="btn btn-primary"
          >
            Create Order
          </router-link>
        </div>

        <div v-if="patient.status !== 'completed'" class="aside-box text-center">
          <h4 class="mb-3">
            Continue Client Registration
          </h4>
          <!-- <p>Order products in their regimen or find other products on RegimenPro<sup>®</sup> to purchase.</p> -->
          <router-link
            :to="{
              name: 'PatientCreateRegimen',
              params: {
                id: patient.id
              }
            }"
            class="btn btn-primary"
          >
            Continue
          </router-link>
        </div>
        <div
          v-if="patient.regimen && patient.regimen.products.length > 0"
          class="text-center px-sm-3 px-2"
        >
          <h4 class="mb-3">
            Email Regimen to Client
          </h4>
          <p>Send an email to your client with all the details they need to follow their skincare routine.</p>
          <a
            href="#"
            class="action-top"
            @click.prevent="sendUserEmail"
          >
            Send email
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PatientViewInfo from './PatientViewInfo';
import PatientViewOrder from './PatientViewOrder';
import PatientViewRegimen from './PatientViewRegimen';
import PatientViewQuizResults from './PatientViewQuizResults.vue';
import { SEND_REGIMEN } from '@/store/patients/actions.types';

// @group Patinet
export default {
  name: 'PatientView',
  components: {
    PatientViewInfo,
    PatientViewOrder,
    PatientViewRegimen,
    PatientViewQuizResults
  },
  props: {
    // @vuese
    // Patinet object
    patient: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    shouldDisplayRegimen() {
      if (this.patient.regimen && this.patient.regimen.products && this.patient.regimen.products.length > 0) {
        let allProductsNotAvailable = this.patient.regimen.products.every(product => !product.available);
        return !allProductsNotAvailable;
      }
      return false;
    }
  },
  methods: {
    // @vuese
    // Used to send email with regimen to patient
    sendUserEmail() {
      this.$store.dispatch(
        SEND_REGIMEN,
        {
          id: this.patient.id,
          practiceId: this.getRequestPracticeId()
        }
      ).then(
        resp => {
          if(resp.status == 204){
            this.flashSuccess('You’ve emailed your client their regimen. It should arrive in under 5 minutes.');
          }
        }
      );
    },
    // @vuese
    // Used to get practice ID for super admin user
    getRequestPracticeId() {
      return this.isRole('super_admin') ? `/${this.patient.practice.id}` : '';
    },
  }
};
</script>