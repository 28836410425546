<template>
  <div
    :class="$route.name.toLowerCase()"
  >
    <Navigation
      v-if="!fullWidthTemplate"
      v-model="practiceId"
      @set-practices="setPractices"
    />
    <PracticeHeader
      v-if="!fullWidthTemplate"
    />
    <section>
      <div class="policy-notifications">
        <Notifications />
      </div>
      <div
        v-if="isRole('authorized_principal')"
        class="policy-content"
        v-html="policyContent"
      />
      <div
        v-if="isRole('authorized_principal')"
        class="button-agreed"
      >
        <button
          :disabled="isSigning"
          type="button"
          class="btn btn-primary agree-btn"
          @click="signPolicy"
        >
          I &nbsp;AGREE
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import Navigation from '@/views/dashboard/partials/Navigation.vue';
import PracticeHeader from '@/views/dashboard/partials/Header';
import ApiService from '@/services/api.service';
import { mapGetters } from 'vuex';
import { PolicyService } from '../../services/api.service';
import NotificationMixin from '@/mixins/notifications';

export default {
  name: 'Dashboard',
  components: {
    Navigation,
    PracticeHeader
  },
  mixins: [NotificationMixin],
  data() {
    return {
      isSigning: false,
      fullWidthTemplate: false,
      isDashboard: false,
      navOpen: false,
      practiceId: '',
      practices: [],
      policyContent: ''
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'practice'
    ])
  },
  watch: {
    '$route': {
      handler: 'routeWatcher',
      immediate: true
    },
    practiceId() {
      ApiService.setPracticeId(this.practiceId ? `/${this.practiceId}` : '');
      this.superAdminRedirect();
    },
    fullWidthTemplate() {
      if (ApiService.practiceId)
        this.practiceId = parseInt(ApiService.practiceId.replace('/', ''));
    }
  },
  beforeMount() {
    this.routeWatcher();
  },
  created() {
    this.fetchPolicy();
  },
  methods: {
    fetchPolicy() {
      return PolicyService.showPolicy().then(
        ({ data }) => {
          this.policyContent = data.content;
          this.isSigning = data.signed;
        }
      ).catch(error => {
        this.flashError('Error fetching policy:', error);
      });
    },
    signPolicy() {
      this.isSigning = true;
      return PolicyService.signPolicy().then(response => {
        this.flashSuccess(response.data.message);
      }).catch(error => {
        this.flashError('Error Signing policy:', error);
        this.isSigning = false;
      });
    },
    routeWatcher() {
      const meta = this.$route.meta;
      if (meta.fullWidth) this.fullWidthTemplate = true;
      else this.fullWidthTemplate = false;

      this.isDashboard = this.$route.path == '/dashboard';
    },
    setPractices(practices) {
      this.practices = practices;
    },
    superAdminRedirect() {
      const { name } = this.$route;
      switch(name) {
      case 'PatientView':
        this.$router.push({name: 'Patients'});
        break;
      }
    }
  }
};
</script>
