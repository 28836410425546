<template>
  <select
    v-model="value"
    :name="name"
  >
    <option
      value=""
      selected=""
    >
      {{ defaultValue }}
    </option>
    <optgroup
      v-for="(item, idx) in items"
      v-if="item.values.length"
      :key="`${item.value}-${idx}`"
      :label="item.name"
    >
      <option
        v-for="(el, elIdx) in item.values"
        :key="elIdx"
        :value="el.value"
      >
        {{ el.name }}
      </option>
    </optgroup>
    <option 
      v-for="(item, idx) in items"
      v-if="item.values.length == 0"
      :key="`${item.value}-${idx}`"
      :value="item.value"
    >
      {{ item.name }}
    </option>
  </select>
</template>

<script>
// @group Partials
export default {
  name: 'FilterSelect',

  props: {
    // @vuese
    // Items for selecet
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    // @vuese
    // Default value
    defaultValue: {
      type: String,
      default: ''
    },
    // @vuese
    // Select name to make it unique
    name: {
      type: String,
      default: 'select',
    }
  },

  data() {
    return {
      value: ''
    };
  },

  watch: {
    value() {
      this.$emit('input', this.value);
    }
  }
};
</script>