import  ApiService, {
  LoginService,
  InvitationService,
  AccountService
} from '@/services/api.service';
import {
  LOGIN,
  LOGOUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  FIRST_TIME,
  GET_USER,
  SET_PASSWORD,
  GET_USER_JWT,
  UPDATE_ACCOUNT
} from './actions.types';
import { SET_USER, CLEAR_USER } from './mutations.type';
import { saveToken, saveInvToken } from '@/services/jwt.service';
import { isRole } from '@/helper';

const state = {
  errors: null,
  user: {},
};

const getters = {
  currentUser(state) {
    return state.user;
  }
};

const actions = {
  [GET_USER](context, token) {
    return new Promise((resolve, reject) => {
      InvitationService.get(token)
        .then(resp => {
          context.commit(SET_USER, resp.data.user);
          resolve({success: true, ...resp.data });
        })
        .catch(err => reject(err));
    });
  },
  [GET_USER_JWT](context, JWT_token) {
    return new Promise((resolve, reject) => {
      InvitationService.getJWT()
        .then(resp => {
          const user = resp.data.current_user;
          if (isRole(user, 'super_admin') || isRole(user, 'group_practice_admin') || isRole(user, 'group_admin'))
            ApiService.setPrefixUrl('su/practices');
          else {
            ApiService.setPrefixUrl('practice');
            ApiService.setPracticeId('');
          }
          context.commit(SET_USER, user);
          resolve({success: true, ...resp.data });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [SET_PASSWORD](context, params) {
    return new Promise((resolve, reject) => {
      InvitationService.update(params)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => reject(err));
    });
  },
  [UPDATE_ACCOUNT](context, params) {
    return new Promise((resolve, reject) => {
      AccountService.updateAccountInformation(params)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => reject(err));
    });
  },
  [LOGIN](context, userData) {
    return new Promise((resolve, reject) => {
      LoginService.signIn(userData)
        .then(resp => {
          if (resp.status == 200) {
            const token = resp.headers.authorization.split(' ')[1];
            saveToken(token);
            ApiService.setHeader();

            const user = resp.data.user;
            if (isRole(user, 'super_admin') || isRole(user, 'group_practice_admin') || isRole(user, 'group_admin'))
              ApiService.setPrefixUrl('su/practices');
            else ApiService.setPrefixUrl('practice');

            context.commit(SET_USER, user);
            setTimeout(() =>resolve(resp));
          }
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  [LOGOUT]({ commit }) {
    return new Promise((resolve, reject) => {
      LoginService.logOut()
        .then(resp => {resolve(resp);
          commit('clearUser');
        })
        .catch(err => reject(err));
    });
  },
  [FORGOT_PASSWORD](context, userData) {
    return new Promise((resolve, reject) => {
      LoginService.forgotPassword(userData)
        .then(resp => {resolve(resp);})
        .catch(err => reject(err));
    });
  },
  [RESET_PASSWORD](context, userData) {
    return new Promise((resolve, reject) => {
      LoginService.resetPassword(userData)
        .then(resp => {resolve(resp);})
        .catch(err => reject(err));
    });
  },
  [FIRST_TIME](context, userData) {
    return new Promise((resolve, reject) => {
      LoginService.firstTime(userData)
        .then(resp => {
          if(resp.status == '200'){
            if(resp.data.role != 'practice_admin'){
              const token = resp.data.invitation_token;
              saveInvToken(token);
            }
          }
          resolve(resp);
        })
        .catch(err => reject(err));
    });
  }
};

const mutations = {
  [SET_USER](state, user) {
    if (user) {
      user.avatarPreview = user.avatar.url;
      user.avatarFilename = '';
    }
    state.user = user;
  },
  [CLEAR_USER](state) {
    state.user = {};
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};