<template>
  <header class="dashboard-header">
    <nav class="navbar fixed-top">
      <button 
        id="d_toggler"
        class="nav-toggler open" 
        @click.prevent="openNav"
      >
        <span class="nav-toggler-icon" />
      </button>
      <div class="nav-left">
        <router-link 
          :to="{
            name: 'MyPractice'
          }" 
          tag="a" 
          class="user-info"
        >
          <strong>Hello, {{ currentUser.first_name }}<span v-if="currentUser.last_name"> {{ currentUser.last_name }}</span>!</strong>
          <span 
            v-if="isRole('super_admin')" 
            class="user-tag"
          >
            Super Admin</span>
          <span 
            v-else-if="isRole('practice_admin')" 
            class="user-tag"
          >
            Practice Admin</span>
          <span 
            v-else-if="isRole('physician')" 
            class="user-tag"
          >
            Skincare Pro</span>
          <span 
            v-else-if="isRole('esthetician')" 
            class="user-tag"
          >
            Esthetician</span>
          <span 
            v-else-if="isRole('group_admin')" 
            class="user-tag"
          >
            Group Admin</span>
          <span 
            v-else-if="isRole('group_practice_admin')" 
            class="user-tag"
          >
            Group Practice Admin</span>
          <span 
            v-else 
            class="user-tag"
          >
            Staff</span>
        </router-link>
      </div>
      <div class="nav-right">
        <div class="nav-right">
          <div class="dropdown">
            <a 
              id="dropdownMenuLink"
              class="dropdown-toggle"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              My Account
            </a>
            <div 
              class="dropdown-menu" 
              aria-labelledby="dropdownMenuLink"
            >
              <router-link 
                class="dropdown-item" 
                :to="{
                  name: 'MyAccount'
                }"
              >
                Accounts Settings
              </router-link>
              <a
                class="dropdown-item hover-btn"
                @click="logout" 
              >
                Log Out
              </a>
            </div>
          </div>
          <a 
            class="help-link" 
            target="_blank" 
            href="https://regimenmd.myshopify.com/pages/contact-us"
          >
            Need Help?
          </a>
        </div>
      </div>
    </nav>
  </header>
  <!-- /. dashboard-header -->
</template>

<script>
import { mapGetters } from 'vuex';
import { destroyToken } from '../../../services/jwt.service';
import { LOGOUT } from '../../../store/login/actions.types';

// @group Partials
export default {
  name: 'Header',
  computed: {
    ...mapGetters([
      'currentUser',
      'practice'
    ])
  },
  methods: {
    // @vuese
    // Used to logout user, destroy JWT token and redirect to Login page
    logout() {
      destroyToken();
      this.$store.dispatch(LOGOUT).then(
        () => {
          document.afterLogOut = true;
          this.$router.push({name: 'Login'});
        }
      );
    },
    // @vuese
    // Used to open nav in mobile
    openNav() {
      document.querySelector('.dashboard-nav').classList.add('open-nav');
    }
  }
};
</script>