<template>
  <div class="front-wrapper">
    <Header :patient="patient" />
    <div class="frontend-main">
      <div class="container container-md-xs">
        <Notifications />
        <div class="steps-wrap">
          <div class="step passed">
            <router-link 
              class="hover-btn" 
              :to="{
                name: 'PatientCreateRegimen',
                params: {
                  id: patient.id
                }
              }"
              tag="p"
            >
              Create Regimen
            </router-link>
            <span class="step-marker" />
          </div>

          <!-- <div class="step passed">
            <router-link 
              class="hover-btn" 
              :to="{
                name: 'PatientCreateInfo',
                params: {
                  id: patient.id
                }
              }"
              tag="p"
            >
              Client Information
            </router-link>
            <span class="step-marker" />
          </div> -->

          <div class="step current">
            <p>Review</p>
            <span class="step-marker" />
          </div>
        </div>
      </div>
      <!-- /. steps-wrap -->

      <div class="container container-md-lg">
        <h3 class="mb-sm-3 mb-1">
          Review
        </h3>
        <div class="row row-layout-370 align-items-baseline">
          <div class="col-md-7">
            <div class="d-flex justify-content-between">
              <h5>Regimen</h5>
              <router-link 
                class="float-right action-top" 
                :to="{
                  name: 'PatientCreateRegimen',
                  params: {
                    id: patient.id
                  }
                }"
              >
                Change Regimen
              </router-link>
            </div>
          </div>
          <div class="col-md-5">
            <h5>Create Client's Account and Send Welcome Email</h5>
          </div>
        </div>

        <div class="row row-layout-370">
          <div class="col-md-7">
            <!-- table -->
            <div class="wrap-table order figs-box">
              <table class="table table-box">
                <thead>
                  <tr v-if="selectedProducts.length == 0">
                    <th />
                  </tr>
                  <tr v-else>
                    <th />
                    <th class="details-col">
                      Product
                    </th>
                    <th>Order Frequency</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="selectedProducts.length == 0">
                    <td class="text-center">
                      No products have been added
                    </td>
                  </tr>
                  <tr 
                    v-for="product in selectedProducts"
                    v-else
                    :key="product.id"
                  >
                    <td>
                      <figure 
                        v-if="product.image"
                        class="product-image"
                      >
                        <span class="badge-counter" />
                        <figcaption>{{ renderRecommendationType(product.recomendation_type) }}</figcaption>
                        <img 
                          :src="product.image" 
                          alt="product 1"
                        >
                      </figure>
                      <span 
                        v-if="product.sku"
                        class="img-detail"
                      >SKU: {{ product.sku }}</span>
                    </td>
                    <td>
                      <h6>{{ product.title }}</h6>
                      <p class="product-info">
                        {{ product.vendor }} 
                        <span v-if="product.variant_title != 'Default Title'">| {{ product.variant_title }}</span>
                      </p>
                      <p  
                        v-if="product.time_of_use"
                        class="when-to-apply mb-1"
                      >
                        When to apply: {{ product.time_of_use }}
                      </p>
                      <p 
                        v-if="product.usage_instruction"
                        class="usage_instruction mb-0"
                      >
                        Usage Instructions: {{ product.usage_instruction }}
                      </p>
                    </td>
                    <td>{{ product.frequency != 'One time' ? `Every ${product.frequency} Days` : product.frequency }}</td>
                    <td>${{ product.price }}</td>
                  </tr>
                  <tr 
                    v-if="selectedProducts.length"
                  >
                    <td colspan="2" />
                    <td><strong>Subtotal</strong></td>
                    <td><strong>${{ formatMoney(calcSubtotal()) }}</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- <div class="row pt-sm-3">
              <div class="col-sm-6">
                <h5>Client Information</h5>
              </div>
              <div class="col-sm-6 text-sm-right mb-sm-2 mb-3">
                <router-link 
                  class="float-right action-top" 
                  :to="{
                    name: 'PatientCreateInfo',
                    params: {
                      id: patient.id
                    }
                  }"
                >
                  Edit Client Information
                </router-link>
              </div>
            </div>

            <div class="wrap-table border-table">
              <div class="row row-group">
                <div class="col-md-4 text-up">
                  CLIENT NAME
                </div>
                <div class="col-md-8">
                  <div class="row-large">
                    <div class="col-md-9">
                      <span class>{{ patient.first_name }} {{ patient.last_name }}</span>
                    </div>
                    <div class="col-md-3 action-box" />
                  </div>
                </div>
              </div>
              <div 
                v-if="patient.physician"
                class="row row-group"
              >
                <div class="col-md-4 text-up">
                  PHYSICIAN
                </div>
                <div class="col-md-8">
                  <div class="row-large">
                    <div class="col-md-10">
                      <span class>{{ patient.physician.name }}</span>
                    </div>
                    <div class="col-md-2 action-box">
                      <a 
                        class="link_action" 
                        href="#" 
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row row-group">
                <div class="col-md-4 text-up">
                  EMAIL ADDRESS
                </div>
                <div class="col-md-8">
                  <div class="row-large">
                    <div class="col-md-10">
                      <span class>{{ patient.email }}</span>
                    </div>
                    <div class="col-md-2 action-box">
                      <a 
                        class="link_action" 
                        href="#" 
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row row-group">
                <div class="col-md-4 text-up">
                  PHONE NUMBER
                </div>
                <div class="col-md-8">
                  <div class="row-large">
                    <div 
                      v-if="patient.phone != ''" 
                      class="col-md-10"
                    >
                      <span class>{{ patient.phone }}</span>
                    </div>
                    <div 
                      v-else 
                      class="col-md-10"
                    >
                      <span class>None</span>
                    </div>
                    <div class="col-md-2 action-box">
                      <a 
                        class="link_action"
                        href="#" 
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div 
                v-if="patient.shipping_address"
                class="row row-group"
              >
                <div class="col-md-4 text-up">
                  SHIPPING ADDRESSES
                </div>
                <div class="col-md-8">
                  <div class="row-large">
                    <div class="col-md-10">
                      <span>
                        {{ bulidAddressStr(patient.shipping_address) }}
                      </span>
                    </div>
                    <div class="col-md-2 action-box">
                      <a 
                        class="link_action" 
                        href="#" 
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row row-group">
                <div class="col-md-4 text-up">
                  PAYMENT METHODS
                </div>
                <div class="col-md-8">
                  <div class="row-large">
                    <div class="col-md-10">
                      <span v-if="patient.payment_method && patient.payment_method.card_number">
                        {{ patient.payment_method.card_type }} ending in {{ patient.payment_method.card_number }}
                      </span>
                      <span v-else>None</span>
                    </div>
                    <div class="col-md-2 action-box">
                      <a 
                        class="link_action" 
                        href="#" 
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>

          <!-- side column -->
          <div class="col-md-5 aside-sticky-5">
            <div class="d-box with-lpad">
              <p>
                The client will receive a welcome email with their regimen and a link to create their
                password. You can place orders for them before they create their password.
              </p>
              <button
                id="next_step"
                class="btn btn-primary"
                :disabled="sending"
                @click="createAccount"
              >
                Create Account And Send Welcome
                Email
              </button>
            </div>
          </div>
        </div>
        <!-- /. row-layout-370 -->
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header';
import { COMPLETE_REGISTRATION } from '@/store/patients/actions.types';
import { formatMoney, bulidAddressStr, renderRecommendationType } from '@/helper';

// @group Patinet
// Compnent to show las step for patient create process
export default {
  name: 'PatientCreateReview',

  components: {
    Header,
  },

  props: {
    // @vuese
    // Patient object
    patient: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data() {
    return {
      sending: false,
      productId: null,
      selectedProducts: [],
    };
  },

  watch: {
    patient: {
      handler: 'fillData',
      immediate: true,
    }
  },

  beforeMount(){
    window.scrollTo(0,0);
  },

  methods: {
    renderRecommendationType,
    // @vuese
    // Used to complite patient registration
    createAccount() {
      this.sending = true;
      const patientId = this.$route.params.id;

      this.$store.dispatch(COMPLETE_REGISTRATION, {
        id: patientId,
        practiceId: this.getRequestPracticeId()
      })
        .then(() => {
          this.$router.push({name: 'PatientView', params: {id: patientId}});
          this.flashSuccess('You’ve created a new patient');
        })
        .catch((e) => {
          if(e.data && e.data.errors){
            this.flashWarning(e.data.errors);
          }else{
            this.flashWarning('Opps something went wrong... Please reload and try again');
          }
        })
        .finally(() => {
          this.sending = false;
        });
    },
    // @vuese
    // Used to fill local store date
    fillData() {
      const { regimen } = this.patient;
      this.patientId = this.$route.params.id;
      if (regimen && regimen.products.length) this.selectedProducts = regimen.products;
    },
    // @vuese
    // Used to convert address object to string, if object have empty  props
    // return word `None`
    // @arg address object
    bulidAddressStr(data) {
      return bulidAddressStr(data);
    },
    // @vuese
    // Calc total price of all products
    calcSubtotal() {
      return this.selectedProducts.reduce((sum, product) => {
        return sum + (product.price ? parseFloat(product.price) : 0);
      }, 0);
    },
    // @vuese
    // Used format money
    // @arg Price value
    formatMoney (val) {
      return formatMoney(val);
    },
    // @vuese
    // Used to get practice ID for super admin user
    getRequestPracticeId() {
      return this.isRole('super_admin') ? `/${this.patient.practice.id}` : '';
    }
  }
};
</script>