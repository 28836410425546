<template>
  <div class="front-wrapper">
    <header class="main-header">
      <div class="container container-md-lg">
        <div class="header-wrap wrap-lg">
          <div class="header-logo">
            <router-link 
              :to="{
                name: 'userdashboard'
              }"
            >
              <img
                :src="logo"
                alt="Logo"
              >
            </router-link>
          </div>
          <div class="header-right lg-wrap">
            <div class="row justify-content-between mx-0">
              <p>
                Patient Name:
                <strong>{{ patient.name }}</strong>
              </p>
              <p>
                Email Address:
                <strong>{{ patient.email }}</strong>
              </p>
              <p v-if="patient.physician">
                Physician Name:
                <strong>{{ patient.physician.name }}</strong>
              </p>
            </div>
            <p v-if="patient.shipping_address">
              Shipping Address:
              <strong>{{ bulidAddressStr(patient.shipping_address) }},</strong>
            </p>
            <p>
              Payment Method:
              <strong v-if="patient.payment_method && patient.payment_method.card_number">{{ patient.payment_method.card_type }} ending in {{ patient.payment_method.card_number }}</strong>
              <strong v-else>None</strong>
            </p>
          </div>
          <div class="header-right-link">
            <router-link
              :to="{
                name: 'PatientView',
                params: {
                  id: patient.id
                }
              }"
            >
              Leave Without Saving
            </router-link>
          </div>
        </div>
      </div>
    </header>

    <div class="container mt-3">
      <Notifications />
    </div>

    <div class="frontend-main">
      <div class="container container-md-lg">
        <h2>Patient's Order</h2>
        <div class="row">
          <div class="col-md-7">
            <h4 class="mb-4">
              Payment Method 
            </h4>
            <div class="d-box with-lpad pb-4">
              <div class="form form-gray">
                <div class="form-group">
                  <label for="card_number">Card Number</label>
                  <div id="card-element" />
                </div>

                <div class="row gutter-22">
                  <div class="col-lg-6 form-group">
                    <label for="card_exp">Expiration Date</label>
                    <div id="card-expiry-element" />
                  </div>
                  <div class="col-lg-6 form-group">
                    <label for="card_sc">Security Code</label>
                    <div id="card-cvc-element" />
                  </div>
                </div>

                <div class="row">
                  <p
                    v-if="paymentError"
                    class="validation error col-12"
                  >
                    {{ paymentError }}
                  </p>
                </div>

                <div class="form-check-box mb-4">
                  <input
                    id="billing_same_shipping"
                    v-model="billingSameShipping"
                    type="checkbox"
                  >
                  <label for="billing_same_shipping">Billing same as shipping address</label>
                </div>

                <ValidationObserver 
                  v-if="patient.billing_address"
                  ref="billing_address" 
                  v-slot="{ invalid }"
                  tag="form"
                >
                  <div class="row">
                    <div class="col-sm-6 form-group">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Shipping first name"
                        rules="required"
                        class="form-group"
                      >
                        <label for="sh_f_name">First Name</label>
                        <input
                          id="sh_f_name"
                          v-model="patient.billing_address.first_name"
                          type="text"
                          :class="errors.length ? 'error' : ''"
                        >
                        <p 
                          v-for="(err, errKey) in errors"
                          :key="errKey"
                          class="validation error mt-2"
                        >
                          {{ err }}
                        </p>
                      </validation-provider>
                    </div>
                    <div class="col-sm-6 form-group">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Shipping last name"
                        rules="required"
                        class="form-group"
                      >
                        <label for="sh_l_name">Last Name</label>
                        <input
                          id="sh_l_name"
                          v-model="patient.billing_address.last_name"
                          type="text"
                          :class="errors.length ? 'error' : ''"
                        >
                        <p 
                          v-for="(err, errKey) in errors"
                          :key="errKey"
                          class="validation error mt-2"
                        >
                          {{ err }}
                        </p>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Street Address"
                      rules="required"
                      class="form-group"
                    >
                      <label for="street">Street address</label>
                      <input
                        id="street"
                        v-model="patient.billing_address.address1"
                        placeholder="113 Main Stret"
                        type="text"
                        name="street"
                        :class="errors.length ? 'error' : ''"
                      >
                      <p 
                        v-for="(err, errKey) in errors"
                        :key="errKey"
                        class="validation error mt-2"
                      >
                        {{ err }}
                      </p>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <label for="suite1">Unit or suite number (optional)</label>
                    <input 
                      id="suite1"
                      v-model="patient.billing_address.address2"
                      placeholder="Suite 600"
                      type="text"
                      name="suite1"
                    >
                  </div>
                  <div class="row">
                    <div class="form-group col-sm-6">
                      <validation-provider
                        v-slot="{ errors }"
                        name="City"
                        rules="required"
                        class="form-group"
                      >
                        <label for="city">City</label>
                        <input
                          id="city"
                          v-model="patient.billing_address.city"
                          placeholder="Chicago"
                          type="text"
                          name="city"
                          :class="errors.length ? 'error' : ''"
                        >
                        <p 
                          v-for="(err, errKey) in errors"
                          :key="errKey"
                          class="validation error mt-2"
                        >
                          {{ err }}
                        </p>
                      </validation-provider>
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="state1">State</label>
                      <StateSelect 
                        id="state1"
                        v-model="patient.billing_address.state"
                        class="select-lg col" 
                        @change="validZip('billing_address')"
                      />
                    </div>
                    <div class="form-group col-sm-6">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Zip code"
                        rules="required"
                        class="form-group"
                      >
                        <label for="zip1">Zip code</label>
                        <input
                          id="zip1"
                          v-model="patient.billing_address.zip"
                          placeholder="12345"
                          type="text"
                          name="zip1"
                          :class="errors.length || billingZipMessage ? 'error' : ''"
                          @input="validZip('billing_address')" 
                        >
                        <p 
                          v-for="(err, errKey) in errors"
                          :key="errKey"
                          class="validation error mt-2"
                        >
                          {{ err }}
                        </p>
                        <p
                          v-if="billingZipMessage"
                          class="validation error mt-2"
                        >
                          {{ billingZipMessage }}
                        </p>
                      </validation-provider>
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="country1">Country</label>
                      <input
                        id="country1"
                        placeholder="United States"
                        readonly
                        value="United States"
                        type="text"
                        name="ext"
                      >
                    </div>
                    <div class="form-group col-sm-6 mb-0">
                      <div class="head-tooltip form-group">
                        <label for="phonen">
                          Phone Number
                          <i 
                            class="fas fa-question-circle" 
                            @mouseover="hover('b_phone')" 
                            @mouseleave="hoverOff('b_phone')"
                          />
                        </label>
                        <div 
                          data-tooltip-type="b_phone" 
                          class="tooltip-black"
                        >
                          Phone numbers are used by delivery drivers to reach out if they have delivery issues.
                        </div>
                      </div>
                      
                      <input
                        id="phonen"
                        v-model="patient.billing_address.phone"
                        placeholder="(555) 555 - 5555"
                        type="tel"
                        name="phonen"
                        @input="enterNumber(phoneTypes.billing)" 
                      >
                      <p 
                        v-for="(err, errKey) in billingPhoneErrors"
                        :key="errKey"
                        class="validation error mt-2"
                      >
                        {{ err }}
                      </p>
                    </div>
                  </div>
                </ValidationObserver>
              </div>
            </div>
          </div>

          <div class="col-md-5">
            <h4 class="mb-4">
              Order Review
            </h4>
            <div class="wrap-table border-table">
              <div 
                v-if="lineItems.length == 0"
                class="added-products"
              >
                <p>Please add products</p>
              </div>
              <div 
                v-for="lineItem in lineItems"
                :key="lineItem.id"
                class="row row-group"
              >
                <div class="col-10 pr-0">
                  <div class="row-large">
                    <div class="col-lg-3">
                      <img
                        v-if="lineItem.image"
                        :src="lineItem.image"
                        alt="Product 1"
                      >
                    </div>
                    <div class="col-lg-9 product-actions">
                      <p class="mb-1">
                        <strong v-html="lineItem.title" />
                      </p>
                      <p class="text-soft mb-1">
                        {{ lineItem.vendor }}
                        <span class="pl-3">${{ formatMoney(calcLineItemPrice(lineItem)) }}</span>
                      </p>
                      <p
                        v-if="lineItem.frequency"
                        class="text-soft mb-1"
                      >
                        Frequency:
                        <span class="pl-1">
                          {{ lineItem.frequency != 'One time' ? `Every ${lineItem.frequency} Days` : lineItem.frequency }}
                        </span>
                      </p>
                      <p 
                        :class="!lineItem.changeQty ? 'mb- quanty-action' : 'mb-0 quanty-action d-none'"
                      >
                        <span class="text-soft">Quantity: <span>{{ lineItem.quantity }}</span></span>
                      </p>
                    </div>
                  </div>
                </div>
                <status-preloader :stage="lineItem.stage" />
              </div>
              <div class="row py-3 text-right">
                <div class="col-lg-9 col-6">
                  <strong>Subtotal</strong>
                </div>
                <div class="col-lg-3 col-6">
                  <strong>${{ lineItems.length > 0 ? formatMoney(calcSubtotal()) : '0' }}</strong>
                </div>
              </div>
              <div class="pt-1">
                <button
                  v-if="!sending"
                  class="btn btn-primary"
                  @click.prevent="placeOrder"
                >
                  Place Order
                </button>
                <button
                  v-else
                  class="btn btn-primary"
                  disabled
                >
                  Placing Order...
                </button>
              </div>
              <div
                v-if="checkoutError"
                class="message warning"
              >
                <p class="icon">
                  {{ checkoutError }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- /. row-create-account -->
      </div>
    </div>
    <!--/. frontend-main -->
  </div>
  <!--/. frontend-wrap -->
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import logo from '@images/logo-light.png';
import {
  formatMoney,
  bulidAddressStr,
  validPhone
} from '@/helper';
import { AsYouType } from 'libphonenumber-js';

import { CheckoutService, RegimenService } from '@/services/api.service';
import stripe from '@/mixins/stripe';
import StatusPreloader from '@/views/components/StatusPreloader';
import {
  UPDATE_PATIENT_BILLING_ADDRESS,
  ADD_PATIENT_BILLING_ADDRESS
} from '@/store/patients/actions.types';

import patientStateValidation from '@/mixins/patientStateValidation';
import StateSelect from '@/views/components/StateSelect';
import googleAutocompleate from '@/mixins/googleAutocompleate';

// @group Patinet
export default {
  name: 'PatientConfirmOrderCharge',

  components: {
    ValidationProvider,
    ValidationObserver,
    StatusPreloader,
    StateSelect,
  },

  mixins: [stripe, patientStateValidation, googleAutocompleate],

  props: {
    patient: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    },
    selectedProducts: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  data() {
    return {
      sending: false,
      skipConfirm: false,
      logo,
      patientId: null,
      checkoutError: '',
      lineItems: [],
      billingPhoneErrors: '',
      billingError: false,
      billingSameShipping: false,
      paymentError: '',
      phoneTypes: {
        patient: 'patient',
        billing: 'billing',
      },
      updateBilling: false
    };
  },

  watch: {
    billingSameShipping() {
      if (this.billingSameShipping)
        this.patient.billing_address = {...this.patient.shipping_address};
    },

    'patient.billing_address': {
      deep: true,
      handler() {
        this.updateBilling = true;
      }
    }
  },

  beforeMount() {
    this.shippingComponent.forEach(el => {
      if (el.element == 'state')
        el.type.administrative_area_level_1 = 'long_name';
    });
    this.$nextTick(() => {
      if (this.patient.billing_address) {
        setTimeout(() => {
          this.initAutocomplete('street', this.shippingComponent, 'patient.billing_address');
        });
      }
    });
  },

  mounted() {
    if(this.hasNoLineItems()) {
      this.skipConfirm = true;
      this.$router.push({ name: 'PatientCreateOrder' });
    }
    this.lineItems = this.buildLineItems();

    if (this.patient && !this.patient.phone) {
      this.patient.phone = this.getPhone();
    }

    if (this.patient.billing_address && !this.patient.billing_address.phone) {
      this.patient.billing_address.phone = this.getPhone();
    }

    if (this.patient.shipping_address && this.patient.shipping_address.phone) {
      this.patient.shipping_address.phone = this.getPhone();
    }
  },

  methods: {
    // @vuese
    // Used to convert address object to string, if object have empty  props
    // return word `None`
    // @arg address object
    bulidAddressStr,
    // @vuese
    // Used to validate phone
    // @arg Phone value
    validPhone,

    // @vuese
    // Used to fill phone in shipping, billing address and to patient object if
    // value empty
    getPhone() {
      let phone;
      if (this.patient && this.patient.phone)
        phone = this.patient.phone;
      if (this.patient.shipping_address && this.patient.shipping_address.phone)
        phone = this.patient.shipping_address.phone;
      if (this.patient.billing_address && this.patient.billing_address.phone)
        phone = this.patient.billing_address.phone;
      return phone;
    },

    // @vuese
    // Used to check if billing address valid
    async billingValid() {
      return await this.$refs.billing_address.validate() &&
        this.billingPhoneErrors.length == 0;
    },
    // @vuese
    // Used to save billing address
    async saveBilling() {
      const patientId = this.$route.params.id;
      if (await this.billingValid()) {
        if(this.updateBilling) {
          let handler = UPDATE_PATIENT_BILLING_ADDRESS;
          if (!this.patient.billing_address.id) {
            handler = ADD_PATIENT_BILLING_ADDRESS;
          }

          await this.$store.dispatch(handler, {
            id: patientId,
            billing_address: this.patient.billing_address,
            practiceId: this.getRequestPracticeId()
          }).then(() => {
            this.billingError = false;
          }).catch(err => {
            this.billingError = true;
            if (err.data.errors && err.data.errors.phone)
              this.billingPhoneErrors = err.data.errors.phone;
            else {
              this.flashError('Error updating billing address');
              
            }
          });
        }
      } else {
        this.billingError = true;
      }
    },
    // @vuese
    // Used to prepare line items
    buildLineItems() {
      return this.selectedProducts.map(product => Object.assign({}, product, { stage: '' }));
    },
    // @vuese
    // Used to check we have at least one product
    hasNoLineItems() {
      return this.selectedProducts.length === 0;
    },
    // @vuese
    // Used to calculate price of all line items
    calcLineItemPrice(lineItem) {
      return parseFloat(lineItem.price) * lineItem.quantity;
    },

    // @vuese
    // Calc total price of all products
    calcSubtotal() {
      return this.lineItems.reduce((sum, lineItem) => {
        return sum + this.calcLineItemPrice(lineItem);
      }, 0);
    },

    // @vuese
    // Used format money
    // @arg Price value
    formatMoney(val) {
      return formatMoney(val);
    },

    setStage(lineItem, stage) {
      let currentLineItem = this.lineItems.filter(item => item.variant_id.toString() === lineItem.variant_id.toString())[0];
      if(typeof currentLineItem !== 'undefined') currentLineItem.stage = stage;
    },
    // @vuese
    // Used to enter number, validate, and convert it
    // @arg row index
    enterNumber(type) {
      let number = '';
      if (type == this.phoneTypes.patient) {
        number = this.patient.phone;
      } else if (type == this.phoneTypes.billing) {
        number = this.patient.billing_address.phone;
      }
      if (!this.validPhone(number)) {
        if (type == this.phoneTypes.patient) {
          this.phoneErrors = ['Please enter valid phone number'];
        } else if (type == this.phoneTypes.billing) {
          this.billingPhoneErrors = ['Please enter valid phone number'];
        }
      } else {
        if (type == this.phoneTypes.patient) {
          this.phoneErrors = [];
        } else if (type == this.phoneTypes.billing) {
          this.billingPhoneErrors = [];
        }
      }

      let count_numbers = 0, last_number = 0, max_numbers = 10;
      if(number[0] == 1)
        max_numbers += 1;

      for (let i = 0; i < number.length; i++) {
        if(/^\d+$/.test(number[i])){
          count_numbers +=1;
          if(count_numbers > max_numbers && last_number == 0)
            last_number = i;
        }
      }

      if(last_number != 0){
        number = number.slice(0,last_number);
      }

      number = new AsYouType('US').input(number);

      if(event.inputType && event.inputType.indexOf('deleteContent') == -1){
        if (type == this.phoneTypes.patient) {
          this.patient.phone = number;
        } else if (type == this.phoneTypes.billing) {
          this.patient.billing_address.phone = number;
        }
      }
    },

    buildCheckout() {
      let checkoutObj = {};
      checkoutObj.line_items = this.lineItems.filter(item => item.stage === '' || item.stage === 'error').map((product) => {
        product.stage = 'loading';
        const lineItem = {};
        lineItem.variant_id = product.variant_id;
        lineItem.quantity = product.quantity;
        if (product.frequency && product.frequency.toLowerCase() != 'one time') {
          lineItem.order_interval_frequency = product.frequency;
          lineItem.charge_interval_frequency = product.frequency;
          lineItem.order_interval_unit = 'day';
        }
        return lineItem;
      });
      return checkoutObj;
    },

    async placeOrderItem(lineItem) {
      return await CheckoutService.process({
        checkout: { line_items: [lineItem] }
      }, this.patient.id, this.getRequestPracticeId());
    },

    ordersExecutor(callsQueueOrder) {
      let next = callsQueueOrder.next();
      if (!next.done) {
        if(next.value[1] !== 'completed') {
          next.value[0].then(
            ({ data }) => {
              this.setStage(next.value[1], 'success');
              this.ordersExecutor(callsQueueOrder);
            },
            ({ response }) => {
              this.setStage(next.value[1], 'error');
              this.ordersExecutor(callsQueueOrder);
            }
          );
        }
      }
    },

    redirectToPatientPage() {
      this.skipConfirm = true;
      this.flashSuccess('Order successfully placed!');
      this.$router.push({
        name: 'PatientView',
        params: {
          id: this.patient.id
        }
      });
    },

    async placeOrder() {
      if (this.billingSameShipping)
        this.patient.billing_address = {...this.patient.billing_address, ...this.patient.shipping_address};

      if(this.sending) return;
      this.sending = true;
      const patientId = this.$route.params.id;
      let error = false;

      if (!this.patient.payment_method && !this.shouldCreateToken) {
        this.paymentError = 'Please add payments';
        error = true;
      } else {
        this.paymentError = '';
      }

      await this.saveBilling();
      if (this.billingError) {
        error = true;
      }

      if (this.shouldCreateToken) {
        // await this.createToken();
        await this.createPaymentMethod();
        if (this.cardError) {
          error = true;
          this.sending = false;
          return;
        }
        await this.saveToken(patientId);
      }

      if (error) {
        this.sending = false;
        return;
      }

      const checkoutObj = this.buildCheckout();

      let callsQueue = function* () {
        for(var lineItem of checkoutObj.line_items) {
          yield [this.placeOrderItem(lineItem), lineItem];
        }
        yield [this.processOrder().then(this.completeOrder, this.completeOrder), 'completed'];
      }.bind(this);

      this.ordersExecutor(
        callsQueue()
      );
    },

    processOrder() {
      return CheckoutService.complete(
        this.patient.id, this.getRequestPracticeId()
      );
    },

    completeOrder(data) {
      this.sending = false;
      if(this.lineItems.filter(item => item.stage === 'error').length > 0) {
        const answer = window.confirm('Some products handled with errors. Retry these products?');
        if(answer) {
          // this.placeOrder();
          return;
        } else {
          this.redirectToPatientPage();
        }
      } else {
        this.handleProducts(this.patient.regimen.id);
      }
    },
    // @vuese
    // Used to go through all selected products in regimen
    // and call update or create product function
    // @arg Regimen ID
    async handleProducts(regimenId) {
      const productHandles = [];
      const products = this.lineItems.filter(prod => !prod.id);

      for (let i = 0; i < products.length; i++) {
        let product = products[i];
        productHandles.push(await this.creteProduct(product, regimenId));
      }

      Promise.all(productHandles)
        .finally(() => {
          this.redirectToPatientPage();
        });
    },

    async creteProduct(product, regimenId) {
      return await RegimenService.addProduct({
        product: product,
      }, regimenId, this.getRequestPracticeId());
    },

    // @vuese
    // Used to get practice ID for super admin user
    getRequestPracticeId() {
      return this.isRole('super_admin') ? `/${this.patient.practice.id}` : '';
    },

    // @vuese
    // Used to show tooltip
    // @arg HTML element data type
    hover(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.add('open');
    },

    // @vuese
    // Used to hide tooltip
    // @arg HTML element data type
    hoverOff(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.remove('open');
    },

    beforeLeaveMessage() {
      if(this.sending)
        return 'Order processing started, please wait. If you leave the order can be processed not completely. Leave?';
      else
        return 'Leave without placing order?';
    }
  },

  beforeRouteLeave(to, from, next) {
    const answer = this.skipConfirm || window.confirm(this.beforeLeaveMessage());
    if(answer)
      next();
    else
      next(false);
  }
};
</script>

<style lang="scss" scoped>
  .btn-primary:disabled {
    cursor: not-allowed;
  }
</style>
