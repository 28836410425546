import { usStates, isZipValidForState } from '@/helper';

export default {
  data() {
    return {
      usStates,
      billingZipMessage: '',
      shippingZipMessage: '',
    };
  },
  methods: {
    // @vuese
    // Used to validate zip by type
    // @arg Type of zip
    validZip(type) {
      const { valid, message } = this.validateZip(
        this.patient[type].zip, 
        this.patient[type].state 
      );
      if (type == 'shipping_address')
        this.shippingZipMessage = message;
      else if (type == 'billing_address')
        this.billingZipMessage = message;
      return valid;
    },
    // @vuese
    // Used to validate zip for state
    // @arg Zip code
    // @arg State name
    validateZip(zipCode, stateName) {
      let valid = false,
        message = '';
      if (!stateName) {
        message = 'Please select state first';
        valid = false;
      } else if (!isZipValidForState(zipCode, this.findState(stateName).abbreviation)) {
        message = `Please enter valid zip for ${this.findState(stateName).name}`;
        valid = false;
      } else {
        valid = true;
      }
      return { valid, message };
    },
    // @vuese
    // Used to find state
    // @arg State name
    findState(stateName) {
      return this.usStates.find(state => state.name == stateName);
    },
  }
};