var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-table"},[_c('table',{staticClass:"table orders-table table-box table-striped"},[_c('thead',[_c('tr',[_c('th',{class:_vm.getSortClass('created_at'),on:{"click":function($event){return _vm.setSorting('created_at')}}},[_vm._v("\n          Order Placed\n        ")]),_vm._v(" "),_c('th',{class:_vm.getSortClass('number'),on:{"click":function($event){return _vm.setSorting('number')}}},[_vm._v("\n          Order Number\n        ")]),_vm._v(" "),_c('th',{class:_vm.getSortClass('patient_name'),on:{"click":function($event){return _vm.setSorting('patient_name')}}},[_vm._v("\n          Client\n        ")]),_vm._v(" "),_c('th',{class:_vm.getSortClass('physician_name'),on:{"click":function($event){return _vm.setSorting('physician_name')}}},[_vm._v("\n          Skincare Pro\n        ")]),_vm._v(" "),_c('th',{class:_vm.getSortClass('total_price'),on:{"click":function($event){return _vm.setSorting('total_price')}}},[_vm._v("\n          Total\n        ")]),_vm._v(" "),_c('th')])]),_vm._v(" "),_c('tbody',_vm._l((_vm.orders),function(order,index){return _c('tr',{key:index},[_c('td',[(order.shopify)?_c('span',{staticClass:"text-bold"},[_vm._v("\n            "+_vm._s(order.shopify.created_at)+"\n          ")]):_vm._e()]),_vm._v(" "),_c('td',[(order.status === 'completed' && order.shopify)?_c('router-link',{staticClass:"link_action",attrs:{"to":{
              name: 'ShowOrder',
              params: {
                id: order.id
              }
            }}},[_vm._v("\n            #"+_vm._s(order.shopify.order_number)+"\n          ")]):_c('span',[_vm._v("-")])],1),_vm._v(" "),_c('td',[(order.patient)?_c('span',[_vm._v("\n            "+_vm._s(order.patient.name)+"\n          ")]):_vm._e()]),_vm._v(" "),_c('td',[(order.physician)?_c('span',[_vm._v("\n            "+_vm._s(order.physician.name)+"\n          ")]):_vm._e()]),_vm._v(" "),_c('td',[_c('span',[_vm._v(_vm._s(order.total_price))])]),_vm._v(" "),_c('td',[_c('router-link',{staticClass:"link_action",attrs:{"to":{
              name: 'ShowOrder',
              params: {
                id: order.id
              }
            }}},[(order.status === 'completed' && order.shopify !== null)?_c('span',[_vm._v("\n              View\n            ")]):(order.status === 'failed')?_c('span',[_vm._v("\n              Failed\n            ")]):_c('div',{staticClass:"head-tooltip"},[_c('span',{on:{"mouseover":function($event){return _vm.hover(("order-view-" + (order.id)))},"mouseleave":function($event){return _vm.hoverOff(("order-view-" + (order.id)))}}},[_vm._v("\n                Confirmed\n                "),_c('i',{staticClass:"fas fa-question-circle fa-xs"})]),_vm._v(" "),_c('div',{staticClass:"tooltip-black order-tooltip",attrs:{"data-tooltip-type":("order-view-" + (order.id))}},[_vm._v("\n                Order details will be available once the manufacturer receives the order. Any additional orders placed today for this client will show under the same order number.\n              ")])])])],1)])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }