<template>
  <select
    :name="name"
    @change="change"
  >
    <option value="">
      Select a State
    </option>
    <option
      v-for="(state, index) in usStates"
      :key="index"
      :selected="value === state.name"
      :value="state.name"
    >
      {{ state.name }}
    </option>
  </select>
</template>

<script>
import { usStates } from '@/helper';
// @group Components
export default {
  name: 'StateSelect',
  props: {
    // @vuese
    // Select value
    value: {
      type: String,
      default: '',
    },
    // @vuese
    // Select name to make it unique
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      usStates
    };
  },
  methods: {
    // @vuese
    // Used to emit `change` and `input` events to parent
    change (e) {
      this.$emit('input', e.target.value);
      this.$emit('change', e.target.value);
    }
  }
};
</script>