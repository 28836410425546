var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrap"},[_c('Notifications'),_vm._v(" "),_c('h2',[_vm._v("Clients")]),_vm._v(" "),_c('PatientsFilter',{on:{"filter-physician":_vm.filterPhysician,"filter-status":_vm.filterStatus,"filter-search":_vm.filterSearch}}),_vm._v(" "),_c('hr'),_vm._v(" "),_c('Toolbar',{key:_vm.toolbarKey,attrs:{"select-all":_vm.selectAll,"selected-count":_vm.selected.length,"download-orders-csv":_vm.downloadOrdersCsv,"load-csv":_vm.loadCSV},on:{"show-add-patient":function($event){return _vm.showModal('add')},"show-remove-patient":function($event){return _vm.showModal('remove')},"show-reassign-patient":function($event){return _vm.showModal('reassign')}}}),_vm._v(" "),_c('table',{staticClass:"table table-box table-striped patient-table"},[_c('thead',[_c('tr',[_c('th'),_vm._v(" "),_c('th',{class:_vm.orders[_vm.orderTypes.name] ? 
            ("sort-" + (_vm.orders[_vm.orderTypes.name])) : 
            'sortable',on:{"click":function($event){return _vm.changeOrder(_vm.orderTypes.name)}}},[_vm._v("\n          Client Name\n        ")]),_vm._v(" "),_c('th',{class:_vm.orders[_vm.orderTypes.email] ? 
            ("sort-" + (_vm.orders[_vm.orderTypes.email])) : 
            'sortable',on:{"click":function($event){return _vm.changeOrder(_vm.orderTypes.email)}}},[_vm._v("\n          Email Address\n        ")]),_vm._v(" "),_c('th',{class:_vm.orders[_vm.orderTypes.physician] ? 
            ("sort-" + (_vm.orders[_vm.orderTypes.physician])) : 
            'sortable',on:{"click":function($event){return _vm.changeOrder(_vm.orderTypes.physician)}}},[_vm._v("\n          Skincare Pro\n        ")]),_vm._v(" "),(_vm.isRole('super_admin'))?_c('th',[_vm._v("\n          Practice\n        ")]):_vm._e(),_vm._v(" "),_c('th',{class:_vm.orders[_vm.orderTypes.date] ?
            ("sort-" + (_vm.orders[_vm.orderTypes.date])) :
            'sortable',on:{"click":function($event){return _vm.changeOrder(_vm.orderTypes.date)}}},[_vm._v("\n          Date Added\n        ")]),_vm._v(" "),_c('th',{class:_vm.orders[_vm.orderTypes.status] ? 
            ("sort-" + (_vm.orders[_vm.orderTypes.status])) : 
            'sortable',on:{"click":function($event){return _vm.changeOrder(_vm.orderTypes.status)}}},[_vm._v("\n          Status\n        ")]),_vm._v(" "),_c('th')])]),_vm._v(" "),_c('tbody',_vm._l((_vm.patients.items),function(patient){return _c('router-link',{key:patient.id,staticClass:"hover-btn",attrs:{"to":patient.status == 'pending' ? 
            {
              name: 'PatientCreateRegimen',
              params: {
                id: patient.id
              }
            }
            :
            {
              name: 'PatientView',
              params: {
                id: patient.id
              }
            },"tag":"tr"}},[_c('td',{on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"box-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(patient.selected),expression:"patient.selected"}],attrs:{"id":("check-" + (patient.id)),"name":"check","type":"checkbox"},domProps:{"checked":Array.isArray(patient.selected)?_vm._i(patient.selected,null)>-1:(patient.selected)},on:{"change":[function($event){var $$a=patient.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(patient, "selected", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(patient, "selected", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(patient, "selected", $$c)}},_vm.countSelected]}}),_vm._v(" "),_c('label',{staticClass:"check-label",attrs:{"for":("check-" + (patient.id))}})])]),_vm._v(" "),_c('td',[_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(patient.name))])]),_vm._v(" "),_c('td',[_vm._v(_vm._s(patient.email))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(patient.physician && patient.physician.name ? patient.physician.name : ''))]),_vm._v(" "),(_vm.isRole('super_admin'))?_c('td',[_vm._v("\n          "+_vm._s(_vm.getPracticeName(patient))+"\n        ")]):_vm._e(),_vm._v(" "),_c('td',[_vm._v(_vm._s(patient.date_added))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.capitalize(patient.status)))]),_vm._v(" "),_c('td',{attrs:{"align":"right"}},[(patient.status == 'completed')?_c('router-link',{staticClass:"link link_action",attrs:{"to":{
              name: 'PatientView',
              params: {
                id: patient.id
              }
            },"tag":"a"}},[_vm._v("\n            View\n          ")]):_vm._e(),_vm._v(" "),(patient.status == 'pending')?_c('router-link',{staticClass:"link link_action",attrs:{"to":{
              name: 'PatientCreateRegimen',
              params: {
                id: patient.id
              }
            }}},[_vm._v("\n            Continue\n          ")]):_vm._e()],1)])}),1)]),_vm._v(" "),_c('Pagination',{attrs:{"total-rows":_vm.patients.total_items,"total-pages":_vm.patients.total_pages,"current-page":_vm.currentPage,"per-page":_vm.perPage},on:{"change-per-page":_vm.changePerPage,"change-page":_vm.changePage}}),_vm._v(" "),_c('Modal',{attrs:{"show-modal":_vm.showAddModal,"modal-content-class":"overflow-visible","modal-id":"add_patients"},on:{"hide-modal-window":function($event){return _vm.hideModal('add')}}},[_c('AddPatientModal',{attrs:{"show-modal":_vm.showAddModal,"allow-filter-by-practice":false},on:{"add-patient":function($event){return _vm.hideModal('add')},"hide-modal-window":function($event){return _vm.hideModal('add')}}})],1),_vm._v(" "),_c('Modal',{attrs:{"show-modal":_vm.showRemoveModal,"modal-id":"remove_patients"},on:{"hide-modal-window":function($event){return _vm.hideModal('remove')}}},[_c('RemovePatientsModal',{key:_vm.toolbarKey,attrs:{"selected":_vm.selected},on:{"patiens-removed":_vm.patientsRemoved,"hide-modal-window":function($event){return _vm.hideModal('remove')}}})],1),_vm._v(" "),_c('Modal',{attrs:{"show-modal":_vm.showReassignModal,"modal-id":"reassign_patients","modal-content-class":"overflow-visible"},on:{"hide-modal-window":function($event){return _vm.hideModal('reassign')}}},[_c('ReassignPatiensModal',{key:_vm.toolbarKey,attrs:{"selected":_vm.selected},on:{"patiens-reassigned":_vm.patientsReassigned,"hide-modal-window":function($event){return _vm.hideModal('reassign')}}})],1),_vm._v(" "),(_vm.showAddModal || _vm.showRemoveModal || _vm.showReassignModal)?_c('div',{staticClass:"modal-backdrop fade show"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }