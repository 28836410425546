export const patient = {
  id: '',
  first_name: '',
  last_name: '',
  phone:'',
  email: '',
  user_id: '',
  emailErrors: [],
  selected: false,
  shipping_address: null,
};

export const address = {
  address1: '',
  address2: '',
  city: '',
  first_name: '',
  last_name: '',
  country: 'United States',
  phone: '',
  state: '',
  type: 'default',
  zip: '',
};