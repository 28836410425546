<template>
  <div 
    :class="showAsideLinks ? 'col-12 col-xl-9' : 'col-12'"
  >
    <h4>
      Recently Added Clients &nbsp;
      <router-link 
        :to="{
          name: 'Patients'
        }" 
        tag="a" 
        class="action-top float-sm-right"
      >
        see all
      </router-link>
    </h4>
    <div class="content-table">
      <table 
        v-if="patients && patients.items.length" 
        class="table table-box table-striped"
      >
        <thead>
          <tr>
            <th>Names</th>
            <th>Skincare Pro</th>
            <th>Status</th>
            <th>Added</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="patient in patients.items" 
            :key="patient.id"
          >
            <td>{{ patient.name }}</td>
            <td>
              <span v-if="patient.physician">{{ patient.physician.name }}</span>
            </td>
            <td>
              <span class="status_field">{{ patient.status }}</span>
            </td>
            <td>
              <span>{{ patient.date_added }}</span>
            </td>
            <td>
              <router-link 
                v-if="patient.status == 'completed'" 
                :to="{
                  name: 'PatientView',
                  params: {
                    id: patient.id
                  }
                }" 
                tag="a"
                class="btn btn-primary" 
                href
              >
                View
              </router-link>
              <router-link 
                v-if="patient.status == 'pending'" 
                :to="{
                  name: 'PatientCreateRegimen',
                  params: {
                    id: patient.id
                  }
                }" 
                class="btn btn-primary" 
                href
              >
                Continue
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <div 
        v-else 
        class="wrap-table border-table"
      >
        <div class="text-center">
          No clients have been added
        </div>
      </div>
    </div>
  </div>
  <!-- /. Table box -->
</template>
<style scoped>
.status_field{
  text-transform: capitalize;
}
</style>
<script>
import { mapGetters } from 'vuex';
import { GET_PATIENTS } from '@/store/patients/actions.types';
import { encodeQueryData } from '@/helper';

// Component to show patients on dashboard
// @group Dashboard main
export default {
  name: 'AddedPatients',

  props: {
    // Prop that tell do we show aside link component
    showAsideLinks: {
      type: [String, Boolean],
      default: false,
    }
  },

  data() {
    return {
      sending: false,
      selected: [],
      currentPage: 1,
      perPage: 7,
      orders: {
        date_added: 'desc',
      },
      filters: {},
      search: '',
      orderTypes: {
        name: 'name',
        email: 'email',
        physician: 'physician',
        date: 'date_added',
        status: 'status',
      }
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'practice', 'patients'])
  },
  beforeMount() {
    this.loadPatients(this.buildPatientsParams());
  },
  methods: {
    // @vuese 
    // Used to load patients
    // @arg patients query params
    loadPatients(params) {
      if (this.sending) return;

      this.selected = [];
      this.sending = true;

      return this.$store.dispatch(GET_PATIENTS, encodeQueryData(params))
        .then(
          resp => {
            resp.data.patients.items.forEach(function(el){
              if(!el.physician){
                el.physician = {name: 'N/A'};
              }
            });
          }
        )
        .finally(() => {
          this.sending = false;
        });
    },
    // @vuese
    // Used to prepare query params to load patients
    buildPatientsParams() {
      return {
        per: this.perPage,
        orders:  this.orders,
        page: this.currentPage
      };
    }
  }
};
</script>