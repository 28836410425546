<template>
  <div class="row tbl-toolbar">
    <div class="col-md-7 toolbar-l">
      <div class="tolbar-l-content">
        <div class="toolbar-controls">
          <input
            id="all"
            type="checkbox"
            :checked="selectedCount"
            @change="toggleSelectAll"
          >
          <label for="all">{{ selectedCount ? `${selectedCount} Selected` : 'Select All' }}</label>
        </div>
        <span v-if="selectedCount">|</span>
        <a
          v-if="selectedCount"
          class="hover-btn"
          data-toggle="modal"
          data-target="#reassign_patients"
          @click="showReassignPatient"
        >
          <i class="fas fa-user-edit" /> Reassign Skincare Pro
        </a>
        <a 
          v-if="selectedCount"
          data-toggle="modal"
          class="hover-btn"
          data-target="#remove_patients"
          @click="showRemovePatient"
        >
          <i class="cicle-icon fas fa-minus" /> Remove Client(s)
        </a>
      </div>
    </div>

    <div class="col-md-5 toolbar-r">
      <a
        v-if="!isRole('group_admin')"
        class="hover-btn"
        data-toggle="modal"
        data-target="#add_patients"
        @click="showAddPatient"
      >
        <i class="cicle-icon fas fa-plus" /> Add New Client
      </a>
      {{ isRole('group_admin') ? "" : "|" }}
      <a
        href="/api/practice/patients.csv"
        @click.prevent="downloadOrdersCsv"
      >
        <img
          v-if="!loadCsv"
          :src="exportIcon" 
          alt="export"
        > 
        <div
          v-show="loadCsv"
          class="preloader"
        >
          <i />
        </div>
        Export .CSV
      </a>
    </div>
  </div>
</template>


<style lang="scss" scoped>
  .tbl-toolbar .toolbar-r .preloader {
    display: inline-flex;
    position: static;
    background: transparent;
    vertical-align: middle;
    padding-top: 0;
    i {
      width: 15px;
      height: 15px;
      border-width: 2px;
      border-top-color: transparent;
      border-bottom-color: transparent;
    }
  }
</style>

<script>
import exportIcon from '../../../../images/export-icon.png';

import { mapGetters } from 'vuex';

// @group Patinets
export default {
  name: 'Toolbar',
  props:{
    // Function to select all users
    selectAll: {
      type: Function,
      // Default is empty function
      default() {
        return () => {};
      }
    },
    // Number of selected users
    selectedCount: {
      type: Number,
      // Default value is: `0`
      default: 0
    },
    // Used to get users by current selection and download CSV
    downloadOrdersCsv: {
      type: Function,
      // Default is empty function
      default() {
        return () => {};
      }
    },
    loadCsv: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      exportIcon,
      showAddPatientModal: false,
    };
  },
  computed: {
    ...mapGetters([
      'physicians',
      'patients'
    ])
  },
  methods: {
    // @vuese 
    // Function to show add patient modal in parent component
    showAddPatient() {
      // Trigger to show add patient modal
      this.$emit('show-add-patient');
    },
    // @vuese 
    // Function to show remove patient modal in parent component
    showRemovePatient() {
      // Trigger to show remove patient modal
      this.$emit('show-remove-patient');
    },
    // @vuese
    // Function to show reassign patient modal in parent component
    showReassignPatient() {
      // Trigger to show reassign patient modal
      this.$emit('show-reassign-patient');
    },
    // @vuese
    // Used to toggle all patients selection
    toggleSelectAll() {
      if (this.selectedCount) this.selectAll(false);
      else this.selectAll(true);
    }
  }
};
</script>