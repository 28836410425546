// @vuese
// Used to create temp div pass download data to it and click on it
// @arg base64File
// @arg name of attribute
export default (base64File, name = 'file') => {
  const url = window.URL.createObjectURL(new Blob([base64File]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
