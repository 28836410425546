<template>
  <div class="front-wrapper">
    <Header 
      :regimen="regimen"
    />
    <div class="frontend-main">
      <div class="container container-md-xs">
        <Notifications />
        <div class="steps-wrap mb-0 mb-sm-2">
          <div class="step passed">
            <p>Enter Regimen Information</p>
            <span class="step-marker" />
          </div>

          <div class="step passed">
            <p>Create Preset Regimen</p>
            <span class="step-marker" />
          </div>

          <div class="step current">
            <p>Review</p>
            <span class="step-marker" />
          </div>
        </div>
      </div>
      <!-- /. steps-wrap -->

      <div class="container container-md-lg">
        <h3 class="mb-sm-3 mb-1">
          Review
        </h3>
        <div class="row row-layout-370 align-items-baseline">
          <div class="col-md-7">
            <div class="d-flex justify-content-between flex-wrap">
              <h4>Regimen Information</h4>
              <router-link 
                class="float-right link_action mb-2" 
                :to="
                  regimen.id ?
                    {
                      name: 'RegimenUpdateInfo',
                      params: {
                        id: regimen.id
                      }
                    }
                    :
                    {
                      name: 'RegimenInfo',
                    }"
              >
                Edit Regimen Information
              </router-link>
            </div>
          </div>
          <div class="col-md-5">
            <h4>{{ regimen.id ? 'Update' : 'Create' }} Your Preset Regimen</h4>
          </div>
        </div>

        <div class="row row-layout-370">
          <div class="col-md-7">
            <div class="wrap-table border-table">
              <div class="row row-group">
                <div class="col-md-4">
                  <span class="text-up">Name of regimen</span>
                </div>
                <div class="col-md-8">
                  <div class="row-large">
                    <div class="col">
                      <span>
                        {{ regimen.name }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row row-group">
                <div class="col-md-4">
                  <span class="text-up">Description of regimen</span>
                </div>
                <div class="col-md-8">
                  <div class="row-large">
                    <div class="col-md-10">
                      <span>{{ regimen.description }}</span>
                    </div>
                    <div class="col-md-2 action-box">
                      <a 
                        class="link_action" 
                        href="#" 
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row row-group">
                <div class="col-md-4">
                  <span class="text-up">Tags</span>
                </div>
                <div class="col-md-8">
                  <span>{{ tags.map(tag => {
                    return getTagName(tag).value;
                  }).join(', ') }}
                  </span>
                </div>
              </div>
            </div>
            <!-- /info -->
            <div class="title-table d-flex justify-content-between">
              <h5>Regimen</h5>
              <router-link 
                class="link_action" 
                :to="
                  regimen.id ? 
                    {
                      name: 'RegimenUpdatePreset',
                      params: {
                        id: regimen.id
                      }
                    }
                    :
                    {
                      name: 'CreatePreset',
                    }"
              >
                Change Regimen
              </router-link>
            </div>
            <div class="wrap-table order figs-box">
              <table class="table table-box">
                <thead>
                  <tr>
                    <th />
                    <th class="details-col">
                      Product
                    </th>
                    <th>Order Frequency</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr 
                    v-for="product in regimen.products"
                    :key="product.id"
                  >
                    <td>
                      <div 
                        v-if="product.image"
                      >
                        <figure class="product-image md-figure">
                          <span class="badge-counter" />
                          <figcaption>{{ renderRecommendationType(product.recomendation_type) }}</figcaption>
                          <img 
                            v-if="product.image"
                            :src="product.image" 
                            alt="product 1"
                          >
                        </figure>
                        <span 
                          v-if="product.sku"
                          class="img-detail"
                        >
                          SKU: {{ product.sku }}</span>
                      </div>
                    </td>
                    <td>
                      <h6> {{ product.title }}</h6>
                      <p class="product-info">
                        {{ product.vendor }}
                        <span v-if="product.variant_title != 'Default Title'">| {{ product.variant_title }}</span>
                      </p>
                      <p 
                        v-if="product.usage_instruction"
                        class="usage_instruction mb-0"
                      >
                        Usage Instructions: {{ product.usage_instruction }}
                      </p>
                    </td>
                    <td>{{ product.frequency != 'One time' ? `Every ${product.frequency} Days` : product.frequency }}</td>
                    <td>${{ product.price }}</td>
                  </tr>
                  <tr>
                    <td />
                    <td />
                    <td>
                      <b>Subtotal</b>
                    </td>
                    <td>
                      <b>${{ regimen.products.length > 0 ? formatMoney(calcSubtotal()) : '0' }}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /table -->
          </div>

          <!-- side column -->
          <div class="col-md-5 aside-sticky-5">
            <div class="d-box with-lpad">
              <p>
                Once you create your preset regimen you will have the ability to recommend them to clients.
                You can still make changes to this regimen after it is created.
              </p>
              <button 
                id="next_step" 
                class="btn btn-primary"
                @click="handlePresetRegimen"
              >
                <!-- {{ regimen.id ? 'Update' : 'Create' }} Preset Regimen -->
                Save Your Preset Regimen
              </button>
            </div>
          </div>
        </div>
        <!-- /. row-layout-370 -->
      </div>
    </div>
    <!--/. frontend-main -->
  </div>
  <!--/. frontend-wrap -->
</template>

<script>
import Header from './Header';
import { RegimenService } from '@/services/api.service';
import { formatMoney, renderRecommendationType } from '@/helper';
import { tags } from '@/store/regimen/initialObject';

// @vuese
// @group Preset Regimen
export default {
  name: 'RegimenReview',
  components: {
    Header
  },
  props: {
    // @vuese
    // Regimen object
    regimen: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      sending: false,
      regimenTags: tags,
      tags: [],
    };
  },
  watch: {
    regimen: {
      immediate: true,
      handler() {
        this.tags = this.regimen.tags;
      }
    }
  },
  beforeMount() {
    if (this.regimen.products.length == 0) {
      const id = this.$route.params.id;
      if (id) {
        this.$router.push({name: 'RegimenUpdatePreset', params: {id: this.regimen.id}});
      } else {
        this.$router.push({name: 'CreatePreset'});
      }
    }
  },
  methods: {
    renderRecommendationType,
    // @vuese
    // Used to check if we need to update or create preset regimen
    handlePresetRegimen() {
      if (this.sending) return;

      this.sending = true;
      this.regimen.tags = this.tags.map(tag => { return {name: tag};});
      
      if (this.regimen.id) this.updateRegimen();
      else this.createRegimen();
      
    },
    // @vuese
    // Used to update regimen
    updateRegimen() {
      RegimenService.update({
        regimen: this.regimen
      }, this.regimen.id, this.getRequestPracticeId())
        .then(resp => {
          this.handleProducts(resp.data.regimen.id);
        })
        .catch(err => {
          this.flashWarning('Opps something went wrong... Please reload and try again');
        });
    },
    // @vuese
    // Used to create regimen
    createRegimen() {
      RegimenService.create({
        regimen: this.regimen
      }, this.getRequestPracticeId())
        .then(resp => {
          this.handleProducts(resp.data.regimen.id);
        })
        .catch(err => {
          this.flashWarning('Opps something went wrong... Please reload and try again');
        });
    },
    // @vuese
    // Used to go through all selected products in regimen
    // and call update or create product function
    // @arg Regimen ID
    handleProducts(regimenId) {
      const productHandles = [];
      this.regimen.products.forEach((product,index) => {
        product.position = index;
        if (product.id) productHandles.push(this.updateProduct(product, regimenId));
        else productHandles.push(this.creteProduct(product, regimenId));
      });

      Promise.all(productHandles)
        .then(resp => {
          this.regimen.products = resp.map(productResp => {
            return productResp.data.product;
          });
          this.$router.push({name: 'PresetRegimen'});
          this.flashSuccess(`You've succesfully ${this.regimen.id ? 'updated' : 'created'} a preset regimen!`);
        })
        .finally(() => {
          this.sending = false;
        });
    },
    // @vuese
    // Used to add product to regimen
    // @arg product
    // @arg / Regimen ID
    creteProduct(product, regimenId) {
      return RegimenService.addProduct({
        product: product,
      }, regimenId, this.getRequestPracticeId());
    },
    // @vuese
    // Used to update product to regimen
    // @arg product
    // @arg / Regimen ID
    updateProduct(product, regimenId) {
      return RegimenService.updateProduct({
        product: product,
      }, regimenId, product.id, this.getRequestPracticeId());
    },
    // @vuese
    // Used to calculate subtotal of products in regimen
    calcSubtotal() {
      return this.regimen.products.reduce((sum, product) => {
        return sum + parseFloat(product.price);
      }, 0);
    },
    // @vuese
    // Used to format momey
    // @arg Price value
    formatMoney (val) {
      return formatMoney(val);
    },
    // @vuese
    // Used to to get tag name by tag key
    // @arg Tag key
    getTagName(key) {
      return this.regimenTags.find(tag => tag.name == key);
    },

    // @vuese
    // Used to get practice ID for super admin user
    getRequestPracticeId() {
      return this.isRole('super_admin') ? 
        this.regimen.practice ? 
          `/${this.regimen.practice.id}` : 
          `/${this.currentUser.practice.id}` : '';
    }
  }
};
</script>