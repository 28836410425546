<template>
  <div 
    v-if="notification.message" 
    :class="`notification message ${notification.type}`"
  >
    <p 
      class="icon"
      v-html="notification.message"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { REMOVE_NOTIFICATION } from '../../../store/notifications/actions.types';

// @group Partials
// Notification component
export default {
  name: 'Notifications',
  computed: {
    ...mapGetters([
      'notification'
    ])
  },

  deactivated() {
    this.$store.dispatch(REMOVE_NOTIFICATION);
  },

  beforeDestroy() {
    this.$store.dispatch(REMOVE_NOTIFICATION);
  }
};
</script>