<template>
  <div class="front-wrapper">
    <header class="main-header">
      <div class="container container-md-lg">
        <div class="header-wrap wrap-lg">
          <div class="header-logo">
            <router-link 
              :to="{
                name: 'userdashboard'
              }"
            >
              <img 
                :src="logo" 
                alt="logo" 
              >
            </router-link>
          </div>
          <div class="header-right">
            <div class="header-center">
              <p v-if="physician">
                Skincare Pro: {{ physician.name }}
              </p>
            </div>
            <p>Please select products to appear as the Skincare Pro's Top Picks for their clients to browse.</p>
          </div>
          <div class="header-right-link">
            <router-link 
              :to="{
                name: 'TopPicks'
              }"
              tag="a"
            >
              Leave Without Saving
            </router-link>
          </div>
        </div>
      </div>
    </header>

    <div class="container mt-4">
      <Notifications />
    </div>

    <div class="frontend-main">
      <div class="container container-md-lg">
        <h2 v-if="physician">
          Select {{ physician.name }}'s Top Picks
        </h2>
        <div class="row">
          <div class="col-lg-7">
            <TopProductList
              :open-product-modal="openProductModal"
              :selected-products="productsToBeSaved"
              :physician="physician"
              @product-selected="productSelected"
            />
          </div>

          <div class="col-lg-5">
            <h5 class="mb-1">
              {{ physician.name }}'s Top Picks
            </h5>
            <p>Their client's will see these products on their RegimenPro<sup>®</sup> account</p>
            <div class="wrap-table border-table bagde-table">
              <div class="text-center top-picks-heading">
                <h5 class="top-picks-title">
                  Top Picks
                </h5>
                <p class="top-picks-subtitle">
                  Click and drag to set picks position
                </p>
              </div>

              <draggable
                v-model="topPickProducts"
                class="top-picks-draggable-wrapper"
                :group="topPicksProductsGroup"
              >
                <div
                  v-for="(product, idx) in topPickProducts"
                  :key="idx"
                  class="row row-group top-pick-item"
                  :class="{'ignored-top-pick': product.toBeRemoved}"
                >
                  <span class="badge-counter" />
                  <div class="col-md-1 top-picks-drag-btn-wrapper">
                    <button class="top-picks-drag-btn">
                      <i class="fas fa-grip-vertical" />
                    </button>
                  </div>
                  <div class="col-md-3">
                    <div class="picture-product">
                      <img 
                        v-if="product.image"
                        :src="product.image"
                        alt="img"
                      >
                    </div>
                  </div>
                  <div class="col-md-8 pr-0">
                    <div class="row-large">
                      <div class="col-md-9 pr-0">
                        <p
                          class="title-sm"
                          v-html="product.title"
                        />
                        <div class="row mx-0">
                          <div
                            class="col-sm-5 vendor"
                            v-html="product.vendor"
                          />
                          <div class="col-sm-7 top-pick-price">
                            Price: ${{ formatStorefrontMoney(
                              product.variants[0].price.amount || product.variants[0].price
                            ) }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 action-box">
                        <a 
                          class="link_action hover-btn" 
                          @click.prevent="removeProduct(product)"
                        >
                          Remove
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="top-pick-placeholder">
                  <p>Top pick</p>
                </div>
              </draggable>


              <div class="row-group pt-sm-3 px-0">
                <a
                  class="btn btn-primary hover-btn"
                  @click.prevent="handleTopPicks"
                >
                  Save
                </a>
              </div>

              <div 
                v-if="showProductsError"
                class="message danger mb-0"
              >
                <p class="icon">
                  You must select at least {{ minLength }} products as top picks.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- /. row-create-account -->
      </div>
    </div>
    <!--/. frontend-main -->
    <Modal 
      :show-modal="showModal" 
      :modal-type="'popup-lg'"
      modal-id="add_product"
      @hide-modal-window="showModal = false"
    >
      <TopPickProductModal
        :key="showModal"
        :product-id="productId"
        :modal-action="modalAction"
        @hide-modal-window="showModal=false"
        @product-selected="productSelected"
      />
    </Modal>

    <div 
      v-if="showModal" 
      class="modal-backdrop fade show" 
    />
  </div>
</template>
<style scoped lang="scss">
.bagde-table {
  &.wrap-table.border-table {
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.05);
    background-color: #eeeef3;
    margin-bottom: 30px;
    counter-reset: count;
    padding: 25px 25px 25px 40px;
  }
  .top-pick-price{
    padding-right: 0;
    padding-left: 10px !important;
  }
  .vendor {
    word-break: break-all;
  }
}

.top-picks-heading {
  margin-bottom: 20px;

  .top-picks-title {
    margin-bottom: 5px;
  }

  .top-picks-subtitle {
    margin-bottom: 0;
    font-weight: 100;
    font-size: 12px;
  }
}

.toppicksedit {
  .top-picks-draggable-wrapper {
    .top-pick-item:first-child,
    .top-pick-item:last-child,
    .top-pick-item {
      background-color: #fff;
      margin-bottom: 20px;
      padding: 20px 15px;
      padding-left: 0;
    }

    .badge-counter {
      top: calc(50% - 12px);
      left: -35px;
    }

    .picture-product {
      margin-left: 15px;
    }

    .top-picks-drag-btn-wrapper {
      padding: 0;
      justify-content: center;
      align-items: center;
      display: flex;
      margin-top: -20px;
      margin-bottom: -20px;
      background-color: #f7f7f8;
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
      .top-picks-drag-btn {
        background-color: #f7f7f8;
        cursor: grab;
        appearance: none;
        border: 0;
        color: #c7c7c7;
        font-size: 20px;
        &:active {
          cursor: grabbing;
        }
      }
    }
  }

  .top-pick-placeholder {
    border: 1px dashed #c6c7ca;
    margin-bottom: 20px;
    p {
      margin: 0;
      padding: 35px 0px;
      color: #c6c7ca;
      text-align:  center;
      font-size: 13px;
      font-weight: 100;
    }
  }

  .ignored-top-pick {
    display: none;
  }
}
</style>

<script>
import draggable from 'vuedraggable';
import Modal from '../partials/Modal';
import TopPickProductModal from './TopPickProductModal';
import TopProductList from './TopProductList';
import { TopPicksService } from '@/services/api.service';
import { formatStorefrontMoney } from '@/helper';
import logo from '@images/logo-light.png';

// @vuese
// @group Top Picks
export default {
  name: 'TopPickChange',
  components: {
    draggable,
    TopProductList,
    TopPickProductModal,
    Modal
  },

  data() {
    return {
      topPicksProductsGroup: {
        name: 'topPicksProducts',
        filter: '.ignored-top-pick'
      },
      featuredTopPicksProductsGroup: {
        name: 'topPicksProducts',
        filter: '.ignored-top-pick, .top-pick-placeholder',
        put: to => Array.from(to.el.children).filter(
          item => !item.classList.contains('ignored-top-pick') && !item.classList.contains('top-pick-placeholder')
        ).length < 3
      },
      sending: false,
      showModal: false,
      modalAction: 'create',
      physician: {},
      featuredTopPickProducts: [],
      topPickProducts: [],
      productsToBeSaved: [],
      productId: null,
      logo,
      showProductsError: false,
      minLength: 6,
    };
  },
  watch: {
    featuredTopPickProducts: {
      immediate: true,
      handler: 'updateProductsToBeSaved',
    },

    topPickProducts: {
      immediate: true,
      handler: 'updateProductsToBeSaved',
    }
  },
  beforeMount() {
    this.fetchTopPick();
  },
  methods: {
    formatStorefrontMoney: formatStorefrontMoney,
    // @vuese
    // Used to fetch top pick
    fetchTopPick() {
      const id = this.$route.params.id;

      TopPicksService.getPhysicianPicks(id)
        .then(resp => {
          const { data } = resp;
          this.selectedProducts = data.physician.top_picks_products;
          this.topPickProducts = this.selectedProducts.filter(
            product => !product.toBeRemoved && !product.featured
          ).sort(
            (a, b) => a.position - b.position
          );
          this.featuredTopPickProducts = this.selectedProducts.filter(
            product => !product.toBeRemoved && product.featured
          ).sort(
            (a, b) => a.position - b.position
          );
          this.physician = data.physician;
          if (this.physician.name)
            this.physician.name = this.physician.name.trim();
        });
    },
    // @vuese
    // Used to check if we need to update or create top pick
    handleTopPicks() {
      if (!this.isValid()) return;
      const productHandles = [];

      [...this.featuredTopPickProducts, ...this.topPickProducts].forEach(product => {
        if (product.toBeRemoved) productHandles.push(this.deleteProduct(product));
        else if (!product.id) productHandles.push(this.addProduct(product));
        else productHandles.push(this.updateProduct(product));
      });

      Promise.all(productHandles)
        .then(resp => {
          this.$router.push({name: 'TopPicks'});
          this.flashSuccess(`${this.physician.name} top picks succesfully saved`);
        })
        .catch(err => {
          this.flashWarning('Opps something went wrong... Please reload and try again.');
        });
    },
    // @vuese
    // Used to add product to top pick
    // @arg Product object `Object`
    addProduct(product) {
      return TopPicksService.create(this.physician.id, {
        top_pick: {
          shopify_product_id: product.shopify_product_id,
          featured: product.featured,
          position: product.position
        }
      }, this.getRequestPracticeId());
    },

    updateProduct(product) {
      return TopPicksService.update(this.physician.id, product.id, {
        top_pick: {
          shopify_product_id: product.shopify_product_id,
          featured: product.featured,
          position: product.position
        }
      }, this.getRequestPracticeId());
    },

    // @vuese
    // Used to remove product to top pick
    // @arg Product object `Object`
    deleteProduct(product) {
      return TopPicksService.delete(this.physician.id, product.id, this.getRequestPracticeId());
    },
    // @vuese
    // Used to check if top pick valid
    isValid() {
      let valid = true;

      if (this.productsToBeSaved.length < this.minLength) {
        this.showProductsError = true;
        valid = false;
      } else this.showProductsError = false;

      return valid;
    },
    // @vuese
    // Used to filter what products should be saved, and what need to be removed
    updateProductsToBeSaved() {
      this.productsToBeSaved = [...this.featuredTopPickProducts, ...this.topPickProducts].filter(
        product => product.toBeRemoved != true
      );

      this.productsToBeSaved.forEach((product, index) => {
        product.position = index + 1;
      });
    },
    // @vuese
    // Used to open product modal
    // @arg Product ID `Number`
    openProductModal(productId) {
      this.productId = productId;
      this.modalAction = 'create';
      this.showModal = true;
    },
    // @vuese
    // Used to add product to top pick
    // @arg Product object `Object`
    productSelected(value) {
      this.showModal = false;
      var productIndex = this.featuredTopPickProducts.findIndex(product => {
        return product.shopify_product_id == value.shopify_product_id;
      });
      if (productIndex !== -1) {
        this.featuredTopPickProducts[productIndex].toBeRemoved = false;
      } else {
        productIndex = this.topPickProducts.findIndex(product => {
          return product.shopify_product_id == value.shopify_product_id;
        });
        if (productIndex !== -1) {
          this.topPickProducts[productIndex].toBeRemoved = false;
        } else {
          this.topPickProducts.push(value);
        }
      }
      this.$forceUpdate();
      this.updateProductsToBeSaved();
    },
    // @vuese
    // Used to remove product from top pick
    // @arg Product object `Object`
    removeProduct(value) {
      var productIndex = this.featuredTopPickProducts.findIndex(product => {
        return product.shopify_product_id == value.shopify_product_id;
      });

      if (productIndex == -1) {
        productIndex = this.topPickProducts.findIndex(product => {
          return product.shopify_product_id == value.shopify_product_id;
        });

        if (productIndex == -1) return;

        const product = this.topPickProducts[productIndex];
        if (product.id) {
          product.toBeRemoved = true;
        } else {
          this.topPickProducts.splice(productIndex, 1);
        }

        this.$forceUpdate();
        this.updateProductsToBeSaved();

        return;
      }

      const product = this.featuredTopPickProducts[productIndex];
      if (product.id) {
        product.toBeRemoved = true;
      } else {
        this.featuredTopPickProducts.splice(productIndex, 1);
      }
      this.$forceUpdate();
      this.updateProductsToBeSaved();
    },

    setProductAsFeatured(evt) {
      console.log(evt);
      if(typeof evt.added === 'object') {
        evt.added.element.featured = true;
      }
    },

    updatePreviewItems(evt) {
      console.log(evt);
    },

    // @vuese
    // Used to get practice ID for super admin user
    getRequestPracticeId() {
      return this.isRole('super_admin') ? `/${this.physician.practice.id}` : '';
    },
  }
};
</script>
