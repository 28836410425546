<template>
  <div id="group2">
    <h4>Select Products to Appear on the Skincare Pro's Top Picks</h4>
    <p>
      Find any product on RegimenPro<sup>®</sup> and add them to the skincare pro's top picks
    </p>
    <div class="wrap-table border-table pos-rel">
      <div 
        v-if="sending"
        class="preloader"
      >
        <i />
      </div>
      <div 
        v-if="topBarShow == topBar.search"
        class="tool-bar-search"
      >
        <div class="form-group">
          <div class="row">
            <div class="col-sm-7">
              <div class="filter-search">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlns:xlink="http://www.w3.org/1999/xlink" 
                  width="29" 
                  height="29" 
                  viewBox="0 0 29 29"
                >
                  <metadata><?xpacket begin=" " id="W5M0MpCehiHzreSzNTczkc9d"?>
                    <x:xmpmeta 
                      xmlns:x="adobe:ns:meta/" 
                      x:xmptk="Adobe XMP Core 5.6-c140 79.160451, 2017/05/06-01:08:21"
                    >
                      <rdf:RDF xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                        <rdf:Description rdf:about />
                      </rdf:RDF>
                    </x:xmpmeta><?xpacket end="w"?>
                  </metadata>
                  <image 
                    width="29" 
                    height="29" 
                    xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAdCAQAAAD8mq+EAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfjCQIKKCCQuWLCAAACVUlEQVQ4y53UTWgTQRQH8H/GJoYkTWOJMY1axESL4heFUAl4kapBSsCIevASqgcR8eBBrYhSRIIeBEFBkGAvHopECSoJRDyZitUiWlD6EaSUNsZYNWnz1bh5HkbJbnazEGcub97Ob/ftzsxqCNL2y/Jy74edafuSyfbNMbfnlXuUVaHcSNSnXceGWyqSFNnSt86V9AR5F4U3z2srUJpDzuTHHfK0hhdMrD80FOB1dE32PXNNtS6lbaM9UW/ODADGQvjwgZhiwQNBHmz5HPWK77xoujq4sgwCGfPj2xQKjnn5yBdZNMkLG/GszoBAm6bKujoqsO3jIJAnofw5CAkPf/Lts3U04gOBtJXkRmVIIFy+BgJ1pKpMQgMPQKCT9xtDQs5szINAI55ajgHx/QBw5BFUWmvOGwOAeG8tx4il7ADgfgfV5n4LALPrRVRgVQZof6/6oU7XfAWAtF1E+Q6ttBQN6pRvDUNBQttyADDtUqeTXQDgmBdRwD0KAE/71OnzgwDgSUg24p0zINC62aK+8eKE/SCQvrjQLlnXjNWcBYEGgo3gT4szCQL1h2R7+PolPhoKKMG8YV+cH4AvG2S0pO95w8cXbhQMUvhpa/cYD0/fVTzq847OGR6unRu8MtadNQssZY/4jj9cIfybq62E/Yp/iZR992vxTTWimJHlL37sV6CEkj54sS0rf9ld7xOeic0dqXpcN2+h/d4pb9Sa0RCICc7kidCLXn5FjhssR1n33Sowaa4eq5xReZfipqgUN0lr+Ohw05TjQ0+Wdf9BCTOdyzrCHxW1lyJMyp75AAAAAElFTkSuQmCC"
                  />
                </svg>
                <input 
                  v-model="search"
                  type="text" 
                  placeholder="Search" 
                  class="form-control search" 
                  @input="searchProduct"
                >
              </div>
            </div>
            <div class="col-sm-5 pt-2 pt-sm-0">
              <a
                class="link-back hover-btn"
                @click="changeTopBar(topBar.filter)"
              >
                Sort and Filter
                <i class="fas fa-arrow-right" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div 
        v-if="topBarShow == topBar.filter"
        class="filter"
      >
        <div class="d-flex justify-content-between">
          <a 
            class="link-back hover-btn"
            @click="changeTopBar(topBar.search)" 
          >
            <i class="fas fa-arrow-left" /> Back to Product Search
          </a>
          <a 
            class="link-back hover-btn"
            @click.prevent="resetAll" 
          >Reset All</a>
        </div>
        
        <div class="form form-inline form-gray">
          <div class="form-group row">
            <div class="col-md-3">
              <label 
                for="filter" 
                class="form-label"
              >Filter by</label>
            </div>
            <div class="col-md-3">
              <filter-select
                v-model="concern"
                :items="concerns"
                default-value="All Concerns"
                name="concerns-select"
              />
            </div>
            <div class="col-md-3">
              <filter-select
                v-model="brand"
                :items="brands"
                default-value="All Brands"
                name="brands-select"
              />
            </div>
            <div class="col-md-3">
              <filter-select
                v-model="category"
                :items="categories"
                default-value="All Categories"
                name="categories-select"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-3" />
            <div class="col-md-3">
              <filter-select
                v-model="ingredient"
                :items="ingredients"
                default-value="All Ingredients"
                name="ingredients-select"
              />
            </div>
            <div class="col-md-3">
              <select
                v-model="price"
                name="ranges-select"
              >
                <option 
                  value="" 
                  selected=""
                >
                  All Price Ranges
                </option>
                <option value="0-99">
                  $99 or less
                </option>
                <option value="100-199">
                  $100 - $199
                </option>
                <option value="200">
                  Over $200
                </option>
              </select>
            </div>
            <div class="col-md-3" />
          </div>
          <div class="form-group row">
            <div class="col-md-3">
              <label 
                for="filter" 
                class="form-label"
              >Sort by</label>
            </div>
            <div class="col-md-3">
              <select 
                v-model="sortKeyOption"
                name="alphabetically-select"
              >
                <option 
                  v-for="sortOption in sortValues"
                  :key="sortOption.value"
                  :value="sortOption.value" 
                  selected=""
                >
                  {{ sortOption.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <hr class="mb-2">
      <!-- <p class="mb-2">Showing 652 results</p> -->
      <!-- <p 
        v-if="allProductsModel.length"
        class="mb-2"
      >
        {{ allProductsModel.length }} products loaded
      </p> -->

      <transition-group 
        name="list" 
        tag="div"
      >
        <div 
          v-for="product in products"
          :key="product.id"
          class="row row-group btn-sm-actions"
        >
          <div class="col-sm-2">
            <div class="md-image">
              <img 
                v-if="product.images.length && product.images[0].src"
                :src="product.images[0].src" 
                alt="img" 
              >
            </div>
          </div>
          <div class="col-sm-7">
            <div class="row-large">
              <div class="col-lg-5">
                <strong v-html="product.title" />
              </div>
              <div class="col-lg-4 vendor">
                {{ product.vendor }}
              </div>
              <div class="col-lg-3">
                ${{ formatStorefrontMoney(product.variants[0].price.amount) }}
              </div>
            </div>
          </div>
          <div class="col-sm-3 pr-0 text-right btn-wrap">
            <a 
              class="btn btn-primary btn-view hover-btn"
              @click.prevent="openProductModal(product.id)"
            >View</a>
            <a 
              v-if="!selected(product.id)"
              class="btn btn-primary hover-btn"
              @click.prevent="productSelected(product)"
            >Add</a>
            <span 
              v-if="selected(product.id)"
              class="product-added"
            >Added</span>
          </div>
        </div>
      </transition-group>

      <div 
        v-if="!products.length"
        class="no-products"
      >
        No products found
      </div>
    </div>
    <div class="pagination-wrap">
      <div class="pagination-l">
        <div class="page-results">
          Display
          <select 
            v-model="productsPerPage"
            @change="changePerPage"
          >
            <option value="20">
              20
            </option>
            <option value="30">
              30
            </option>
            <option value="40">
              40
            </option>
            <option value="50">
              50
            </option>
          </select>
          results per page
        </div>
      </div>
      <div class="pagination-r">
        <nav class="pagination">
          <!-- <p class="pag-viewing">Viewing 1 - 20 of 150</p> -->
          <ul class="pagination">
            <li>
              <a 
                href="#" 
                :disabled="!hasPreviousPage"
                @click.prevent="showPrevProducts"
              >
                <i class="fas fa-angle-left" />
              </a>
            </li>
            <li>
              <a 
                href="#"
                :disabled="!hasNextPage"
                @click.prevent="getMoreProducts"
              >
                <i class="fas fa-angle-right" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .vendor {
    // word-break: break-all;
    word-break: break-word;
    padding-right: 12px;
  }
  .btn-wrap {
    min-width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .btn-view {
    background: transparent;
    color: #a989de !important;
    &:hover {
      color: #fff !important;
    }
  }
  .btn-primary {
    margin-right: 0 !important;
  }
</style>

<script>
import { formatTopPick, getShopifyId, formatStorefrontMoney } from '@/helper';
import productList from '@/mixins/productList';
// import productList from '@/mixins/productListWithAlgoliaSearch';

// @vuese
// @group Top Picks
export default {
  name: 'TopProductList',

  mixins: [productList],

  methods: {
    formatStorefrontMoney: formatStorefrontMoney,
    // @vuese
    // Used to emit that product was selected to parent component
    // @arg Product object `Object`
    productSelected(product) {
      this.$emit('product-selected', formatTopPick(product));
    },
    // @vuese
    // Used to check if product is selected
    // @arg Product ID `Number`
    selected(productId) {
      return this.selectedProducts.find(product => {
        return product.shopify_product_id == getShopifyId(productId);
      });
    }
  }
};
</script>