<template>
  <div
    :id="modalId"
    :class="showModal ? 'modal fade dashboard-modal show' : 'modal fade dashboard-modal'"
    role="dialog"
  >
    <div
      class="modal-overlay"
      @click="hideModal"
    />
    <div 
      :class="modalType ? `modal-dialog modal-dialog-centered ${modalType}` : 'modal-dialog modal-dialog-centered popup-md'"
    >
      <div 
        :class="`${modalContentClass} modal-content`"
      >
        <slot />
      </div>
    </div>
  </div>
  <!-- /. Add Patients -->
</template>

<script>
// @group Partials
export default {
  props: {
    // @vuese
    // Variable that tell do we show modal or not
    showModal: {
      type: Boolean,
      default: false
    },
    // @vuese
    // Modal ID
    modalId: {
      type: String,
      default: ''
    },
    // @vuese
    // Modal type, is it large or not
    modalType: {
      type: String,
      default: ''
    },
    // @vuese
    // Modal content classs
    modalContentClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    // @vuese
    // Used to emit to parent component to close modal
    hideModal() {
      this.$emit('hide-modal-window');
    }
  }
};
</script>