import { PracticeService } from '@/services/api.service';
import { encodeQueryData } from '@/helper';

export default {
  data() {
    return {
      practices: [],
      practicesTotal: 0,
      selectedPractices: [],
      practicesListVisible: false,
      isPracticesLoading: false,
      loadOnInit: true,
      filters: {}
    };
  },
  mounted() {
    if (this.loadOnInit)
      this.loadMorePractices({type: 'search'});
  },
  methods: {
    // @vuese
    // Used to convert object to get string
    // @arg Query object
    encodeQueryData,

    // @vuese
    // Prepare data for select options
    // @arg Data to change
    // @arg / what data we get from object
    valuesForSelect(resources, map={title: 'name', value: 'id'}) {
      return resources.map(item => { return {value: item[map.value], title: item[map.title] }; });
    },

    // @vuese
    // Used to load physican 
    // @arg Query object
    loadMorePractices(queryObj) {
      this.isPracticesLoading = true;
      const type = queryObj.type;
      delete queryObj.type;
      // if (this.isRole('group_admin')) {
      //   return PracticeService.getChildPracticeFor(
      //     this.currentUser.practice.id,
      //     this.encodeQueryData(queryObj)
      //   ).then(
      //     ({ data }) => {
      //       console.log(data)
      //       if (type == 'search')
      //         this.practices = data.practices.items;
      //       else
      //         this.practices = [...this.practices, ...data.practices.items];

      //       this.practicesTotal = data.practices.total_items;
      //     }
      //   ).finally(() => {
      //     this.isPracticesLoading = false;
      //   });
      // }else{
        return PracticeService.getPractice(
          this.encodeQueryData(queryObj)
        ).then(
          ({ data }) => {
            if (type == 'search')
              this.practices = data.practices.items;
            else
              this.practices = [...this.practices, ...data.practices.items];

            this.practicesTotal = data.practices.total_items;
          }
        ).finally(() => {
          this.isPracticesLoading = false;
        });
      // }
    }
  }
};