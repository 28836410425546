<template>
  <div>
    <div class="title-table d-flex justify-content-between">
      <h4>Recent Orders</h4>
      <router-link 
        v-if="orders.length"
        :to="{
          name: 'Orders',
          params: {
            patient_id: patient.id,
            patientDateRange: patientDateRange
          }
        }" 
        class="action-top"
      >
        View All Orders
      </router-link>
    </div>
    <div class="wrap-table order recent-orders">
      <table class="table table-box table-striped">
        <thead v-if="orders.length">
          <tr>
            <th>Date Ordered</th>
            <th>Order Number</th>
            <th>Skincare Pro</th>
            <th>Total price</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="order in orders"
            :key="order.id"
          >
            <td>{{ order.created_at }}</td>
            <td>
              <router-link
                v-if="order.status === 'completed' && order.shopify !== null"
                class="link_action"
                :to="{
                  name: 'ShowOrder',
                  params: {
                    id: order.id
                  }
                }"
              >
                #{{ order.shopify.order_number }}
              </router-link>
              <span v-else>-</span>
            </td>
            <td>
              <span>{{ order.physician.name }}</span>
            </td>
            <td>
              <span>{{ order.total_price }}</span>
            </td>
            <td align="right">
              <router-link
                class="link_action"
                :to="{
                  name: 'ShowOrder',
                  params: {
                    id: order.id
                  }
                }"
              >
                {{ order.status === 'completed' && order.shopify !== null ? 'View' : 
                  order.status == 'failed' ? 'Failed' : 'Confirmed' }}
              </router-link>
            </td>
          </tr>
          <tr
            v-if="!orders.length"
          >
            <td 
              class="pt-4 pb-4" 
              align="center"
            >
              No orders have been placed
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- /. Table Orders  -->
  </div>
</template>

<script>
import { OrdersService } from '@/services/api.service';
import { encodeQueryData } from '@/helper';

// @group Patinet
export default {
  name: 'PatientViewOrder',
  props: {
    // Patient object
    patient: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      filteringParams: {
        filters: {}
      },
      sortingParams: {
        orders: {
          created_at: 'desc'
        }
      },
      patientDateRange: [],
      orders: [],
    };
  },
  watch: {
    patient: {
      immediate: true,
      handler() {
        this.filteringParams['filters']['by_patients'] = [this.patient.id];
        this.patientDateRange = [new Date(this.patient.date_added), new Date()];
        this.fetchOrders();
      }
    }
  },
  methods: {
    // @vuese
    // Used to load patinet order
    fetchOrders() {
      return OrdersService.list(
        this.orderParams()
      ).then(this.setOrders);
    },

    // @vuese
    // Used to prepare query parameters for patient orders
    buildFilterObject() {
      return {
        ...this.filteringParams,
        ...this.sortingParams,
        page: 1,
        per: 3
      };
    },

    // @vuese
    // Used to store orders to loacal state
    // @arg Server response
    setOrders({ data }) {
      this.orders = data.orders.items;
    },

    // @vuese
    // Used to convert query object to get string
    orderParams() {
      return encodeQueryData(
        this.buildFilterObject()
      );
    },
  }
};
</script>