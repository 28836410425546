<template>
  <keep-alive include="PatientCreateOrder">
    <router-view 
      :patient="patient" 
    />
  </keep-alive>
</template>

<script>
import { GET_PATIENT } from '@/store/patients/actions.types';

// @vuese
// @group Patinet
// Main patient component used to load patient
export default {
  name: 'Patient',
  data() {
    return {
      patient: {},
    };
  },
  mounted() {
    this.fetchPatient();
    window.scrollTo(0,0);
  },
  methods: {
    // @vuese
    // Used to load patient, and redirect to view if it is one of continue 
    // pages and patient status page is 'completed'
    fetchPatient() {
      const id = this.$route.params.id,
        currentRoute = this.$route.path;
      let redirectTo;

      const practiceId = this.$route.query.practiceId ? this.$route.query.practiceId : null;
      const params = {
        id: this.$route.params.id
      };

      if (practiceId && this.isRole('super_admin')) {
        params.practiceId = practiceId;
      }

      this.$store
        .dispatch(GET_PATIENT, params)
        .then(resp => {
          this.patient = resp.data.patient;
          if(this.patient.shipping_address.first_name == '' && this.patient.shipping_address.last_name == ''){
            this.patient.shipping_address.first_name = this.patient.first_name;
            this.patient.shipping_address.last_name = this.patient.last_name;
          }
          
          if (this.patient.status == 'completed' && currentRoute.indexOf('continue') !== -1) {
            redirectTo = `/dashboard/patient/${id}/view`;
          }

          if (redirectTo && redirectTo != currentRoute)
            this.$router.push(redirectTo);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>