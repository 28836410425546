import moment from 'moment';

export default {
  data() {
    return {
      startDate: null,
      startQuarter: null,
      endDate: moment(),
      endQuarter: moment().quarter(),
      options: [],
      monthsByQuarter: {
        1: [1,3],
        2: [4,6],
        3: [7,9],
        4: [10,12]
      }
    };
  },
  methods: {
    // @vuese
    // Used to build single querter
    // @arg Year
    // @arg / Quarter start
    // @arg / Quarter end
    buildQuarters(year, startQuarter, endQuarter) {
      const options = [];
      console.log('123')
      for (let i = startQuarter; i <= endQuarter; i++) {
        let monthOption = this.monthsByQuarter[i];
        let quarterStart = moment(`${monthOption[0]}-01-${year}`, 'MM-DD-YYYY');
        let quarterEnd = moment(`${monthOption[1]}-01-${year}`, 'MM-DD-YYYY').endOf('month');

        options.push({
          title: `Quarter ${i} (${quarterStart.format('MMM')}-${quarterEnd.format('MMM')} ${year})`,
          value: [
            quarterStart,
            quarterEnd
          ]
        });
      }

      return options;
    },
    // @vuese
    // Used to build querters option
    // @arg Start date
    buildQuarterOptions(startDate) {
      this.startDate = moment(new Date(startDate));
      this.startQuarter = this.startDate.quarter();

      const startYear = this.startDate.year();
      const endYear = this.endDate.year();

      for (let i = startYear; i <= endYear; i++) {
        let options;
        if (i == startYear) {
          options = this.buildQuarters(i, this.startQuarter, startYear == endYear ? this.endQuarter : 4);
        } else if (i == endYear) {
          options = this.buildQuarters(i, 1, this.endQuarter);
        } else {
          options = this.buildQuarters(i, 1, 4);
        }
        this.options = [...this.options, ...options];
      }

      return this.options;
    },
    // @vuese
    // Used to build year option
    // @arg Start date
    buildYearOptions(startDate){
      this.startDate = moment(new Date(startDate));
      const startYear = this.startDate.year();
      const endYear = this.endDate.year();
      for (let i = startYear; i <= endYear; i++) {
        let year = moment(i, 'YYYY');
        year = year.utc(true);
        this.options.push({
          title: i,
          value: [
            year.startOf('year').toString(),
            year.endOf('year').toString()
          ]
        });
      }
      return this.options;
    },
    // @vuese
    // Used to build months option
    // @arg Start date
    buildMonthOptions(startDate) {
      this.startDate = moment(new Date(startDate));

      const startYear = this.startDate.year();
      const endYear = this.endDate.year();
      const startMonth = this.startDate.format('M');
      const endMonth = this.endDate.format('M');

      for (let i = startYear; i <= endYear; i++) {
        let options = [];
        if (i == startYear) {
          options = this.buildMonths(i, startMonth, startYear == endYear ? endMonth : 12);
        } else if (i == endYear) {
          options = this.buildMonths(i, 1, endMonth);
        } else {
          options = this.buildMonths(i, 1, 12);
        }
        this.options = [...this.options, ...options];
      }
      return this.options;
    },
    // @vuese
    // Used to build month option
    // @arg Year
    // @arg / Start month
    // @arg / End month
    buildMonths(year, startMonth, endMonth) {
      const options = [];
      for (let month = +startMonth; month <= +endMonth; month++) {
        let monthObj = moment(`${month}-${year}`, 'MM-YYYY');
        monthObj = monthObj.utc(true);
        options.push({
          title: monthObj.format('MMM YYYY'),
          value: [
            monthObj.toString(),
            monthObj.endOf('month').toString()
          ]
        });
      }
      return options;
    },

    // @vuese
    // Return arrat of months
    getArrayOfMonths() {
      const months = [];
      for (let month = 1; month <= 12; month++) {
        months.push(moment(month, 'MM').format('YYYY-MM-DDTHH:mm:ss.sss'));
      }
      return months;
    },
    // @vuese
    // Used to return array of weeks beetwen 2 dates
    // @arg / Start date
    // @arg / End date
    getArrayOfWeeksBetweenDates(startDate, endDate) {
      this.startDate = moment(new Date(startDate)).isoWeekday(8);
      this.endDate = moment(new Date(endDate)).isoWeekday('Sunday');

      if(this.startDate.date() == 8) {
        this.startDate = this.startDate.isoWeekday(-6);
      }
      const weeks = [];

      while (this.startDate.isBefore(this.endDate)) {
        let startDateWeek = this.startDate.isoWeekday('Monday').format('YYYY-MM-DDTHH:mm:ss.sss');
        this.startDate.add(7, 'days');
        weeks.push(startDateWeek);
      }

      return weeks;
    }
  }
};