import Vue from 'vue';
import Router from 'vue-router';

//Policies
import Privacy from '@/views/components/Privacy';
import Terms from '@/views/components/Terms';
import Standard from '@/views/components/Standard';
import Affiliate from '@/views/components/Affiliate';
import Contact from '@/views/components/Contact';

// Login
import Login from '@/views/login/Login';
import LoginFirstTime from '@/views/login/LoginFirstTime';
import ForgotPassword from '@/views/login/ForgotPassword';
import ResetPassword from '@/views/login/ResetPassword';
import SetPassword from '@/views/login/SetPassword';

// Create Account
import Auth from '@/views/create-account/Auth';
import CreateAccount from '@/views/create-account/CreateAccount';
import CreateAccount_s2 from '@/views/create-account/CreateAccount_s2';
import CreateAccount_s3 from '@/views/create-account/CreateAccount_s3';
import CreateAccount_s4 from '@/views/create-account/CreateAccount_s4';
import CreateAccount_s5 from '@/views/create-account/CreateAccount_s5';
import CreateAccount_s6 from '@/views/create-account/CreateAccount_s6';

// Dashboard
import Dashboard from '@/views/dashboard/Dashboard';
import MyPractice from '@/views/dashboard/myPractices/MyPractice';
import Patients from '@/views/dashboard/patients/Patients';
import MyAccount from '@/views/dashboard/MyAccount';
import InviteTeam from '@/views/dashboard/myPractices/inviteMain';
// Patient
import PatientView from '@/views/dashboard/patient/PatientView';
import Patient from '@/views/dashboard/patient/Patient';
import PatientCreateRegimen from '@/views/dashboard/patient/PatientCreateRegimen';
import PatientCreateInfo from '@/views/dashboard/patient/PatientCreateInfo';
import PatientCreateReview from '@/views/dashboard/patient/PatientCreateReview';
import PatientChangeRegimen from '@/views/dashboard/patient/PatientChangeRegimen';
import PatientCreateOrder from '@/views/dashboard/patient/PatientCreateOrder';
import PatientOrderAddShipping from '@/views/dashboard/patient/PatientOrderAddShipping';
import PatientConfirmOrderCharge from '@/views/dashboard/patient/PatientConfirmOrderCharge';
import PatientConfirmOrderCheckout from '@/views/dashboard/patient/PatientConfirmOrderCheckout';

// Preset Regimen
import PresetRegimen from '@/views/dashboard/preset-regimen/PresetRegimen';
import Regimen from '@/views/dashboard/preset-regimen/Regimen';
import RegimenInfo from '@/views/dashboard/preset-regimen/RegimenInfo';
import RegimenCreatePreset from '@/views/dashboard/preset-regimen/RegimenCreatePreset';
import RegimenReview from '@/views/dashboard/preset-regimen/RegimenReview';
import RegimenView from '@/views/dashboard/preset-regimen/RegimenView';

// Orders
// import Orders from '@/views/dashboard/Orders';
import ListOrders from '@/views/dashboard/Orders/ListOrders';
import ShowOrder from '@/views/dashboard/Orders/ShowOrder';

// Top Picks
import TopPicks from '@/views/dashboard/top-picks/TopPicks';
import TopPickChange from '@/views/dashboard/top-picks/TopPickChange';

// Reports
import Reports from '@/views/dashboard/reports/Reports';

// 404
import PageNotFound from '@/views/PageNotFound';

Vue.use(Router);

import ApiService from '@/services/api.service';
import store from '@/store';
import { GET_USER_JWT } from '@/store/login/actions.types';
import { GET_PRACTICE } from '@/store/practice/actions.types';
import { LOGOUT } from '@/store/login/actions.types';
import { checkPracticeCompetion } from '@/helper';

/// JWT
import { getToken, destroyToken } from '../services/jwt.service.js';

const router = new Router({
  mode: 'history',
  routes: [{
    path: '/',
    redirect: '/login'
  },
  {
    path: '/shopify',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/termsofuse',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/standard_tnc',
    name: 'Standard',
    component: Standard
  },
  {
    path: '/affiliate',
    name: 'Affiliate',
    component: Affiliate,
    meta: {
      requiresAuth: true,
    },
    beforeEnter(to, from, next) {
      if (getToken()) {
        ApiService.setHeader();
        store.dispatch(GET_USER_JWT)
          .then(() => {
            store.dispatch(GET_PRACTICE);
            next();
          })
          .catch(() => {
            destroyToken();
            next('/login');
          });
      }
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/reset-password',
    component: ResetPassword
  },
  {
    path: '/login-first-time',
    name: 'LoginFirstTime',
    component: LoginFirstTime,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/set-password',
    component: SetPassword,
    name: 'SetPassword',
  },
  {
    path: '/create-account',
    component: Auth,
    props: true,
    beforeEnter(to, from, next) {
      if (to.meta.requiresAuth && getToken() && to.params.checkPractice !== false) {
        ApiService.setHeader();
        store.dispatch(GET_USER_JWT)
          .then(resp => {
            const user = resp.current_user;
            store.dispatch(GET_PRACTICE)
              .then(practiceResp => {
                const practice = practiceResp.data.practice;
                checkPracticeCompetion(practice, user, null, next);
              });
          })
          .catch(() => {
            destroyToken();
            store.dispatch(LOGOUT)
              .then(() => {
                next('/login');
              });
          });
      } else {
        next();
      }
    },
    children: [{
      path: 'step-1',
      component: CreateAccount,
      name: 'CreateAccount',
      props: true
    },
    {
      path: 'step-2',
      component: CreateAccount_s2,
      name: 'CreateAccountS2',
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'step-3',
      component: CreateAccount_s3,
      name: 'CreateAccountS3',
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'step-4',
      component: CreateAccount_s4,
      name: 'CreateAccountS4',
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'step-5',
      component: CreateAccount_s5,
      name: 'CreateAccountS5',
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'step-6',
      component: CreateAccount_s6,
      name: 'CreateAccountS6',
      meta: {
        requiresAuth: true
      }
    },
    ]
  },
  {
    path: '/dashboard',
    name: 'userdashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
    beforeEnter(to, from, next) {
      if (getToken()) {
        ApiService.setHeader();
        store.dispatch(GET_USER_JWT)
          .then(() => {
            store.dispatch(GET_PRACTICE);
            next();
          })
          .catch(() => {
            destroyToken();
            next('/login');
          });
      }
    },
    children: [{
      path: 'my-practice',
      component: MyPractice,
      name: 'MyPractice',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'invite-team',
      component: InviteTeam,
      name: 'InviteTeam',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'patients',
      component: Patients,
      name: 'Patients',
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: 'orders',
      component: ListOrders,
      name: 'Orders',
      meta: { requiresAuth: true }
    },
    {
      path: 'orders/:id',
      name: 'ShowOrder',
      component: ShowOrder,
      meta: { requiresAuth: true }
    },
    {
      path: 'patient/:id',
      component: Patient,
      name: 'Patient',
      meta: {
        requiresAuth: true,
      },
      children: [{
        path: 'continue/regimen',
        component: PatientCreateRegimen,
        name: 'PatientCreateRegimen',
        meta: {
          requiresAuth: true,
          fullWidth: true
        }
      },
      {
        path: 'continue/info',
        component: PatientCreateInfo,
        name: 'PatientCreateInfo',
        meta: {
          requiresAuth: true,
          fullWidth: true
        }
      },
      {
        path: 'continue/review',
        component: PatientCreateReview,
        name: 'PatientCreateReview',
        meta: {
          requiresAuth: true,
          fullWidth: true
        }
      },
      {
        path: 'view',
        name: 'PatientView',
        component: PatientView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'change-regimen',
        name: 'PatientChangeRegimen',
        component: PatientChangeRegimen,
        meta: {
          requiresAuth: true,
          fullWidth: true
        }
      },
      {
        path: 'create-order',
        name: 'PatientCreateOrder',
        component: PatientCreateOrder,
        meta: {
          requiresAuth: true,
          fullWidth: true
        }
      },
      {
        path: 'create-order/shipping',
        name: 'PatientOrderAddShipping',
        component: PatientOrderAddShipping,
        props: true,
        meta: {
          requiresAuth: true,
          fullWidth: true
        }
      },
      {
        path: 'create-order/charge',
        name: 'PatientConfirmOrderCharge',
        component: PatientConfirmOrderCharge,
        props: true,
        meta: {
          requiresAuth: true,
          fullWidth: true
        }
      },
      {
        path: 'create-order/confirm',
        name: 'PatientConfirmOrderCheckout',
        component: PatientConfirmOrderCheckout,
        props: true,
        meta: {
          requiresAuth: true,
          fullWidth: true
        }
      }]
    },
    {
      path: 'my-account',
      component: MyAccount,
      name: 'MyAccount',
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: 'top-picks',
      component: TopPicks,
      name: 'TopPicks',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'top-picks/:id',
      component: TopPickChange,
      name: 'TopPicksEdit',
      meta: {
        requiresAuth: true,
        fullWidth: true,
      }
    },
    {
      path: 'preset-regimen',
      component: PresetRegimen,
      name: 'PresetRegimen',
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: 'regimen',
      component: Regimen,
      name: 'Regimen',
      meta: {
        requiresAuth: true,
      },
      children: [{
        path: 'create/info',
        component: RegimenInfo,
        name: 'RegimenInfo',
        meta: {
          requiresAuth: true,
          fullWidth: true
        }
      },
      {
        path: 'create/preset',
        component: RegimenCreatePreset,
        name: 'CreatePreset',
        meta: {
          requiresAuth: true,
          fullWidth: true
        }
      },
      {
        path: 'create/review',
        component: RegimenReview,
        name: 'CreateReview',
        meta: {
          requiresAuth: true,
          fullWidth: true
        }
      },
      {
        path: ':id',
        component: RegimenView,
        name: 'RegimenEdit',
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'update/info/:id',
        component: RegimenInfo,
        name: 'RegimenUpdateInfo',
        meta: {
          requiresAuth: true,
          fullWidth: true
        }
      },
      {
        path: 'update/preset/:id',
        component: RegimenCreatePreset,
        name: 'RegimenUpdatePreset',
        meta: {
          requiresAuth: true,
          fullWidth: true
        }
      },
      {
        path: 'update/review/:id',
        component: RegimenReview,
        name: 'RegimenUpdateReview',
        meta: {
          requiresAuth: true,
          fullWidth: true
        }
      },
      ]
    },
    {
      path: 'reports',
      component: Reports,
      name: 'ReportsAll',
      meta: {
        requiresAuth: true,
      }
    }
    ]
  },
  { path: '*', component: PageNotFound }
  ]
});

// Check if user logged in
router.beforeEach(async(to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (getToken() == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;