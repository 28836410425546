<template>
  <div>
    <button 
      type="button" 
      class="close" 
      data-dismiss="modal" 
      aria-label="Close"
      @click="hideModalWindow" 
    >
      <i 
        class="fa fa-times" 
        aria-hidden="true"
      />
    </button>
    <!-- <img :src="warning" alt="Warning!" class="warning-icon"> -->
    <ValidationObserver 
      v-if="showExist == false" 
      ref="observer" 
      v-slot="{ invalid }" 
      tag="form" 
      @submit.prevent="addPatient"
    >
      <h4 class="modal-title text-center">
        Add New Client
      </h4>
      <p 
        v-for="(err, errIdx) in emailErrors"
        :key="errIdx"
        class="validation error mt-2"
      >
        Email: {{ err }}
      </p>
      <div class="row align-items-start">
        <div class="box-input col-md-6 pr-2">
          <validation-provider 
            v-slot="{ errors }" 
            name="First Name" 
            rules="required"
          >
            <label>Client Name</label>
            <input
              v-model="patient.first_name"
              placeholder="First Name"
              type="text"
              name="first_name"
              :class="errors.length ? 'error' : ''"
            >
            <p 
              v-if="errors.length" 
              class="validation error mt-2"
            >
              {{ errors[0] }}
            </p>
          </validation-provider>
        </div>
        <div class="box-input col-md-6 pl-2">
          <validation-provider 
            v-slot="{ errors }" 
            name="Last Name" 
            rules="required"
          >
            <label>&nbsp;</label>
            <input 
              v-model="patient.last_name"
              placeholder="Last Name" 
              type="text" 
              name="last_name"
              :class="errors.length ? 'error' : ''"
            >
            <p 
              v-if="errors.length" 
              class="validation error mt-2"
            >
              {{ errors[0] }}
            </p>
          </validation-provider>
        </div>
      </div>
      <div class="box-input">
        <validation-provider 
          v-slot="{ errors }" 
          name="Email" 
          rules="email|required"
        >
          <label for="patients">Email Address</label>
          <i
            class="fas fa-question-circle fa-xs"
            @mouseover="hover('newPatient')"
            @mouseleave="hoverOff('newPatient')"
          />
          <div
            data-tooltip-type="newPatient"
            class="tooltip-black"
          >
            Email address is optional at this point in the patient account creation,
            but it will be required later on. The reason we added this here is so we
            can have a way to check if the patient has an existing account with the practice.
            For example, if the patient's name is Alexandrea Walker, they might have an account under
          </div>
          <div class="form-group">
            <input
              v-model="patient.email"
              placeholder="Email"
              type="text"
              name="email"
              :class="errors.length || emailError ? 'error' : ''"
              @input="enterEmail"
            >
            <p 
              v-if="errors.length" 
              class="validation error mt-2"
            >
              {{ errors[0] }}
            </p>
            <p 
              v-if="emailError" 
              class="validation error field2 mt-2"
            >
              {{ emailError }}
            </p>
          </div>
        </validation-provider>
      </div>
      <div class="box-input">
        <label for="patientPhone">Client Phone Number</label>
        <div class="form-group">
          <input
            id="patientPhone"
            v-model="patient.phone"
            placeholder="(555) 555 - 5555"
            :class="phoneError || phoneRequiredError ? 'error' : ''"
            type="text"
            name="phone"
            @input="enterNumber" 
            @change="changeNumber" 
          >
          <p
            v-if="phoneError"
            class="validation error mt-2"
          >
            {{ phoneError }}
          </p>
          <p
            v-if="phoneRequiredError"
            class="validation error mt-2"
          >
            {{ phoneRequiredError }}
          </p>
        </div>
      </div>
      <div 
        v-if="isRole('super_admin')"
        class="box-input"
      >
        <label for="patients">Practice</label>
        <div class="form-group">
          <validation-provider 
            v-slot="{ errors }" 
            name="Practice" 
            rules="required"
          >
            <!-- <select
              id="practices"
              v-model="practiceId"
              class="select-lg"
              :class="errors.length ? 'select-lg error' : 'select-lg'"
              name="compare-to"
            >
              <option
                value=""
                selected
              >
                Select practice
              </option>
              <option 
                v-for="(practiceObj, idx) in practice"
                :key="idx"
                :value="practiceObj.id"
              >
                {{ practiceObj.name }}
              </option>
            </select> -->
            <SelectPractice 
              v-model="practiceId"
              title="Select practice"
              :items="valuesForSelect(practices)"
              :total="practicesTotal"
              :loading="isPracticesLoading"
              :name="`${name}-practice-select-add-pat`"
              :filters="filters"
              @load-more="loadMorePractices"
            />
            <p
              v-if="errors.length"
              class="validation error mt-2"
            >
              {{ errors[0] }}
            </p>
          </validation-provider>
        </div>
      </div>
      <div class="box-input">
        <label for="patients">Skincare Pro</label>
        <div class="form-group">
          <validation-provider 
            v-slot="{ errors }" 
            name="Physician" 
            rules="required"
          >
            <select-physican
              v-model="patient.user_id"
              title="All Skincare Pros"
              :items="valuesForSelect(physicians)"
              :total="physiciansTotal"
              :loading="isPhysiciansLoading"
              :name="`${name}-patinet-select`"
              :filters="filters"
              @load-more="loadMorePhysicians"
            />
            <p 
              v-if="errors.length" 
              class="validation error mt-2"
            >
              {{ errors[0] }}
            </p>
          </validation-provider>
        </div>
      </div>
      <div class="mb-3">
        <button 
          type="submit" 
          class="btn btn-primary btn-cta"
        >
          Create Client's Account
        </button>
      </div>
    </ValidationObserver>
    <div v-else>
      <div class="text-center">
        <img 
          :src="warning" 
          alt="Warning!" 
          class="warning-icon"
        >
        <h4 class="modal-title mb-4">
          Is {{ patient.first_name }} {{ patient.last_name }} an Existing Client?
        </h4>
      </div>
      <p>
        Looks like there is already an existing client with this email address.
        Is this the same client you're entering?
      </p>
      <div class="row mb-2 align-items-end">
        <div class="col-sm-4 pb-3 pb-sm-0">
          <span class="font-weight-bold">Name</span>
          <p class="mb-0">
            {{ existPatient.name }}
          </p>
        </div>
        <div class="col-sm-5">
          <span class="font-weight-bold">Email</span>
          <p class="mb-0">
            {{ existPatient.email }}
          </p>
        </div>
        <div class="col-sm-3 text-right">
          <router-link 
            v-if="existPatient.status == 'completed'" 
            :to="{
              name: 'PatientView',
              params: {
                id: patient.id
              }
            }"
            tag="a"
            class="action-top"
          >
            View
          </router-link>
          <router-link 
            v-if="existPatient.status == 'pending'" 
            :to="{
              name: 'PatientCreateRegimen',
              params: {
                id: patient.id
              }
            }"
            class="action-top"
          >
            Continue
          </router-link>
        </div>
      </div>
      <div><hr></div>
      <p class="text-center action-top label-is-not">
        No, this isn't the same client.
      </p>
      <div class="pt-3">
        <a 
          class="btn btn-primary w-100" 
          href
          @click.prevent="enterDifferntEmail" 
        >Enter A Different Email</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .label-is-not {
    color: #a989de;
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 1;
    letter-spacing: 0.3px;
  }
  #add_patients .content-down {
    width: 100%;
    margin-right: 0;
    .title {
      width: 100%;
    }
    .multi-select {
      width: 100%;
    }
    .multi-select {
      .multi-select-list {
        // height: 80px;
      }
    }
    input[type="text"] {
      width: 100%;
    }
  }
</style>

<script>
import warning from '@images/warning.png';
import { mapGetters } from 'vuex';
import {
  ValidationProvider,
  ValidationObserver,
  extend
} from 'vee-validate';
import { email } from 'vee-validate/dist/rules';

import { patient } from '@/store/patients/initialObject';
import { ADD_PATIENT } from '@/store/patients/actions.types';
import { AsYouType } from 'libphonenumber-js';
import { validPhone } from '@/helper';

import physician from '@/mixins/physician';
import SelectPhysican from '@/views/components/Select';
import SelectPractice from '@/views/components/Select';
import practices from '@/mixins/practices';
import { get } from 'lodash';

email['message'] = 'Please enter a valid email address';
extend('email', email);

// Component to add patient to practice
// @group Patinets
export default {
  name: 'AddPatientModal',

  // @vuese 
  // Components
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectPhysican,
    SelectPractice
  },

  mixins: [physician,practices],

  props: {
    // @vuese
    // variable to show or hide modal
    showModal: {
      type: Boolean,
      // The default value is: `false`
      default: false,
    },
    // @vuese
    // name to make select tags unique
    name: {
      type: String,
      // The default value is: `''`
      default: '',
    }
  },

  data() {
    return {
      showExist: false,
      existPatient: {},
      sending: false,
      patient: {...patient},
      emailError: '',
      emailErrors: [],
      warning,
      loadOnInit: false,
      practiceId: '',
      filters: {},
      phoneError: '',
      phoneRequiredError: '',
    };
  },

  computed: {
    ...mapGetters([
      'practice'
    ])
  },

  watch: {
    showModal: {
      handler() {
        // this.$emit('hide-modal-window');
        this.showExist = false;
        this.patient.email = '';
        this.patient.first_name = '';
        this.patient.last_name = '';
        this.patient.user_id = '';
        this.practiceId = '';
        this.emailError = '';
        this.emailErrors = [];
        this.patient.phone = '';
        this.phoneError = '';
        this.phoneRequiredError = '';
        if(this.$refs.observer)
          this.$refs.observer.reset();
      }
    },

    practiceId(newValue, oldValue) {
      const { filters } = this;
      filters['practice_id'] = this.practiceId;

      if (newValue != oldValue) {
        this.loadMorePhysicians({
          type: 'search',
          filters,
        });
        this.patient.user_id = '';
      }
    }
  },

  beforeMount() {
    if (this.isRole('super_admin')) {
      this.loadOnInit = true;
    }
  },


  // @vuese
  // Load physicans if current user is super admin
  mounted() {
    if (!this.isRole('super_admin')) {
      this.loadMorePhysicians({type: 'search'});
    }
  },

  methods: {
    // Used to check if phone valid for US
    // @arg The argument is a integet number
    validPhone,

    // @vuese
    // Used to create patient
    async addPatient() {
      if (await this.isValid() && !this.emailError) {
        const patient = {...this.patient};
        if (!patient.email) delete patient.email;
        this.sending = true;
        this.$store.dispatch(ADD_PATIENT, {
          patient: patient,
          practiceId: this.getRequestPracticeId()
        })
          .then(resp => {
            const patient = resp.data.patient;
            // @vuese
            // Add patinet event
            this.$emit('add-patient');
            this.$router.push({name: 'PatientCreateRegimen', params: {id: patient.id}});
          })
          .catch(err => {
            if (err.status == 302) {
              this.showExist = true;
              this.existPatient = err.data.patient;
            } else {
              if (get(err, 'data.errors.email')) {
                this.emailErrors = err.data.errors.email;
              }
              if(get(err, 'data.errors.phone')) {
                this.phoneError = err.data.errors.phone;
              }
            }
          })
          .finally(() => {
            this.sending = false;
          });
      }
    },

    // @vuese
    // Used to change type of phone and validate it
    enterNumber() { 
      let count_numbers = 0, last_number = 0, max_numbers = 10;
      if(this.patient.phone[0] == 1)
        max_numbers += 1;

      for (let i = 0; i < this.patient.phone.length; i++) {
        if(/^\d+$/.test(this.patient.phone[i])){
          count_numbers +=1;
          if(count_numbers > max_numbers && last_number == 0)
            last_number = i;
        }          
      }
      if(last_number != 0){
        this.patient.phone = this.patient.phone.slice(0,last_number);
      }

      if(event.inputType && event.inputType.indexOf('deleteContent') == -1){
        if(this.validatePhone()){
          this.patient.phone = new AsYouType('US').input(
            this.patient.phone
          );
        }
      }
    },

    // @vuese
    // Used to convert phone number
    changeNumber() {
      if (this.validatePhone()) {
        this.patient.phone = new AsYouType('US').input(
          this.patient.phone
        );
      }
    },

    // @vuese
    // Used to clear email errors, when user type something
    enterEmail() {
      this.emailError = '';
      this.emailErrors = [];
    },

    // @vuese
    // Used to show that current email is already taken
    enterDifferntEmail() {
      this.emailError = 'Email address already in use';
      this.showExist = false;
    },

    // @vuese
    // Used to validate phone number
    validatePhone() {
      let valid = this.validPhone(this.patient.phone);
      if (valid) {
        this.phoneRequiredError = '';
        this.phoneError = '';
      } else {
        if(this.patient.phone.length == 0){
          this.phoneRequiredError = 'Phone number required';
          this.phoneError = '';
        }else{
          this.phoneRequiredError = '';
          this.phoneError = 'Please enter valid phone number';
        }
      }
      return valid;
    },

    // @vuese
    // Check if phone is valid
    isValid() {
      let phone = this.validatePhone();
      let obs = this.$refs.observer.validate();
      return phone && obs;
    },

    // @vuese
    // Used to close modal and clear form data and remove validation messages
    hideModalWindow(){
      // close modal
      this.$emit('hide-modal-window');
      this.showExist = false;
      this.patient.email = '';
      this.patient.first_name = '';
      this.patient.last_name = '';
      this.patient.user_id = '';
      this.emailError = '';
      this.patient.emailErrors = [];
      if(this.$refs.observer)
        this.$refs.observer.reset();
    },

    // @vuese
    // Get practice id for super admin
    getRequestPracticeId() {
      return this.isRole('super_admin') ? `/${this.practiceId}` : '';
    },

    // @vuese
    // Used to show tooltip
    // @arg HTML element data type
    hover(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.add('open');
    },

    // @vuese
    // Used to hide tooltip
    // @arg HTML element data type
    hoverOff(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.remove('open');
    }
  }
};
</script>