<template>
  <header class="main-header">
    <div class="container container-md">
      <div class="header-wrap">
        <div class="header-logo">
          <img 
            :src="logo" 
            alt="Logo"
          >
        </div>
        <div 
          v-if="currentUser"
          class="header-right" 
        >
          <p>Hi {{ `${currentUser.first_name} ${currentUser.last_name}` }}, you've been invited to become the administrator <span v-if="practice">of {{practice.name}}'s RegimenMD account</span>. Please complete the following to set up your account:</p>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import logo from '@images/logo-light.png';
import { mapGetters } from 'vuex';

// @vuese
// @group Create Account
export default {
  name: 'Header',
  data() {
    return {
      logo,
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'practice',
    ])
  }
};
</script> 