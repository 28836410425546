import {
  GET_TOP_PICKS
} from './actions.types';
import {
  SET_TOP_PICKS
} from './mutations.types';
import { TopPicksService } from '../../services/api.service';

const initialState = {
  top_picks: [],
};

const state = Object.assign({}, initialState);

const getters = {
  topPicks(state) {
    return state.top_picks;
  }
};

const actions = {
  [GET_TOP_PICKS](context, params) {
    return  new Promise((resolve, reject) => {
      TopPicksService.get(params)
        .then(resp => {
          context.commit(SET_TOP_PICKS, resp.data.physicians);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  }
};

const mutations = {
  [SET_TOP_PICKS](state, items) {
    state.top_picks = items;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};