var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"title-table d-flex justify-content-between"},[_c('h4',[_vm._v("Recent Orders")]),_vm._v(" "),(_vm.orders.length)?_c('router-link',{staticClass:"action-top",attrs:{"to":{
        name: 'Orders',
        params: {
          patient_id: _vm.patient.id,
          patientDateRange: _vm.patientDateRange
        }
      }}},[_vm._v("\n      View All Orders\n    ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"wrap-table order recent-orders"},[_c('table',{staticClass:"table table-box table-striped"},[(_vm.orders.length)?_c('thead',[_vm._m(0)]):_vm._e(),_vm._v(" "),_c('tbody',[_vm._l((_vm.orders),function(order){return _c('tr',{key:order.id},[_c('td',[_vm._v(_vm._s(order.created_at))]),_vm._v(" "),_c('td',[(order.status === 'completed' && order.shopify !== null)?_c('router-link',{staticClass:"link_action",attrs:{"to":{
                name: 'ShowOrder',
                params: {
                  id: order.id
                }
              }}},[_vm._v("\n              #"+_vm._s(order.shopify.order_number)+"\n            ")]):_c('span',[_vm._v("-")])],1),_vm._v(" "),_c('td',[_c('span',[_vm._v(_vm._s(order.physician.name))])]),_vm._v(" "),_c('td',[_c('span',[_vm._v(_vm._s(order.total_price))])]),_vm._v(" "),_c('td',{attrs:{"align":"right"}},[_c('router-link',{staticClass:"link_action",attrs:{"to":{
                name: 'ShowOrder',
                params: {
                  id: order.id
                }
              }}},[_vm._v("\n              "+_vm._s(order.status === 'completed' && order.shopify !== null ? 'View' : 
                order.status == 'failed' ? 'Failed' : 'Confirmed')+"\n            ")])],1)])}),_vm._v(" "),(!_vm.orders.length)?_c('tr',[_c('td',{staticClass:"pt-4 pb-4",attrs:{"align":"center"}},[_vm._v("\n            No orders have been placed\n          ")])]):_vm._e()],2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',[_vm._v("Date Ordered")]),_vm._v(" "),_c('th',[_vm._v("Order Number")]),_vm._v(" "),_c('th',[_vm._v("Skincare Pro")]),_vm._v(" "),_c('th',[_vm._v("Total price")]),_vm._v(" "),_c('th')])}]

export { render, staticRenderFns }