<template>
  <div class="wrap-table border-table bagde-table">
    <div 
      v-if="loading"
      class="preloader"
    >
      <i />
    </div>
    <div 
      class="product-wrapper"
      :class="selectedProducts.length > 0 ? 'dnd-group' : ''"
    >
      <div 
        v-if="selectedProducts.length == 0"
        class="added-products"
      >
        <span class="d-flex align-items-center justify-content-center">1</span>
        <p>Add products in the order clients should apply them</p>
      </div>
      <ProductElement 
        v-for="(product,index) in selectedProducts"
        :key="product.variant_id + index"
        :index="index"
        :data-index="index"
        :product="product"
      />
    </div>
    <div 
      v-if="selectedProducts.length > 0"
      class="row py-3 text-center info-wrapper"
      v-html="'Click and drag to reorder'"
    />
    <div class="row py-3 text-right subtotal-wrapper">
      <strong class="pr-3">Subtotal</strong>
      <div class="separator" />
      <strong class="pl-3">${{ selectedProducts.length > 0 ? formatMoney(calcSubtotal()) : '0' }}</strong>
    </div>
    <div class="pt-1 apply-btn-wrapper">
      <button 
        v-if="selectedProducts.length > 0"
        class="btn btn-primary"
        @click="$emit('create')"
      >
        Save
      </button>
      <button 
        v-else
        class="btn btn-primary"
        disabled=true
      >
        Next
      </button>
      <p
        v-if="productsError"
        class="validation error mt-2" 
      >
        {{ productsError }}
      </p>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import { formatMoney } from '@/helper';
import ProductElement from './SelectedProductElement';
import { ContainerMixin } from 'vue-slicksort';

// @group Components
export default {
  name: 'SelectedProductList',

  components: {
    ProductElement
  },

  mixins: [ContainerMixin],

  props: {
    // @vuese
    // Used to show if product still loading
    loading: {
      type: Boolean,
      default: false
    },
    // @vuese
    // Parent component selected products
    selectedProducts: {
      type: Array,
      default: () => []
    },
    // @vuese
    // Prodcut error
    productsError: {
      type: String,
      default: ''
    }
    
  },

  data() {
    return {
    };
  },

  methods: {
    // @vuese
    // Used format money
    // @arg Price value
    formatMoney (val) {
      return formatMoney(val);
    },
    // @vuese
    // Calc total price of all products
    calcSubtotal() {
      return this.selectedProducts.reduce((sum, product) => {
        return sum + parseFloat(product.price);
      }, 0);
    }
  }
};
</script>