import { render, staticRenderFns } from "./CreateAccount_s2.vue?vue&type=template&id=d133ec68&"
import script from "./CreateAccount_s2.vue?vue&type=script&lang=js&"
export * from "./CreateAccount_s2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports