<template>
  <div
    :class="$route.name.toLowerCase()"
  >
    <Navigation
      v-if="!fullWidthTemplate"
      v-model="practiceId"
      @set-practices="setPractices"
    />
    <PracticeHeader 
      v-if="!fullWidthTemplate"
    />
    <section
      v-if="!fullWidthTemplate"
      id="dashboard_main"
      :key="practiceId"
      class="dashboard-main"
    >
      <keep-alive include="ListOrders,Patients">
        <router-view
          v-if="!isDashboard"
          :practice-id="practiceId"
          :practices="practices"
        />
        <DashboardMain 
          v-if="isDashboard"
          :practices="practices"
        />
      </keep-alive>
    </section>
    <router-view 
      v-if="fullWidthTemplate"
    />
  </div>
</template>

<script>
import Navigation from './partials/Navigation';
import PracticeHeader from './partials/Header';
import DashboardMain from './dashboard/DashboardMain';
import ApiService from '@/services/api.service';

// @vuese
// @group Dashboards
export default {
  name: 'Dashboard',
  components: {
    Navigation,
    PracticeHeader,
    DashboardMain
  },
  data() {
    return {
      fullWidthTemplate: false,
      isDashboard: false,
      navOpen: false,
      practiceId: '',
      practices: [],
    };
  },
  watch: {
    '$route': {
      handler: 'routeWatcher',
      immediate: true
    },
    practiceId() {
      ApiService.setPracticeId(this.practiceId ? `/${this.practiceId}` : '');
      this.superAdminRedirect();
    },
    fullWidthTemplate() {
      if (ApiService.practiceId)
        this.practiceId = parseInt(ApiService.practiceId.replace('/', ''));
    }
  },
  beforeMount() {
    // change view to full-width
    this.routeWatcher();
  },
  methods: {
    // @vuese
    // Used to watch when route change and change to full width template if needed
    routeWatcher() {
      const meta = this.$route.meta;
      if (meta.fullWidth) this.fullWidthTemplate = true;
      else this.fullWidthTemplate = false;

      this.isDashboard = this.$route.path == '/dashboard';
    },
    // @vuese
    // Used to change selected Practice ID
    // @arg Practice ID
    changePractice(value) {
      this.practiceId = value;
    },
    // @vuese
    // Used to set loacal practices array, only for super admin used
    setPractices(practices) {
      this.practices = practices;
    },
    // @vuese
    // Used to redirect to patients page when super admin change practice id
    // and redirect to patients page
    superAdminRedirect() {
      const { name } = this.$route;
      switch(name) {
      case 'PatientView': 
        this.$router.push({name: 'Patients'});
        break;
      }
    }
  }
};
</script>