<template>
  <div class="top-filters action-filters">
    <div class="row align-items-end">
      <div class="col-xl-3 col-md-5 filter-l">
        <search-input
          id="search"
          v-model="query"
          type="text"
          name="search"
          placeholder="Search"
          classes="content-filter"
        />
      </div>
      <div class="col-xl-9 col-md-7">
        <div class="row-filters">
          <label for>Filter By</label>
          <multi-select
            v-model="selectedPhysicians"
            :title="physicianMultiSelectTitle"
            :items="valuesForSelect(physicians)"
            :total="physiciansTotal"
            :loading="isPhysiciansLoading"
            :selected-items="selectedPhysicians"
            @load-more="loadMorePhysicians"
          /> 
          <multi-select
            v-model="selectedPatients"
            :title="patientMultiSelectTitle"
            :items="valuesForSelect(patients)"
            :total="patientsTotal"
            :loading="isPatientsLoading"
            :selected-items="selectedPatients"
            :new-list="newList"
            @load-more="loadMorePatients"
            @reinited="newList = false"
          />

          <!-- <date-picker
            v-model="dateRange"
            :range="true"
          /> -->
          <date-range-picker-custom 
            v-model="dateRange"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- Top filters -->
</template>

<script>
import { encodeQueryData } from '@/helper';
import SearchInput from '@/views/components/SearchInput';
import MultiSelect from '@/views/components/MultiSelect';
// import DatePicker from '@/views/components/DatePicker';
import DateRangePickerCustom from '@/views/components/DateRangePickerCustom';
import { PatientsService, PhysiciansService } from '@/services/api.service';
import { GET_PATIENT } from '@/store/patients/actions.types';
import physician from '@/mixins/physician';

// @vuese
// @group Orders
export default {
  name: 'OrdersFilter',

  components: {
    SearchInput,
    MultiSelect,
    // DatePicker,
    DateRangePickerCustom,
  },

  mixins: [physician],

  props: {
    // @props Filter object
    value: {
      type: Object,
      default: () => {
        return {
          filters: {},
          search: ''
        };
      }
    }
  },

  data() {
    return {
      dateRange: [ new Date(Date.now() - 30 * 86400000), new Date() ],
      patients: [],
      patientsTotal: 0,
      selectedPatients: [],
      query: '',
      patientsListVisible: false,
      isPatientsLoading: false,
      patientMultiSelectTitle: 'All Clients',
      physicianMultiSelectTitle: 'All Skincare Pros',
      searchTimeout: null,
      withoutDate: false,
      newList: false
    };
  },

  watch: {
    dateRange() {
      this.buildFilterObject();
    },

    query() {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(()=>{
        this.buildFilterObject();
      }, 500);
    },

    selectedPhysicians() {
      if(this.isRole('super_admin')){
        this.newList = true;
        this.loadMorePatients({type: 'search', orders: {name: 'asc'}, filters: {physicians: this.selectedPhysicians}});
      }
      if(this.selectedPhysicians.length <= 0){
        this.physicianMultiSelectTitle = 'All Skincare Pros';
      }
      else{
        this.selectedPhysicians.length == 1 ? this.fetchPhysicianInfo(this.selectedPhysicians[0]) : this.physicianMultiSelectTitle = this.selectedPhysicians.length + ' skincare pros';
      }
      this.buildFilterObject();
    },

    async selectedPatients() {
      if(this.selectedPatients.length <= 0){
        this.patientMultiSelectTitle = 'All Clients';
      }
      else{
        this.selectedPatients.length == 1 ? await this.fetchPatientInfo(this.selectedPatients[0]) : this.patientMultiSelectTitle = this.selectedPatients.length + ' clients';
      }

      this.buildFilterObject();
    }
  },

  mounted() {
    this.loadMorePatients({type: 'search', orders: {name: 'asc'}});
    this.loadMorePhysicians({type: 'search'});
    this.buildFilterObject();
  },

  activated() {
    if (this.query) {
      this.query = '';
    }
    if (this.$route.params.patient_id) {
      this.selectedPatients = [this.$route.params.patient_id];
      this.withoutDate = true;
    } else if (this.selectedPatients.length) {
      this.selectedPatients = [];
      this.dateRange = [ new Date(Date.now() - 30 * 86400000), new Date() ];
      this.withoutDate = true;
    }

    if (this.$route.params.patientDateRange) {
      this.dateRange = this.$route.params.patientDateRange;
      this.withoutDate = false;
    }
  },

  methods: {
    // @vuese 
    // Used to convert object to query string
    // @arg object
    encodeQueryData,

    // @vuese
    // Used to fetch patient Info
    // @arg patient Id
    fetchPatientInfo(patientId) {
      const params = {
        id: patientId
      };

      this.$store
        .dispatch(GET_PATIENT, params)
        .then(resp => {
          this.patientMultiSelectTitle = resp.data.patient.name; 
        });
    },

    fetchPhysicianInfo(userId){
      PhysiciansService.get(
        userId
      ).then(
        ({ data }) => {
          this.physicianMultiSelectTitle = data.physician.name;
        }
      )
    },

    // @vuese
    // Used to build query object and emit to parent that filter data was changed
    buildFilterObject() {
      this.clearValue();
      if(this.selectedPhysicians.length > 0) this.value['filters']['by_physicians'] = this.selectedPhysicians;
      if(this.selectedPatients.length > 0) this.value['filters']['by_patients'] = this.selectedPatients;
      if(this.query !== '') this.value['search'] = this.query;
      if(this.withoutDate){
        this.withoutDate = false;
      }else{
        if(this.dateRange.length > 0) this.value['filters']['by_date'] = this.dateRange.filter(dateItem => dateItem !== null).map(dateItem => {
          // dateItem.setHours(0,0,0,0);
          return dateItem.toISOString();
        });
      }

      this.$emit('change');
    },

    // @vuese
    // Used to clear filters
    clearValue() {
      this.value.filters = {};
      delete this.value.search;
    },

    // @vuese
    // Used to toggle physican list
    showPhysiciansList() {
      this.physiciansListVisible = !this.physiciansListVisible;
    },

    // @vuese
    // Used to toggle patients list
    showPatientsList() {
      this.patientsListVisible = !this.patientsListVisible;
    },

    // @vuese
    // Used to prepare resouce for select
    valuesForSelect(resources, map={title: 'name', value: 'id'}) {
      return resources.map(item => { return {value: item[map.value], title: item[map.title] }; });
    },

    // @vuese
    // Used to load patients 
    // @arg query object
    loadMorePatients(queryObj) {
      this.isPatientsLoading = true;

      const type = queryObj.type;
      delete queryObj.type;
      if(!queryObj.orders){
        queryObj.orders = {name: 'asc'};
      }
      if(this.isRole('super_admin') && this.selectedPhysicians.length > 0){
        queryObj.filters = {physicians: this.selectedPhysicians};
      }

      return PatientsService.get(
        this.encodeQueryData(queryObj)
      ).then(
        ({ data }) => {
          if (type == 'search'){
            this.patients = data.patients.items;
          }            
          else
            this.patients = [...this.patients, ...data.patients.items];

          this.patientsTotal = data.patients.total_items;
        }
      ).finally(() => {
        this.isPatientsLoading = false;
      });
    }
  }
};
</script>
