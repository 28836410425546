<template>
  <div class="content-wrap">
    <h2>{{ edit.name.value ? edit.name.value : regimen.name }}</h2>
    <Notifications />
    <ul class="breadcrumb-page pt-2">
      <li class="crumb">
        <router-link 
          class="hover-btn" 
          :to="{
            name: 'PresetRegimen'
          }"
        >
          Preset Regimens
        </router-link>
      </li>
      <li class="crumb active">
        {{ edit.name.value ? edit.name.value : regimen.name }}
      </li>
    </ul>
    <div class="row row-layout-370">
      <div class="col-xl-8">
        <h5>Regimen Information</h5>
        <div class="wrap-table border-table">
          <div class="row row-group">
            <div 
              class="col-sm-3"
            >
              <span class="text-up">
                <strong>NAME OF REGIMEN</strong>
              </span>
            </div>
            <div 
              v-if="edit.name.edit == false"
              class="col-sm-9 p-admin-info pr-0"
            >
              <div class="row-large">
                <div class="col-md-9">
                  <span class="d-block">
                    {{ regimen.name }}
                  </span>
                </div>
                <div class="col-md-3 action-box">
                  <a 
                    class="link_action hover-btn" 
                    @click.prevent="editInfo('name')"
                  >
                    Edit
                  </a>
                </div>
              </div>
            </div>
            <div 
              v-if="edit.name.edit"
              class="col-sm-9 p-admin-edit pr-0"
            >
              <div class="row-large">
                <div class="col pr-0">
                  <div class="form-group">
                    <input 
                      id="admin_name" 
                      v-model="regimen.name"
                      type="text"
                      :class="nameError ? 'col error' : ''"
                    >
                  </div>
                </div>
              </div>
              <div class="row-large">
                <div class="col-xl-5 mb-xl-0 mb-2" />
                <div class="col-xl-7 pr-0">
                  <div class="text-right">
                    <a 
                      class="btn btn-bordered right-space hover-btn"
                      @click="cancelEdit('name')"
                    >Cancel</a>
                    <a 
                      class="btn btn-primary autowidth hover-btn"
                      @click="saveInfo('name')"
                    >Save</a>
                  </div>
                </div>
              </div>
              <p 
                v-if="nameError"
                class="validation error mt-2"
              >
                {{ nameError }}
              </p>
            </div>
          </div>
          <div class="row row-group">
            <div class="col-sm-3">
              <span class="text-up">
                <strong>DESCRIPTION OF REGIMEN</strong>
              </span>
            </div>
            <div 
              v-if="edit.description.edit == false"
              class="col-sm-9 p-physician-edition pr-0"
            >
              <div class="row-large">
                <div class="col-md-9">
                  <span>
                    {{ regimen.description }}
                  </span>
                </div>
                <div class="col-md-3 action-box">
                  <a 
                    class="link_action hover-btn" 
                    @click.prevent="editInfo('description')"
                  >
                    Edit</a>
                </div>
              </div>
            </div>
            <div 
              v-if="edit.description.edit"
              class="col-sm-9 p-physician-edit pr-0"
            >
              <div class="row-large">
                <div class="col pr-0">
                  <p>
                    <textarea
                      v-model="regimen.description"
                    />
                  </p>
                </div>
              </div>
              <div class="row-large">
                <div class="col-xl-5 mb-xl-0 mb-2" />
                <div class="col-xl-7 pr-0">
                  <div class="text-right">
                    <a
                      class="btn btn-bordered right-space hover-btn"
                      @click.prevent="cancelEdit('description')"
                    >
                      Cancel
                    </a>
                    <a 
                      class="btn btn-primary autowidth hover-btn"
                      @click.prevent="saveInfo('description')"
                    >
                      Save
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row row-group staff-options">
            <div class="col-sm-3">
              <span class="text-up">
                <strong>PRIMARY CONCERN</strong>
              </span>
            </div>
            <div 
              v-if="edit.tags.edit == false"
              class="col-sm-9 staff-info pr-0"
            >
              <div class="row-large">
                <div class="col-md-9">
                  <span>
                    {{ regimen.tags.map(tag => {
                      return getTagName(tag).value;
                    }).join(', ') }}</span>
                </div>
                <div class="col-md-3 action-box">
                  <a 
                    class="link_action hover-btn"
                    @click.prevent="editInfo('tags')"
                  >
                    Edit
                  </a>
                </div>
              </div>
            </div>
            <!-- Staff edition -->
            <div 
              v-if="edit.tags.edit"
              class="col-sm-12 staff-edition pr-0"
            >
              <div class="row-large">
                <div 
                  v-for="tag in regimenTags"
                  :key="tag.name"
                  class="col-xl-4 col-sm-6"
                >
                  <input
                    :id="tag.name"
                    type="checkbox" 
                    :checked="tagChacked(tag.name)"
                    @change="handleTag(tag.name)"
                  >
                  <label :for="tag.name">
                    {{ tag.value }}
                  </label>
                </div>

                <p 
                  v-if="tagError"
                  class="validation error mt-2"
                >
                  {{ tagError }}
                </p>
              </div>
              <div class="row-large">
                <div class="col-xl-5 mb-xl-0 mb-2" />
                <div class="col-xl-7 pr-0">
                  <p>&nbsp;</p>
                  <div class="text-right">
                    <a 
                      class="btn btn-bordered right-space hover-btn"
                      @click.prevent="cancelEdit('tags')"
                    >
                      Cancel</a>
                    <a
                      class="btn btn-primary autowidth hover-btn"
                      @click.prevent="saveInfo('tags')"
                    >Save</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Practice allergan APP -->
        <div class="title-table d-flex justify-content-between">
          <h5>Regimen</h5>
          <router-link 
            class="hover-btn action-top" 
            :to="{
              name: 'RegimenUpdatePreset',
              params: {
                id: regimen.id
              }
            }"
            tag="a"
          >
            Change Regimen
          </router-link>
        </div>
        <div class="wrap-table order figs-box">
          <table class="table table-box">
            <thead>
              <tr>
                <th />
                <th>Product</th>
                <th class="th-width">
                  Order Frequency
                </th>
                <th>Price</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="product in regimen.products"
                :key="product.id"
              >
                <td>
                  <div 
                    v-if="product.image"
                  >
                    <figure class="product-image md-figure">
                      <span class="badge-counter" />
                      <figcaption>
                        {{ renderRecommendationType(product.recomendation_type) }}
                      </figcaption>
                      <img 
                        v-if="product.image"
                        :src="product.image" 
                        alt="product 1"
                      >
                    </figure>
                    <span 
                      v-if="product.sku"
                      class="img-detail"
                    >
                      SKU: {{ product.sku }}</span>
                  </div>
                </td>
                <td>
                  <h6> {{ product.title }}</h6>
                  <p class="product-info">
                    {{ product.vendor }} 
                    <span v-if="product.variant_title != 'Default Title'">| {{ product.variant_title }}</span>
                  </p>
                  <p 
                    v-if="product.usage_instruction"
                    class="usage_instruction mb-0"
                  >
                    Usage Instructions: {{ product.usage_instruction }}
                  </p>
                </td>
                <td>
                  {{ product.frequency != 'One time' ? `Every ${product.frequency} Days` : product.frequency }}
                </td>
                <td>
                  ${{ product.price }}
                </td>
                <td align="right">
                  <a
                    class="link_action"
                    href="#"
                    @click.prevent="editProductModal(product.shopify_product_graph_id, product)"
                  >Edit</a>
                </td>
              </tr>
              <tr>
                <td />
                <td />
                <td>
                  <b>Subtotal</b>
                </td>
                <td>
                  <b>${{ regimen.products.length > 0 ? formatMoney(calcSubtotal()) : '0' }}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- End Practice allergan APP -->
      </div>
      <div class="col-xl-4 aside-sticky">
        <h5>&nbsp;</h5>
        <div class="aside-box">
          <div class="row">
            <div class="col-sm-12">
              <p 
                style="margin-bottom:0;" 
                class="text-center"
              >
                No longer need this regimen?
                <br>
                <a
                  href="#"
                  class="hover-btn hover-btn action-top"
                  @click.prevent="showDeleteModal=true"
                >Remove Preset Regimen</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr>
    </div>

    <!-- Remove modal -->
    <Modal
      :show-modal="showDeleteModal" 
      modal-id="edit_modal"
      @hide-modal-window="showDeleteModal=false"
    >
      <div class="text-center">
        <img 
          :src="warning" 
          alt="Warning!" 
          class="warning-icon"
        >
        <h4 class="modal-title">
          Remove Preset Regimen
        </h4>
        <p>Are you sure want to remove this regimen from your practice?</p>
        <div class="bottom-cta mb-3">
          <a 
            href="#"
            class="btn btn-primary hover-btn"
            @click.prevent="removeRegimen"
          >Remove</a>
        </div>
        <div class="bottom-cta">
          <a 
            href="#"
            class="btn btn-bordered w-100 hover-btn"
            @click.prevent="showDeleteModal=false"
          >Cancel</a>
        </div>
      </div>
    </Modal>
    <!-- End remove modal -->

    <Modal
      :show-modal="showEditModal"
      modal-id="edit_modal"
      @hide-modal-window="showEditModal=false"
    >
      <div class="text-center">
        <img
          :src="warning" 
          alt="Warning!" 
          class="warning-icon" 
        >
        <h4 class="modal-title">
          Before you go ...
        </h4>
        <p>You have unsaved changes. Are you sure you want to continue?</p>
        <div class="mb-3">
          <a
            href="#"
            class="btn btn-primary w-100"
            @click.prevent="continueWithoutSaving(modalCheck)"
          >
            Continue Without Saving
          </a>
        </div>
        <div class>
          <a
            class="btn btn-bordered w-100 hover-btn"
            @click.prevent="showEditModal = false"
          >Cancel</a>
        </div>
      </div>
    </Modal>

    <Modal 
      :show-modal="showProductModal" 
      :modal-type="'popup-lg'"
      modal-id="add_product"
      @hide-modal-window="showProductModal = false"
    >
      <ProductModal
        :key="showProductModal"
        :product-id="productId"
        :modal-action="modalAction"
        :selected-product="selectedProduct"
        @remove-product="removeProduct"
        @hide-modal-window="showProductModal=false"
        @product-selected="productSelected"
      />
    </Modal>

    <div 
      v-if="showEditModal || showDeleteModal || showProductModal" 
      class="modal-backdrop fade show"
    />
  </div>
</template>

<script>
import warning from '../../../../images/warning.png';
import Modal from '../partials/Modal';
import ProductModal from '../../dashboard/partials/ProductModal';
import { RegimenService } from '../../../services/api.service';
import { formatMoney, renderRecommendationType } from '@/helper';
import { tags } from '@/store/regimen/initialObject';

import { isEqual } from 'lodash';

// @vuese
// @group Preset Regimen
export default {
  name: 'RegimenView',
  components: {
    Modal,
    ProductModal,
  },
  props: {
    // @vuese
    // Regimen object
    regimen: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showDeleteModal: false,
      showEditModal: false,
      showProductModal: false,
      productId: null,
      selectedProduct: null,
      modalAction: 'update',
      sending: false,
      modalCheck: '',
      warning,
      nameError: '',
      tagError: '',
      regimenTags: tags,
      edit: {
        name: {
          edit: false,
          value: '',
        },
        description: {
          edit: false,
          value: ''
        },
        tags: {
          edit: false,
          value: [],
        },
      }
    };
  },
  watch: {
    regimen: {
      immediate: true,
      handler() {
        if (this.regimen.name.trim().length == 0) {
          this.nameError = 'This field is required';
        } else {
          this.nameError = '';
        }

        if (!this.regimen.tags.length) {
          this.tagError = 'Primary Concern is required';
        } else {
          this.tagError = '';
        }
      }
    }
  },
  methods: {
    renderRecommendationType,
    // @vuese
    // Used to caclulate subtotal in regimen
    calcSubtotal() {
      return this.regimen.products.reduce((sum, product) => {
        return sum + parseFloat(product.price);
      }, 0);
    },
    // @vuese
    // Used format money
    // @arg Price data `String / Number`
    formatMoney (val) {
      return formatMoney(val);
    },
    // @vuese
    // Used to open edit tags box and store current value
    editInfo(type) {
      this.edit[type].edit = true;
      if (type == 'tags') {
        this.edit[type].value = [...this.regimen[type]];
      } else {
        this.edit[type].value = this.regimen[type];
      }
    },
    // @vuese
    // Used to cancel edit box, and to check if value was changed
    // if yes then show continue without saving modal
    // @arg Type of box `String`
    cancelEdit(type) {
      switch (type) {
      case 'name':
      case 'description':
        if (this.edit[type].value != this.regimen[type]) {
          this.showEditModal = true;
          this.modalCheck = type;
        } else {
          this.continueWithoutSaving(type);
        }
        break;
      case 'tags':
        if (!isEqual(this.edit.tags.value, this.regimen.tags)) {
          this.showEditModal = true;
          this.modalCheck = type;
        } else {
          this.continueWithoutSaving(type);
        }
        break;
      }
    },
    // @vuese
    // Used to restore old info close edit box `String`
    continueWithoutSaving(type) {
      this.showEditModal = false;
      this.edit[type].edit = false;
      this.regimen[type] = this.edit[type].value;
    },
    // @vue
    // Used to update regimen data
    // @arg Type of property that we need to update `String`
    async saveInfo(type) {
      if (type == 'phone' || this.$refs[type] && await this.$refs[type].validate()) {
        return;
      } 
      let data, updating;
      switch (type) {
      case 'name':
        data = {
          name: this.regimen.name
        };
        updating = 'name';
        break;
      case 'description':
        data = {
          description: this.regimen.description
        };
        updating = 'description';
        break;
      case 'tags': 
        data = {
          tags: this.regimen.tags.map(tag => { return {'name': tag};})
        };
        updating = 'tags';
        break;
      }

      RegimenService.update({regimen: data}, this.regimen.id, this.getRequestPracticeId())
        .then(resp => {
          this.edit[type].edit = false;
          
          if (resp.data.regimen && resp.data.regimen.name)
            this.edit.name.value = resp.data.regimen.name;

          this.flashSuccess(`You updated ${updating}`);
        })
        .catch(err => {
          this.flashError(`Error updating ${updating}`);
        });
    },
    // @vuese
    // Used to update selected product from modal
    // @arg Product object `Object`
    productSelected(product) {
      if (this.sending) return;
      this.sending =false;
      this.showProductModal = false;

      return RegimenService.updateProduct({
        product: product,
      }, this.regimen.id, product.id, this.getRequestPracticeId())
        .then(resp => {
          this.flashSuccess('You updated product');
        })
        .catch(err => {
          this.flashError('Error updating product');
        })
        .finally(() => {
          this.sending = false;
        });
    },
    // @vuese
    // Used to remove product from regimen
    // @arg Product object `Object`
    removeProduct(value) {
      if (this.sending) return;

      this.showProductModal = false;
      const productIndex = this.regimen.products.findIndex(product => {
        return product.variant_graph_id == value.variant_graph_id;
      });
      if (productIndex !== -1) {
        const productId = this.regimen.products[productIndex].id;

        this.sending = true;
        RegimenService.deleteProduct(this.regimen.id, productId, this.getRequestPracticeId())
          .then(reps => {
            this.regimen.products.splice(productIndex, 1);
            this.flashSuccess('You removed product');
          })
          .catch(err => {
            this.flashError('Error removing product');
          })
          .finally(() => {
            this.sending = false;
          });
      }
    },
    // @vuese
    // Used to remove regimen
    async removeRegimen () {
      if (this.sending) return;
      this.sending = true;
      RegimenService.delete(this.regimen.id, this.getRequestPracticeId())
        .then(resp => {
          this.$router.push({name: 'PresetRegimen'});
          this.flashSuccess('You successfully removed regimen');
        })
        .catch(err => {
          this.flashError('Error deleting regimen');
        })
        .finally(() => {
          this.showDeleteModal = false;
          this.sending = false;
        });
    },
    // @vuese
    // Used to get tagname by tag key
    // @arg Tag key `String`
    getTagName(key) {
      return this.regimenTags.find(tag => tag.name == key);
    },
    // @vuese
    // Used to add and remove tags from regimen
    // @arg Tag key `String`
    handleTag(tagValue) {
      const tagIndex = this.regimen.tags.findIndex(tag => {
        return tag == tagValue;
      });
      if (tagIndex != -1) {
        this.regimen.tags.splice(tagIndex, 1);
      } else {
        this.regimen.tags = [tagValue]
        this.tagError = '';
      }

      if(!this.regimen.tags.length){
        this.tagError = 'Primary Concern is required.';
      }
    },
    // @vuese
    // Used to check if tag alreadt added to regimen
    // @arg Tag key `String`
    tagChacked(tagValue) {
      return this.regimen.tags.find(tag => tag == tagValue);
    },

    // @vuese
    // Used to open edit modal 
    // @arg Product ID `String`
    // @arg / Selected product object `Object`
    editProductModal(productId, selectedProduct) {
      this.productId = productId;
      this.selectedProduct = selectedProduct;
      this.modalAction = 'update';
      this.showProductModal = true;
    },
    // @vuese
    // Used to get practice ID for super admin user
    getRequestPracticeId() {
      return this.isRole('super_admin') ? 
        this.regimen.practice ? 
          `/${this.regimen.practice.id}` : 
          this.currentUser.practice.id : '';
    }
  }
};
</script>