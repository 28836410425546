<template>
  <div v-if="product">
    <div
      v-if="sending"
      class="preloader"
    >
      <i />
    </div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      @click="$emit('hide-modal-window')"
    >
      <i
        class="fa fa-times"
        aria-hidden="true"
      />
    </button>
    <ValidationObserver
      ref="observer"
    >
      <transition name="slide">
        <div
          v-if="isVisible(blockTypes.reactivate)"
          class="text-center pb-2"
        >
          <img
            :src="warning"
            alt="Warning!"
            class="warning-icon"
          >
          <h4 class="modal-title mb-4">
            Would You Like to Reactivate This Subscription?
          </h4>
          <hr>
        </div>
      </transition>

      <div class="row">
        <div
          class="col-md-3"
        >
          <div class="image-box">
            <img
              v-if="productSelected.image"
              :src="productSelected.image"
              alt="image"
            >
            <div
              v-if="productSelected.sku"
              class="sku mt-2 mb-2 text-left"
            >
              <strong>SKU: {{ productSelected.sku }}</strong>
            </div>
            <div
              class="text-left"
            >
              <a
                :href="`${shopifyURL}/products/${product.handle}`"
                target="_blank"
              >View on RegimenPro<sup>®</sup></a>
            </div>
          </div>
        </div>
        <div
          class="col-md-9"
        >
          <h2 class="modal-title">
            {{ product.title }}
          </h2>
          <p>
            by {{ product.vendor }}<br><br>
            <span v-html="product.shortDesc" />
          </p>
          <h3>${{ productSelected.price }}</h3>
          <span
            v-if="product.variants && product.variants.length > 1"
            class="title-detail"
          >
            Select size</span>
          <div
            v-if="product.variants && product.variants.length > 1"
            class="row no-gutters form"
          >
            <div
              v-for="variant in product.variants"
              :key="variant.id"
              class="form-radio col-4"
            >
              <input
                :id="variant.id"
                type="radio"
                name="size"
                :checked="productSelected.variant_graph_id == variant.id"
                :class="variantError ? 'error' : ''"
                :value="variant.id"
              >
              <label
                :for="variant.id"
                @click="slectVariant(variant.id)"
              >
                {{ variant.title }}</label>
            </div>
            <div
              v-if="variantError"
              class="validation error mt-2 col-12"
            >
              {{ variantError }}
            </div>
          </div>
          <div
            v-if="forPhysician"
          >
            <hr>
            <a
              class="show-more collapsed"
              data-toggle="collapse"
              href="#more_about"
              @click="readMore=!readMore"
            >
              Read more about this product
            </a>
            <br>
            <transition name="slide-fade">
              <div
                v-if="readMore"
                id="more_about"
              >
                <p>{{ forPhysician }}</p>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <hr>
      <Subscription
        v-if="productSelected.subscriptionObj"
        :product="productSelected"
        :frequency-options="frequencyOptions"
        :hide-modal="hideModal"
        @edit-subscription="handeleEditSubsrciption"
      />
      <transition name="slide">
        <div
          v-if="isVisible(blockTypes.info)"
        >
          <div class="row">
            <div class="form-group col-md-6">
              <validation-provider
                v-slot="{ errors }"
                name="Recommendation type"
                rules="required"
              >
                <label for="state">Recommendation type</label>
                <select
                  id="state"
                  v-model="productSelected.recomendation_type"
                  :class="errors.length ? 'select-md col error' : 'select-md col'"
                  name="state"
                >
                  <option value="Core Product">
                    Core Product
                  </option>
                  <option value="Also Recommended">
                    Also Recommended
                  </option>
                </select>
                <p
                  v-for="(err, errKey) in errors"
                  :key="errKey"
                  class="validation error mt-2"
                >
                  {{ err }}
                </p>
              </validation-provider>
            </div>
            <div class="form-group col-md-6">
              <validation-provider
                v-slot="{ errors }"
                name="Time of Use"
                rules="required"
              >
                <label for="state">Time of Use</label>
                <select
                  id="state"
                  v-model="productSelected.time_of_use"
                  :class="errors.length ? 'select-md col error' : 'select-md col'"
                  name="state"
                >
                  <option value="Morning and Night">
                    Morning and Night
                  </option>
                  <option value="Morning">
                    Morning
                  </option>
                  <option value="Night">
                    Night
                  </option>
                  <option value="Per Package Instructions">
                    Per Package Instructions
                  </option>
                </select>
                <p
                  v-for="(err, errKey) in errors"
                  :key="errKey"
                  class="validation error mt-2"
                >
                  {{ err }}
                </p>
              </validation-provider>
            </div>
            <div
              v-if="!productSelected.subscriptionObj"
              class="form-group col-md-6"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Subscription Frequency"
                rules="required"
              >
                <label for="state">Subscription Frequency</label>
                <select
                  v-model="productSelected.frequency"
                  :class="errors.length ? 'select-md col error' : 'select-md col'"
                  name="state"
                  @change="handleChange($event)"
                >
                  <option value="One time">
                    One Time
                  </option>
                  <option
                    v-for="(frequency, valIdx) in frequencyOptions"
                    :key="valIdx"
                    :value="frequency.value"
                  >
                    {{ frequency.text }}
                  </option>
                </select>
                <p
                  v-for="(err, errKey) in errors"
                  :key="errKey"
                  class="validation error mt-2"
                >
                  {{ err }}
                </p>
              </validation-provider>
            </div>
            <div class="form-group col-md-6">
              <label for="country">Usage Instructions</label>
              <textarea v-model="productSelected.usage_instruction" />
            </div>
          </div>
          <div
            class="add-action-box"
          >
            <button
              v-if="hideAddBtn()"
              type="submit"
              class="btn btn-primary"
              @click="addProduct"
            >
              {{ modalAction == 'create' ? submitText : 'Save' }}
            </button>
            <a
              v-if="modalAction == 'update' && !productSelected.subscriptionObj"
              class="hover-btn link"
              @click.prevent="removeProduct"
            >
              {{ removeText ? removeText : 'Remove from Regimen' }}</a>
          </div>
        </div>
      </transition>
    </ValidationObserver>
  </div>
  <div v-else>
    <inline-notification
      type="warning"
      extra-class="out-of-stock"
    >
      <span class="font-normal">Product is not available for Regimen App Channel</span>
    </inline-notification>
    <div class="add-action-box" >
      <a
        class="hover-btn link"
        @click.prevent="removeProduct"
      >
        Remove from Regimen
      </a>
    </div>
  </div>
</template>

<script>
import warning from '@images/warning.png';
import ShopifyService from '@/services/shopify';
import { shopifyURL } from '@/services/config';
import {
  ValidationProvider,
  ValidationObserver,
  extend
} from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { ProductService } from '@/services/api.service';
import {
  formatMetaModel,
  findMeta,
  formatStorefrontMoney,
  getShopifyId,
  stripHTML,
  stripStyle
} from '@/helper';
import InlineNotification from '@/views/components/InlineNotification';
import Subscription from '@/views/dashboard/partials/Subscription';

// Install rules
extend('required', required);

// @group Partials
export default {
  name: 'ProductModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    InlineNotification,
    Subscription
  },

  props: {
    // @vuese
    // Variable that tell if product out of stock or not
    disableOnOutOfStock: {
      type: Boolean,
      default: false
    },
    // @vuese
    // Product ID
    productId: {
      type: String,
      default: '',
    },
    // @vuese
    // Selected product object
    selectedProduct: {
      type: Object,
      default() {
        return {};
      },
    },
    // @vuese
    // Modal action `update` or `create`
    modalAction: {
      type: String,
      default: '',
    },
    // @vuese
    // Submit button text
    submitText: {
      type: String,
      default: 'Add Product To Regimen',
    },
    // @vuese
    // Remove button text
    removeText: {
      type: String,
      default: 'Remove from Regimen',
    }
  },

  data() {
    return {
      sending: false,
      variantAvaliable: true,
      productSelected: {
        shopify_product_id: null,
        shopify_product_graph_id: null,
        handle: '',
        variant_id: null,
        variant_graph_id: null,
        variant: {},
        discount_product_id: '',
        discount_variant_id: '',
        usage_instruction: '',
        recomendation_type: 'Core Product',
        time_of_use: 'Per Package Instructions',
        frequency: 'One Time',
        product: {},
        image: '',
        sku: '',
        title: '',
        vendor: '',
        price: '',
        variant_title: '',
        selling_plan_id: '',
      },
      blockTypes: {
        info: 'info',
        change: 'change',
        cancel: 'cancel',
        reactivate: 'reactivate',
      },
      warning,
      variantError: '',
      frequencyOptions: [],
      product: {},
      forPhysician: '',
      shopifyURL: `https://${shopifyURL}`,
      readMore: false,
      dataShow: 'info',
    };
  },

  mounted() {
    this.getProduct();
  },

  methods: {
    // @vuese
    // Used emit to parent that product selected
    async addProduct () {
      if (await this.isValid()) {
        this.$emit('product-selected', this.productSelected);
      }
    },

    handleChange(event){
      this.productSelected.frequency = event.target[event.target.selectedIndex].text
    },

    // @vuese
    // Used to show edit subscription box
    // @arg Box type
    handeleEditSubsrciption(val) {
      this.dataShow = val;
    },

    // @vuese
    // Used to check if we need to hide add button
    hideAddBtn() {
      return this.disableOnOutOfStock ? this.product.availableForSale && this.variantAvaliable : true;
    },

    // @vuese
    // Used to check if modal valid
    async isValid() {
      if (!this.productSelected.variant_id) {
        this.variantError = 'Please select size';
        if(this.productSelected.product.id){
          let reg = /^\d+$/;
          // let url = atob(this.productSelected.product.id);
          let url = this.productSelected.product.id;
          let id = url.split('/');
          if(reg.test(id[id.length -1])){
            return await this.$refs.observer.validate() && id[id.length -1];
          }
        }
      }
      return await this.$refs.observer.validate() && this.productSelected.variant_id;
    },
    // @vuese
    // Used to tell parent that we need to remove product from selected list
    // @arg Product object
    removeProduct() {
      this.$emit('remove-product', this.productSelected);
    },

    // @vuese
    // Used to handle variant select
    // @arg Variant ID
    slectVariant(id) {
      this.fillVariantData(id);
      this.variantError = '';
    },


    // @vuese
    // used to load product from Shopify
    getProduct() {
      if (!this.productId) return;
      this.sending = true;
      this.productSelected.shopify_product_id = getShopifyId(this.productId);
      this.productSelected.shopify_product_graph_id = this.productId;

      // get product
      ShopifyService.fetch(this.productId)
        .then(product => {
          this.productSelected.handle  = product ? product.handle : "";
          this.productSelected.title   = product ? product.title : "";
          this.productSelected.vendor  = product ? product.vendor : "";
          this.product                 = product ? product : null;
          this.productSelected.product = this.product;
          if (product && product.images && product.images.length) {
            this.productSelected.image = product.images[0].src;
          }

          this.productSelected.price   = product ? product.variants[0].price.amount : "";
          this.productSelected.sku     = product ? product.variants[0].sku : "";
          if(product){
            product.shortDesc            = stripStyle(product.descriptionHtml.split('<!--short description-->')[0]);
            this.getMetaByHandle(product.handle);
          }
        });
    },

    // @vuese
    // Used to load meta data for product from Shopify
    // @arg Product handle
    getMetaByHandle(handle) {
      ProductService.getProductMetaByHandle(handle)
        .then(resp => {
          if (resp.data.data.productByHandle) {
            this.product.meta = formatMetaModel(resp.data.data.productByHandle.metafields);
            this.product.variantsMeta = formatMetaModel(resp.data.data.productByHandle.variants.edges);

            this.getSellingPlan(handle)
            this.productSelected.frequency = 'One time';


            const discountProductId = findMeta(this.product.meta, 'subscriptions', 'discount_product_id');
            if(discountProductId)
              this.productSelected.discount_product_id = discountProductId.value;

            const forPhysician = findMeta(this.product.meta, 'detail', 'for_physician');
            if (forPhysician) this.forPhysician = forPhysician.value;

            const tempElement = document.createElement('div');
            tempElement.innerHTML = this.product.descriptionHtml;
            const useInstructionsElement = tempElement.querySelector('.use-instructions');
            this.productSelected.usage_instruction = useInstructionsElement ? useInstructionsElement.textContent.trim() : null;
          }

          if (this.selectedProduct){
            this.productSelected = this.selectedProduct;
            this.productSelected.product = this.product;
            this.fillVariantData(this.productSelected.variant_graph_id);
          } else {
            // select variant if we have only one
            this.fillVariantData(this.product.variants[0].id);
          }
        })
        .finally(() => {
          this.sending = false;
          if (this.modalAction == 'create') {
            this.addProduct();
          }
        });
    },

    getSellingPlan(handle) {
      ProductService.getSellingPlanByVariant(handle)
        .then(resp => {
          if(resp.data){
            if (
              resp.data.data &&
              resp.data.data.product &&
              resp.data.data.product.sellingPlanGroups &&
              resp.data.data.product.sellingPlanGroups.edges.length > 0 &&
              resp.data.data.product.sellingPlanGroups.edges[0].node.sellingPlans
            ) {
              this.frequencyOptions = resp.data.data.product.sellingPlanGroups.edges[0].node.sellingPlans.edges.map(plan => ({
                value: plan.node.id,
                text: plan.node.name
              }));
            }
          }
        })
        .finally(() => {
          this.sending = false;
        });
    },

    // @vuese
    // Used to fill selected product with data from variant
    // @arg Variant ID
    fillVariantData(variantId) {
      const variant = this.product.variants.find(variantObj => {
        return variantObj.id == variantId;
      });
      this.productSelected.variant_id       = getShopifyId(variant.id);
      this.productSelected.variant_graph_id = variant.id;
      this.productSelected.variant          = variant;
      this.productSelected.price            = formatStorefrontMoney(variant.price.amount);
      this.productSelected.sku              = variant.sku;
      this.productSelected.variant_title    = variant.title;
      this.variantAvaliable                 = variant.available;

      this.fillVariantMeta(variantId);
    },
    // @vuese
    // Used to fill selected product with metadata from variant
    // @arg Variant ID
    fillVariantMeta(variantId) {
      if (this.product.variantsMeta) {
        const variantMetaData = this.product.variantsMeta.find(variantMeta => {
          return variantMeta.id = variantId;
        });
        const formatedMeta = formatMetaModel(variantMetaData.metafields);
        const discounVariantIdObj = findMeta(formatedMeta, 'subscriptions', 'discount_variant_id');
        if (discounVariantIdObj)
          this.productSelected.discount_variant_id = discounVariantIdObj.value;
      }
    },

    // @vuese
    // Used to check if box visible
    // @arg Box type
    isVisible(type) {
      return this.dataShow == type;
    },

    // @vuese
    // Used to tell parent that we closing modal
    hideModal() {
      this.$emit('hide-modal-window');
    }
  }
};
</script>