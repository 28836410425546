<template>
  <div class="col-xl-3">
    <h4>Practice Homepage Link</h4>
    <div class="onboard-box">
      <div class="onboard-item">
        <div class="item-header">
          <p>{{ currentPractice.name }}</p>
          <a
            data-type="home"
            target="_blank" 
            :href="currentPractice.practice_home_url"
            @click.prevent="copingLink"
          >
            {{ currentPractice.practice_home_url }}
          </a>
          <i 
            id="home-link" 
            data-type="home" 
            class="fas fa-link" 
            @click.prevent="copyLink" 
          />
          <div 
            v-if="copyed.home" 
            class="home_link timelink"
          >
            Copied to Clipboard
          </div>
        </div>
      </div>
      <p
        class="mb-0 mt-2"
      >
        Your practice homepage link is intended for marketing use, when clients are not in your office.
      </p>
    </div>
    <h4 class="mt-3">
      RegimenPro<sup>®</sup> Create Account Link
    </h4>
    <div class="onboard-box">
      <div class="onboard-item">
        <div class="item-header">
          <p>
            RegimenPro<sup>®</sup> Create Account Link
          </p>
          <a 
            target="_blank"
            data-type="registration"
            :href="currentPractice.practice_reg_url"
            @click.prevent="copingLink"
          >
            {{ currentPractice.practice_reg_url }}
          </a>
          <i 
            id="reg-link" 
            data-type="registration" 
            class="fas fa-link" 
            @click.prevent="copyLink" 
          />
          <div 
            v-if="copyed.registration" 
            class="reg_link timelink"
          >
            Copied to Clipboard
          </div>
        </div>
      </div>
      <p class="mb-0 mt-2">
        Once you inform clients on RegimenPro<sup>®</sup>,
        send them this link so they can quickly sign up in office.
      </p>
    </div>
  </div>
  <!-- /. Oboard box -->
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '@/services/api.service';

// Component that show current practice home page and current practice register link
// @group Dashboard main
export default {
  name: 'AsideLinks',

  props: {
    // List of all loaded practices
    practices: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      sending: false,
      copyed: {
        home: false,
        registration: false
      },
      selected: [],
      currentPage: 1,
      perPage: 7,
      orders: {
        date_added: 'desc',
      },
      filters: {},
      search: '',
      orderTypes: {
        name: 'name',
        email: 'email',
        physician: 'physician',
        date: 'date_added',
        status: 'status',
      },
      currentPractice: {}
    };
  },

  computed: {
    ...mapGetters(['currentUser', 'practice'])
  },

  watch: {
    practice: {
      deep: true,
      handler() {
        this.definePractice();
      }
    }
  },

  mounted() {
    this.definePractice();
  },

  methods: {
    // @vuese
    // Used to сopy data to clipboard
    copyLink(){
      var text = event.target.parentElement.querySelector('a').href;
      var type_link = event.target.dataset.type;
      var textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.maxHeight = 0;
      
      event.target.parentNode.insertBefore(textArea, event.target.parentNode.childNodes[0]);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      event.target.parentNode.removeChild(textArea);

      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text)
      if(type_link == 'home'){
        this.copyed.home = true;
        let timerId = setTimeout(() => {
          this.copyed.home = false;
        }, 5000);
      }else{
        this.copyed.registration = true;
        let timerId = setTimeout(() => {
          this.copyed.registration = false;
        }, 5000);
      }
    },

    // @vuese
    // Used to сopy data to clipboard
    copingLink(){
      var text = event.target.href;
      var type_link = event.target.dataset.type;
      var textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.maxHeight = 0;
      
      event.target.parentNode.insertBefore(textArea, event.target.parentNode.childNodes[0]);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      event.target.parentNode.removeChild(textArea);

      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text);
      if(type_link == 'home'){
        this.copyed.home = true;
        let timerId = setTimeout(() => {
          this.copyed.home = false;
        }, 5000);
      }else{
        this.copyed.registration = true;
        let timerId = setTimeout(() => {
          this.copyed.registration = false;
        }, 5000);
      }
    },

    // @vuese
    // Used to find current selected practice when current use is super admin
    findPractice() {
      return this.practices.find(practice => practice.id == ApiService.practiceId.replace('/', ''));
    },

    // @vuese
    // Used to define current practice
    definePractice() {
      const practice = this.findPractice();
      if (this.isRole('super_admin') && practice) {
        this.currentPractice = practice;
      } else {
        this.currentPractice = this.practice;
      }
    }
  }
};
</script>
<style scoped>
.item-header{
    position: relative;
}
.timelink{
    position: absolute;
    padding: 10px;
    margin-top: 10px;
    background: rgba(0,0,0,0.7);
    right: 25%;
}
.timelink:before{
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: -9px;
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    
    border-bottom: 10px solid  rgba(0,0,0,0.7);
}
</style>