<template>
  <div class="content-wrap">
    <Notifications />
    <h2>
      {{ isRole('super_admin') ? 'Practices' : 'My Practice' }}
    </h2>
    <div
      v-if="!superAdminShowPractice()"
      class="wrap-table border-table"
    >
      Please choose a practice first.
    </div>
    <div
      v-if="superAdminShowPractice()"
      class="row row-layout-370"
    >
      <div class="col-md-12">
        <h5>Practice Team</h5>
        <team 
          :can-user-edit="canUserEdit()"
        />
        <div class="head-tooltip">
          <h5>
            Practice Allergan APP 
            <i
              class="fas fa-question-circle fa-xs"
              @mouseover="hover('allergan')"
              @mouseleave="hoverOff('allergan')"
            />
          </h5>
          <div
            data-tooltip-type="allergan"
            class="tooltip-black"
          >
            Connect all the doctors in your practice to a single Allergan identifier using these fields, or add an Allergan identifier to each doctor individually.
          </div>
        </div>
        <allerganApp 
          :can-user-edit="canUserEdit()"
        />
          
        <h5>Practice Information</h5>
        <information 
          :can-user-edit="canUserEdit()"
        />
          
        <h5>Addresses</h5>
        <addresses 
          :can-user-edit="canUserEdit()"
        />
      </div>
      <div class="col-md-12 aside-sticky">
        <div 
          v-if="canUserEdit()" 
          class="aside-box wrap-table border-table text-center "
        >
          <h5>Add Practice Member</h5>
          <div class="row">
            <div class="col-sm-12 text-center">
              <p>Add Skincare Pros and Staff members to your practice team.</p>
              <router-link 
                :to="{
                  name: 'InviteTeam'
                }" 
                tag="a" 
                class="btn btn-primary"
              >
                Invite New Practice Member
              </router-link>
            </div>
          </div>
        </div>
        <div 
          v-else 
          class="aside-box wrap-table border-table"
        >
          <div class="row">
            <div class="col-sm-12 text-center">
              <p>If you see incorrect information, please contact your practice admin or email them at 
                <a 
                  class="btn-link" 
                  :href="`mailto: ${practice.admin.email}`"
                >{{ practice.admin.email }}</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import team from './team';
import allerganApp from './allerganApp';
import information from './information';
import addresses from './addresses';
import {
  SUPER_ADMIN_GET_PRACTICE
} from '@/store/practice/actions.types';
import ApiService from '@/services/api.service';

// Component to show Practice page,
// This page includes Practice team, Practice Allergan app
// Practice inforamtion, Practice addreses
// @group Practice
export default {
  name: 'MyPractice',

  components: {
    team,
    allerganApp,
    information,
    addresses
  },

  data() {
    return {
      practiceData: null,
    };
  },

  computed: {
    ...mapGetters([
      'currentUser',
      'practice',
      'address',
    ])
  },

  beforeMount() {
    if(document.needRefresh && document.needRefresh == true){
      delete document.needRefresh;
      this.$store.dispatch(SUPER_ADMIN_GET_PRACTICE);
    }
    if (this.isRole('super_admin') && ApiService.practiceId) {
      this.$store.dispatch(SUPER_ADMIN_GET_PRACTICE)
        .then(resp => {
          this.practiceData = resp.data.practice;
        });
    }
  },
  methods: {
    // @vuese
    // Used to show tooltip
    // @arg HTML element data type
    hover(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.add('open');
    },
    // @vuese
    // Used to hide tooltip
    // @arg HTML element data type
    hoverOff(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.remove('open');
    },
    // @vuese 
    // Used to check if user staff, and if it is, then remove ability to edit forms
    canUserEdit(){
      return this.isRole('staff') ? false : true; 
    },
    // @vuese
    // Used to check if practice selected when current use is super admin
    superAdminShowPractice() {
      return this.isRole('super_admin') ? ApiService.practiceId && this.practiceData : true;
    }
  }
};
</script>