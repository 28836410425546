<template>
  <div class="row row-group drag-box"><!-- product-inner -->
    <div class="col-1 column-badge">
      <span class="badge-counter" />
    </div>
    <div class="col-11 pt-0 row row-group product-box">
      <div class="col-1 pr-0 icon">
        <i class="fas fa-grip-vertical" />
      </div>
      <div class="col-10 pl-3 pt-3 pb-3 content">
        <div class="row-large">
          <div 
            v-if="product.image"
            class="col-lg-4"
          >
            <figure class="product-image md-figure">
              <!-- <span class="badge-counter" /> -->
              <figcaption>
                {{ product.recomendation_type.split(' ')[0] }}
              </figcaption>
              <img 
                v-if="product.image"
                :src="product.image" 
                alt="product 1"
              >
            </figure>
            <span 
              v-if="product.sku"
              class="img-detail"
            >
              SKU: {{ product.sku }}
            </span>
          </div>
          <div class="col-lg-8">
            <p class="title-sm mb-1">
              {{ product.title }}
            </p>
            <p class="brand-and_variant_title">
              {{ product.vendor }} 
              <span v-if="product.variant_title != 'Default Title'">| {{ product.variant_title }}</span>
            </p>
            <p class="frequency_and_title">
              Frequency: {{ product.frequency != 'One time' ? `Every ${product.frequency} Days` : product.frequency }} <br>
              Price: ${{ product.price }}
            </p>
            <p
              v-if="product.time_of_use"
              class="when-to-apply mb-1"
            >
              When to apply: {{ product.time_of_use }}
            </p>
            <p 
              v-if="product.usage_instruction"
              class="usage_instruction mb-0"
            >
              Usage Instructions: {{ product.usage_instruction | truncate(30, true) }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-1 pt-3 edit-btn">
        <a
          class="link_action hover-btn" 
          :data-index="index"
        >
          Edit
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ElementMixin} from 'vue-slicksort';

// @group Components
// Drag Product
export default {
  name: 'SelectedProductElement',
  
  mixins: [ElementMixin],
 
  props: {
    // @vuese
    // Product Object
    product: {
      type: Object,
      default: () => {}
    },
    // @vuese
    // ELement index
    index: {
      type: Number,
      default: -1
    }
  }
};
</script>