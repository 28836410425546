<template>
  <div class="container container-md-sm">
    <div class="steps-wrap">
      <div class="step current">
        <p> Set Password</p>
        <span class="step-marker" />
      </div>
      
      <div class="step">
        <p>Add Practice Info</p>
        <span class="step-marker" />
      </div>

      <div class="step">
        <p>Add Practice Address</p>
        <span class="step-marker" />
      </div>
        
      <div class="step">
        <p>Add Physicians</p>
        <span class="step-marker" />
      </div>
      
      <div class="step">
        <p>Add Practice Staff</p>
        <span class="step-marker" />
      </div>
      
      <div class="step">
        <p>Review</p>
        <span class="step-marker" />
      </div>
    </div>
    <!-- /. steps-wrap -->
      
    <div class="steps-content">
      <div class="top-title">
        <h3>Set Password for Your Admin Account</h3>
      </div>
      <form @submit.prevent="setPassword">
        <div class="row row-create-account">
          <div class="col-md-8">
            <div class="f-box form form-white">
              <div class="reset-input">
                <div class="form-group mb-0">
                  <label for="pass0">Password</label>
                  <i 
                    :class="pass_visible ? 'show-pass fas fa-eye' : 'show-pass fas fa-eye-slash'" 
                    @click="pass_visible = !pass_visible" 
                  />
                  <input 
                    id="pass0"
                    v-model="password"
                    placeholder="Password"
                    :type="pass_visible ? 'text' : 'password'" 
                    autocomplete="new-password"
                    name="password"
                  >
                </div>
                <div class="char-content">
                  <p :class="validatePass()['length'] ? 'char checked' : 'char no-checked' ">
                    At least 8 characters
                  </p>
                  <p :class="validatePass()['specialChar'] ? 'char checked' : 'char no-checked' ">
                    At least one special character (e.g. !, &amp;, #, etc.)
                  </p>
                  <p :class="validatePass()['haveNumber'] ? 'char checked' : 'char no-checked' ">
                    At least one number
                  </p>
                </div>
              </div>
              <div class="reset-input pb-0">
                <div class="form-group mb-0">
                  <label for="confirm_pass">Confirm password</label>
                  <i 
                    :class="confirm_pass_visible ? 'show-pass fas fa-eye' : 'show-pass fas fa-eye-slash'" 
                    @click="confirm_pass_visible = !confirm_pass_visible" 
                  />
                  <input 
                    id="confirm_pass"
                    v-model="confirm_password"
                    placeholder="Confirm Password"
                    :type="confirm_pass_visible ? 'text' : 'password'" 
                    autocomplete="new-password"
                    name="reset"
                  >
                </div>
                <div class="char-content">
                  <p :class="passEqual() ? 'char checked' : 'char no-checked' ">
                    Password matches
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <Sidebar />
            <button 
              type="submit" 
              class="btn btn-primary" 
              :disabled="!validated()"
            >
              Create Account
            </button>
          </div>
        </div>
      </form>
    </div>
    <!-- /. row-create-account -->
    <ContactUs />
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import { GET_USER, SET_PASSWORD } from '@/store/login/actions.types';
import { LOGIN, LOGOUT } from '@/store/login/actions.types';
import { SET_PRACTICE_CUSTOM, GET_PRACTICE } from '@/store/practice/actions.types';
import Sidebar from './partials/Sidebar';
import { getToken, destroyToken } from '@/services/jwt.service';
import ContactUs from '../components/ContactUs';

// @vuese
// @group Create Account
export default {
  name: 'CreateAccount',
  components: {
    Sidebar,
    ContactUs,
  },
  data() {
    return {
      password: '',
      confirm_password: '',
      pass_visible: false,
      confirm_pass_visible: false,
      invitationToken: '',
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
    ])
  },
  mounted() {
    this.fetchUser();
  },
  methods: {
    // @vuese
    // Validate password
    validatePass() {
      return {
        length: this.password.length >= 8,
        specialChar: /[^a-zA-Z0-9\-\/]/.test(this.password),
        haveNumber: /[0-9]/g.test(this.password)
      };
    },
    // @vuese
    // Used to check if password equal
    passEqual() {
      return this.password != '' && this.password === this.confirm_password;
    },
    // @vuese
    // Used to check if pass validated
    validated() {
      const validatePass = this.validatePass();
      let passValidated = true;

      Object.keys(validatePass).forEach(passPatternKey => {
        if (!validatePass[passPatternKey]) passValidated = false;
      });

      return this.passEqual() && passValidated;
    },
    // @vuese
    // Used to fetch user datas
    async fetchUser() {
      if (getToken()) {
        destroyToken();
        await this.$store.dispatch(LOGOUT);
      }
      let params  = (new URL(document.location)).searchParams;
      this.invitationToken = params.get('invitation_token');
      this.$store.dispatch(GET_USER, {
        token: this.invitationToken
      })
        .then(resp => {
          if (resp.success) {
            if (resp.practice) this.$store.dispatch(SET_PRACTICE_CUSTOM, resp.practice);
          }
          if (!resp.user) {
            this.$router.push({name: 'Login'});
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    // @vuese
    // Used to set password and redirect to nex page or to dashboard
    setPassword() {
      if (!this.validated()) return;

      const {
        invitationToken,
        password,
        confirm_password,
        currentUser
      } = this;


      this.$store.dispatch(SET_PASSWORD, {
        user: {
          invitation_token: invitationToken,
          password: password,
          password_confirmation: confirm_password,
        }
      }).then(resp => {
        this.$store.dispatch(LOGIN, {
          user: {
            email: currentUser.email,
            password: password,
          }
        })
          .then(resp => {
            this.$store.dispatch(GET_PRACTICE).then(resp => {
              if(resp.data.practice.registration_completed == true){
                localStorage.removeItem('invitationToken');
                this.$router.push({name: 'userdashboard'});
              }else{
                localStorage.removeItem('invitationToken');
                this.$router.push({name: 'CreateAccountS2'});
              }
            });
          });
      });
    }
  }
};
</script>