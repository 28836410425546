<template>
  <div 
    v-if="isLoading" 
    class="preloader fixed"
  >
    <i />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

// @group Components
export default {
  name: 'PreLoader',
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters([
      'currentUser'
    ])
  },
  watch: {
    currentUser: {
      immediate: true,
      handler() {
        if (this.currentUser) {
          this.isLoading = false;
        }
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .valu-state-preloader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 99999;
    line-height: 100%;
    font-size: 50px;
    background-color: rgba(255,255,255,.6);
  }

  .valu-state-preloader i {
    position: absolute;
    top: 50vh;
    margin-top: -25px;
  }
</style>