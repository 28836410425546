<template>
  <div class="front-wrapper">
    <Header />
    <div class="frontend-main d-flex">
      <Preloader />
      <!-- <transition  name="slide" mode="in-out"> -->
      <router-view />
      <!-- </transition> -->
    </div>
    <!--/. frontend-main -->
  </div>
  <!--/. frontend-wrap -->
</template>

<style>
.frontend-main {
  position: relative;
}
</style>

<script>
import Header from './partials/Header.vue';
import Preloader from '../components/Preloader.vue';

// @vuese
// @group Create Account
export default {
  name: 'Auth',
  components: {
    Header,
    Preloader
  },
  // data() {
  //   return {
  //     transitionName: 'slide-left',
  //   };
  // },
  // watch: {
  //   '$route' (to, from) {
  //     const toDepth = to.path.split('/').length;
  //     const fromDepth = from.path.split('/').length;
  //     this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
  //   }
  // },
};
</script>