<template>
  <div>
    <div class="row no-gutters justify-content-between mb-1 mt-md-4 pb-2">
      <h4>Clients</h4>
      <div class="d-flex flex-wrap">
        <div class="filter-group my-1">
          <label for="filter_data">
            View clients for:
          </label>
          <custom-select
            v-model="filterDate"
            :items="filterDateOption"
            class="select-xs"
          />
        </div>
        <div class="filter-group my-1">
          <label for="compare_to">Compare To:</label>
          <custom-select 
            v-model="compareToDate"
            :items="compareToDateOption"
            class="select-xs"
          />
        </div>
        <div class="filter-group my-1">
          <label for="filter_view">View:</label>
          <select-physican
            v-model="physician"
            title="Entire Practice"
            :items="valuesForSelect(physicians)"
            :total="physiciansTotal"
            :loading="isPhysiciansLoading"
            :name="'patient-report-select'"
            :filters="filters"
            @load-more="loadMorePhysicians"
            @click.native="patinetClick"
          />
        </div>
      </div>
    </div>
    <!-- /Title -->
    <div class="row patient-reports">
      <div class="col-lg-5 col-md-4 col-sm-6 mb-4">
        <div class="d-box with-pad view-report">
          <div class="row metrics-grid">
            <div class="col metric-box mb-3">
              <p>New Clients</p>
              <div class="metric-card">
                <p class="stat-num">
                  {{ patients.new_current_period }}
                </p>
                <p>Client Added</p>
                <p class="percent">
                  <span 
                    :class="getReportDeltaClass(patients.patinets_added)"
                  >
                    {{ patients.patinets_added }}%
                  </span>
                </p>
              </div>
            </div>
            <div class="col metric-box mb-3">
              <p>Total Clients in Practice</p>
              <div class="metric-card">
                <p class="stat-num">
                  {{ patients.total }}
                </p>
                <p>Clients</p>
              </div>
            </div>
          </div>
          <!-- <a 
            href 
            class="view-more collapsed"
          >View Report</a> -->
        </div>
      </div>
      <!-- /. card 1 -->
      <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div class="d-box with-pad">
          <div class="metrics-grid mb-3">
            <p>Average Number of Orders</p>
            <div class="metric-card">
              <p class="stat-num">
                {{ patients.average_number_of_orders_current_period }}
              </p>
              <p class="text-soft">
                Orders per client per year
              </p>
              <p class="percent">
                <span 
                  :class="getReportDeltaClass(patients.average_delta)"
                >
                  {{ patients.average_delta }}%
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- /. card 2 -->
    </div>
  </div>
</template>

<style lang="scss">
  .reportsall,
  .userdashboard {
    .content-down {
      margin-top: 0;
      .title {
        padding-top: 3px;
        padding-bottom: 3px;
      }
      .multi-select {
        left: inherit;
        right: 0;
      }
    }
  }
  .patient-reports {
    .d-box {
      height: calc(100% - 15px);
    }
  }
</style>

<script>
import { encodeQueryData, calcDelta, getReportDeltaClass } from '@/helper';
import CustomSelect from '../../components/CustomSelect';
import { ReportService } from '@/services/api.service';
import dateOptions from '@/mixins/dateOptions';
import SelectPhysican from '@/views/components/Select';
import physician from '@/mixins/physician';

// @group Reports
export default {
  name: 'ReportsPatients',

  components: {
    CustomSelect,
    SelectPhysican
  },

  mixins: [dateOptions, physician],

  props: {
    practiceCreatedAt: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      filterDate: '',
      filterDateOption: [],
      compareToDate: '',
      compareToDateOption: [],
      physician: '',
      loadOnInit: false,
      physiciansLoaded: false,
      patients: {
        new_current_period: 0,
        new_previous_period: 0,
        total: 0,
        average_number_of_orders_current_period: 0,
        average_number_of_orders_previous_period: 0,
        average_delta: 0,
        patinets_added: 0
      }
    };
  },

  watch: {
    physician() {
      this.getInfo();
    },

    filterDate: {
      immediate: true,
      handler() {
        if (this.filterDate && this.compareToDate)
          this.getInfo();
      }
    },

    compareToDate: {
      handler() {
        this.getInfo();
      }
    },

    practiceCreatedAt: {
      immediate: true,
      handler() {
        if (!this.practiceCreatedAt) return;
        const dataOption = this.buildYearOptions(this.practiceCreatedAt);
        this.filterDateOption = dataOption;
        this.compareToDateOption = dataOption;

        this.filterDate = this.filterDateOption[this.filterDateOption.length - 1].value.map((el) => {
          return el.toString();
        }).join(',');

        setTimeout(() => {
          let prevYear = this.compareToDateOption[this.compareToDateOption.length - 2];
          this.compareToDate = prevYear ? prevYear.value.join(',') : this.compareToDateOption[0].value.join(',');
        });
      }
    }
  },

  beforeMount() {
  },

  methods: {
    // @vuese
    // Used to convert object to get string
    // @arg Query object
    encodeQueryData,
    // @vuese
    // Used to calculate delta
    // @arg Current perriod value
    // @arg / Previous period value
    calcDelta,
    // @vuese
    // Used to get report minus or plus class, based on delta value
    // @arg Delta value
    getReportDeltaClass,

    // @vuese
    // Used to get patient report info data
    getInfo() {
      ReportService.getPatientsInfo(this.buildQueryObj())
        .then(resp => {
          if (resp.data.patients) {
            if (isNaN(resp.data.patients.average_number_of_orders))
              resp.data.patients.average_number_of_orders = 0;

            this.patients = resp.data.patients;
            this.patients.patinets_added = this.calcDelta(
              resp.data.patients.new_current_period,
              resp.data.patients.new_previous_period
            );

            this.patients.average_delta = this.calcDelta(
              resp.data.patients.average_number_of_orders_current_period,
              resp.data.patients.average_number_of_orders_previous_period
            );
          }
        });
    },

    // @vuese
    // Convert object to query string
    buildQueryObj() {
      return this.encodeQueryData({
        filters: {
          current_period: this.filterDate.split(',').map(dateItem => {
            return new Date(dateItem).toISOString();
          }),
          previous_period: this.compareToDate.split(',').map(dateItem => {
            return new Date(dateItem).toISOString();
          }),
          physician: this.physician,
        }
      });
    },

    // @vuese
    // Load physican only on click
    patinetClick() {
      if (!this.physiciansLoaded) {
        this.loadMorePhysicians({type: 'search'});
        this.physiciansLoaded = true;
      }
    }
  }
};
</script>