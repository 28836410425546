<template>
  <select @change="change">
    <option 
      v-for="(item, idx) in items"
      :key="idx"
      :value="item.value"
      :selected="value == item.value"
    >
      {{ item.title }}
    </option>
  </select>
</template>

<script>
// @group Components
export default {
  name: 'CustomSelect',
  props: {
    // @vuese
    // Select value
    value: {
      type: String,
      default: '',
    },
    // @vuese
    // Select options
    items: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    // @vuese
    // Used to emit that value was change
    // @arg Node event
    change(e) {
      this.$emit('input', e.target.value);
    }
  }
};
</script>