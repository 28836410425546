var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrap"},[_c('Notifications'),_vm._v(" "),_c('h2',[_vm._v(_vm._s(_vm.patient.first_name)+" "+_vm._s(_vm.patient.last_name))]),_vm._v(" "),_c('ul',{staticClass:"breadcrumb-page"},[_c('li',{staticClass:"crumb"},[_c('router-link',{attrs:{"to":{
          name: 'Patients'
        }}},[_vm._v("\n        All Clients\n      ")])],1),_vm._v(" "),_c('li',{staticClass:"crumb active"},[_vm._v("\n      Client: "+_vm._s(_vm.patient.first_name)+" "+_vm._s(_vm.patient.last_name)+"\n    ")])]),_vm._v(" "),_c('div',{staticClass:"row row-layout-370"},[_c('div',{staticClass:"col-xl-8"},[_c('PatientViewInfo',{attrs:{"patient":_vm.patient}}),_vm._v(" "),_c('PatientViewOrder',{attrs:{"patient":_vm.patient}}),_vm._v(" "),_c('PatientViewRegimen',{attrs:{"patient":_vm.patient}}),_vm._v(" "),_c('PatientViewQuizResults',{attrs:{"patient_id":_vm.patient.id,"patient":_vm.patient}})],1),_vm._v(" "),_c('div',{staticClass:"col-xl-4 aside-sticky"},[(_vm.patient.status == 'completed' && ( this.isRole('super_admin') || (_vm.patient.practice && _vm.patient.practice.can_create_orders)))?_c('div',{staticClass:"aside-box text-center"},[_c('h4',{staticClass:"mb-3"},[_vm._v("\n          Place Order For Patient\n        ")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
            name: 'PatientCreateOrder',
            params: {
              id: _vm.patient.id
            }
          }}},[_vm._v("\n          Create Order\n        ")])],1):_vm._e(),_vm._v(" "),(_vm.patient.status !== 'completed')?_c('div',{staticClass:"aside-box text-center"},[_c('h4',{staticClass:"mb-3"},[_vm._v("\n          Continue Client Registration\n        ")]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
            name: 'PatientCreateRegimen',
            params: {
              id: _vm.patient.id
            }
          }}},[_vm._v("\n          Continue\n        ")])],1):_vm._e(),_vm._v(" "),(_vm.patient.regimen && _vm.patient.regimen.products.length > 0)?_c('div',{staticClass:"text-center px-sm-3 px-2"},[_c('h4',{staticClass:"mb-3"},[_vm._v("\n          Email Regimen to Client\n        ")]),_vm._v(" "),_c('p',[_vm._v("Send an email to your client with all the details they need to follow their skincare routine.")]),_vm._v(" "),_c('a',{staticClass:"action-top",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.sendUserEmail($event)}}},[_vm._v("\n          Send email\n        ")])]):_vm._e()])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v("Order products in their regimen or find other products on RegimenPro"),_c('sup',[_vm._v("®")]),_vm._v(" to purchase.")])}]

export { render, staticRenderFns }