var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container container-md-sm"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"container"},[_c('Notifications')],1),_vm._v(" "),_c('div',{staticClass:"steps-content"},[_vm._m(1),_vm._v(" "),_c('form',{staticClass:"row row-create-account",on:{"submit":function($event){$event.preventDefault();return _vm.finishRegistration($event)}}},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"f-box"},[_c('div',{staticClass:"review-header"},[_c('h4',[_vm._v("Practice Information")]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-bordered w-auto",attrs:{"to":{
                name: 'CreateAccountS2',
              }}},[_vm._v("\n              Edit\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"review-body"},[(_vm.practice.email)?_c('div',{staticClass:"item"},[_c('p',[_vm._v("Practice email address")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.practice.email))])]):_vm._e(),_vm._v(" "),(_vm.practice.url)?_c('div',{staticClass:"item"},[_c('p',[_vm._v("practice website link")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.practice.url))])]):_vm._e(),_vm._v(" "),(_vm.practice.phones[0])?_c('div',{staticClass:"item"},[_c('p',[_vm._v("practice number")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.practice.phones[0].number)+"    "),(_vm.practice.phones[0].ext)?_c('span',[_vm._v("ext. "+_vm._s(_vm.practice.phones[0].ext))]):_vm._e()])]):_vm._e(),_vm._v(" "),(_vm.practice.logo && _vm.practice.logo.url)?_c('div',{staticClass:"item"},[_c('p',[_vm._v("practice logo")]),_vm._v(" "),_c('div',{staticClass:"box-image"},[_c('img',{attrs:{"src":_vm.practice.logo.url,"alt":"Practice Logo"}})])]):_vm._e(),_vm._v(" "),_vm._l((_vm.practice.social_media),function(social,index){return (social.url)?_c('div',{key:index,staticClass:"item"},[_c('p',[_vm._v(_vm._s(social.type)+" link")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(social.url))])]):_vm._e()})],2)]),_vm._v(" "),_c('div',{staticClass:"f-box"},[_c('div',{staticClass:"review-header"},[_c('h4',[_vm._v("Practice Address")]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-bordered w-auto",attrs:{"to":{
                name: 'CreateAccountS3',
              }}},[_vm._v("\n              Edit\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"review-body"},[_c('div',{staticClass:"item"},[_c('p',[_vm._v("practice address")]),_vm._v(" "),_c('p',[_vm._v("\n                "+_vm._s(_vm.address.default_address.address1)+"\n                "),(_vm.address.default_address.address2)?_c('span',[_c('br'),_vm._v(_vm._s(_vm.address.default_address.address2))]):_vm._e(),_vm._v(" "),_c('br'),_vm._v("\n                "+_vm._s(_vm.address.default_address.city)+", "+_vm._s(_vm.address.default_address.state)+" "+_vm._s(_vm.address.default_address.zip)+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"item"},[_c('p',[_vm._v("Receiving address")]),_vm._v(" "),_c('p',[_vm._v("\n                "+_vm._s(_vm.address.delivery_address.address1)+"\n                "),(_vm.address.delivery_address.address2)?_c('span',[_c('br'),_vm._v(_vm._s(_vm.address.delivery_address.address2))]):_vm._e(),_vm._v(" "),_c('br'),_vm._v(_vm._s(_vm.address.delivery_address.city)+", "+_vm._s(_vm.address.delivery_address.state)+" "+_vm._s(_vm.address.delivery_address.zip)+"\n              ")]),_vm._v(" "),_c('p',{staticClass:"mt-4"},[_vm._v("\n                Notes: "+_vm._s(_vm.address.delivery_address.note)+"\n              ")])])])]),_vm._v(" "),_c('div',{staticClass:"f-box"},[_c('div',{staticClass:"review-header"},[_c('h4',[_vm._v("Physicians")]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-bordered w-auto",attrs:{"to":{
                name: 'CreateAccountS4',
              }}},[_vm._v("\n              Edit\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"review-body"},[_vm._l((_vm.physicians),function(physician,index){return _c('div',{key:index,staticClass:"row"},[(index > 0)?_c('div',{staticClass:"col-12"},[_c('hr',{staticClass:"mt-0 mb-3"})]):_vm._e(),_vm._v(" "),(physician.avatar.url)?_c('div',{staticClass:"col-md-2 pr-2"},[_c('img',{staticClass:"rounded-circle",attrs:{"src":physician.avatar.url,"alt":physician.name}})]):_vm._e(),_vm._v(" "),_c('div',{class:physician.avatar.url ? 'col-md-10' : 'col-md-12'},[_c('div',{staticClass:"item mb-3"},[_c('p',[_vm._v("Name")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(physician.name))])]),_vm._v(" "),_c('div',{staticClass:"item"},[_c('p',[_vm._v("EMAIL")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(physician.email))])])])])}),_vm._v(" "),_vm._m(2)],2)]),_vm._v(" "),(_vm.staff.length && _vm.staff[0].email)?_c('div',{staticClass:"f-box"},[_c('div',{staticClass:"review-header"},[_c('h4',[_vm._v("Staff")]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-bordered w-auto",attrs:{"to":{
                name: 'CreateAccountS5',
              }}},[_vm._v("\n              Edit\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"review-body"},[_c('div',_vm._l((_vm.staff),function(staffObj,index){return _c('div',{key:index,staticClass:"item"},[_c('p',[_vm._v("Name")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(staffObj.name))]),_vm._v(" "),_c('p',[_vm._v("EMAIL")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(staffObj.email))])])}),0)])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-md-4"},[_c('Sidebar'),_vm._v(" "),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.sending}},[_vm._v("\n          Create Practice\n        ")]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-bordered mt-3",attrs:{"to":{
            name: 'CreateAccountS2',
          },"tag":"a"}},[_vm._v("\n          Back\n        ")])],1)])]),_vm._v(" "),_c('ContactUs')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"steps-wrap"},[_c('div',{staticClass:"step passed"},[_c('p',[_vm._v("Set Password")]),_vm._v(" "),_c('span',{staticClass:"step-marker"})]),_vm._v(" "),_c('div',{staticClass:"step passed"},[_c('p',[_vm._v("Add Practice Info")]),_vm._v(" "),_c('span',{staticClass:"step-marker"})]),_vm._v(" "),_c('div',{staticClass:"step passed"},[_c('p',[_vm._v("Add Practice Addresses")]),_vm._v(" "),_c('span',{staticClass:"step-marker"})]),_vm._v(" "),_c('div',{staticClass:"step passed"},[_c('p',[_vm._v("Add Skincare Pros")]),_vm._v(" "),_c('span',{staticClass:"step-marker"})]),_vm._v(" "),_c('div',{staticClass:"step passed"},[_c('p',[_vm._v("Add Practice Staff")]),_vm._v(" "),_c('span',{staticClass:"step-marker"})]),_vm._v(" "),_c('div',{staticClass:"step current"},[_c('p',[_vm._v("Review")]),_vm._v(" "),_c('span',{staticClass:"step-marker"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-title"},[_c('h3',[_vm._v("Review")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"item col-sm-6 pb-2"},[_c('p',[_vm._v("Practice app soldto")]),_vm._v(" "),_c('p',[_vm._v("xjks99")])]),_vm._v(" "),_c('div',{staticClass:"item col-sm-6 pb-2"},[_c('p',[_vm._v("practice app shipto")]),_vm._v(" "),_c('p',[_vm._v("3YU5KJA")])])])}]

export { render, staticRenderFns }