export const allegran = {
  soldto: '',
  shipto: ''
};

export const physician = {
  first_name: '',
  name: '',
  email: '',
  emailErrors: [],
  avatar: '',
  avatarError: '',
  avatarPreview: '',
  avatarFilename: '',
  uniqueAllegran: false,
  allegran: {...allegran}
};

export const staff = {
  first_name: '',
  email: '',
  emailErrors: [],
};

export const phone = {number: '', ext: ''};

export const default_address =  {
  address1: '',
  address2: '',
  city: '',
  state: 'AL',
  zip: '',
  country: 'United States',
  type: 'default',
  action: 'create',
};

export const delivery_address = {
  address1: '',
  address2: '',
  city: '',
  state: 'AL',
  zip: '',
  country: 'United States',
  type: 'delivery',
  note: '',
  action: 'create',
};

export const socials = [
  {type: 'instagram', url: ''},
  {type: 'facebook', url: ''},
  {type: 'pinterest', url: ''},
  {type: 'linkedin', url: ''},
  {type: 'twitter', url: ''},
  {type: 'youtube', url: ''}
];