<template>
  <div
    class="subscription"
  >
    <div
      class="row pt-2 pb-2"
    >
      <div class="col-md-2 label">
        {{ isActive ? 'Subscriptions' : 'Previous Subscription Information' }}
      </div>
      <div class="col-md-10">
        <transition name="slide">
          <div
            v-if="isVisible(blockTypes.info)"
            class="row"
          >
            <div class="col-md-5">
              <div class="mb-1 row">
                <div class="col-7">
                  Quantity:
                </div>
                <div class="col-5">
                  {{ subscription.quantity }}
                </div>
              </div>
              <div class="mb-1 row">
                <div class="col-7">
                  Frequency:
                </div>
                <div class="col-5">
                  {{ subscription.order_interval_frequency }}
                  {{ subscription.order_interval_unit }}s
                </div>
              </div>
              <div 
                v-if="isActive"
                class="mb-1 row"
              >
                <div class="col-7">
                  Next Charge Day:
                </div>
                <div class="col-5">
                  {{ subscription.next_charge_scheduled_at }}
                </div>
              </div>
            </div>
            <div class="col-md-7 d-flex">
              <div>
                <div
                  v-if="payment_method"
                  class="mb-1"
                >
                  Payment Method:
                  {{ payment_method.card_type }} ending in {{ payment_method.card_number }}
                </div>
                <div
                  class="mb-1"
                >
                  Ship to:
                  {{ bulidAddressStr(shipping_address) }}
                </div>
              </div>
              <div>
                <a
                  v-if="isActive"
                  class="link_action hover-btn"
                  @click.prevent="changeBlock(blockTypes.change)"
                >Edit</a>
                <a 
                  v-else
                  class="link_action hover-btn"
                  @click.prevent="changeBlock(blockTypes.reactivate)"
                >Reactivate</a>
              </div>
            </div>
          </div>
        </transition>
        <transition name="slide">
          <ValidationObserver
            v-if="isVisible(blockTypes.change) || isVisible(blockTypes.reactivate)"
            ref="changeObserver"
            class="row"
            tag="form"
            @submit.prevent="updateSubscription(false)"
          >
            <div
              class="col-3"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Quantity"
                rules="required"
              >
                <label for="subscription-qty">Quantity</label>
                <input
                  id="subscription-qty"
                  v-model="qty"
                  type="number"
                  :class="errors.length ? 'error' : ''"
                >
                <p 
                  v-for="(err, errKey) in errors"
                  :key="errKey"
                  class="validation error mt-2"
                >
                  {{ err }}
                </p>
              </validation-provider>
            </div>
            <div
              class="col-9"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Frequency"
                rules="required"
              >
                <label for="frequency-interval">Frequency</label>
                <select
                  id="frequency-interval"
                  v-model="frequency"
                  :class="errors.length ? 'frequency-interval error' : 'frequency-interval'"
                >
                  <option
                    v-for="(frequencyItem, valIdx) in frequencyOptions"
                    :key="valIdx"
                    :value="frequencyItem.value"
                  >
                    {{ frequencyItem.text }}{{ valIdx == 0 ? ' (Recommended)' : '' }}
                  </option>
                </select>
                <p 
                  v-for="(err, errKey) in errors"
                  :key="errKey"
                  class="validation error mt-2"
                >
                  {{ err }}
                </p>
              </validation-provider>
            </div>
            <div class="col-12 mt-3">
              <validation-provider
                v-slot="{ errors }"
                name="Next Charge"
                rules="required"
              >
                <label>Next Charge</label>
                <date-picker
                  v-model="next_charge_scheduled_at"
                  format="YYYY-MM-DD"
                  type="date"
                  :lang="translation"
                  :class="errors.length ? 'error' : ''"
                  :append-to-body="false"
                  :disabled-date="disabledDate"
                />
                <p
                  v-for="(err, errKey) in errors"
                  :key="errKey"
                  class="validation error mt-2"
                >
                  {{ err }}
                </p>
              </validation-provider>
            </div>
            <div
              v-if="isVisible(blockTypes.change)"
              class="mt-3 col-12"
            >
              Payment method and ship to address can be edited on the patient's profile page.
            </div>
            <div
              v-if="isVisible(blockTypes.change)"
              class="mt-3 d-flex align-items-center justify-content-between col-12"
            >
              <a
                v-if="isActive"
                class="link_action hover-btn"
                @click.prevent="changeBlock(blockTypes.cancel)"
              >
                Cancel Subscription
                <i class="fa fa-arrow-right" />
              </a>
              <span v-else />

              <div class="text-right">
                <button
                  class="btn btn-bordered mr-2 hover-bth"
                  @click.prevent="changeBlock(blockTypes.info)"
                >
                  Back
                </button>
                <button
                  type="submit"
                  class="btn btn-primary autowidth hover-bth"
                >
                  Save
                </button>
              </div>
            </div>
          </ValidationObserver>
        </transition>
        <transition name="slide">
          <ValidationObserver
            v-if="isVisible(blockTypes.cancel)"
            ref="cancelObserver"
            class="row"
            tag="form"
            @submit.prevent="cancelSubscription"
          >
            <div class="col-12 d-flex align-items-start">
              <div>
                <validation-provider
                  v-slot="{ errors }"
                  name="Cancel Subscription"
                  rules="required"
                >
                  <label for="cancel-subscription">
                    Cancel Subscription
                  </label>
                  <select
                    id="cancel-subscription"
                    v-model="cancelReason"
                  >
                    <option 
                      v-for="(reason, idx) in cancelOptions"
                      :key="idx"
                      :value="reason.value"
                    >
                      {{ reason.label }}
                    </option>
                  </select>
                  <p
                    v-for="(err, errKey) in errors"
                    :key="errKey"
                    class="validation error mt-2"
                  >
                    {{ err }}
                  </p>
                </validation-provider>
              </div>
              <div
                class="comments-wrap"
              >
                <label>&nbsp;</label>
                <input
                  v-model="cancellationReasonComments"
                  type="text"
                  class="ml-2"
                  placeholder="Additional comments"
                >
              </div>
            </div>
            <div class="mt-3 d-flex align-items-center justify-content-end col-12 pl-0 pr-0">
              <div class="text-right">
                <button
                  class="btn btn-bordered mr-2 hover-bth"
                  @click.prevent="changeBlock(blockTypes.change)"
                >
                  Back
                </button>
                <button
                  class="btn btn-primary autowidth hover-bth"
                  type="submit"
                >
                  Cancel Subscription
                </button>
              </div>
            </div>
          </ValidationObserver>
        </transition>
      </div>
    </div>

    <hr v-if="isVisible(blockTypes.info) || isVisible(blockTypes.reactivate)">

    <transition name="slide">
      <ValidationObserver
        v-if="isVisible(blockTypes.reactivate)"
        ref="reactivateObserver"
        tag="form"
        class="row align-items-center justify-content-center pt-2"
        @submit.prevent="reactivateSubscription"
      >
        <button
          class="btn btn-primary autowidth hover-bth"
          type="submit"
        >
          Reactivate Subscription
        </button>
      </ValidationObserver>
    </transition>
  </div>
</template>

<script>
import {
  bulidAddressStr,
  capitalize
} from '@/helper';
import { get } from 'lodash';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate';
import { SubscriptionService } from '@/services/api.service';

// @group Partials
export default {
  name: 'Subscription',

  components: {
    DatePicker,
    ValidationObserver,
    ValidationProvider
  },

  props: {
    // @vuese
    // Product object
    product: {
      type: Object,
      default() {
        return {};
      }
    },
    // @vuese
    // Frequency Options
    frequencyOptions: {
      type: Array,
      default() {
        return [];
      }
    },
    // @vuese
    // Hide modal function
    hideModal: {
      type: Function,
      default() {
        return () => ({});
      }
    }
  },

  data() {
    return {
      next_charge_scheduled_at: null,
      qty: null,
      frequency: null,
      dataShow: 'info',
      blockTypes: {
        info: 'info',
        change: 'change',
        cancel: 'cancel',
        reactivate: 'reactivate',
      },
      unitOptions: [
        {
          value: 'Day',
          label: 'Days'
        },
        {
          value: 'Week',
          label: 'Weeks'
        },
        {
          value: 'Month',
          label: 'Months'
        }
      ],
      cancelReason: '',
      cancellationReasonComments: '',
      cancelOptions: [
        {
          value: '',
          label: 'Select a reason'
        },
        {
          value: 'This is too expensive',
          label: 'This is too expensive'
        },
        {
          value: 'This was created by accident',
          label: 'This was created by accident'
        },
        {
          value: 'I already have more than I need',
          label: 'I already have more than I need'
        },
        {
          value: 'I need it sooner',
          label: 'I need it sooner'
        },
        {
          value: 'I no longer use this product',
          label: 'I no longer use this product'
        },
        {
          value: 'I want a different product',
          label: 'I want a different product'
        },
        {
          value: 'I want a different flavor/scent/count',
          label: 'I want a different flavor/scent/count'
        },
        {
          value: 'Other Reason',
          label: 'Other Reason'
        }
      ],
      translation: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      }
    };
  },

  computed: {
    payment_method() {
      return get(this.product, 'patient.payment_method.card_number') ?
        this.product.patient.payment_method : null;
    },
    shipping_address() {
      return get(this.product, 'patient.shipping_address') ?
        this.product.patient.shipping_address : null;
    },
    subscription() {
      return this.product.subscriptionObj;
    },
    isActive() {
      return this.product.subscriptionObj.status == 'active';
    },
    practice() {
      return this.product.patient.practice;
    }
  },

  watch: {
    subscription: {
      deep: true,
      immediate: true,
      handler() {
        if (!this.subscription.next_charge_scheduled_at)
          this.subscription.next_charge_scheduled_at = new Date().format('YYYY-MM-DD');

        if (!this.qty) this.qty = this.subscription.quantity;
        if (!this.frequency) 
          this.frequency = this.subscription.order_interval_frequency;

        if (this.next_charge_scheduled_at instanceof Date == false) {
          this.next_charge_scheduled_at = 
            new Date(this.subscription.next_charge_scheduled_at);
        }
      }
    }
  },

  methods: {
    // @vuese
    // Used to convert address object to string, if object have empty  props
    // return word `None`
    // @arg address object
    bulidAddressStr,
    
    // @vuese
    // Used to choose which box should be shown
    // @arg Box type
    changeBlock(type) {
      this.dataShow = type;
      this.$emit('edit-subscription', this.dataShow);
    },

    // @vuese
    // Used to check if box visible
    // @arg Box type
    isVisible(type) {
      return this.dataShow == type;
    },

    // @vuese
    // Used to update subscription
    // @arg Agument is it after reactivation
    async updateSubscription(afterReactivation = false) {
      if (await this.$refs.changeObserver.validate()) {
        const subscription = {...this.subscription};
        subscription.next_charge_scheduled_at = this.next_charge_scheduled_at.format('YYYY-MM-DD');
        subscription.quantity = this.qty;
        subscription.order_interval_frequency = this.frequency;
        subscription.charge_interval_frequency = this.frequency;
        subscription.status = 'active';

        SubscriptionService.update(
          {
            subscription: subscription
          }, this.subscription.re_charge_subscription_id, this.getRequestPracticeId()
        )
          .then(() => {
            
            this.product.subscription = subscription;
            if (afterReactivation)
              this.flashSuccess('Subscription Reactivated');
            else
              this.flashSuccess('Subscription succesfully updated');
          })
          .catch(err => {
            const errors = get(err, 'response.data.errors') ? err.response.data.errors : null;
            const error = get(err, 'response.data.error') ? err.response.data.error : null;
            let message = 'Error updating subscription.';
            if (errors) {
              message = typeof errors === 'string' ? errors : Object.keys(errors).map(errKey => {
                return capitalize(`${errKey.split('_').join(' ')}: ${errors[errKey]}`);
              }).join('<br>');
            }

            if(error) message = error;

            this.flashError(message);
          })
          .finally(() => {
            this.hideModal();
          });
      }
    },

    // @vuese
    // Used to cances subscription
    async cancelSubscription() {
      const valid = await this.$refs.cancelObserver.validate();
      if (!valid) return;

      SubscriptionService.cancel(
        {
          subscription: {
            cancellation_reason: this.cancelReason,
            cancellation_reason_comments: this.cancellationReasonComments
          }
        }, this.subscription.re_charge_subscription_id, this.getRequestPracticeId()
      )
        .then(() => {
          this.product.subscription.status = 'cancelled';
          this.flashSuccess('Subscription succesfully canceled');
        })
        .catch(err => {
          let message = get(err, 'response.data.error') ? 
            capitalize(err.response.data.error) : 
            'Error canceling subscription.';
          this.flashError(message);
        })
        .finally(() => {
          this.hideModal();
        });
    },

    // @vuese
    // Used to get practice ID for super admin user
    getRequestPracticeId() {
      const practiceId = this.practice ? this.practice.id : '';
      return this.isRole('super_admin') ? `/${practiceId}` : '';
    },

    // @vuese
    // Used to reactivate subscription
    async reactivateSubscription() {
      if (await !this.$refs.changeObserver.validate()) return;

      SubscriptionService.activate(
        this.subscription.re_charge_subscription_id, this.getRequestPracticeId()
      )
        .then(() => {
          this.updateSubscription(true);
        })
        .catch(err => {
          this.flashError('Error reactivating subscription');
        });
    },
    // @vuese
    // Used to chek if date is disabled
    // @arg Date
    disabledDate(date) {
      const today = new Date();
      today.setHours(0,0,0,0);
      return date.getTime() < today.getTime() ? true : false;
    }
  }
};
</script>

<style lang="scss" scoped>
.subscription {
  color: rgb(99, 94, 109);
  font-size: 13px;
  .label {
    font-weight: 600;
    color: #000;
  }
  label {
    font-weight: 600;
    color: #000;
    font-size: 12px;
  }
  .mx-datepicker {
    display: block;
    width: 100%;
  }
  .comments-wrap {
    flex: 1;
  }
}
</style>