export const notification = {
  error: {
    type: 'danger'
  },
  warning: {
    type: 'warning'
  },
  success: {
    type: 'success'
  }
};