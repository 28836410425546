<template>
  <div class="content-wrap">
    <h2>Account Settings</h2>
    <Notifications />

    <div class="row">
      <div class="col-xl-8">
        <div class="wrap-table border-table">
          <div 
            v-if="isPhysician() && !isRole('super_admin')" 
            class="row row-group"
          >
            <div class="col-md-4">
              <span class="text-up">PHOTO</span>
            </div>
            <div 
              v-if="!edit.photo.edit" 
              class="col-md-8 pr-0 p-physician-edition"
            >
              <div class="row-large">
                <div class="col-md-9"> 
                  <img 
                    :src="currentUser.avatarPreview" 
                    class="team-m-img"
                  > 
                </div>
                <div class="col-md-3 action-box">
                  <a 
                    class="link_action" 
                    href="#"
                    @click.prevent="editField('photo')" 
                  >Edit</a>
                </div>
              </div>
            </div>
            <ValidationObserver 
              v-else 
              ref="form" 
              v-slot="{ invalid }" 
              class="col-md-8 p-physician-edit pr-0"
              tag="div"
            >
              <div class="row-large imgUp-2 p-file-edit">
                <ImagePicker 
                  :image="currentUser.avatar"
                  :image-preview="currentUser.avatarPreview"
                  :filename="currentUser.avatarFilename"
                  :bordered="true"
                  :can-remove="false"
                  @image="val => currentUser.avatar = val"
                  @image-preview="val => currentUser.avatarPreview = val"
                  @filename="val => currentUser.avatarFilename = val"
                />
                <p 
                  v-if="currentUser.avatarError" 
                  class="validation error mt-2"
                >
                  {{ currentUser.avatarError }}
                </p>
              </div>
              <div class="row-large">
                <div class="col-md-6 offset-md-6 pr-0">
                  <div class="text-right">
                    <a 
                      href="#" 
                      class="btn btn-bordered right-space" 
                      @click.prevent="action('cancel_photo')"
                    >Cancel</a>
                    <a 
                      href="#" 
                      class="btn btn-primary autowidth"
                      @click.prevent="action('save_photo')"
                    >Save</a>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>
          <div class="row row-group">
            <div class="col-md-4">
              NAME
            </div>
            <div class="col-md-8 pr-0">
              <div 
                v-if="!edit.name.edit" 
                class="row-large name_staff name_pa"
              >
                <div class="col-10"> 
                  <span>{{ currentUser.first_name }} {{ currentUser.last_name }}</span> 
                </div>
                <div class="col-2 action-box">
                  <a 
                    href="#" 
                    class="link_action"  
                    @click.prevent="editField('name')" 
                  >Edit</a>
                </div>
              </div>
              <ValidationObserver 
                v-else 
                ref="form"
                v-slot="{ invalid }" 
                class="col pr-0 pl-0" 
                tag="div" 
                lass="col-md-12 form_name pr-0"
              >
                <input 
                  id="name" 
                  v-model="currentUser.first_name" 
                  placeholder="Leslee" 
                  type="text" 
                  name="name"
                >
                <div class="text-right pt-2">
                  <a 
                    href="#"
                    class="btn btn-bordered mr-2"
                    @click.prevent="action('cancel_name')"
                  >Cancel</a>
                  <a 
                    href="#" 
                    class="btn btn-primary autowidth"
                    @click.prevent="action('save_name')"
                  >Save</a>
                </div> 
              </ValidationObserver>
            </div>
          </div>
          <div class="row row-group">
            <div class="col-md-4">
              EMAIL
            </div>
            <div class="col-md-8 pr-0">
              <div 
                v-if="!edit.email.edit" 
                class="row-large"
              >
                <div class="col-10"> 
                  <span>{{ currentUser.email }}</span> 
                </div>
                <div class="col-2 action-box">
                  <a 
                    href="#" 
                    class="link_action" 
                    @click.prevent="editField('email')"  
                  >Edit</a>
                </div>
              </div>
              <ValidationObserver 
                v-else 
                ref="form"
                v-slot="{ invalid }" 
                class="col pr-0 pl-0" 
                tag="div" 
                lass="col-md-12 form_email pr-0"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="email" 
                  class="form-group"
                >
                  <input 
                    id="email" 
                    v-model="currentUser.email" 
                    placeholder="lesleemoss@practice.com" 
                    type="text" 
                    :class="errors.length || emailErrors.length ? 'error' : ''"
                    name="email"
                  >
                  <p class="validation error mt-2">
                    {{ errors[0] }}
                  </p>
                  <p 
                    v-for="(err, errIdx) in emailErrors" 
                    :key="errIdx"
                    class="validation error mt-2"
                  >
                    {{ err }}
                  </p>
                </validation-provider>
                <div class="text-right pt-2">
                  <a 
                    href="#"
                    class="btn btn-bordered mr-2"
                    @click.prevent="action('cancel_email')"
                  >Cancel</a>
                  <a 
                    href="#" 
                    class="btn btn-primary autowidth"
                    @click.prevent="action('save_email')"
                  >Save</a>
                </div> 
              </ValidationObserver>
            </div>
          </div>
          <div 
            v-if="isPhysician() && !isRole('super_admin')" 
            class="row row-group"
          >
            <div class="col-md-4">
              <span>ALLERGAN APP SHIPTO</span>
            </div>
            <div class="col-md-8 pr-0 mb-4">
              <div 
                v-if="!edit.allergan.edit" 
                class="row-large allergan"
              >
                <div class="col-md-9">
                  <span v-if="currentUser.allegran.shipto">{{ currentUser.allegran.shipto }}</span>
                  <span v-else>Allergan APP inherited from practice</span>
                </div>
                <div class="col-md-3 action-box">
                  <a 
                    class="link_action" 
                    @click.prevent="editField('allergan')"
                  >Edit</a>
                </div>
              </div>
              <div 
                v-else 
                class="col-md-12 form_allergan pr-0"
              >
                <input
                  id="shipto"
                  v-model="currentUser.allegran.shipto"
                  type="text"
                  name="shipto"
                >
              </div>
            </div>
            <div class="col-md-4">
              <span class="d-block pt-sm-3">ALLERGAN APP SOLDTO</span>
            </div>
            <div class="col-md-8 pr-0">
              <div 
                v-if="!edit.allergan.edit" 
                class="row-large allergan"
              >
                <div class="col-md-9 ">
                  <span 
                    v-if="currentUser.allegran.soldto" 
                    class=" d-block pt-sm-3"
                  >{{ currentUser.allegran.soldto }}</span>
                  <span 
                    v-else 
                    class=" d-block pt-sm-3"
                  >Allergan APP inherited from practice</span>
                </div>
              </div>
              <div 
                v-else 
                class="col-md-12 form_allergan pr-0"
              >
                <input
                  id="soldto"
                  v-model="currentUser.allegran.soldto"
                  type="text"
                  name="soldto"
                >
                <div class="text-right">
                  <a 
                    href="#"
                    class="btn btn-bordered mr-2"
                    @click.prevent="action('cancel_allergan')"
                  >Cancel</a>
                  <a 
                    href="#" 
                    class="btn btn-primary autowidth"
                    @click.prevent="action('save_allergan')"
                  >Save</a>
                </div> 
              </div>
            </div>
          </div>
          <div class="row row-group">
            <div class="col-md-4">
              PASSWORD
            </div>
            <div class="col-md-8 pr-0">
              <div 
                v-if="!edit.pass.edit" 
                class="row-large pass_staff"
              >
                <div class="col-10">
                  <input 
                    class="unedit" 
                    readonly 
                    placeholder="Password" 
                    type="password" 
                    value="Denise Doudny"
                  >
                </div>
                <div class="col-2 action-box">
                  <a 
                    class="link_action" 
                    href="#"
                    @click.prevent="editField('pass')" 
                  >Edit</a>
                </div>
              </div>
              <div 
                v-else 
                class="col-md-12 form_pass pr-0"
              >
                <div class="form-group">
                  <label for="passCur">Current Password</label>
                  <i 
                    :class="edit.pass.pass_cur_visible ? 'show-pass fas fa-eye' : 'show-pass fas fa-eye-slash'" 
                    @click="edit.pass.pass_cur_visible = !edit.pass.pass_cur_visible"
                  />
                  <input 
                    id="passCur" 
                    v-model="edit.pass.password_current" 
                    placeholder="Password" 
                    :type="edit.pass.pass_cur_visible ? 'text' : 'password'" 
                    autocomplete="cur-password" 
                    name="passwordcurr"
                  >
                </div>
                <div class="form-group mb-0">
                  <label for="pass0">New Password</label>
                  <i 
                    :class="edit.pass.pass_visible ? 'show-pass fas fa-eye' : 'show-pass fas fa-eye-slash'" 
                    @click="edit.pass.pass_visible = !edit.pass.pass_visible"
                  />
                  <input 
                    id="pass0" 
                    v-model="edit.pass.password" 
                    placeholder="Password" 
                    :type="edit.pass.pass_visible ? 'text' : 'password'" 
                    autocomplete="new-password" 
                    name="password"
                  >
                </div>
                <div class="char-content mb-3">
                  <p 
                    :class="validatePass()['length'] ? 'char checked' : 'char no-checked' "
                  >
                    At least 8 characters
                  </p>
                  <p 
                    :class="validatePass()['specialChar'] ? 'char checked' : 'char no-checked' "
                  >
                    At least one special character (e.g. !, &amp;, #, etc.)
                  </p>
                  <p 
                    :class="validatePass()['haveNumber'] ? 'char checked' : 'char no-checked' "
                  >
                    At least one number
                  </p>
                </div>
                <div class="form-group mb-0">
                  <label for="confirm_pass">Confirm New Password</label>
                  <i 
                    :class="edit.pass.confirm_pass_visible ? 'show-pass fas fa-eye' : 'show-pass fas fa-eye-slash'" 
                    @click="edit.pass.confirm_pass_visible = !edit.pass.confirm_pass_visible"
                  />
                  <input 
                    id="confirm_pass" 
                    v-model="edit.pass.confirm_password"
                    placeholder="Confirm Password" 
                    :type="edit.pass.confirm_pass_visible ? 'text' : 'password'" 
                    autocomplete="new-password"
                    name="reset"
                  >
                </div>
                <div class="char-content mb-3">
                  <p 
                    :class="passEqual() ? 'char checked' : 'char no-checked' "
                  >
                    Password matches
                  </p>
                </div>
                <div class="text-right pt-2">
                  <a 
                    href="#" 
                    class="btn btn-bordered mr-2"
                    @click.prevent="action('cancel_pass')"
                  >Cancel</a>
                  <a 
                    href="#" 
                    class="btn btn-primary autowidth"
                    @click.prevent="action('save_pass')"
                  >Save</a>
                </div> 
              </div>
            </div>
          </div>
        </div>
        <!-- /. Table Patient Information  -->
      </div>
    </div>
    <Modal
      :show-modal="showCancelModal"
      modal-id="before"
      @hide-modal-window="showCancelModal = false"
    >
      <img 
        src="../../../images/warning.png" 
        alt="Warning!" 
        class="warning-icon" 
      >
      <h4 class="modal-title">
        Before you go ...
      </h4>
      <p>You have unsaved changes. Are you sure you want to continue?</p>
      <div class="mb-3">
        <a 
          href="#" 
          class="btn btn-primary w-100"
          @click.prevent="hideModal" 
        >Continue Without Saving</a>
      </div>
      <div class>
        <a
          href="#"
          class="btn btn-bordered w-100"
          data-dismiss="modal"
          @click.prevent="showCancelModal = false"
        >Cancel</a>
      </div>
    </Modal>
    <div 
      v-if="showCancelModal" 
      class="modal-backdrop fade show" 
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import Modal from './partials/Modal';
import { GET_USER_JWT } from '@/store/login/actions.types';
import { physician } from '@/store/practice/initialObject';
import ImagePicker from '../components/ImagePicker';
import { UPDATE_ACCOUNT } from '@/store/login/actions.types';
import { get } from 'lodash';

extend('required', {
  message: 'This field is required'
});

// @vuese
// @group MyAccount
export default {
  name: 'MyAccount',
  components: {
    ValidationProvider,
    ValidationObserver,
    Modal,
    ImagePicker
  },

  data() {
    return {
      showCancelModal: false,
      currenField: '',
      sending: false,
      emailErrors: [],
      edit: {
        photo: {
          edit: false,
          image: '',
          obj: {} 
        },
        name: {
          edit: false,
          value: '',
        },
        email: {
          edit: false,
          value: '',
        },
        allergan: {
          edit: false,
          shipto: '',
          soldto: ''
        },
        pass: {
          pass_cur_visible: false,
          pass_visible: false,
          confirm_pass_visible: false,
          edit: false,
          password_current: '',
          password: '',
          confirm_password: ''
        }
      }
    };
  },
  computed: {
    ...mapGetters([
      'currentUser', 'physicians'
    ])
  },

  beforeMount() {
    if(this.isRole('physician') || this.isRole('practice_admin')){
      this.edit.photo.obj = {...physician};
    }
  },
  
  methods: {
    // @vuese
    // Used to process save and cancel operations
    // @arg Operation type `String`
    async action(type){
      let data = {};
      switch(type) {
      case 'save_name': 
        if(this.edit.name.value == this.currentUser.first_name){
          this.edit.name.edit = false;
        }else{
          const valid = await this.isValid();
          if (valid) {
            this.sending = true;
            data = {
              current_user: {
                first_name: this.currentUser.first_name
              }
            };
          }
          this.$store.dispatch(UPDATE_ACCOUNT, data)
            .then(() => {
              this.$store.dispatch(GET_USER_JWT);
              this.edit.name.edit = false;
              this.flashSuccess('You’ve updated your name');
            })
            .catch(() => {
              this.flashError('Error updating name');
            })
            .finally(() => {
              this.sending = false;
            });
        }
        break;  
      case 'cancel_name': 
        if(this.edit.name.value == this.currentUser.first_name){
          this.edit.name.edit = false;
        }else{
          this.currenField = 'name';
          this.showCancelModal = true;
        }
        break;
      case 'save_email': 
        if(this.edit.email.value == this.currentUser.email){
          this.edit.email.edit = false;
        }else{
          const valid = await this.isValid();
          this.emailErrors = [];
          if (valid) {
            this.sending = true;
            data = {
              current_user: {
                email: this.currentUser.email
              }
            };
          }
          this.$store.dispatch(UPDATE_ACCOUNT, data)
            .then(() => {
              this.$store.dispatch(GET_USER_JWT);
              this.edit.email.edit = false;
              this.flashSuccess('You’ve updated your email address');
            })
            .catch(err => {
              const emailErrors = get(err, 'response.data.errors.email');
              if (emailErrors) {
                this.emailErrors = emailErrors;
              }
              this.flashError('Error updating email');
            })
            .finally(() => {
              this.sending = false;
            });
        }
        break;
      case 'cancel_email':
        if(this.edit.email.value == this.currentUser.email){
          this.edit.email.edit = false;
        }else{
          this.currenField = 'email';
          this.showCancelModal = true;
        }
        break;
      case 'save_photo':
        if(!this.currentUser.avatarPreview || this.currentUser.avatarPreview == ''){
          this.edit.photo.edit = false;
        }else{
          const validImages = this.validImg();
          if (validImages) {
            let formData = new FormData();
            this.sending = true;
            formData.append('current_user[avatar]', this.currentUser.avatar, this.currentUser.avatarFilename);
            this.$store.dispatch(UPDATE_ACCOUNT, formData)
              .then(() => {
                this.$store.dispatch(GET_USER_JWT);
                this.edit.photo.edit = false;
                this.flashSuccess('You’ve updated your photo');
              })
              .catch(err => {
                this.flashWarning('Error updating photo');
              })
              .finally(() => {
                this.sending = false;
              });
          }
        }
        break;
      case 'cancel_photo': 
        if(!this.currentUser.avatarPreview || this.currentUser.avatarPreview == ''){
          this.edit.photo.edit = false;
        }else{
          this.currenField = 'photo';
          this.showCancelModal = true;
        }
        break;
      case 'save_allergan': 
        if(this.edit.allergan.shipto == this.currentUser.allegran.shipto && this.edit.allergan.soldto == this.currentUser.allegran.soldto){
          this.edit.allergan.edit = false;
        }else{
          this.sending = true;
          data = {
            current_user: {
              allegran: {
                shipto: this.currentUser.allegran.shipto,
                soldto: this.currentUser.allegran.soldto
              }
            }
          };
          this.$store.dispatch(UPDATE_ACCOUNT, data)
            .then(() => {
              this.$store.dispatch(GET_USER_JWT);
              this.edit.allergan.edit = false;
              this.flashSuccess('You’ve updated your Allergen APP identifier');
            })
            .catch(() => {
              this.flashError('Error updating Allergen APP');
            })
            .finally(() => {
              this.sending = false;
            });
        }
        break;  
      case 'cancel_allergan':
        if(this.edit.allergan.shipto == this.currentUser.allegran.shipto && this.edit.allergan.soldto == this.currentUser.allegran.soldto){
          this.edit.allergan.edit = false;
        }else{
          this.currenField = 'allergan';
          this.showCancelModal = true;
        }
        break;
      case 'save_pass': 
        if(this.edit.pass.confirm_password != '' && this.edit.pass.password != '' && this.edit.pass.password_current != ''){
          this.sending = true;
          data = {
            current_user: {
              current_password: this.edit.pass.password_current,
              password: this.edit.pass.password,
              password_confirmation: this.edit.pass.confirm_password
            }
          };
          this.$store.dispatch(UPDATE_ACCOUNT, data)
            .then(() => {
              this.$store.dispatch(GET_USER_JWT);
              this.edit.pass.edit = false;
              this.edit.pass.password_current = '';
              this.edit.pass.password = '';
              this.edit.pass.confirm_password = '';
            })
            .catch(() => {
              this.flashError('Error updating password');
            })
            .finally(() => {
              this.sending = false;
              this.flashSuccess('You’ve changed your password');
            });
        }
        break;
      case 'cancel_pass':
        if(this.edit.pass.confirm_password != '' || this.edit.pass.password != '' || this.edit.pass.password_current != ''){
          this.currenField = 'pass';
          this.showCancelModal = true;
        }else{
          this.edit.pass.edit = false;
        }
        break;
      }
    },
    // @vuese
    // Used to store old data and open edit box
    // @arg Box type `String`
    editField(field){
      switch (field) {
      case 'name':
        this.edit.name.edit = true;
        this.edit.name.value = this.currentUser.first_name;
        break;
      case 'email':
        this.edit.email.edit = true;
        this.edit.email.value = this.currentUser.email;
        break;
      
      case 'pass':
        this.edit.pass.edit = true;
        break;
      case 'photo':
        this.edit.photo.image = `background-image: url(${this.currentUser.avatar.url});`;
        this.edit.photo.edit = true;
        this.edit.photo.obj = {...this.currentUser.avatar};
        break;
      case 'allergan':
        this.edit.allergan.edit = true;
        this.edit.allergan.shipto = this.currentUser.allegran.shipto;
        this.edit.allergan.soldto = this.currentUser.allegran.soldto;
        break;
      }
    },
    // @vuese
    // Used to close continue without saving modal
    hideModal() {
      switch(this.currenField) {
      case 'photo': 
        this.currentUser.avatar = {...this.edit.photo.obj};
        this.currentUser.avatarPreview = '';
        this.showCancelModal = false;
        this.edit.photo.edit = false;
        break;
      case 'name': 
        this.currentUser.first_name = this.edit.name.value;
        this.showCancelModal = false;
        this.edit.name.edit = false;
        break;
      case 'email': 
        this.currentUser.email = this.edit.email.value;
        this.showCancelModal = false;
        this.edit.email.edit = false;
        break;
      case 'allergan':
        this.currentUser.allegran.shipto = this.edit.allergan.shipto;
        this.currentUser.allegran.soldto = this.edit.allergan.soldto;
        this.showCancelModal = false;
        this.edit.allergan.edit = false;
        break;
      case 'pass':
        this.edit.pass.password_current = '';
        this.edit.pass.password = '';
        this.edit.pass.confirm_password = '';
        this.showCancelModal = false;
        this.edit.pass.edit = false;
        break;
      }
    },
    // @vuese
    // Used to validate password
    validatePass() {
      return {
        length: this.edit.pass.password.length >= 8,
        specialChar: /[^a-zA-Z0-9\-\/]/.test(this.edit.pass.password),
        haveNumber: /[0-9]/g.test(this.edit.pass.password)
      };
    },
    // @vuese
    // Used to validate account image
    validImg(){
      const avatar = this.currentUser.avatar;
      if ((avatar.hasOwnProperty('url') && !avatar.url) || !avatar) {
        this.currentUser.avatarError = 'Please add skincare pro avatar';
        return false;
      }
      return true;
    }, 
    // @vuese
    // Used to check if passwords is equal
    passEqual() {
      return this.edit.pass.password != '' && this.edit.pass.password === this.edit.pass.confirm_password;
    },
    // @vuese
    // Used to validate account form
    isValid() {
      return this.$refs.form.validate();
    },
    // Used to check if current user is physican
    isPhysician() {
      return this.isRole('physician');
    }
  }
};
</script>