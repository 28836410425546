<template>
  <div class="container container-md-sm">
    <div class="steps-wrap">
      <div class="step passed">
        <p>Set Password</p>
        <span class="step-marker" />
      </div>
      
      <div class="step passed">
        <p>Add Practice Info</p>
        <span class="step-marker" />
      </div>
      
      <div class="step passed">
        <p>Add Practice Addresses</p>
        <span class="step-marker" />
      </div>
        
      <div class="step current">
        <p>Add Skincare Pros</p>
        <span class="step-marker" />
      </div>
      
      <div class="step">
        <p>Add Practice Staff</p>
        <span class="step-marker" />
      </div>
      
      <div class="step">
        <p>Review</p>
        <span class="step-marker" />
      </div>
    </div>
    <!-- /. steps-wrap -->

    <div class="container">
      <Notifications />
    </div>

    <div class="steps-content">
      <div class="top-title">
        <h3 class="mb-2">
          Add Physician to Your Practice
        </h3>
        <a 
          class="right-link" 
          href="#"
          @click.prevent="submit(true)"
        >
          Save for Later
        </a>
      </div>
      <ValidationObserver
        ref="form"
        v-slot="{ invalid }"
        tag="form"
        @submit.prevent="submit(false)"
      >
        <div class="row row-create-account">
          <div class="col-md-8">
            <p class="mb-2">
              Every client you add will be associated with a physician.
              They will see their name and photo on their account.
            </p>
            <div class="f-box form form-white">
              <div 
                v-for="(physician, index) in physicians" 
                :key="index"
              >
                <div class="form-group">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="`Physician name ${index}`"
                    rules="required"
                    mode="passive"
                  >
                    <label :for="`name-${index}`">PHYSICIAN NAME</label>
                    <input
                      :id="`name-${index}`"
                      v-model="physician.first_name"
                      placeholder="Dr. Jean Grey"
                      type="text"
                      :class="errors.length ? 'error' : ''"
                      name="name"
                    >
                    <p class="validation error mt-2">
                      {{ errors[0] }}
                    </p>
                  </validation-provider>
                </div>
                <div class="form-group">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="`Physician email ${index}`"
                    rules="required|email"
                    mode="passive"
                  >
                    <div class="head-tooltip">
                      <label :for="`mail-${index}`">
                        PHYSICIAN EMAIL
                        <i 
                          class="fas fa-question-circle"
                          @mouseover="hover(`physician-email-${index}`)" 
                          @mouseleave="hoverOff(`physician-email-${index}`)"
                        />
                      </label>
                      <div 
                        :data-tooltip-type="`physician-email-${index}`" 
                        class="tooltip-black"
                      >
                        The physician’s email address is never shared with clients.
                      </div>
                    </div>
                    <input
                      :id="`mail-${index}`"
                      v-model="physician.email"
                      placeholder="jeangrey@practice.com"
                      type="text"
                      :class="errors.length || physician.emailErrors.length ? 'error' : ''"
                      name="mail"
                    >
                    <p class="validation error mt-2">
                      {{ errors[0] }}
                    </p>
                    <p 
                      v-for="(err, errIdx) in physician.emailErrors" 
                      :key="errIdx"
                      class="validation error mt-2"
                    >
                      {{ err }}
                    </p>
                  </validation-provider>
                </div>
                <div class="form-check-box mb-3">
                  <div class="head-tooltip">
                    <input
                      :id="`optional_info-${index}`"
                      v-model="physician.uniqueAllegran"
                      type="checkbox"
                      name="optional_info"
                    >
                    <label :for="`optional_info-${index}`">
                      Add unique Allergan APP (optional)
                      <i 
                        class="fas fa-question-circle"
                        @mouseover="hover(`s4-allergan-${index}`)" 
                        @mouseleave="hoverOff(`s4-allergan-${index}`)"
                      />
                    </label>
                    <div 
                      :data-tooltip-type="`s4-allergan-${index}`" 
                      class="tooltip-black"
                    >
                      Connect all the doctors in your practice to a single Allergan identifier using these fields, or add an Allergan identifier to each doctor individually.
                    </div>
                  </div>   
                </div>
                <div 
                  v-if="physician.uniqueAllegran" 
                  class="row alegren-wrap"
                >
                  <div class="form-group col-md-6">
                    <label :for="`soldto-${index}`">PHYSICIAN SOLDTO</label>
                    <input
                      :id="`soldto-${index}`"
                      v-model="physician.allegran.soldto"
                      type="text"
                      :class="errorPhysAlrg && errorPhysAlrgInd.includes(index) && physician.allegran.soldto == '' ? 'error' : ''" 
                      name="soldto"
                    >
                  </div>
                  <div class="form-group col-md-6">
                    <label :for="`shipto-${index}`">PHYSICIAN SHIPTO</label>
                    <input
                      :id="`shipto-${index}`"
                      v-model="physician.allegran.shipto"
                      type="text"
                      :class="errorPhysAlrg && errorPhysAlrgInd.includes(index) && physician.allegran.shipto == '' ? 'error' : ''"
                      name="shipto"
                    >
                  </div>
                  <p 
                    v-if="errorPhysAlrg && errorPhysAlrgInd.includes(index) && (physician.allegran.shipto == '' || physician.allegran.soldto == '')" 
                    class="validation error pl-3"
                  >
                    Please enter your allergan identifier.
                  </p>
                </div>

                <div class="form-group mb-3">
                  <label :for="`pphoto-${index}`">PHYSICIAN Photo</label>
                  <ImagePicker 
                    :image="physician.avatar"
                    :image-preview="physician.avatarPreview"
                    :filename="physician.avatarFilename"
                    :bordered="true"
                    :can-remove="physician.id ? false : true"
                    @image="val => physician.avatar = val"
                    @image-preview="val => physician.avatarPreview = val"
                    @filename="val => physician.avatarFilename = val"
                  />
                  <p
                    v-if="physician.avatarError"
                    class="validation error mt-2"
                  >
                    {{ physician.avatarError }}
                  </p>
                </div>
                <a
                  v-if="index != 0 && !physician.id"
                  class="hover-btn minus-physician mb-4 d-block"
                  @click="removePhysician(index)"
                >
                  <div class="form-options">
                    <i class="fas fa-minus" /> Remove Physician
                  </div>
                </a>
                <hr>
              </div>
              <div class="form-options pt-sm-2">
                <a 
                  class="hover-btn"
                  @click="addPhysician"
                >
                  <i class="fas fa-plus" />
                  Add Another Physician
                </a>
              </div>
            </div>
            <!-- /. First Group -->
            <h3>Link Allergan APP to Entire Practice (Optional)</h3>
            <p class="mb-2">
              Enter your identifier to credit all your practice's Allergan APP points to the practice.
              Or, add each individuality with their associated physician.
            </p>
            <div class="f-box form form-white">
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="soldto">PRACTICE APP SOLDTO</label>
                  <input
                    id="soldto"
                    v-model="allegran.soldto"
                    type="text"
                    :class="errorAlrg && allegran.soldto == '' ? 'error' : ''"
                    name="soldto"
                  >
                </div>
                <div class="form-group col-md-6">
                  <label for="shipto">PRACTICE APP SHIPTO</label>
                  <input
                    id="shipto"
                    v-model="allegran.shipto"
                    type="text"
                    :class="errorAlrg && allegran.shipto == '' ? 'error' : ''"
                    name="shipto"
                  >
                </div>
                <p 
                  v-if="errorAlrg && (allegran.shipto == '' || allegran.soldto == '')" 
                  class="validation error pl-3"
                >
                  Please enter your allergan identifier.
                </p>
              </div>
            </div>
            <!-- /. Second Group -->
          </div>

          <div class="col-md-4">
            <Sidebar />
            <button 
              class="btn btn-primary" 
              :disabled="sending" 
              type="submit"
            >
              Continue
            </button>
            <router-link 
              class="btn btn-bordered mt-3" 
              :to="{
                name: 'CreateAccountS3',
              }" 
              tag="a"
            >
              Back
            </router-link>
          </div>
        </div>
        <!-- /. row-create-account -->
      </ValidationObserver>
    </div>
    <ContactUs />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Sidebar from './partials/Sidebar';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { physician, allegran } from '@/store/practice/initialObject';
import {
  ADD_PHYSICIAN,
  UPDATE_PHYSICIAN,
  UPDATE_PRACTICE,
} from '@/store/practice/actions.types';
import ContactUs from '../components/ContactUs';
import ImagePicker from '@/views/components/ImagePicker';

extend('required', {
  message: 'This field is required'
});

// @vuese
// @group Create Account
export default {
  name: 'CreateAccountS4',
  components: {
    Sidebar,
    ValidationProvider,
    ValidationObserver,
    ContactUs,
    ImagePicker,
  },
  data() {
    return {
      sending: false,
      errorPhysAlrg: false,
      errorAlrg: false,
      errorPhysAlrgInd: []
    };
  },
  computed: {
    ...mapGetters(['practice', 'physicians', 'allegran'])
  },
  watch: {
    physicians: function (newPhysicians) {
      if (!newPhysicians.length) this.addPhysician();
    }
  },
  beforeMount() {
    if (this.physicians.length == 0) this.addPhysician();
  },
  methods: {
    // @vuese
    // Used to store physicans and store Alergan data
    // @arg Save for later
    async submit(saveForLater) {
      const valid = await this.isValid();
      const validPhysicianAllergan = this.validPhysAllergan();
      const validAllergan = this.validAllergan();
      // const validImages = this.validImages();
      const { allegran, physicians } = this;
      // if (valid && validImages) {
      if (valid && validPhysicianAllergan && validAllergan) {
        this.sending = true;

        const data = [],
          physicanPromises = [];
        physicians.forEach(physician => {
          data.push({
            data: this.prepareData(physician),
            id: physician.id ? physician.id : null
          });
        });
        data.forEach(physicianData => {
          if (physicianData.id)
            physicanPromises.push(this.$store.dispatch(UPDATE_PHYSICIAN, physicianData));
          else
            physicanPromises.push(this.$store.dispatch(ADD_PHYSICIAN, physicianData));
        });
        // here val
        if (allegran.soldto && allegran.shipto) {
          physicanPromises.push(this.$store.dispatch(UPDATE_PRACTICE, {practice:{allegran}}));
        }

        Promise.all(physicanPromises)
          .then(responses => {
            responses.forEach(resp => {
              if (resp.data && resp.data.physician) {
                this.physicians.forEach(physician => {
                  if (physician.email == resp.data.physician.email) {
                    physician.id = resp.data.physician.id;
                    physician.avatar = resp.data.physician.avatar;
                  }
                });
              }
            });
            if(saveForLater)
              this.flashSuccess('Practice staff has been saved');
            this.$router.push({name: 'CreateAccountS5'});
          })
          .catch(err => {
            const errors = err.data.errors;
            const data = err.config.data;
            const errEmail = data.get('physician[email]');

            if (errors.email) {
              physicians.forEach(physician => {
                if (physician.email == errEmail) {
                  physician.emailErrors = errors.email;
                }
              });
            } else if (errors.message) {
              physicians.forEach(physician => {
                if (physician.email == errEmail) {
                  physician.emailErrors = [errors.message];
                }
              });
            } else {
              this.flashError('Error updating practice physician');
            }
          })
          .finally(() => {
            this.sending = false;
          });
      }
    },

    // @vuese
    // Used to prepare physican data
    prepareData(physician) {
      let formData = new FormData();
      if (!physician.avatar.hasOwnProperty('url') && physician.avatar)
        formData.append('physician[avatar]', physician.avatar, physician.avatarFilename);

      formData.append('physician[email]', physician.email);
      formData.append('physician[first_name]', physician.first_name);
      // here val
      if (physician.uniqueAllegran) {
        formData.append(
          'physician[allegran][soldto]',
          physician.allegran.soldto
        );
        formData.append(
          'physician[allegran][shipto]',
          physician.allegran.shipto
        );
      }
      return formData;
    },
    // @vuese
    // Used to check if form valid
    async isValid() {
      return await this.$refs.form.validate();
    },
    // @vuese
    // Used to valid images
    validImages() {
      let validImages = true;
      this.physicians.forEach((physician, idx) => {
        let validImage = this.validImage(physician, idx);
        if (!validImage) validImages = validImage;
      });
      return validImages;
    },
    // @vuese
    // Used to validate Allergan
    validAllergan() {
      this.errorAlrg = false;
      if((this.allegran.shipto == '' && this.allegran.soldto == '') || (this.allegran.shipto != '' && this.allegran.soldto != '')){
        return true;
      }else{
        this.errorAlrg = true;
        return false;
      }
    },
    // @vuese
    // Used to validate physican Allergan
    validPhysAllergan() {
      let valid = true;
      this.errorPhysAlrgInd = [];
      this.errorPhysAlrg = false;
      this.physicians.forEach((physician, index) => {
        if(!((physician.allegran.shipto == '' && physician.allegran.soldto == '') || (physician.allegran.shipto != '' && physician.allegran.soldto != ''))){
          valid = false;
          this.errorPhysAlrgInd.push(index);
          this.errorPhysAlrg = true;
        }
      });
      return valid;
    },
    // @vuese
    // Used to validate image
    validImage(physician, idx) {
      const avatar = physician.avatar;
      if ((avatar.hasOwnProperty('url') && !avatar.url) || !avatar) {
        this.physicians[idx].avatarError = 'Please add physician avatar';
        return false;
      }
      return true;
    },
    // @vuese
    // Used to add new physican object to physicans array
    addPhysician() {
      const physicianObj = { ...physician };
      physicianObj.allegran = { ...allegran };
      this.physicians.push({ ...physicianObj });
    },
    // @vuese
    // Used to remove physican from physicans array
    // @arg physican index
    removePhysician(index) {
      this.physicians.splice(index, 1);
    },
    // @vuese
    // Used to show tooltip
    // @arg HTML element data type
    hover(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.add('open');
    },
    // @vuese
    // Used to hide tooltip
    // @arg HTML element data type
    hoverOff(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.remove('open');
    }
  }
};
</script>