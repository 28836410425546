<template>
  <div class="rmd-single-preloader">
    <i 
      class="fa"
      :class="stageClasses[stage]"
    />
  </div>
</template>

<script>
// @group Components
export default {
  name: 'StatusPreloader',
  props: {
    // @vuese
    // Stage og preloader
    stage: {
      type: String,
      default: 'loading'
    }
  },

  data() {
    return {
      requestStages: ['loading', 'success', 'error'],
      stageClasses: {
        loading: 'fa-circle-notch fa-spin',
        success: 'fa-check-circle',
        error: 'fa-times-circle'
      }
    };
  }
};
</script>

<style lang="scss" scoped>
  .rmd-single-preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    font-size: 25px;

    .fa-circle-notch {
      color: #a989de;
    }

    .fa-check-circle {
      color: #5eb052;
    }

    .fa-times-circle {
      color: red;
    }
  }
</style>
