<template>
  <div>
    <nav class="dashboard-nav">
      <button
        class="nav-toggler close"
        @click.prevent="closeNav"
      />
      <div class="inner-pad">
        <picture class="nav-logo">
          <img
            :src="logo"
            alt="Logo"
          >
          <span
            v-if="!isRole('super_admin') && !isRole('group_admin') && !isRole('group_practice_admin')"
            class="brand">
            {{ practice.name }} is a RegimenPro<sup>®</sup> practice.
          </span>
        </picture>
      </div>

      <div
        v-if="isRole('super_admin') || isRole('group_admin') || isRole('group_practice_admin')"
        id="practice-select"
        class="inner-pad"
      >
        <SelectPractice
          v-model="selectedPractice"
          title="All Practices"
          :items="valuesForSelect(practices)"
          :total="practicesTotal"
          :loading="isPracticesLoading"
          :name="'practice-select'"
          :filters="filters"
          @load-more="loadMorePractices"
        />
      </div>

      <ul v-if="!isRole('esthetician')" class="dmain-menu">
        <li :class="$route.path == '/dashboard' ? 'active' : ''">
          <router-link
            :to="{
              name: 'userdashboard'
            }"
            tag="a"
            class="nav-link"
          >
            <span class="nav-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="30"
                height="26"
                viewBox="0 0 30 26"
              >
                <metadata><?xpacket begin=" " id="W5M0MpCehiHzreSzNTczkc9d"?>
                  <x:xmpmeta
                    xmlns:x="adobe:ns:meta/"
                    x:xmptk="Adobe XMP Core 5.6-c140 79.160451, 2017/05/06-01:08:21"
                  >
                    <rdf:RDF xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                      <rdf:Description rdf:about />
                    </rdf:RDF>
                  </x:xmpmeta><?xpacket end="w"?></metadata>
                <image
                  width="30"
                  height="26"
                  xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAaCAQAAAAKqCQ/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAAHdElNRQfjCB8NLCP3gEYLAAABDklEQVQ4y2P8z4AbHC5hYLDtwaPgPw5YxXyu/f/l/5fPtVcx41KDQ7iO89as/1Bwa1YdJwmam4WfrPqPBJ6sahYmUnOXzJvt/9HAm+1dMkRonqzz+fB/LODz4ck6BDTPsfxx8j8O8OPkHEs8mpf7/LnwHw/4c2G5Dw7NW+L+Xf5PAPy7vCUOi+ZDJf8Jav3//////5cPlaBohiYIYgE84aAlCGIBJOFgJAhiwZNVzcKMK3yE5RkYGBiUzJTiGIgC9xbdO8XAwMDw9iE8wHZnE2vn7myYHibibMMOWHCIX9kzA8FxyWDQIUUzg8tUJE4GdjUUOXtUMz01w+P53uk9OcgSLkhs5ATDwHDvNIwFADG1VdXmlWvWAAAAAElFTkSuQmCC"
                />
              </svg>
            </span> Dashboard
          </router-link>
        </li>
        <li :class="currentTab(['Patients','PatientView']) ? 'active' : ''">
          <router-link
            :to="{
              name: 'Patients'
            }"
            tag="a"
            class="nav-link"
          >
            <span class="nav-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="30"
                height="22"
                viewBox="0 0 30 22"
              >
                <metadata><?xpacket begin=" " id="W5M0MpCehiHzreSzNTczkc9d"?>
                  <x:xmpmeta
                    xmlns:x="adobe:ns:meta/"
                    x:xmptk="Adobe XMP Core 5.6-c140 79.160451, 2017/05/06-01:08:21"
                  >
                    <rdf:RDF xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                      <rdf:Description rdf:about />
                    </rdf:RDF>
                  </x:xmpmeta><?xpacket end="w"?>
                </metadata>
                <image
                  width="30"
                  height="22"
                  xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAWCAQAAAB9auREAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAAHdElNRQfjCB8NLABV5zd5AAABmklEQVQ4y2P4z4Af+thtXPzo6uu7u9amRaDLEdA6tf3fx/8w8HH7SjEOojWnR/1HaP3//////1PaidQswPTu4X808Pe9ngJCBRMDTqCmJiiHLsYkYGqGxBNkaiq5e/7O2QmNCkKze+9fvHW6Jg8ipayCzUglJSROWwXMST/hAVOe9Z/hP4ORCrqP/////z8uAMnPT65jKjh/ECL5/Ca6zK+3GlJIfmbC4+uiYoZPqCLd3defIXEXTcW0uacOZnZ90a+3iHheOFmQBSWqlMXe3kfV+vCyJA9CgZXO7N4z+68eXzI90AVLCvO0+fYSofX9QysdQkkWJZEEufx4DXXZY2czYrX+Z2D8z8DAwMDg77BsObfEuwdBwQfOIQeQAJOsjKKSgAAz08dPDx48fPD2D0r4wUxxNjuz30wDYaqyWFrEqrmo4fH1+faVxWn6SnjTtrHa6rl/3//HBT4e2OxuhUNzZgxyAGIH/z72NWLR7OeAx04U+8uzMDRvWEyU1v///985j5E4lZUYiATKSkzEKsUGWNAF7twhXjMAoSaKtAUv2V8AAAAASUVORK5CYII="
                />
              </svg>
            </span> Clients
          </router-link>
        </li>
        <li
          :class="currentTab(['Orders', 'ShowOrder']) ? 'active' : ''"
        >
          <router-link
            :to="{
              name: 'Orders'
            }"
            class="nav-link"
          >
            <span class="nav-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="30"
                height="22"
                viewBox="0 0 30 22"
              >
                <metadata><?xpacket begin=" " id="W5M0MpCehiHzreSzNTczkc9d"?>
                  <x:xmpmeta
                    xmlns:x="adobe:ns:meta/"
                    x:xmptk="Adobe XMP Core 5.6-c140 79.160451, 2017/05/06-01:08:21"
                  >
                    <rdf:RDF xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                      <rdf:Description rdf:about />
                    </rdf:RDF>
                  </x:xmpmeta><?xpacket end="w"?>
                </metadata>
                <image
                  width="30"
                  height="22"
                  xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAWCAQAAAB9auREAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAAHdElNRQfjCB8NKxLpH9D2AAABjUlEQVQ4y62TQUsCURSFj+VChhpyY4ISUq0kZIICITAYRKxN0bpf0KJ20apV0GJ2kSREEESLkn5AhO5amRhoQplYoVLBUGMRgshpYRYzYal13+rdez8O99z3TAN9ijI13duDXyOdHpkwpA622WKkTgn96QoGf9dsFl2i+Ae4cxQwt95qs62vAAAR2iy+AgCotWrYV0R26oaZqKHFqdWbw0MAmPS53YtLG7ttKTdW5Xaq+bcHr5udwMSsXHu6jNuFjmBibZnc32pjZpR1NxHlNlb1XeRPj+Tf4HIh81L6qfmlVMjoJv90W1uYJ/qF40gzt48j/QKxMP9FmG5TgMViG86dD40CgDwWi9eeiwW9psPZ3SePx84AIJcckh6vKxUABCENUrvPWs0EMecn41Hjt49HyTk/QVjN91lq0iBBfBTvLsi9kMcV8GYTpLJqhJVVMpsIeD2uvRB5m6pnP4ozclVtzHaVsAtG2C5cJRr1qjoj62DCJ50cqflcMqw4RCNKEA4xrOSSav7kyCc1cu/5qPIaTO8m/gAAAABJRU5ErkJggg=="
                />
              </svg>
            </span>
            Orders
          </router-link>
        </li>
        <li
          v-if="!isRole('staff') && !isRole('group_practice_admin')"
          :class="currentTab(['ReportsAll']) ? 'active' : ''"
        >
          <router-link
            :to="{
              name: 'ReportsAll'
            }"
            class="nav-link"
          >
            <span class="nav-icon">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="60px"
                height="54px"
                viewBox="0 0 600 540"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  id="layer101"
                  fill="#ffffff"
                  stroke="none"
                >
                  <path
                    d="M0 270 l0 -270 30 0 30 0 2 208 3 207 74 -128 c40 -71 79 -131 86 -133 7 -3 51 18 98 45 47 28 88 51 91 51 3 0 33 -47 67 -105 34 -58 63 -105 65 -105 13 0 54 25 54 33 0 8 -113 210 -149 266 -7 10 -29 1 -105 -43 l-96 -55 -14 22 c-8 12 -39 66 -69 120 l-56 97 244 0 245 0 0 30 0 30 -300 0 -300 0 0 -270z"
                  />
                </g>
              </svg>
            </span>
            Reports
          </router-link>
        </li>
        <li
          v-if="!isRole('group_admin')"
          :class="$route.path == '/dashboard/top-picks' ? 'active' : ''"
        >
          <router-link
            :to="{
              name: 'TopPicks'
            }"
            tag="a"
            class="nav-link"
          >
            <span class="nav-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="30"
                height="28"
                viewBox="0 0 30 28"
              >
                <metadata><?xpacket begin=" " id="W5M0MpCehiHzreSzNTczkc9d"?>
                  <x:xmpmeta
                    xmlns:x="adobe:ns:meta/"
                    x:xmptk="Adobe XMP Core 5.6-c140 79.160451, 2017/05/06-01:08:21"
                  >
                    <rdf:RDF xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                      <rdf:Description rdf:about />
                    </rdf:RDF>
                  </x:xmpmeta><?xpacket end="w"?>
                </metadata>
                <image
                  width="30"
                  height="28"
                  xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAcCAQAAADc8cciAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAAHdElNRQfjCB8NKR7Sn/5fAAABkklEQVQ4y53UO2vCYBgF4NdUhGZwKE7qkKGTBQcXwaVFCEKhOCgODoLg4OBWBPEH6CziWtFddHLxF7h0EKogBRWqIF4gWKxo8XTQtlHzefnO+p4HQsKJDrTJnTUUkmVJuhYHg0ajVKpWp9+by43+8dHns9vN5q95t1urFYtvH1sEAoFSicUIO2nWPS4QyONq1ncvi1EqsVEEApXyUHCQ1SQSiARWk8MLlFJ+i1MJLQoAa6zBiJJKgMhm3X/g87IY2axXz7H7J+KIXvycCLLMQ4mIZFmQJF4sSYIo8mJRFPoDXtwfCO02L263hUqZF1fKZDFOezzfedqzGAmUjPHgZAxEIJOhVb+Utuomw3YYTtt8eAmdD522v1WBokHWODSiRIOqSeLotvbp75pVGPSSOYMrL5l/ocKgQvYEVwpZdX8Hn+B79ACDcmkGV3Lp/e4BZrw61Ws6ikHx6HqHr5V4VKuniUFh//Lvr7mchP3aLQYGed2zPgDM+l43q8PEoAfHuDPuPDjYDR2OLNZxS/T6zr7/AHIRmb14WPt1AAAAAElFTkSuQmCC"
                />
              </svg>
            </span> Top Picks
          </router-link>
        </li>
        <li
          v-if="!isRole('group_admin')"
          :class="currentTab(['PresetRegimen', 'RegimenEdit']) ? 'active' : ''"
        >
          <router-link
            :to="{
              name: 'PresetRegimen'
            }"
            tag="a"
            class="nav-link"
          >
            <span class="nav-icon">
              <img
                :src="presentIcon"
                alt="preset"
              >
            </span> Preset Regimens
          </router-link>
        </li>
        <li :class="currentTab(['MyPractice','InviteTeam']) ? 'active' : ''">
          <router-link
            :to="{
              name: 'MyPractice'
            }"
            tag="a"
            class="nav-link"
          >
            <span class="nav-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="30"
                height="27"
                viewBox="0 0 30 27"
              >
                <metadata><?xpacket begin=" " id="W5M0MpCehiHzreSzNTczkc9d"?>
                  <x:xmpmeta
                    xmlns:x="adobe:ns:meta/"
                    x:xmptk="Adobe XMP Core 5.6-c140 79.160451, 2017/05/06-01:08:21"
                  >
                    <rdf:RDF xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                      <rdf:Description rdf:about />
                    </rdf:RDF>
                  </x:xmpmeta><?xpacket end="w"?>
                </metadata>
                <image
                  width="30"
                  height="27"
                  xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAbCAQAAADB9PeaAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAAHdElNRQfjCB8NKiemtyWUAAAAeklEQVQ4y+2UwQrAIAxD6/Ds/v8z6w+4U8G1JJ2M7WROkRppn2IZKkwnK1YRabDa6cFS3aZ28/IkbO1p8FRHvuWjsLU9N4l8UBmagwHqnDa7qpbTRo9EXwP7gTYYpNrwMwjnIcI12m69QjuQ37Q3be9BmNOm4eRrx7oAEvIvCKEy9OkAAAAASUVORK5CYII="
                />
              </svg>
            </span>
            {{ (isRole('super_admin') || isRole('group_admin') || isRole('group_practice_admin')) ? 'Practices' : 'My Practice' }}
          </router-link>
        </li>
        <li :class="$route.path == '/dashboard/my-account' ? 'active' : ''">
          <router-link
            :to="{
              name: 'MyAccount'
            }"
            tag="a"
            class="nav-link"
          >
            <span class="nav-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="30"
                height="30"
                viewBox="0 0 30 30"
              >
                <metadata><?xpacket begin=" " id="W5M0MpCehiHzreSzNTczkc9d"?>
                  <x:xmpmeta
                    xmlns:x="adobe:ns:meta/"
                    x:xmptk="Adobe XMP Core 5.6-c140 79.160451, 2017/05/06-01:08:21"
                  >
                    <rdf:RDF xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                      <rdf:Description rdf:about />
                    </rdf:RDF>
                  </x:xmpmeta><?xpacket end="w"?>
                </metadata>
                <image
                  width="30"
                  height="30"
                  xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAQAAACROWYpAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAAHdElNRQfjCB8NKDb+MWfkAAABTklEQVQ4y7WTsWrCUBSGT2IHkSDiEIKDUwXBJdnawcXRF6hPIZ0lXZpFN30h1xRKSIYsGodSkIqCmJghi/m7tLS5JCa9oV+2/96PA/f8IVDa12vPDdc8b89b15wbvXb6rdRQH0cH/CI66OOC8sKADxZ/YRSQR8MUFQD80TBXXlvIYG3lyH01Yy4A+H01eVukBJpKdcqirqnJgJGbTboCe8rIx+M1mT1lZNuhINMNbIdJir/26vV/91yqYSDQ0yN3t4v/VQKIH7GEW06+SY/lqixLkiQRhWEY7vf7KFduiJo6GNzdd253bbHxk8ent3dv82Iul7ZzilMa1m1NJzsPOXysppNuK7EquTrTk5u9RnSY6XL1S+4orllU/MY1OwqIlNrG/qsKABtbqVWe9eEDz5qaSuVCnsUzFwA8S4h9oc4zmQiBKHD3Syhfz4BX/gQ2R94i+5t4FQAAAABJRU5ErkJggg=="
                />
              </svg>
            </span> My Account
          </router-link>
        </li>
        <!-- <li :class="$route.path == '/dashboard' ? 'active' : ''">
          <router-link to="/dashboard/help-and-support" tag="a" class="nav-link">
            <span class="nav-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
                <metadata><?xpacket begin=" " id="W5M0MpCehiHzreSzNTczkc9d"?>
                  <x:xmpmeta xmlns:x="adobe:ns:meta/" x:xmptk="Adobe XMP Core 5.6-c140 79.160451, 2017/05/06-01:08:21">
                    <rdf:RDF xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                      <rdf:Description rdf:about />
                    </rdf:RDF>
                  </x:xmpmeta><?xpacket end="w"?>
                </metadata>
                <image
                  width="30"
                  height="30"
                  xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAQAAACROWYpAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAAHdElNRQfjCB8NJzCQyt4eAAACH0lEQVQ4y52VMWhTQRjH/3l0eNOly9P3BJdCoUULSQuJutjJQpNmdGi6OHUpoSAoxNQxWBxaOiuFjtJFaEU7uESwliaZMtVQSGjz+kCbUNAs5u9gEn337lLp/6aP737ve3f33f9ChKxhIx5LzkUijm3bgOs23HJpZ/fgoNkJTKVvWObKslelQmdHzzKW6Z/tCxZS9QoHqFaZT2rgl8/Z4mVqvcgq4O1NP9rmHje4wT22JfzNawmWqxY4QdEdEyxI+GruH3gh5Ue3+mBvbEl4OtWFLdO/Tcd0KCg4ygwzHKWgoMNjaesskwCxsuz/7CIFBad5TpI85zQFBRelnctlCIQN+VzHKCi434/3KSg4JsFeNWwMxWPWiL9tHuEXgGg/vgUA+C41lzUSjw0l5+SeeyLFFQDAeKA3EwkjEsFA/cRTAMBMIBOdNBx7ENrGPMoAxvE4kHNsXJzoe7HDhxQUvM2aIntxYgyq+wrvAYTxFjfVE76WdXW/0aag4I4mf1Q0Gqe6uh/xA0AUCU3edY1iSQfXAABT2kUdHoYe3PnwWZ38hAKAe7ivgWfuKtrz/+RVw4bR7Kyv4QpaX2t2FFeypwLzzMtG0FW9dyWJdErlXnkKCuZV7F8zIIjVXBDXwn4bUhmg9rdb25sK61VV11cNmH76EtOvV/6sVQkTlpldUp+7V80uyc9NSP3QzSamJp0b168BZ17jtFh6t/tF8dD9Bq1Iz0oZklrkAAAAAElFTkSuQmCC"
                />
              </svg>
            </span> Help and Support
          </router-link>
        </li> -->
      </ul>

      <div class="inner-pad text-center">
        <a
          v-if="!isRole('group_admin')"
          href="#"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#add_patients"
          @click="showAddModal = true"
        >
          Add New Client
        </a>
      </div>
    </nav>
    <Modal
      :show-modal="showAddModal"
      modal-content-class="overflow-visible"
      modal-id="add_patients"
      @hide-modal-window="showAddModal = false"
    >
      <AddPatientModal
        :name="'nav'"
        :show-modal="showAddModal"
        :allow-filter-by-practice="false"
        @add-patient="showAddModal = false"
        @hide-modal-window="showAddModal = false"
      />
    </Modal>
    <div
      v-if="showAddModal"
      class="modal-backdrop fade show"
    />
  </div>
  <!-- /. dashboard-nav -->
</template>

<style lang="scss">
  .dashboard-nav .content-down {
    width: 100%;
    margin-right: 0;
    .title {
      width: 100%;
    }
    .multi-select {
      width: 100%;
    }
    input[type="text"] {
      width: 100%;
    }
  }
</style>

<script>
import logo from '@images/logo-light.png';
import presentIcon from '@images/present-icon.png';
import Modal from '../partials/Modal';
import { mapGetters } from 'vuex';
import AddPatientModal from '../patients/AddPatientModal';
import SelectPractice from '@/views/components/Select';
import practices from '@/mixins/practices';

// @group Partials
export default {
  name: 'Navigation',
  components: {
    Modal,
    AddPatientModal,
    SelectPractice,
  },
  mixins: [practices],
  props: {
    // @vuese
    // Practice ID
    value: {
      type: [String,Number],
      default: null
    }
  },
  data() {
    return {
      showAddModal: false,
      logo,
      presentIcon,
      selectedPractice: '',
      loadOnInit: false,
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'practice'
    ])
  },
  watch: {
    selectedPractice() {
      this.$emit('input', this.selectedPractice);
    },
    value: {
      immediate: true,
      handler() {
        this.selectedPractice = this.value;
      }
    },
    practices: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('set-practices', this.practices);
      }
    }
  },
  beforeMount() {
    if (this.isRole('super_admin') || this.isRole('group_admin') || this.isRole('group_practice_admin')) {
      this.loadOnInit = true;
    }
  },
  methods: {
    // @vuese
    // Used to close mobile menu on mobile
    closeNav() {
      document.querySelector('.dashboard-nav').classList.remove('open-nav');
    },
    // @vuese
    // Used to check if current route in specific array
    // @arg Array of route names
    currentTab(args){
      if(args.length){
        return args.includes(this.$route.name);
      }
    }
  }
};
</script>