<template>
  <section class="container text-center pt-5">
    <div class="error-page">
      <h2 class="headline text-yellow"> 404</h2>

      <div class="error-content">
        <h3><i class="fa fa-warning text-yellow"></i> Oops! Page not found.</h3>
        <p>We could not find the page you were looking for.</p>
      </div>
      <!-- /.error-content -->
    </div>
    <!-- /.error-page -->
  </section>
</template>

<script>
export default {
  name: 'PageNotFound',
  components: {}
};
</script>

<style scoped>
  .headline.text-yellow {
    float: none;
  }

  .error-content {
    margin-left: 0;
  }

</style>
