<template>
  <div class="container container-md-sm">
    <div class="steps-wrap">
      <div class="step passed">
        <p>Set Password</p>
        <span class="step-marker" />
      </div>
      
      <div class="step passed">
        <p>Add Practice Info</p>
        <span class="step-marker" />
      </div>
      
      <div class="step current">
        <p>Add Practice Addresses</p>
        <span class="step-marker" />
      </div>
        
      <div class="step">
        <p>Add Skincare Pros</p>
        <span class="step-marker" />
      </div>
      
      <div class="step">
        <p>Add Practice Staff</p>
        <span class="step-marker" />
      </div>
      
      <div class="step">
        <p>Review</p>
        <span class="step-marker" />
      </div>
    </div>
    <!-- /. steps-wrap -->

    <div class="container">
      <Notifications />
    </div>
    
    <div class="steps-content">
      <div class="top-title">
        <h3 class="mb-2">
          Add Practice Address
        </h3>
        <a 
          class="right-link" 
          href="#"
          @click.prevent="addPracticeAdress(true)"
        >Save for Later</a>
      </div>
      <ValidationObserver
        ref="form"
        v-slot="{ invalid }"
        tag="form"
        autocomplete="nope"
        @submit.prevent="addPracticeAdress(false)"
      >
        <div class="row row-create-account">
          <div class="col-md-8">
            <p class="mb-2">
              This information will be viewable to your clients.
            </p>
            <div class="f-box form form-white">
              <validation-provider
                v-slot="{ errors }"
                name="Street address"
                rules="required"
                mode="passive"
              >
                <div class="form-group">
                  <label for="email">STREET ADDRESS</label>
                  <input 
                    id="address"
                    v-model="address.default_address.address1"
                    placeholder="113 Main Stret"
                    type="text"
                    :class="errors.length ? 'error' : ''"
                    autocomplete="nope"
                    name="address1"
                    @keydown.enter.prevent
                  >
                  <p class="validation error mt-2">
                    {{ errors[0] }}
                  </p>
                </div>
              </validation-provider>
              <div class="form-group">
                <label for="suite">UNIT OR SUITE NUMBER (OPTIONAL)</label>
                <input
                  id="address2"
                  v-model="address.default_address.address2"
                  placeholder="Suite 600"
                  type="text"
                  name="address2"
                >
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="City"
                    rules="required"
                    mode="passive"
                  >
                    <label for="city">CITY</label>
                    <input
                      id="city"
                      v-model="address.default_address.city"
                      placeholder="Chicago"
                      type="text"
                      :class="errors.length ? 'error' : ''"
                      name="city"
                    >
                    <p class="validation error mt-2">
                      {{ errors[0] }}
                    </p>
                  </validation-provider>
                </div>
                <div class="form-group col-md-6">
                  <label for="state">STATE</label>
                  <select
                    id="state"
                    v-model="address.default_address.state"
                    class="select-md col"
                    name="state"
                    @change="validZip(zipTypes.default)"
                  >
                    <option value="">
                      Select a State
                    </option>
                    <option
                      v-for="(state, index) in usStates"
                      :key="index"
                      :value="state.name"
                    >
                      {{ state.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="zip">ZIP CODE</label>
                  <input
                    id="zip"
                    v-model="address.default_address.zip"
                    placeholder="12345"
                    type="text"
                    :class="defaultZipMessage ? 'error' : ''"
                    name="zip"
                    @input="validZip(zipTypes.default)"
                  >
                  <p
                    v-if="defaultZipMessage"
                    class="validation error mt-2"
                  >
                    {{ defaultZipMessage }}
                  </p>
                </div>
                <div class="form-group col-md-6">
                  <label for="country">COUNTRY</label>
                  <input
                    id="country"
                    v-model="address.default_address.country"
                    placeholder="United States"
                    readonly
                    value="United States"
                    type="text"
                    name="country"
                  >
                </div>
              </div>
            </div><!-- /. First Group -->
            <h3 class="mt-2">
              Add Practice Delivery Address
            </h3>
            <p class="mb-2">
              This information is only for the RegimenPro® staff.
            </p>
            <div class="f-box form form-white">
              <div class="form-check-box mb-3">
                <input
                  id="same_info"
                  v-model="deliverySameAsDefault"
                  type="checkbox"
                  name="same_info"
                >
                <label for="same_info">Same as Practice Address</label>
              </div>
              <div 
                v-if="!deliverySameAsDefault"
              >
                <div class="form-group">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Street address "
                    rules="required"
                    mode="passive"
                  >
                    <label for="street">STREET ADDRESS</label>
                    <input
                      id="street"
                      v-model="address.delivery_address.address1"
                      placeholder="113 Main Stret"
                      type="text"
                      :class="errors.length ? 'error' : ''"
                      name="street"
                    >
                    <p class="validation error mt-2">
                      {{ errors[0] }}
                    </p>
                  </validation-provider>
                </div>
                <div class="form-group">
                  <label for="suite1">UNIT OR SUITE NUMBER (OPTIONAL)</label>
                  <input
                    id="suite1"
                    v-model="address.delivery_address.address2"
                    placeholder="Suite 600"
                    type="text"
                    name="suite1"
                  >
                </div>
                <div class="row">
                  <div class="form-group col-md-6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="City "
                      rules="required"
                      mode="passive"
                    >
                      <label for="city">CITY</label>
                      <input
                        id="city"
                        v-model="address.delivery_address.city"
                        placeholder="Chicago"
                        type="text"
                        :class="errors.length ? 'error' : ''"
                        name="city"
                      >
                      <p class="validation error mt-2">
                        {{ errors[0] }}
                      </p>
                    </validation-provider>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="state1">STATE</label>
                    <select
                      id="state1"
                      v-model="address.delivery_address.state"
                      class="select-lg col"
                      name="state"
                      @change="validZip(zipTypes.delivery)" 
                    >
                      <option value="">
                        Select a State
                      </option>
                      <option
                        v-for="(state, index) in usStates"
                        :key="index"
                        :value="state.name"
                      >
                        {{ state.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="zip1">ZIP CODE</label>
                    <input
                      id="zip1"
                      v-model="address.delivery_address.zip"
                      placeholder="12345"
                      type="text"
                      :class="deliveryZipMessage ? 'error' : ''"
                      name="zip"
                      @input="validZip(zipTypes.delivery)"
                    >
                    <p
                      v-if="deliveryZipMessage"
                      class="validation error mt-2"
                    >
                      {{ deliveryZipMessage }}
                    </p>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="country1">COUNTRY</label>
                    <input
                      id="country1"
                      v-model="address.delivery_address.country"
                      placeholder="United States"
                      readonly
                      value="United States"
                      type="text"
                      name="ext"
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col">
                  <label for="delivery">DELIVERY NOTES</label>
                  <textarea
                    id="delivery"
                    v-model="address.delivery_address.note"
                    class="col"
                    name="notes"
                    placeholder="Enter any special delivery instructions"
                    cols="30"
                    rows="5"
                  />
                </div>
              </div>
            </div><!-- /. Second Group -->
          </div>

          <div class="col-md-4">
            <Sidebar />
            <button
              type="submit"
              :disabled="sending"
              class="btn btn-primary"
            >
              Continue
            </button>
            <router-link
              class="btn btn-bordered mt-3"
              :to="{
                name: 'CreateAccountS2',
              }"
              tag="a"
            >
              Back
            </router-link>
          </div>
        </div>
      </ValidationObserver>
    </div><!-- /. row-create-account -->
    <ContactUs />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Sidebar from './partials/Sidebar';
import {
  ValidationProvider, 
  ValidationObserver, 
  extend
} from 'vee-validate';
import {
  required
} from 'vee-validate/dist/rules';
import {
  usStates,
  isZipValidForState,
  copyDefaultToDelivery
} from '@/helper';
import {
  ADD_ADDRESS,
  UPDATE_ADDRESS
} from '@/store/practice/actions.types';
import ContactUs from '@/views/components/ContactUs';
import googleAutocompleate from '@/mixins/googleAutocompleate';

// Install rules
extend('required', required);

// @vuese
// @group Create Account
export default {
  name: 'CreateAccountS3',

  components: {
    Sidebar,
    ValidationProvider,
    ValidationObserver,
    ContactUs,
  },

  mixins: [googleAutocompleate],

  data() {
    return {
      sending: false,
      zipTypes: {
        default: 'default',
        delivery: 'delivery'
      },
      deliverySameAsDefault: false,
      usStates,
      defaultZipMessage: '',
      deliveryZipMessage: '',
    };
  },

  computed: {
    ...mapGetters([
      'practice',
      'address',
    ])
  },

  watch: {
    deliverySameAsDefault() {
      if (!this.deliverySameAsDefault)
        this.initAutocomplete('street', this.shippingComponent, 'address.delivery_address');
    }
  },

  beforeMount() {
    this.$nextTick(() => {
      this.initAutocomplete('address', this.shippingComponent, 'address.default_address');
      this.initAutocomplete('street', this.shippingComponent, 'address.delivery_address');
    });
  },

  methods: {
    // @vuese
    // Used to store practice addreses
    // @arg Save for later
    async addPracticeAdress(saveForLater) {
      if (this.deliverySameAsDefault) {
        this.address.delivery_address = copyDefaultToDelivery(this.address.default_address, this.address.delivery_address);
      }
      const defaultZip = this.validZip(this.zipTypes.default);
      const deliveryZip = this.validZip(this.zipTypes.delivery);
      const valid = await this.isValid();

      if (valid && defaultZip && deliveryZip) {
        this.sending = true;
        const default_address = this.address.default_address;
        let delivery_address = this.address.delivery_address;

        let defaultAddressPromise = null,
          deliveryAddressPromise = null;

        if (default_address.action && default_address.action == 'create') {
          defaultAddressPromise = this.$store.dispatch(ADD_ADDRESS, default_address);
        } else {
          defaultAddressPromise = this.$store.dispatch(UPDATE_ADDRESS, default_address);
        }

        if (delivery_address.action && delivery_address.action == 'create') {
          deliveryAddressPromise = this.$store.dispatch(ADD_ADDRESS, delivery_address);
        } else {
          deliveryAddressPromise = this.$store.dispatch(UPDATE_ADDRESS, delivery_address);
        }

        Promise.all([defaultAddressPromise, deliveryAddressPromise])
          .then(() => {
            if(saveForLater)
              this.flashSuccess('Practice address has been saved');
            this.$router.push({name: 'CreateAccountS4'});
          })
          .catch(() => {
            this.flashError('Error updating practice address');
          })
          .finally(() => {
            this.sending = false;
          });
      }
    },

    // @vuese
    // Used to check if form valid
    isValid() {
      return this.$refs.form.validate();
    },

    // @vuese
    // Check if zip code
    // @arg Zip type
    validZip(type) {
      const { default_address, delivery_address } = this.address;
      if (type == this.zipTypes.default) {
        const { valid, message } = this.validateZip(default_address.zip, default_address.state);
        this.defaultZipMessage = message;
        return valid;
      } else if (type == this.zipTypes.delivery) {
        const { valid, message } = this.validateZip(delivery_address.zip, delivery_address.state);
        this.deliveryZipMessage = message;
        return valid;
      }
    },

    // @vuese
    // Used to check if zip code valid for state
    // @arg Zip code
    // @arg / State code
    validateZip(zipCode, stateCode) {
      let valid = false,
        message = '';
      if (!stateCode) {
        message = 'Please select state first';
        valid = false;
      } else if (!isZipValidForState(zipCode, stateCode)) {
        message = `Please enter valid zip for ${this.findState(stateCode).name}`;
        valid = false;
      } else {
        valid = true;
      }
      return { valid, message };
    },

    // @vuese
    // Used to find state object
    // @arg State code
    findState(stateCode) {
      return this.usStates.find(state => state.name == stateCode);
    }
  }
};
</script>