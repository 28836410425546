<template>
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      @click="$emit('hide-modal-window')"
    >
      <i
        class="fa fa-times"
        aria-hidden="true"
      />
    </button>
    <div
      class="schedule-order"
    >
      <h3
        class="modal-title text-center"
      >
        Schedule Order
      </h3>

      <div>
        <ul>
          <li class="input-radio">
            <input
              id="place-today"
              type="radio"
              name="schedule-order"
              checked
              @change="changePlaceType(placeTypes.today)"
            >
            <label
              for="place-today"
            >
              <span>Place Order Today</span>
            </label>
          </li>
          <li 
            class="input-radio"
          >
            <input
              id="place-later"
              type="radio"
              name="schedule-order"
              @change="changePlaceType(placeTypes.schedule)"
            >
            <label
              for="place-later"
            >
              <span>Schedule for Later</span>
            </label>
          </li>
        </ul>
      </div>
      
      <transition 
        name="slide"
      >
        <ValidationObserver
          v-if="placeType == placeTypes.schedule"
          ref="form"
          class="schedule-wrap"
          tag="form"
        >
          <div
            class="form-group"
          >
            <label
              for="due"
            >Due:</label>
            <select
              v-model="due"
              class="select-lg col"
              name="due"
            >
              <option
                v-for="(item, idx) in dueOptions"
                :key="idx"
                :value="item"
              >
                In {{ item }} Days
              </option>
            </select>
          </div>
          <div class="form-group">
            <date-picker
              v-model="product.next_charge_scheduled_at"
              format="YYYY-MM-DD"
              :open="true"
              :inline="true"
              :disabled-date="disabledDate"
              @input="handlePickerInput"
            />
          </div>
          <div class="form-group">
            <label
              for="due"
            >Subscription Frequency:</label>
            <select
              v-model="product.frequency"
              class="select-lg col"
              name="due"
            >
              <option value="One time">
                One Time
              </option>
              <option 
                v-for="(frequency, valIdx) in product.frequencyOptions"
                :key="valIdx"
                :value="frequency.value"
              >
                {{ frequency.text }}{{ valIdx == 0 ? ' (Recommended)' : '' }}
              </option>
            </select>
          </div>
          <div
            class="mb-4"
          >
            Next Charge Date is {{ nextChargeDateFormated }}.
          </div>
        </ValidationObserver>
      </transition>

      <a
        class="btn btn-primary"
        data-dismiss="modal"
        aria-label="Close"
        @click="continueWithOrder"
      >
        Continue with order
      </a>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate';
import moment from 'moment';

// @group Partials
export default {
  name: 'ScheduleOrder',

  components: {
    ValidationProvider,
    ValidationObserver,
    DatePicker
  },

  props: {
    // @vuese
    //  Product object
    product: {
      type: Object,
      default() {
        return {
          nextChargeDate: null
        };
      }
    }
  },

  data() {
    return {
      placeType: 'today',
      placeTypes: {
        today: 'today',
        schedule: 'schedule'
      },
      today: new Date(),
      due: 15,
      dueOptions: [15,30,45,60,75,90],
      nextChargeDateFormated: null,
    };
  },

  watch: {
    product: {
      immediate: true,
      handler() {
        this.today.setDate(this.today.getDate() + this.due);
        if (!this.product.next_charge_scheduled_at)
          this.product.next_charge_scheduled_at = this.today;
        this.today = new Date();
        this.updateNextChageDateFormated();
      }
    },
    due: {
      handler() {
        this.today.setDate(this.today.getDate() + this.due);
        this.product.next_charge_scheduled_at = this.today;
        this.today = new Date();
        this.updateNextChageDateFormated();
      }
    }
  },

  methods: {
    // @vuese
    // Used to choose which box should be shown
    // @arg Box type
    changePlaceType(type) {
      this.placeType = type;
    },
    // @vuese
    // Used to add product to order
    continueWithOrder() {
      if (this.placeType == this.placeTypes.today)
        delete this.product.next_charge_scheduled_at;

      this.$emit('add-to-order', {...this.product});
    },
    // @vuese
    // Used to change next chanrge date format
    updateNextChageDateFormated() {
      this.nextChargeDateFormated = moment(this.product.next_charge_scheduled_at).format('MMMM Do YYYY');
    },
    // @vuese
    // Used to process date pick
    handlePickerInput() {
      this.$forceUpdate();
      this.updateNextChageDateFormated();
    },
    // @vuese
    // Used to chek if date is disabled
    // @arg Date
    disabledDate(date) {
      const today = new Date();
      today.setHours(0,0,0,0);
      return date.getTime() < today.getTime() ? true : false;
    }
  }
};
</script>

<style lang="scss">
  .schedule-order {
    ul {
      list-style: none;
      padding: 0;
      li {
        cursor: pointer;
        margin-bottom: 1rem;
      }
    }
    .mx-calendar {
      width: 100%;
      .mx-btn,
      th {
        color: #442a70;
      }
      .mx-calendar-content {
        .cell {
          background: none;
          color: #442a70;
          &.disabled {
            background-color: #f3f3f3;
          }
          &.active {
            div {
              background-color: #442a70;
              color: #fff;
              height: 32px;
              width: 32px;
              margin: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100%;
            }
          }
        }
      }
    }
  }
</style>