<template>
  <div id="app">
    <router-view />
    <Footer />
  </div>
</template>

<script>
import { ADD_STRIPE_PUBLIC_KEY } from '@/store/app/actions.type';
import Footer from './views/components/Footer';
export default {
  name: 'App',
  components: {
    Footer,
  },
  props: {
    stripePublicKey: {
      type: String,
      default: ''
    }
  },
  created() {
    this.$store.dispatch(ADD_STRIPE_PUBLIC_KEY, this.stripePublicKey);
  }
};
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
#app {
  margin-bottom: 10rem;
}
</style>
