<template>
  <div :class="`${extraClass} notification message ${type}`">
    <p class="icon">
      <slot />
    </p>
  </div>
</template>

<script>
// @group Components
export default {
  name: 'InlineNotification',

  props: {
    // @vuese
    // Message type
    type: {
      type: String,
      default: '',
    },
    // @vuese
    // Additioanl class
    extraClass: {
      type: String,
      default: ''
    }
  }
};
</script>