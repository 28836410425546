<template>
  <div class="bg-login">
    <div class="container">
      <Notifications />
      <div class="box-login">
        <ValidationObserver
          ref="observer"
          v-slot="{ invalid }"
          class="ogin-form validate-form"
          tag="form"
          @submit.prevent="login"
        >
          <div class="logo-img">
            <img
              :src="logo"
              alt="Logo"
            >
          </div>
          <h2 class="text-center mb-5">
            Log In
          </h2>
          <validation-provider
            v-slot="{ errors }"
            name="Email"
            rules="email|required"
          >
            <div class="box-input validate-input">
              <label>Email address</label>
              <input
                v-model="email"
                placeholder="Email"
                type="text"
                name="email"
                :class="errors.length || invalidLogin.email == true ? 'error' : ''"
                @input="invalidLogin.email = false"
              >
              <p
                v-if="invalidLogin.email == true"
                class="validation error mt-2"
              >
                Please enter a valid email address
              </p>
              <p class="validation error mt-2">
                {{ errors[0] }}
              </p>
            </div>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Password"
            rules="required"
          >
            <div
              class="box-input validate-input mb-0"
              data-validate="Incorrect password. Please try again."
            >
              <label>Password</label>
              <i
                :class="pass_visible ? 'show-pass fas fa-eye' : 'show-pass fas fa-eye-slash'"
                @click="pass_visible = !pass_visible"
              />
              <input
                v-model="password"
                placeholder="Password"
                :type="pass_visible ? 'text' : 'password'"
                name="pass"
                :class="errors.length || invalidLogin.pass == true ? 'error' : ''"
                @input="invalidLogin.pass = false"
              >
              <p
                v-if="invalidLogin.pass == true"
                class="validation error mt-2"
              >
                Incorrect password. Please try again.
              </p>
              <p class="validation error mt-2">
                {{ errors[0] }}
              </p>
            </div>
          </validation-provider>
          <div class="text-right pb-sm-5 pt-2">
            <router-link
              :to="{
                name: 'ForgotPassword'
              }"
              tag="a"
              class="underline"
            >
              Forgot Password
            </router-link>
          </div>
          <Preload
            v-if="loading"
            style="margin-left: 35%"
          />
          <button
            class="btn btn-primary"
            type="submit"
          >
            Log In
          </button>
          <router-link
            class="link-logging"
            :to="{
              name: 'LoginFirstTime'
            }"
            tag="a"
          >
            First Time Logging In?
          </router-link>
        </ValidationObserver>
      </div>
    </div>
    <ContactUs />

    <Modal
      :show-modal="showSessionModal"
      modal-id="before"
      @hide-modal-window="showSessionModal = false"
    >
      <img
        src
        alt="Warning!"
        class="warning-icon"
      >
      <h4 class="modal-title">
        Before you go ...
      </h4>
      <p>You have unsaved changes. Are you sure you want to continue?</p>
      <div class="mb-3">
        <a
          href="#"
          class="btn btn-primary w-100"
          @click.prevent="hideModal"
        >
          Continue Without Saving
        </a>
      </div>
      <div class>
        <a
          href="#"
          class="btn btn-bordered w-100"
          data-dismiss="modal"
          @click.prevent="showSessionModal = false"
        >Cancel</a>
      </div>
    </Modal>
    <div
      v-if="showSessionModal"
      class="modal-backdrop fade show"
    />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import logo from '@images/logo-primary.png';
import ContactUs from '../components/ContactUs';
import { getToken, destroyToken } from '@/services/jwt.service';
import Modal from '../dashboard/partials/Modal';
import ApiService from '@/services/api.service';
import { GET_USER_JWT } from '@/store/login/actions.types';
import { GET_PRACTICE } from '@/store/practice/actions.types';
import { LOGIN, LOGOUT } from '@/store/login/actions.types';
import { mapGetters } from 'vuex';
import { checkPracticeCompetion, isRole } from '@/helper';
import Preload from '../components/Preload';
import { PolicyService } from '../../services/api.service';

// Install rules
extend('required', required);

email['message'] = 'Please enter a valid email address';
extend('email', email);

// @group Login
export default {
  name: 'Login',
  components: {
    Preload,
    ContactUs,
    Modal,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      isSigning: false,
      loading: false,
      logo: logo,
      email: '',
      password: '',
      pass_visible: false,
      showSessionModal: false,
      invalidLogin: {
        email: false,
        pass: false
      }
    };
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  beforeMount() {
    if (document.afterLogOut == true) {
      this.flashSuccess('You\'ve been successfully logged out');
    }
    this.fetchUser();
  },
  methods: {
    // @vuse
    // Used to load user practice and check practice compleation
    checkPractice(user) {
      this.$store.dispatch(GET_PRACTICE)
        .then(resp => {
          const practice = {...resp.data.practice};
          checkPracticeCompetion(practice, user, this.$router);
        });
    },
    hideModal(){

    },
    // @vuese
    // Used to login user in
    async login() {
      if (this.loading) return;

      const { email, password } = this;
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.loading = true;
        try {
          const response = await this.$store.dispatch(LOGIN, {
            user: {
              email,
              password
            }
          });

          if (isRole(response.data.user, 'authorized_principal')) {
            await this.signedPolicy();
            if (!this.isSigning) {
              this.$router.push({ name: 'Affiliate' });
            } else {
              this.$router.push({ name: 'userdashboard' });
            }
          } else if (isRole(response.data.user, 'practice_admin')) {
            this.checkPractice(response.data.user);
          } else {
            this.$router.push({ name: 'userdashboard' });
          }
        } catch (reject) {
          if (reject.data.error == 'Invalid Email or password.') {
            this.invalidLogin.email = true;
            this.invalidLogin.pass = true;
          }
          if (
            reject.data.error ==
            'You have a pending invitation, accept it to finish creating your account.'
          ) {
            this.$router.push({ name: 'LoginFirstTime' });
          }
          if (
            reject.data.error ==
            'You have one more attempt before your account is locked.'
          ) {
            this.flashWarning('You have one more login attempt before your account is temporarily locked. If you forgot your password, you can reset it here');
          }
          if (reject.data.error == 'Your account is locked.') {
            this.flashError('For your security, we\'ve locked your account after too many failed login attempts. Please contact your practice administrator or RegimenMD');
          }
          if (reject.data.error == 'Signature has expired') {
            await this.$store.dispatch(LOGOUT);
            this.login();
          }
        } finally {
          this.loading = false;
        }
      }
    },
    async signedPolicy() {
      try {
        const { data } = await PolicyService.alreadySigned();
        this.isSigning = data.signed;
      } catch (error) {
        this.flashError('Error fetching policy:', error);
      }
    },
    // @vuese
    // Used to load user data if he is already logged
    fetchUser() {
      if (getToken()) {
        ApiService.setHeader();
        this.$store.dispatch(GET_USER_JWT)
          .then(() => {
            this.checkPractice(this.currentUser);
          })
          .catch(() => {
            destroyToken();
          });
      }
    }
  }
};
</script>