<template>
  <header class="main-header">
    <div class="container container-md-lg">
      <div class="header-wrap wrap-lg">
        <div class="header-logo">
          <router-link
            tag="a" 
            :to="{
              name: 'userdashboard'
            }"
          >
            <img 
              :src="logo"
              alt="logo"
            >
          </router-link>
        </div>
        <div class="header-right">
          <div class="header-right">
            <h4>{{ regimen.id ? 'Update' : 'Add New' }} Preset Regimen</h4>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import logo from '@images/logo-light.png';

// @group Preset Regimen
export default {
  name: 'RegimenHeader',
  props: {
    regimen: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      logo
    };
  }
};
</script>