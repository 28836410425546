export const GET_PRACTICE              = 'getPractice';
export const SUPER_ADMIN_GET_PRACTICE  = 'superAdminGetPractice';
export const SET_PRACTICE_CUSTOM       = 'setPracticeCustom';
export const UPDATE_PRACTICE_FORM      = 'updatePracticeForm';
export const UPDATE_PRACTICE           = 'updatePractice';
export const ADD_ADDRESS               = 'addAddress';
export const UPDATE_ADDRESS            = 'updateAddress';
export const ADD_PHYSICIAN             = 'addPhysician';
export const UPDATE_PHYSICIAN          = 'updatePhysicians';
export const ADD_STAFF                 = 'addStaff';
export const UPDATE_STAFF              = 'updateStaff';
export const REMOVE_STAFF              = 'removeStaff';
export const REASSIGN_REMOVE_PHYSICIAN = 'reassignRemovePhysician';
export const REASSIGN_ADMIN            = 'reassignAdmin' ;
export const UPDATE_ADMIN_INFO         = 'updateAdminInfo';
export const REMOVE_PHYSICIAN          = 'removePhysician';
export const SUPER_ADMIN_PRACTICE      = 'superAdminPractice';