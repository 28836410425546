<template>
  <div v-if="product">
    <div 
      v-if="sending"
      class="preloader"
    >
      <i />
    </div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      @click="$emit('hide-modal-window')"
    >
      <i 
        class="fa fa-times" 
        aria-hidden="true" 
      />
    </button>
    <ValidationObserver 
      ref="observer" 
      v-slot="{ invalid }" 
      tag="form" 
      @submit.prevent="addProduct"
    >
      <div class="row">
        <div 
          class="col-md-3"
        >
          <div class="image-box">
            <img 
              v-if="productSelected.image"
              :src="productSelected.image" 
              alt="image"
            >
            <a 
              :href="`${shopifyURL}/products/${product.handle}`"
              target="_blank"
            >
              View on RegimenPro<sup>®</sup></a>
          </div>
        </div>
        <div 
          class="col-md-9"
        >
          <h2 class="modal-title">
            {{ product.title }}
          </h2>
          <p>
            by {{ product.vendor }}<br>
            <span v-html="product.shortDesc" />
          </p>
          <h3>${{ productSelected.price }}</h3>
          <div
            v-if="forPhysician"
          >
            <hr>
            <a
              href="#more_about"
              class="show-more collapsed"
              data-toggle="collapse"
              @click="readMore=!readMore"
            >
              Read more about this product
            </a>
            <br>
            <transition name="slide-fade">
              <div 
                v-if="readMore"
                id="more_about"
              >
                <p>{{ forPhysician }}</p>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <hr>
      <div class="add-action-box">
        <button
          type="submit"
          class="btn btn-primary"
          @click="addProduct"
        >
          {{ modalAction == 'create' ? 'Add Product To Top Picks' : 'Save' }}
        </button>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import ShopifyService from '@/services/shopify';
import { shopifyURL } from '@/services/config';
import {
  ValidationObserver, 
  extend 
} from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { ProductService } from '@/services/api.service';
import {
  formatMetaModel,
  findMeta,
  getShopifyId,
  formatTopPick,
  formatStorefrontMoney
} from '@/helper';

// Install rules
extend('required', required);

// @vuese
// @group Top Picks
export default {
  name: 'TopPickProductModal',
  components: {
    ValidationObserver, 
  },
  props: ['productId', 'selectedProduct', 'modalAction'],
  data() {
    return {
      sending: false,
      productSelected: {},
      variantError: '',
      product: {},
      forPhysician: '',
      shopifyURL: `https://${shopifyURL}`,
      readMore: false,
    };
  },
  mounted() {
    this.getProduct();
  },
  methods: {
    // @vuese
    // Used to call validation, and emit to parent product to add it to top pick
    async addProduct () {
      if (await this.isValid()) {
        // @vuese
        // Tell parent component that product selected
        // @arg selected product
        this.$emit('product-selected', this.productSelected);
      }
    },
    // @vuese
    // Used to check if data is valid
    async isValid() {
      if (!this.productSelected.variant_id) {
        this.variantError = 'Please select size';
      }
      return await this.$refs.observer.validate() && this.productSelected.variant_id;
    },
    // @vuese
    // Used to load extra data from shopify
    getProduct() {
      if (!this.productId) return;
      this.sending = true;

      // get product
      ShopifyService.fetch(this.productId)
        .then(product => {
          product.shortDesc = product.descriptionHtml.split('<!--short description-->')[0];
          this.product = product;
          this.productSelected = formatTopPick(product);

          this.getMetaByHandle(product.handle);
        });
    },
    // @vuese
    // Used to load meta from shopify
    // @arg Product handle `String`
    getMetaByHandle(handle) {
      ProductService.getProductMetaByHandle(handle)
        .then(resp => {
          if (resp.data.data.productByHandle) {
            this.product.meta = formatMetaModel(resp.data.data.productByHandle.metafields);
            this.product.variantsMeta = formatMetaModel(resp.data.data.productByHandle.variants.edges);

            const forPhysician = findMeta(this.product.meta, 'detail', 'for_physician');
            if (forPhysician) this.forPhysician = forPhysician.value;
          }

          // select variant if we have only one
          this.fillVariantData(this.product.variants[0].id);
        })
        .finally(() => {
          this.sending = false;
        });
    },
    // @vuese
    // Used to fill variant data 
    // @arg Variant ID `Number`s
    fillVariantData(variantId) {
      const variant = this.product.variants.find(variantObj => {
        return variantObj.id == variantId;
      });
      this.productSelected.variant_id       = getShopifyId(variant.id);
      this.productSelected.variant_graph_id = variant.id;
      this.productSelected.price            = formatStorefrontMoney(variant.price.amount);
    }
  }
};
</script>