<template>
  <div class="container container-md-sm">
    <div class="steps-wrap">
      <div class="step passed">
        <p>Set Password</p>
        <span class="step-marker" />
      </div>
      
      <div class="step passed">
        <p>Add Practice Info</p>
        <span class="step-marker" />
      </div>
      
      <div class="step passed">
        <p>Add Practice Addresses</p>
        <span class="step-marker" />
      </div>
        
      <div class="step passed">
        <p>Add Skincare Pros</p>
        <span class="step-marker" />
      </div>
      
      <div class="step passed">
        <p>Add Practice Staff</p>
        <span class="step-marker" />
      </div>
      
      <div class="step current">
        <p>Review</p>
        <span class="step-marker" />
      </div>
    </div>
    <!-- /. steps-wrap -->

    <div class="container">
      <Notifications />
    </div>

    <div class="steps-content">
      <div class="top-title">
        <h3>Review</h3>
        <!-- <a 
          class="right-link" 
          href="#"
          @click="finishRegistration">Save for Later</a> -->
      </div>
      <form 
        class="row row-create-account"
        @submit.prevent="finishRegistration"
      >
        <div class="col-md-8">
          <div class="f-box">
            <div class="review-header">
              <h4>Practice Information</h4>
              <router-link 
                :to="{
                  name: 'CreateAccountS2',
                }" 
                class="btn btn-bordered w-auto"
              >
                Edit
              </router-link>
            </div>
            <div class="review-body">
              <div 
                v-if="practice.email" 
                class="item"
              >
                <p>Practice email address</p>
                <p>{{ practice.email }}</p>
              </div>
              <div 
                v-if="practice.url"
                class="item"
              >
                <p>practice website link</p>
                <p>{{ practice.url }}</p>
              </div>
              <div 
                v-if="practice.phones[0]"
                class="item"
              >
                <p>practice number</p>
                <p>{{ practice.phones[0].number }} &nbsp;&nbsp; <span v-if="practice.phones[0].ext">ext. {{ practice.phones[0].ext }}</span></p>
              </div>
              <div 
                v-if="practice.logo && practice.logo.url"
                class="item"
              >
                <p>practice logo</p>
                <div class="box-image">
                  <img 
                    :src="practice.logo.url" 
                    alt="Practice Logo" 
                  >
                </div>
              </div>
              <div 
                v-for="(social, index) in practice.social_media"
                v-if="social.url"
                :key="index"
                class="item"
              >
                <p>{{ social.type }} link</p>
                <p>{{ social.url }}</p>
              </div>
            </div>
          </div>
          <!-- /. Group 1 -->
          <div class="f-box">
            <div class="review-header">
              <h4>Practice Address</h4>
              <router-link 
                :to="{
                  name: 'CreateAccountS3',
                }" 
                class="btn btn-bordered w-auto"
              >
                Edit
              </router-link>
            </div>
            <div class="review-body">
              <div class="item">
                <p>practice address</p>
                <p>
                  {{ address.default_address.address1 }}
                  <span v-if="address.default_address.address2"><br>{{ address.default_address.address2 }}</span>
                  <br>
                  {{ address.default_address.city }}, {{ address.default_address.state }} {{ address.default_address.zip }}
                </p>
              </div>
              <div class="item">
                <p>Receiving address</p>
                <p>
                  {{ address.delivery_address.address1 }}
                  <span v-if="address.delivery_address.address2"><br>{{ address.delivery_address.address2 }}</span>
                  <br>{{ address.delivery_address.city }}, {{ address.delivery_address.state }} {{ address.delivery_address.zip }}
                </p>
                <p class="mt-4">
                  Notes: {{ address.delivery_address.note }}
                </p>
              </div>
            </div>
          </div>
          <!-- /. Group 2 -->
          <div class="f-box">
            <div class="review-header">
              <h4>Physicians</h4>
              <router-link 
                :to="{
                  name: 'CreateAccountS4',
                }" 
                class="btn btn-bordered w-auto"
              >
                Edit
              </router-link>
            </div>
            <div class="review-body">
              <div 
                v-for="(physician, index) in physicians" 
                :key="index"
                class="row"
              >
                <div 
                  v-if="index > 0" 
                  class="col-12"
                >
                  <hr class="mt-0 mb-3">
                </div>
                <div 
                  v-if="physician.avatar.url"
                  class="col-md-2 pr-2"
                >
                  <img 
                    class="rounded-circle" 
                    :src="physician.avatar.url" 
                    :alt="physician.name"
                  >
                </div>
                <div :class="physician.avatar.url ? 'col-md-10' : 'col-md-12'">
                  <div class="item mb-3">
                    <p>Name</p>
                    <p>{{ physician.name }}</p>
                  </div>
                  <div class="item">
                    <p>EMAIL</p>
                    <p>{{ physician.email }}</p>
                  </div>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="item col-sm-6 pb-2">
                  <p>Practice app soldto</p>
                  <p>xjks99</p>
                </div>
                <div class="item col-sm-6 pb-2">
                  <p>practice app shipto</p>
                  <p>3YU5KJA</p>
                </div>
              </div>
            </div>
          </div>
          <!-- /. Group 3 -->
          <div 
            v-if="staff.length && staff[0].email"
            class="f-box"
          >
            <div class="review-header">
              <h4>Staff</h4>
              <router-link 
                :to="{
                  name: 'CreateAccountS5',
                }" 
                class="btn btn-bordered w-auto"
              >
                Edit
              </router-link>
            </div>
            <div class="review-body">
              <div>
                <div 
                  v-for="(staffObj, index) in staff" 
                  :key="index"
                  class="item"
                >
                  <p>Name</p>
                  <p>{{ staffObj.name }}</p>
                  <p>EMAIL</p>
                  <p>{{ staffObj.email }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- /. Group 4 -->
        </div>
        <div class="col-md-4">
          <Sidebar />

          <button 
            type="submit" 
            :disabled="sending" 
            class="btn btn-primary"
          >
            Create Practice
          </button>
          <router-link 
            class="btn btn-bordered mt-3" 
            :to="{
              name: 'CreateAccountS2',
            }" 
            tag="a"
          >
            Back
          </router-link>
        </div>
      </form>
    </div>
    <ContactUs />
  </div>
  <!-- /. row-create-account -->
</template>

<script>
import {
  mapGetters
} from 'vuex';
import Sidebar from './partials/Sidebar';
import {
  UPDATE_PRACTICE,
} from '@/store/practice/actions.types';
import ContactUs from '../components/ContactUs';

// @vuese
// @group Create Account
export default {
  name: 'CreateAccountS6',
  components: {
    Sidebar,
    ContactUs,
  },
  data() {
    return {
      sending: false,
    };
  },
  computed: {
    ...mapGetters(['practice', 'address', 'physicians', 'allegran', 'staff'])
  },
  methods: {
    // @vuese
    // Used to compleate practice registration
    finishRegistration() {
      this.sending = true;
      this.$store.dispatch(UPDATE_PRACTICE, {practice:{registration_completed: true}})
        .then (() => {
          this.$router.push({name: 'userdashboard'});
        })
        .catch(() => {
          this.flashError('Error updating practice physician');
        })
        .finally(() => {
          this.sending = false;
        });
    }
  }
};
</script>