<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="29"
    height="29"
    viewBox="0 0 29 29"
  >
    <image
      width="29"
      height="29"
      xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAdCAQAAAD8mq+EAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfjCQIKKCCQuWLCAAACVUlEQVQ4y53UTWgTQRQH8H/GJoYkTWOJMY1axESL4heFUAl4kapBSsCIevASqgcR8eBBrYhSRIIeBEFBkGAvHopECSoJRDyZitUiWlD6EaSUNsZYNWnz1bh5HkbJbnazEGcub97Ob/ftzsxqCNL2y/Jy74edafuSyfbNMbfnlXuUVaHcSNSnXceGWyqSFNnSt86V9AR5F4U3z2srUJpDzuTHHfK0hhdMrD80FOB1dE32PXNNtS6lbaM9UW/ODADGQvjwgZhiwQNBHmz5HPWK77xoujq4sgwCGfPj2xQKjnn5yBdZNMkLG/GszoBAm6bKujoqsO3jIJAnofw5CAkPf/Lts3U04gOBtJXkRmVIIFy+BgJ1pKpMQgMPQKCT9xtDQs5szINAI55ajgHx/QBw5BFUWmvOGwOAeG8tx4il7ADgfgfV5n4LALPrRVRgVQZof6/6oU7XfAWAtF1E+Q6ttBQN6pRvDUNBQttyADDtUqeTXQDgmBdRwD0KAE/71OnzgwDgSUg24p0zINC62aK+8eKE/SCQvrjQLlnXjNWcBYEGgo3gT4szCQL1h2R7+PolPhoKKMG8YV+cH4AvG2S0pO95w8cXbhQMUvhpa/cYD0/fVTzq847OGR6unRu8MtadNQssZY/4jj9cIfybq62E/Yp/iZR992vxTTWimJHlL37sV6CEkj54sS0rf9ld7xOeic0dqXpcN2+h/d4pb9Sa0RCICc7kidCLXn5FjhssR1n33Sowaa4eq5xReZfipqgUN0lr+Ohw05TjQ0+Wdf9BCTOdyzrCHxW1lyJMyp75AAAAAElFTkSuQmCC"
    />
  </svg>
</template>

<script>
// @group Components
export default {
  name: 'SearchIcon'
};
</script>