export const ADD_PATIENT                    = 'addPatient';
export const GET_PATIENTS                   = 'getPatients';
export const GET_PATIENT                    = 'getPatient';
export const UPDATE_PATIENT                 = 'updatePatient';
export const REASSIGN_PATIENT               = 'reassignPatient';
export const COMPLETE_REGISTRATION          = 'completeRegistration';
export const UPDATE_PATIENT_ADDRESS         = 'updatePatientAddress';
export const UPDATE_PATIENT_BILLING_ADDRESS = 'updatePatientBillingAddress';
export const ADD_PATIENT_BILLING_ADDRESS    = 'addPatientBillingAddress';
export const ADD_PATIENT_ADDRESS            = 'addPatientAddress';
export const REMOVE_PATIENTS                = 'removePatients';
export const UPDATE_PHYSICIAN               = 'updatePhysician';
export const ADD_PAYMENT_METHOD             = 'addPaymentMethod';
export const UPDATE_PAYMENT_METHOD          = 'updatePaymentMethod';
export const SEND_REGIMEN                   = 'sendRegimen';