var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrap"},[_c('h2',[_vm._v("Preset Regimens")]),_vm._v(" "),_c('Notifications'),_vm._v(" "),_c('div',{},[_c('PresetRegimenFilters',{on:{"filter-tags":_vm.filterTags,"filter-creators":_vm.filterCreators,"reset-all":_vm.resetAll}}),_vm._v(" "),(_vm.isRole('super_admin'))?_c('p',{staticClass:"mt-3"},[_vm._v("\n      All preset regimens created here will be shown to all practices.\n    ")]):_vm._e(),_vm._v(" "),_c('hr'),_vm._v(" "),_c('div',{staticClass:"tbl-toolbar"},[_c('div',{staticClass:"toolbar-r text-right"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.sending),expression:"sending"}],staticClass:"preloader"},[_c('i')]),_vm._v(" "),_c('router-link',{staticClass:"action-top",attrs:{"to":{
            name: 'RegimenInfo'
          }}},[_c('i',{staticClass:"cicle-icon fas fa-plus"}),_vm._v(" Create New Preset Regimen\n        ")])],1)]),_vm._v(" "),_c('div',{staticClass:"wrap-table border-table mb-2"},_vm._l((_vm.regimens.items),function(regimen){return _c('div',{key:regimen.id,staticClass:"row row-group"},[_c('div',{staticClass:"col-sm-9 pl-md-5"},[_c('h6',[_vm._v(_vm._s(regimen.name))]),_vm._v(" "),_c('p',{staticClass:"mb-2"},[_vm._v("\n            "+_vm._s(regimen.products.length)+" Products | Created by "+_vm._s(regimen.practice.name)+"\n          ")]),_vm._v(" "),_vm._l((regimen.tags),function(tag){return _c('span',{key:tag,staticClass:"badge badge-pill badge-primary mb-2 mr-2"},[_vm._v("\n            "+_vm._s(_vm.getTagName(tag).value))])}),_vm._v(" "),(regimen.description)?_c('p',[_vm._v("\n            "+_vm._s(regimen.description)+"\n          ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"grid-images"},_vm._l((regimen.products),function(product){return _c('div',{key:product.id,staticClass:"item-image"},[_c('img',{staticClass:"product",attrs:{"src":product.image}}),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(product.title)}}),_vm._v(" "),_c('span',[_vm._v("\n                "+_vm._s(product.vendor)+"\n                "),_c('br'),_vm._v("$"+_vm._s(product.price)+"\n              ")])])}),0)],2),_vm._v(" "),_c('div',{staticClass:"col-sm-3 action-box"},[(_vm.showForPractice(regimen))?_c('router-link',{staticClass:"link_action",attrs:{"tag":"a","to":{
              name: 'RegimenEdit',
              params: {
                id: regimen.id
              }
            }}},[_vm._v("\n            View\n          ")]):_vm._e()],1)])}),0),_vm._v(" "),_c('Pagination',{attrs:{"total-rows":_vm.regimens.total_items,"total-pages":_vm.regimens.total_pages,"current-page":_vm.currentPage,"hide-per-page":true,"per-page":20},on:{"change-page":_vm.changePage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }