<template>
  <div class="front-wrapper">
    <Header :patient="patient" />
    <div class="frontend-main">
      <div class="container container-md-xs">
        <Notifications />
        <div class="steps-wrap">
          <div class="step passed">
            <router-link
              class="hover-btn"
              :to="{
                name: 'PatientCreateRegimen',
                params: {
                  id: patient.id
                }
              }"
              tag="p"
            >
              Create Regimen
            </router-link>
            <span class="step-marker" />
          </div>

          <div class="step current">
            <p>Enter Client Information</p>
            <span class="step-marker" />
          </div>

          <div class="step">
            <p>Review</p>
            <span class="step-marker" />
          </div>
        </div>
      </div>
      <!-- /. steps-wrap -->

      <div class="container container-md-lg">
        <div class="row row-layout-370 align-items-baseline">
          <div class="col-md-7">
            <h3 class="mb-sm-3 mb-1">
              Enter Client Information (Optional)
            </h3>
          </div>
          <div class="col-md-5">
            <h5>
              Enter Client's Information for RegimenPro<sup>&reg;</sup> Account
            </h5>
          </div>
        </div>

        <ValidationObserver
          ref="observer"
          v-slot="{ invalid }"
          tag="form"
          @submit.prevent="addPatientInfo"
        >
          <div class="row row-layout-370">
            <div class="col-md-7">
              <div class="d-box with-lpad pb-4">
                <ValidationObserver
                  ref="infoAndShipping"
                  v-slot="{ invalid }"
                  tag="div"
                  class="row form form-gray"
                >
                  <div class="col-sm-4 form-group mb-0">
                    <label 
                      class="form-check-label" 
                      for="p_name"
                    >Client name</label>
                  </div>
                  <div class="col-sm-4 form-group">
                    <validation-provider 
                      v-slot="{ errors }" 
                      name="First name" 
                      rules="required"
                    >
                      <input
                        id="p_name"
                        v-model="patient.first_name"
                        type="text"
                        :class="errors.length ? 'error' : ''"
                      >
                      <p
                        v-for="(err, errKey) in errors"
                        :key="errKey"
                        class="validation error mt-2"
                      >
                        {{ err }}
                      </p>
                    </validation-provider>
                  </div>
                  <div class="col-sm-4 form-group">
                    <validation-provider 
                      v-slot="{ errors }" 
                      name="Last name" 
                      rules="required"
                    >
                      <input
                        id="p_lname"
                        v-model="patient.last_name"
                        type="text"
                        :class="errors.length ? 'error' : ''"
                      >
                      <p
                        v-for="(err, errKey) in errors"
                        :key="errKey"
                        class="validation error mt-2"
                      >
                        {{ err }}
                      </p>
                    </validation-provider>
                  </div>
                  <div class="col-md-12">
                    <hr>
                  </div>

                  <div 
                    v-if="patient.physician" 
                    class="col-sm-4 form-group mb-0"
                  >
                    <label 
                      class="form-check-label"
                      for="p_physician"
                    >Skincare Pro</label>
                  </div>
                  <div 
                    v-if="patient.physician" 
                    class="col-sm-8 form-group"
                  >
                    <validation-provider 
                      v-slot="{ errors }" 
                      name="Physician" 
                      rules="required"
                    >
                      <select-physican
                        v-model="patient.user_id"
                        title="All Skincare Pros"
                        :items="valuesForSelect(physicians)"
                        :total="physiciansTotal"
                        :loading="isPhysiciansLoading"
                        :name="'patinet-select'"
                        :filters="filters"
                        @load-more="loadMorePhysicians"
                      />
                      <p
                        v-for="(err, errKey) in errors"
                        :key="errKey"
                        class="validation error mt-2"
                      >
                        {{ err }}
                      </p>
                    </validation-provider>
                  </div>
                  <div class="col-md-12">
                    <hr>
                  </div>

                  <div class="col-sm-4 form-group mb-0">
                    <label 
                      class="form-check-label" 
                      for="p_email"
                    >Email address</label>
                  </div>
                  <div class="col-sm-8 form-group">
                    <validation-provider 
                      v-slot="{ errors }" 
                      name="Email" 
                      rules="email|required"
                    >
                      <input
                        id="p_email"
                        v-model="patient.email"
                        type="text"
                        placeholder="name@example.com"
                        :class="errors.length || emailErrors.length ? 'error' : ''"
                        required
                        @input="enterEmail"
                      >
                      <p
                        v-for="(error, errorKey) in emailErrors"
                        :key="errorKey"
                        class="validation error mt-2"
                      >
                        {{ error }}
                      </p>
                      <p
                        v-for="(err, errKey) in errors"
                        :key="errKey"
                        class="validation error mt-2"
                      >
                        {{ err }}
                      </p>
                    </validation-provider>
                  </div>
                  <div class="col-md-12">
                    <hr>
                  </div>
                  <div class="col-sm-4 form-group mb-0">
                    <label
                      class="form-check-label"
                      for="p_phone"
                    >Phone number</label>
                  </div>
                  <div class="col-sm-8 form-group">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Phone"
                      rules="required"
                    >
                      <input
                        id="p_phone"
                        v-model="patient.phone"
                        type="text"
                        placeholder="(555) 555 - 5555"
                        :class="errors.length || phoneErrors.length ? 'error' : ''"
                        @input="enterNumber(phoneTypes.patient)"
                        @change="changeNumber(phoneTypes.patient)"
                      >
                      <p
                        v-for="(phoneErr, phoneKey) in phoneErrors"
                        :key="phoneKey"
                        class="validation error mt-2"
                      >
                        {{ phoneErr }}
                      </p>
                      <p
                        v-for="err in errors"
                        :key="err"
                        class="validation error mt-2"
                      >
                        {{ err }}
                      </p>
                    </validation-provider>
                  </div>

                  <div class="col-md-12">
                    <hr>
                  </div>

                  <div class="col-sm-4 form-group mb-0">
                    <label
                      class="form-check-label"
                    >Shipping address (Optional)</label>
                  </div>
                  <div
                    v-if="patient.shipping_address"
                    class="col-sm-8"
                  >
                    <div class="row">
                      <div class="col-sm-6 form-group">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Shipping first name"
                          rules="required"
                          class="form-group"
                        >
                          <label for="sh_f_name">First Name</label>
                          <input
                            id="sh_f_name"
                            v-model="patient.shipping_address.first_name"
                            type="text"
                            :class="errors.length ? 'error' : ''"
                          >
                          <p
                            v-for="(err, errKey) in errors"
                            :key="errKey"
                            class="validation error mt-2"
                          >
                            {{ err }}
                          </p>
                        </validation-provider>
                      </div>
                      <div class="col-sm-6 form-group">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Shipping last name"
                          rules="required"
                          class="form-group"
                        >
                          <label for="sh_l_name">Last Name</label>
                          <input
                            id="sh_l_name"
                            v-model="patient.shipping_address.last_name"
                            type="text"
                            :class="errors.length ? 'error' : ''"
                          >
                          <p
                            v-for="(err, errKey) in errors"
                            :key="errKey"
                            class="validation error mt-2"
                          >
                            {{ err }}
                          </p>
                        </validation-provider>
                      </div>
                    </div>

                    <div class="form-group">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Shipping Street Address"
                        rules="required"
                        class="form-group"
                      >
                        <label for="sa_street_address">Street Address</label>
                        <input
                          id="sa_street_address"
                          v-model="patient.shipping_address.address1"
                          type="text"
                          :class="errors.length ? 'error' : ''"
                          placeholder="123 Main St"
                        >
                        <p
                          v-for="(err, errKey) in errors"
                          :key="errKey"
                          class="validation error mt-2"
                        >
                          {{ err }}
                        </p>
                      </validation-provider>
                    </div>
                    <div class="form-group">
                      <label for="sa_city">Unit or Suite Number (Optional)</label>
                      <input
                        id="sa_city"
                        v-model="patient.shipping_address.address2"
                        type="text"
                        placeholder="Suite 600"
                      >
                    </div>
                    <div class="row gutter-22">
                      <div class="col-md-6 form-group">
                        <label for="sa_city">City</label>
                        <validation-provider 
                          v-slot="{ errors }" 
                          name="Shipping City" 
                          rules="required"
                        >
                          <input
                            id="sa_city"
                            v-model="patient.shipping_address.city"
                            type="text"
                            :class="errors.length ? 'error' : ''"
                            placeholder="Chicago"
                          >
                          <p
                            v-for="(err, errKey) in errors"
                            :key="errKey"
                            class="validation error mt-2"
                          >
                            {{ err }}
                          </p>
                        </validation-provider>
                      </div>
                      <div class="col-md-6 form-group">
                        <label for="sa_state">State</label>
                        <validation-provider 
                          v-slot="{ errors }" 
                          name="Shipping State" 
                          rules="required"
                        >
                          <StateSelect
                            id="sa_state"
                            v-model="patient.shipping_address.state"
                            :name="'Shipping state'"
                            @change="validZip('shipping_address')"
                          />
                        </validation-provider>
                      </div>

                      <div class="col-md-6 form-group">
                        <validation-provider 
                          v-slot="{ errors }" 
                          name="Shipping Zip code" 
                          rules="required"
                          class="form-group"
                        >
                          <label for="sa_zip">Zip Code</label>
                          <input
                            id="sa_zip"
                            v-model="patient.shipping_address.zip"
                            type="text"
                            :class="errors.length ? 'error' : ''"
                            placeholder="12345"
                            @input="validZip('shipping_address')"
                          >
                          <p
                            v-for="(err, errKey) in errors"
                            :key="errKey"
                            class="validation error mt-2"
                          >
                            {{ err }}
                          </p>
                          <p
                            v-if="shippingZipMessage"
                            class="validation error mt-2"
                          >
                            {{ shippingZipMessage }}
                          </p>
                        </validation-provider>
                      </div>
                      <div class="col-md-6 form-group">
                        <label for="sa_country">Country</label>
                        <input
                          id="sa_country"
                          v-model="patient.shipping_address.country"
                          type="text"
                          placeholder="United States"
                          readonly
                        >
                      </div>

                      <div class="col-md-6 form-group">
                        <div class="head-tooltip form-group">
                          <label for="sa_phone">
                            Phone Number
                            <i
                              class="fas fa-question-circle"  
                              @mouseover="hover('sa_phone')" 
                              @mouseleave="hoverOff('sa_phone')" 
                            />
                          </label>
                          <div 
                            data-tooltip-type="sa_phone" 
                            class="tooltip-black"
                          >
                            Phone numbers are used by delivery drivers to reach out if they have delivery issues.
                          </div>
                        </div>
                        <input
                          id="sa_phone"
                          v-model="patient.shipping_address.phone"
                          type="tel"
                          maxlength="16"
                          placeholder="(555) 555 - 5555"
                          @input="enterNumber(phoneTypes.shipping)"
                          @change="changeNumber(phoneTypes.shipping)"
                        >
                        <p
                          v-for="(err, errKey) in shippingPhoneErrors"
                          :key="errKey"
                          class="validation error mt-2"
                        >
                          {{ err }}
                        </p>
                      </div>
                      <div class="col-md-12">
                        <hr>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                </ValidationObserver>
                <!-- Payment method -->
                <div class="row form form-gray">
                  <div class="col-sm-4 form-group mb-0">
                    <label 
                      class="form-check-label" 
                      for="card_number"
                    >Payment method (Optional)</label>
                  </div>

                  <div class="col-sm-8">
                    <div class="form-group">
                      <label for="card_number">Card Number</label>
                      <div id="card-element" />
                    </div>

                    <div class="row gutter-22">
                      <div class="col-lg-6 form-group">
                        <label for="card_exp">Expiration Date</label>
                        <div id="card-expiry-element" />
                      </div>
                      <div class="col-lg-6 form-group">
                        <label for="card_sc">Security Code</label>
                        <div id="card-cvc-element" />
                      </div>
                    </div>
                  </div>
                  <!-- /. col-sm-8 card fields -->

                  <!-- shipping -->
                  <div class="col-sm-4" />
                  <div class="col-sm-8">
                    <div class="form-group mt-2">
                      <div class="form-check-box">
                        <input
                          id="billing_same_shipping"
                          v-model="billingSameShipping"
                          type="checkbox"
                        >
                        <label for="billing_same_shipping">Billing same as shipping address</label>
                      </div>
                    </div>

                    <ValidationObserver
                      v-if="patient.billing_address"
                      ref="billing_address"
                      v-slot="{ invalid }"
                      tag="form"
                    >
                      <div class="row">
                        <div class="col-sm-6 form-group">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Billing first name"
                            rules="required"
                            class="form-group"
                          >
                            <label for="sh_f_name">First Name</label>
                            <input
                              id="sh_f_name"
                              v-model="patient.billing_address.first_name"
                              type="text"
                              :class="errors.length ? 'error' : ''"
                            >
                            <p
                              v-for="(err, errKey) in errors"
                              :key="errKey"
                              class="validation error mt-2"
                            >
                              {{ err }}
                            </p>
                          </validation-provider>
                        </div>
                        <div class="col-sm-6 form-group">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Billing last name"
                            rules="required"
                            class="form-group"
                          >
                            <label for="sh_l_name">Last Name</label>
                            <input
                              id="sh_l_name"
                              v-model="patient.billing_address.last_name"
                              :class="errors.length ? 'error' : ''"
                              type="text"
                            >
                            <p
                              v-for="(err, errKey) in errors"
                              :key="errKey"
                              class="validation error mt-2"
                            >
                              {{ err }}
                            </p>
                          </validation-provider>
                        </div>
                      </div>
                      <div class="form-group">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Billing Street Address"
                          rules="required"
                          class="form-group"
                        >
                          <label for="street">Street address</label>
                          <input
                            id="street"
                            v-model="patient.billing_address.address1"
                            type="text"
                            placeholder="113 Main Stret"
                            name="street"
                            :class="errors.length ? 'error' : ''"
                          >
                          <p
                            v-for="(err, errKey) in errors"
                            :key="errKey"
                            class="validation error mt-2"
                          >
                            {{ err }}
                          </p>
                        </validation-provider>
                      </div>
                      <div class="form-group">
                        <label for="suite1">Unit or suite number (optional)</label>
                        <input
                          id="suite1"
                          v-model="patient.billing_address.address2"
                          placeholder="Suite 600"
                          type="text"
                          name="suite1"
                        >
                      </div>
                      <div class="row">
                        <div class="form-group col-sm-6">
                          <validation-provider 
                            v-slot="{ errors }" 
                            name="Billing City" 
                            rules="required"
                            class="form-group"
                          >
                            <label for="city">City</label>
                            <input
                              id="city"
                              v-model="patient.billing_address.city"
                              type="text"
                              placeholder="Chicago"
                              name="city"
                              :class="errors.length ? 'error' : ''"
                            >
                            <p
                              v-for="(err, errKey) in errors"
                              :key="errKey"
                              class="validation error mt-2"
                            >
                              {{ err }}
                            </p>
                          </validation-provider>
                        </div>
                        <div class="form-group col-sm-6">
                          <label for="state1">State</label>
                          <validation-provider 
                            v-slot="{ errors }" 
                            name="Billing State" 
                            rules="required"
                          >
                            <StateSelect
                              id="state1"
                              v-model="patient.billing_address.state"
                              :name="'Billing state'"
                              class="select-lg col"
                              @change="validZip('billing_address')"
                            />
                          </validation-provider>
                        </div>
                        <div class="form-group col-sm-6">
                          <validation-provider 
                            v-slot="{ errors }" 
                            name="Billing Zip code" 
                            rules="required"
                            class="form-group"
                          >
                            <label for="zip1">Zip code</label>
                            <input
                              id="zip1"
                              v-model="patient.billing_address.zip"
                              placeholder="12345"
                              type="text"
                              name="zip1"
                              :class="errors.length || billingZipMessage ? 'error' : ''"
                              @input="validZip('billing_address')"
                            >
                            <p
                              v-for="(err, errKey) in errors"
                              :key="errKey"
                              class="validation error mt-2"
                            >
                              {{ err }}
                            </p>
                            <p
                              v-if="billingZipMessage"
                              class="validation error mt-2"
                            >
                              {{ billingZipMessage }}
                            </p>
                          </validation-provider>
                        </div>
                        <div class="form-group col-sm-6">
                          <label for="country1">Country</label>
                          <input
                            id="country1"
                            placeholder="United States"
                            readonly
                            value="United States"
                            type="text"
                            name="ext"
                          >
                        </div>
                        <div class="form-group col-sm-6 mb-0">
                          <div class="head-tooltip form-group">                            
                            <label for="phonen">
                              Phone Number
                              <i 
                                class="fas fa-question-circle"
                                @mouseover="hover('b_phone')" 
                                @mouseleave="hoverOff('b_phone')"
                              />
                            </label>
                            <div 
                              data-tooltip-type="b_phone" 
                              class="tooltip-black"
                            >
                              Phone numbers are used by delivery drivers to reach out if they have delivery issues.
                            </div>
                          </div>
                          
                          <input
                            id="phonen"
                            v-model="patient.billing_address.phone"
                            placeholder="(555) 555 - 5555"
                            type="tel"
                            name="phonen"
                            @input="enterNumber(phoneTypes.billing)"
                            @change="changeNumber(phoneTypes.billing)"
                          >
                          <p
                            v-for="(err, errKey) in billingPhoneErrors"
                            :key="errKey"
                            class="validation error mt-2"
                          >
                            {{ err }}
                          </p>
                        </div>
                      </div>
                    </ValidationObserver>
                  </div>
                  <!-- /. shipping -->

                  <!-- /. Payment method -->
                </div>
                <!-- /. row.form -->
              </div>
            </div>

            <!-- side column -->
            <div class="col-md-5 aside-sticky-5">
              <div class="d-box with-lpad">
                <p>
                  Your practice can store a shipping address, payment method, 
                  and phone number to easily place orders on behalf of the patient. 
                  The shipping address, payment method, 
                  and phone number stored here will not show up on the client’s RegimenPro® account.
                </p>
                <button 
                  id="next_step" 
                  :disabled="sending" 
                  class="btn btn-primary"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </ValidationObserver>
        <!-- /. row-layout-370 -->
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .patientcreateinfo .content-down {
    width: 100%;
    margin-right: 0;
    .title {
      width: 100%;
    }
    .multi-select {
      width: 100%;
    }
    input[type="text"] {
      width: 100% !important;
    }
  }
</style>

<script>
import {
  AsYouType
} from 'libphonenumber-js';
import Header from './Header';
import {
  UPDATE_PATIENT,
  UPDATE_PATIENT_ADDRESS,
  ADD_PATIENT_ADDRESS,
  UPDATE_PATIENT_BILLING_ADDRESS,
  ADD_PATIENT_BILLING_ADDRESS,
} from '@/store/patients/actions.types';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import {
  required,
  email
} from 'vee-validate/dist/rules';
import {
  validPhone
} from '@/helper';
// Install rules
extend('required', required);
email['message'] = 'Please enter a valid email address';
extend('email', email);

import patientStateValidation from '@/mixins/patientStateValidation';
import StateSelect from '@/views/components/StateSelect';
import SelectPhysican from '@/views/components/Select';
import physician from '@/mixins/physician';
import stripe from '@/mixins/stripe';
import googleAutocompleate from '@/mixins/googleAutocompleate';

// @group Patinet
// Component to add patient info
export default {
  name: 'PatientCreateInfo',

  components: {
    Header,
    ValidationProvider,
    ValidationObserver,
    StateSelect,
    SelectPhysican,
  },

  mixins: [patientStateValidation, physician, stripe, googleAutocompleate],

  props: {
    // @vuese
    // Patient object
    patient: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data() {
    return {
      sending: false,
      billingSameShipping: false,
      shippingPhoneErrors: [],
      billingPhoneErrors: [],
      phoneErrors: [],
      emailErrors: [],
      patientId: null,
      filters: {},
      phoneTypes: {
        patient: 'patient',
        shipping: 'shipping',
        billing: 'billing',
      },
    };
  },

  watch: {
    patient: {
      handler: 'fillData',
      immediate: true
    },
    billingSameShipping() {
      if (this.billingSameShipping)
        this.patient.billing_address = {...this.patient.billing_address, ...this.patient.shipping_address};
    },
  },

  beforeMount() {
    window.scrollTo(0,0);
    this.shippingComponent.forEach(el => {
      if (el.element == 'state')
        el.type.administrative_area_level_1 = 'long_name';
    });
  },
  methods: {
    // @vuese
    // Used to check if phone valid for US
    // @arg Phone Number
    validPhone,

    // @vuese
    // Used to store patient info, create payment method if needed
    // update or create billing, shipping address
    async addPatientInfo() {
      this.patientId = this.$route.params.id;
      if (await this.isValid()) {
        this.sending = true;
        const promises = [];
        if (this.shouldCreateToken) {
          // await this.createToken();
          await this.createPaymentMethod();
          if (this.cardError) {
            this.sending = false;
            return;
          }
          promises.push(this.saveToken(this.patientId));
        }
        if (this.shouldAddShipping()) promises.push(this.handleShippingAddress());
        promises.push(this.handlePatientInfo());
        if (this.shouldAddBilling()) promises.push(this.handleBillingAddress());

        Promise.all(promises)
          .then(responses => {
            let error = false;
            responses.forEach(resp => {
              if (resp.data.errors) error = true;
            });
            if (!error) {
              this.$router.push(
                {
                  name: 'PatientCreateReview',
                  params: {
                    id: this.patientId
                  }
                }
              );
            } else {
              this.flashWarning('Opps something went wrong... Please fix it, and try again');
            }
          })
          .finally(() => {
            this.sending = false;
          });
      }
    },

    // @vuese
    // Used to update patient info
    handlePatientInfo() {
      return this.$store
        .dispatch(UPDATE_PATIENT, {
          id: this.patientId,
          patient: this.patient,
          practiceId: this.getRequestPracticeId()
        })
        .then(resp => {
          this.patient.physician = resp.data.patient.physician;
          return resp;
        })
        .catch(err => {
          if (err.data.errors.email) this.emailErrors = err.data.errors.email;
          if (err.data.errors.phone) this.phoneErrors = err.data.errors.phone;

          return err;
        });
    },

    // @vuese
    // Used to update or create shipping address
    handleShippingAddress() {
      let handler = UPDATE_PATIENT_ADDRESS;
      if (
        this.patient.shipping_address.type &&
        this.patient.shipping_address.type == 'default'
      ) {
        handler = ADD_PATIENT_ADDRESS;
      }
      return this.$store
        .dispatch(handler, {
          id: this.patientId,
          shipping_address: this.patient.shipping_address,
          practiceId: this.getRequestPracticeId()
        })
        .then(resp => {
          this.patient.shipping_address = resp.data.shipping_address;
          return resp;
        })
        .catch(err => {
          if (err.data.errors.phone)
            this.shippingPhoneErrors = err.data.errors.phone;
          return err;
        });
    },

    // @vuese
    // Used to check if form valid
    async test_valid(){
      return await this.$refs.observer.validate();
    },

    // @vuese
    // Used to update or create shipping address
    handleBillingAddress() {
      let handler = UPDATE_PATIENT_BILLING_ADDRESS;
      if (
        this.patient.billing_address.type &&
        this.patient.billing_address.type == 'default'
      ) {
        handler = ADD_PATIENT_BILLING_ADDRESS;
      }
      return this.$store
        .dispatch(handler, {
          id: this.patientId,
          billing_address: this.patient.billing_address,
          practiceId: this.getRequestPracticeId()
        })
        .then(resp => {
          this.patient.billing_address = resp.data.billing_address;
          return resp;
        })
        .catch(err => {
          if (err.data.errors.phone)
            this.billingPhoneErrors = err.data.errors.phone;
          return err;
        });
    },

    // @vuese
    // used to check all forms valid
    async isValid() {
      let valid = false;
      if (this.shouldAddBilling()) {
        valid =
          (await this.$refs.observer.validate()) &&
          this.phoneErrors.length == 0 &&
          this.shippingPhoneErrors.length == 0 &&
          !this.shippingZipMessage &&
          this.billingPhoneErrors.length == 0 &&
          !this.billingZipMessage;
      } else {
        // Old
        if (this.shouldAddShipping()) {
          valid =
          (await this.$refs.infoAndShipping.validate()) &&
          this.phoneErrors.length == 0 &&
          this.shippingPhoneErrors.length == 0 &&
          !this.shippingZipMessage;
        } else {
          valid = await this.validateChildren();
        }
      }
      return valid;
    },

    // @vuese
    // Used to check is shipping fields valid 
    async validateChildren() {
      let valid = true;
      const ids = ['First name', 'Last name', 'Physician', 'Email', 'Phone'];
      for (const item of this.$refs.infoAndShipping.$children){
        if (ids.includes(item.id)) {
          await item.validate().then(
            res => {
              if(!res.valid) valid = false;
            }
          );
        }
      }
      return valid;
    },

    // @vuese
    // Used to check if we need to add shipping address
    shouldAddShipping() {
      let shouldAdd = false;
      const shipping_address = this.patient.shipping_address;
      if (
        // shipping_address.first_name ||
        // shipping_address.last_name ||
        shipping_address.address1 ||
        shipping_address.address2 ||
        shipping_address.city ||
        shipping_address.zip ||
        shipping_address.phone
      ) {
        shouldAdd = true;
      }
      return shouldAdd;
    },

    // @vuese
    // Used to check if we need to add billing address
    shouldAddBilling() {
      const billing_address = this.patient.billing_address;
      let shouldAdd = false;
      if (
        billing_address.first_name ||
        billing_address.last_name ||
        billing_address.address1 ||
        billing_address.address2 ||
        billing_address.city ||
        billing_address.zip ||
        billing_address.phone
      ) {
        shouldAdd = true;
      }
      return shouldAdd;
    },

    // @vuese
    // Used to clear emais errors
    enterEmail() {
      this.emailErrors = [];
    },

    // @vuese
    // Used to enter, valid and format numbwe
    // @arg Number type
    enterNumber(type) {
      let errorText = 'Please enter valid phone number', number;

      switch (type) {
      case this.phoneTypes.patient:
        number = this.patient.phone;
        if (!this.validPhone(number))
          this.phoneErrors = [errorText];
        else 
          this.phoneErrors = [];
        break;
      case this.phoneTypes.shipping:
        number = this.patient.shipping_address.phone;
        if (!this.validPhone(number))
          this.shippingPhoneErrors = [errorText];
        else 
          this.shippingPhoneErrors = [];
        break;
      case this.phoneTypes.billing:
        number = this.patient.billing_address.phone;
        if (!this.validPhone(number))
          this.billingPhoneErrors = [errorText];
        else 
          this.billingPhoneErrors = [];        
        break;
      }

      let count_numbers = 0, last_number = 0, max_numbers = 10;
      if(number[0] == 1)
        max_numbers += 1;

      for (let i = 0; i < number.length; i++) {
        if(/^\d+$/.test(number[i])){
          count_numbers +=1;
          if(count_numbers > max_numbers && last_number == 0)
            last_number = i;
        }
      }

      if(last_number != 0){
        number = number.slice(0,last_number);
      }

      if(event.inputType && event.inputType.indexOf('deleteContent') == -1){
        switch (type) {
        case this.phoneTypes.patient:
          this.patient.phone = new AsYouType('US').input(number);
          break;
        case this.phoneTypes.shipping:
          this.patient.shipping_address.phone = new AsYouType('US').input(number);
          break;
        case this.phoneTypes.billing:
          this.patient.billing_address.phone = new AsYouType('US').input(number);
          break;
        }
      }

    },

    // @vuese
    // Used to change, valid and format numbwe
    // @arg Number type
    changeNumber(type) {
      let errorText = 'Please enter valid phone number';
      switch (type) {
      case this.phoneTypes.patient:
        if (!this.validPhone(this.patient.phone))
          this.phoneErrors = [errorText];
        else 
          this.phoneErrors = [];
        this.patient.phone = new AsYouType('US').input(this.patient.phone);
        break;
      case this.phoneTypes.shipping:
        if (!this.validPhone(this.patient.shipping_address.phone))
          this.shippingPhoneErrors = [errorText];
        else 
          this.shippingPhoneErrors = [];
        this.patient.shipping_address.phone = new AsYouType('US').input(this.patient.shipping_address.phone);        
        break;
      case this.phoneTypes.billing:
        if (!this.validPhone(this.patient.billing_address.phone))
          this.billingPhoneErrors = [errorText];
        else 
          this.billingPhoneErrors = [];
        this.patient.billing_address.phone = new AsYouType('US').input(this.patient.billing_address.phone); 
        break;
      }
    },

    // @vuese
    // Used to fill data, load physicans, init autocompleate functions
    fillData() {
      this.patientId = this.$route.params.id;
      if (this.isRole('super_admin') && this.patient.practice) {
        this.filters['practice_id'] = this.patient.practice.id;
      }
      this.loadMorePhysicians({
        type: 'search',
        filters: this.filters,
      });

      if (this.patient.shipping_address) {
        setTimeout(() => {
          this.initAutocomplete('sa_street_address', this.shippingComponent, 'patient.shipping_address');
        });
      }
      if (this.patient.billing_address) {
        setTimeout(() => {
          this.initAutocomplete('street', this.shippingComponent, 'patient.billing_address');
        });
      }
    },

    // @vuese
    // Used to show tooltip
    // @arg HTML element data type
    hover(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.add('open');
    },

    // @vuese
    // Used to hide tooltip
    // @arg HTML element data type
    hoverOff(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.remove('open');
    },

    // @vuese
    // Used to get practice ID for super admin user
    getRequestPracticeId() {
      return this.isRole('super_admin') ? `/${this.patient.practice.id}` : '';
    },
  }
};
</script>