<template>
  <div
    v-if="currentUser"
    class="f-box right-info"
  >
    <h6 v-if="practice">
      Practice Name
    </h6>
    <p v-if="practice">
      {{ practice.name }}
    </p>
    <h6>Admin Name</h6>
    <p>{{ `${currentUser.first_name} ${currentUser.last_name}` }}</p>
    <h6>Admin Email</h6>
    <p class="email">{{ currentUser.email }}</p>
  </div>
</template>

<style scoped lang="scss">
  .right-info {
    .email {
      word-break: break-all;
    }
  }
</style>

<script>
import { mapGetters } from 'vuex';

// @vuese
// @group Create Account
export default {
  name: 'Sidebar',
  computed: {
    ...mapGetters([
      'currentUser',
      'practice',
    ])
  }
};
</script>