<template>
  <div>
    <div class="d-flex justify-content-end mb-1 mt-md-4 pb-2">
      <div class="d-flex flex-wrap">
        <div class="filter-group my-1">
          <label for="filter_data">View royalties detail for:</label>
          <custom-select 
            v-model="period"
            :items="periodOption"
            class="select-xs"
          />
        </div>
        <div 
          class="filter-group my-1"
        >
          <label for="filter_view">View:</label>
          <select-physican
            v-model="physician"
            title="Entire Practice"
            :items="valuesForSelect(physicians)"
            :total="physiciansTotal"
            :loading="isPhysiciansLoading"
            :name="'royalties-detail-physician-select'"
            :filters="filters"
            @load-more="loadMorePhysicians"
            @click.native="patinetClick"
          />
        </div>
      </div>
    </div>
    <!-- /Filter data -->
    <div class="d-flex justify-content-between pt-2 pb-1">
      <h4>Royalties Detail</h4>
      <a
        href="#"
        @click.prevent="downloadOrdersCsv()"
      >
        <img 
          :src="csvSrc"
          alt="export"
        >
        Export .CSV
      </a>
    </div>
    <!-- /Title reyaties detail -->
    <div class="content-table">
      <table class="table table-box table-striped royal-details">
        <thead>
          <tr>
            <th 
              :class="getSortClass('number')"
              @click="setSorting('number')"
            >
              Order
            </th>
            <th
              :class="getSortClass('patient_name')"
              @click="setSorting('patient_name')"
            >
              Client
            </th>
            <th
              :class="getSortClass('product_title')"
              @click="setSorting('product_title')"
            >
              Products
            </th>
            <th
              :class="getSortClass('total_price')"
              @click="setSorting('total_price')"
            >
              Price
            </th>
            <th
              :class="getSortClass('royalty')"
              @click="setSorting('royalty')"
            >
              Royalty
            </th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(order, idx) in royaltiesDetail.items"
            :key="idx"
          >
            <td>
              <router-link
                v-if="order.status === 'completed' && order.shopify"
                class="link_action"
                :to="{
                  name: 'ShowOrder',
                  params: {
                    id: order.id
                  }
                }"
              >
                #{{ order.shopify.order_number }}
              </router-link>
              <span v-else>-</span>
            </td>
            <td>
              <span
                v-if="order.patient"
              >{{ order.patient.name }}</span>
            </td>
            <td>
              <p
                v-for="line_item in order.line_items"
                :key="line_item.id"
                class="mb-0"
              >
                {{ line_item.shopify_product ? line_item.shopify_product.title : '' }}
              </p>
            </td>
            <td>
              <p
                v-for="line_item in order.line_items"
                :key="line_item.id"
                class="mb-0"
              >
                {{ line_item.price }}
              </p>
              <p class="mb-0">
                <strong>Order Total {{ order.total_price }}</strong>
              </p>
            </td>
            <td>
              <p
                v-for="line_item in order.line_items"
                :key="line_item.id"
                class="mb-0"
              >
                {{ line_item.royalty }}
              </p>
              <p class="mb-0">
                <strong>Royalty Total {{ order.total_royalty }}</strong>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- /Table Orders -->
    <pagination
      :total-rows="royaltiesDetail.total_items"
      :total-pages="royaltiesDetail.total_pages"
      :current-page="currentPage"
      :per-page="perPage"
      @change-per-page="changePerPage"
      @change-page="changePage"
    />
    <!-- /Pagination -->
  </div>
</template>


<style lang="scss" scoped>
  .table-box {
    thead  {
      tr {
        th {
          position: relative;
          &:first-child {
            width: 100px;
          }
          &:nth-child(2) {
            width: 160px;
          }
          &:nth-child(3) {
            width: 490px;
          }
          &:nth-child(4) {
            width: 165px;
          }
          &:last-child {
            width: 125px;
          }
        }
      }
    }
  }
</style>

<script>
import { encodeQueryData, calcDelta } from '@/helper';
import fileDownloader from '@/helper/fileDownloader';
import CustomSelect from '@/views/components/CustomSelect';
import { ReportService } from '@/services/api.service';
import dateOptions from '@/mixins/dateOptions';
import SelectPhysican from '@/views/components/Select';
import physician from '@/mixins/physician';
import Pagination from '../partials/Paginations';
import csvSrc from '@images/export-icon.png';

// @group Reports
export default {
  name: 'RoyaltiesDetail',

  components: {
    CustomSelect,
    SelectPhysican,
    Pagination
  },

  mixins: [dateOptions, physician],

  props: {
    practiceCreatedAt: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      csvSrc,
      period: '',
      periodOption: [],
      physician: '',
      royaltiesDetail: [],
      currentPage: 1,
      perPage: 20,
      loadOnInit: false,
      physiciansLoaded: false,
      orders: {
        number: 'desc',
      },
    };
  },

  watch: {
    physician() {
      this.getInfo();
    },

    period: {
      handler() {
        if (this.period)
          this.getInfo();
      }
    },

    practiceCreatedAt: {
      immediate: true,
      handler() {
        if (!this.practiceCreatedAt) return;
        const dataOption = this.buildMonthOptions(this.practiceCreatedAt);
        this.periodOption = dataOption;

        this.period = this.periodOption[this.periodOption.length - 1].value.map((el) => {
          return el.toString();
        }).join(',');
      }
    }
  },

  methods: {
    // @vuese
    // Used to convert object to get string
    // @arg Query object
    encodeQueryData,
    // @vuese
    // Used to calculate delta
    // @arg Current perriod value
    // @arg / Previous period value
    calcDelta,

    // @vuese
    // Used to create temp div pass download data to it and click on it
    // @arg base64File
    // @arg name of attribute
    fileDownloader,

    // @vuese
    // Used to get patient royalties detail data
    getInfo() {
      ReportService.getRoyaltiesDetail(this.buildQueryObj())
        .then(resp => {
          this.royaltiesDetail = resp.data.royalties_detail;
        });
    },

    // @vuese
    // Convert object to query string
    buildQueryObj() {
      return this.encodeQueryData({
        filters: {
          period: this.period.split(',').map(dateItem => {
            return new Date(dateItem).toISOString();
          }),
          physician: this.physician,
        },
        per: this.perPage,
        orders:  this.orders,
        page: this.currentPage,
      });
    },

    // @vuese
    // Used to request data for csv and call load data
    downloadOrdersCsv() {
      return ReportService.getRoyaltiesDetailCSV(
        this.buildQueryObj()
      ).then((response) => {
        this.fileDownloader(response.data, this.buildFilename());
      });
    },

    // @vuese
    // Used to build csv file name
    buildFilename() {
      return `royalties-detail-${(new Date).toDateString()}.csv`;
    },

    // @vuese
    // Used to change page in pagination
    // @arg Page number
    changePage(value) {
      this.currentPage = value;
      this.getInfo();
    },
    // @vuese
    // Used to change per page in pagination and fetch info
    // @arg Per page value `Number`
    changePerPage(value) {
      this.perPage = value;
      this.currentPage = 1;
      this.getInfo();
    },

    // @vuese
    // Used to get sort class
    // @arg `DESC` or `ASC` or null
    getSortClass(by) {
      return this.orders[by] ? `sort-${this.orders[by]}` : 'sortable';
    },

    // @vuese
    // Used to set sorting and load new info
    setSorting(by, direction= 'desc') {
      if (this.orders[by] && this.orders[by] == 'desc')
        direction = 'asc';

      this.orders = { [`${by}`]: direction };
      this.getInfo();
    },

    // @vuese
    // Load physican only on click
    patinetClick() {
      if (!this.physiciansLoaded) {
        this.loadMorePhysicians({type: 'search'});
        this.physiciansLoaded = true;
      }
    }
  }
};
</script>
