<template>
  <div class="container container-md-sm">
    <div class="steps-wrap">
      <div class="step passed">
        <p>Set Password</p>
        <span class="step-marker" />
      </div>

      <div class="step current">
        <p>Add Practice Info</p>
        <span class="step-marker" />
      </div>

      <div class="step">
        <p>Add Practice Address</p>
        <span class="step-marker" />
      </div>

      <div class="step">
        <p>Add Skincare Pros</p>
        <span class="step-marker" />
      </div>

      <div class="step">
        <p>Add Practice Staff</p>
        <span class="step-marker" />
      </div>

      <div class="step">
        <p>Review</p>
        <span class="step-marker" />
      </div>
    </div>
    <!-- /. steps-wrap -->

    <div class="container">
      <Notifications />
    </div>

    <div class="steps-content">
      <div class="top-title">
        <h3 class="mb-3">
          Add Practice Information
        </h3>
        <a 
          class="right-link" 
          href="#" 
          @click.prevent="addPracticeInfo(true)"
        >
          Save for Later
        </a>
      </div>
      <ValidationObserver
        ref="observer"
        v-slot="{ invalid }"
        tag="form"
        @submit.prevent="addPracticeInfo(false)"
      >
        <div class="row row-create-account">
          <div class="col-md-8">
            <p class="mb-2">
              This information will be viewable to your clients.
            </p>
            <div class="f-box form form-white">
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                rules="email"
                mode="passive"
              >
                <div class="form-group">
                  <label for="email">PRACTICE EMAIL ADDRESS</label>
                  <input
                    id="email"
                    v-model="practice.email"
                    placeholder="contact@practice.com"
                    type="email"
                    :class="errors.length ? 'error' : ''"
                    name="email"
                  >
                  <p class="validation error mt-2">
                    {{ errors[0] }}
                  </p>
                </div>
              </validation-provider>
              <validation-provider 
                v-slot="{ errors }" 
                name="Website" 
                rules="url|required"
                mode="passive"
              >
                <div class="form-group">
                  <label for="link">PRACTICE WEBSITE LINK</label>
                  <input
                    id="link"
                    v-model="practice.url"
                    placeholder="http://www.practicewebsite.com"
                    type="text"
                    :class="errors.length ? 'error' : ''"
                    name="link"
                  >
                  <p class="validation error mt-2">
                    {{ errors[0] }}
                  </p>
                </div>
              </validation-provider>

              <div 
                v-for="(phoneObj, index) in practice.phones" 
                :key="index" 
                class="row"
              >
                <div class="form-group col-md-6">
                  <label
                    v-if="index < 2"
                  >{{ index === 0 ? 'MAIN PRACTICE NUMBER' : 'ADDITIONAL NUMBER' }}</label>
                  <input
                    v-model="phoneObj.number"
                    placeholder="(555) 555 - 5555"
                    :class="addPhoneError[index] ? 'error' : ''"
                    type="text"
                    :name="`phone-${index}`"
                    @input="enterNumber(index)"
                    @change="changeNumber(index)"
                  >
                  <p 
                    v-if="addPhoneError[index]"
                    class="validation error mt-2"
                  >
                    {{ addPhoneError[index] }}
                  </p>
                </div>
                <div class="form-group col-md-4">
                  <label v-if="index < 2">EXT. (OPTIONAL)</label>
                  <input
                    v-if="index === 0"
                    v-model="phoneObj.ext"
                    class="col-8 ext"
                    placeholder="555"
                    type="text"
                    maxlength="3"
                  >
                  <div 
                    v-if="index > 0" 
                    class="row align-items-center no-gutters ext-wrap"
                  >
                    <input
                      v-model="phoneObj.ext"
                      class="col-8 ext"
                      placeholder="555"
                      type="text"
                      maxlength="3"
                      name="ext"
                    >
                    <a 
                      class="col-4 minus-addittional pl-2" 
                      @click="removePhone(index)"
                    >
                      <div class="form-options">
                        <i class="fas fa-minus" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="form-options">
                  <a 
                    class="additional-btn" 
                    @click="addPhone"
                  >
                    <i class="fas fa-plus" /> Add Additional Number
                  </a>
                </div>
              </div>
            </div>

            <div class="f-box form form-white">
              <div class="form-group mb-5">
                <label for="pphoto">Practice Logo</label>
                <ImagePicker 
                  :image="practice.logo"
                  :image-preview="imagePreview"
                  :filename="filename"
                  :bordered="false"
                  :can-remove="false"
                  :cropper-options="{
                    aspectRatio: '',
                    autoCropArea: 1,
                    viewMode: 1,
                    movable: false,
                    zoomable: true
                  }"
                  @image="val => {practice.logo = val; practiceLogoError = ''}"
                  @image-preview="val => imagePreview = val"
                  @filename="val => filename = val"
                />
                <p 
                  v-if="practiceLogoError" 
                  class="validation error mt-2"
                >
                  {{ practiceLogoError }}
                </p>
              </div>
            </div>
            <div class="f-box form form-white">
              <div class="form-group mb-0">
                <legend>ADD SOCIAL MEDIA LINKS (OPTIONAL)</legend>
                <div
                  v-for="(socialItem, index) in practice.social_media"
                  :key="index"
                  class="form-check-box"
                >
                  <input
                    :id="socialItem.type"
                    type="checkbox"
                    :name="socialItem.type"
                    :checked="socialItem.url"
                  >
                  <label :for="socialItem.type">{{ capitalize(socialItem.type) }}</label>
                  <validation-provider 
                    v-slot="{ errors }" 
                    :name="socialItem.type" 
                    rules="url"
                    class="social-input-wrap"
                    mode="passive"
                  >
                    <input
                      v-model="socialItem.url"
                      type="text"
                      :placeholder="`www.${socialItem.type}.com/practice`"
                    >
                    <p 
                      class="validation error mt-2"
                    >
                      {{ errors[0] }}
                    </p>
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <Sidebar />
            <button 
              type="submit" 
              :disabled="sending" 
              class="btn btn-primary"
            >
              Continue
            </button>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <!-- /. row-create-account -->
    <ContactUs />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Sidebar from './partials/Sidebar';
import {
  ValidationProvider,
  ValidationObserver,
  extend
} from 'vee-validate';
import {
  required,
  email
} from 'vee-validate/dist/rules';
import {
  isURL,
  capitalize,
  validPhone
} from '@/helper';
import {
  AsYouType
} from 'libphonenumber-js';
import {
  UPDATE_PRACTICE_FORM
} from '@/store/practice/actions.types';
import { 
  socials
} from '@/store/practice/initialObject';
import ContactUs from '../components/ContactUs';
import ImagePicker from '../components/ImagePicker';
import { get } from 'lodash';

// Install rules
extend('required', required);
extend('url', {
  validate(value) {
    return isURL(value);
  },
  message: 'Please enter a valid website link '
});

email['message'] = 'Please enter a valid email address';
extend('email', email);

// @vuese
// @group Create Account
export default {
  name: 'CreateAccountS2',

  components: {
    Sidebar,
    ValidationProvider,
    ValidationObserver,
    ContactUs,
    ImagePicker,
  },

  data() {
    return {
      sending: false,
      practiceLogoError: '',
      imagePreview: null,
      filename: '',
      addPhoneError: []
    };
  },

  computed: {
    ...mapGetters(['practice'])
  },

  watch: {
    practice: function(newPractice, oldPractice) {
      if (newPractice.logo && newPractice.logo.url){
        this.imagePreview = newPractice.logo.url;
      }

      oldPractice.social_media.forEach(soc => {
        const newSocial = newPractice.social_media.find(
          item => item.type == soc.type
        );
        if (newSocial) soc.url = newSocial.url;
      });

      this.practice.social_media = oldPractice.social_media;
    }
  },

  beforeMount() {
    if (this.practice.logo && this.practice.logo.url) {
      this.imagePreview = this.practice.logo.url;
    }

    const socialsArr = [...socials];
    socialsArr.forEach(soc => {
      const newSocial = this.practice.social_media.find(
        item => item.type == soc.type
      );
      if (newSocial) soc.url = newSocial.url;
    });

    this.practice.social_media = socialsArr;
  },

  methods: {
    // @vuese
    // Used to validate phone
    // @arg Phone number
    validPhone,

    // @vuese
    // Used to store practice info
    // @arg Save for later
    async addPracticeInfo(saveForLater) {
      if ((await this.isValid()) && this.validPhones() && this.validLogo()) {
        this.sending = true;

        let formData = this.getFormData();

        this.$store
          .dispatch(UPDATE_PRACTICE_FORM, formData)
          .then(() => {
            if (saveForLater) 
              this.flashSuccess('Practice info has been saved');

            this.$router.push({name: 'CreateAccountS3'});
          })
          .catch(err => {
            if (get(err, 'response.data.errors.phones[0]') == 'is invalid') {
              this.validPhones();
            }
            this.flashError('Error updating practice information');
            if(document.querySelector('.error'))
              document.querySelector('.error').scrollIntoView(true);
          })
          .finally(() => {
            this.sending = false;
          });
      }
    },

    // @vuese
    // Used to build and return form data
    getFormData() {
      let formData = new FormData();
      if (!this.practice.logo.url)
        formData.append('practice[logo]', this.practice.logo, this.filename);

      formData.append('practice[email]', this.practice.email);
      formData.append('practice[url]', this.practice.url);
      this.practice.phones.forEach(phone => {
        if (!phone.number) return;
        formData.append('practice[phones][][number]', phone.number);
        formData.append('practice[phones][][ext]', phone.ext);
      });
      this.practice.social_media.forEach(soc => {
        formData.append('practice[social_media][][type]', soc.type);
        formData.append('practice[social_media][][url]', soc.url);
      });

      return formData;
    },

    // @vuese
    // Used to validate form
    isValid() {
      return this.$refs.observer.validate();
    },

    // @vuese
    // Used to add phone to practice array
    addPhone() {
      this.practice.phones.push({ number: '', ext: '' });
    },

    // @vuese
    // Used to remove phone from practice array
    removePhone(index) {
      this.practice.phones.splice(index, 1);
    },

    // @vuese
    // Used to enter number, validate, and convert it
    // @arg row index
    enterNumber(index) {
      let count_numbers = 0, last_number = 0, max_numbers = 10;
      if(this.practice.phones[index].number[0] == 1)
        max_numbers += 1;

      for (let i = 0; i < this.practice.phones[index].number.length; i++) {
        if(/^\d+$/.test(this.practice.phones[index].number[i])){
          count_numbers +=1;
          if(count_numbers > max_numbers && last_number == 0)
            last_number = i;
        }
      }

      if(last_number != 0){
        this.practice.phones[index].number = this.practice.phones[index].number.slice(0,last_number);
      }

      if(event.inputType && event.inputType.indexOf('deleteContent') == -1){
        if(this.validPhone(this.practice.phones[index].number)){
          this.practice.phones[index].number = new AsYouType('US').input(
            this.practice.phones[index].number
          );
          this.addPhoneError[index] = '';
        }else{
          this.addPhoneError[index] = 'Please enter valid phone number';
        }
      }
    },

    // @vuese
    // Used to convert phone number
    changeNumber(index) {
      if (!this.validPhone(this.practice.phones[index].number)) {
        this.addPhoneError[index] = 'Please enter valid phone number';
      } else {
        this.addPhoneError[index] = '';

        this.practice.phones[index].number = new AsYouType('US').input(
          this.practice.phones[index].number
        );
      }
    },

    // @vuese
    // Capitalize string
    capitalize(val) {
      return capitalize(val);
    },

    // @vuese
    // Used to validate logo
    validLogo() {
      const logo = this.practice.logo;
      if (
        (logo.hasOwnProperty('url') && !this.practice.logo.url) ||
        !this.practice.logo
      ) {
        this.practiceLogoError = 'Please add practice logo';
        return false;
      }
      return true;
    },

    // @vuese
    // Used to validate all phones
    validPhones() {
      let valid = true;
      this.practice.phones.forEach((phone, idx) => {
        if (!this.validPhone(phone.number)) {
          valid = false;
          this.addPhoneError[idx] = 'Please enter valid phone number';
        }
      });
      return valid;
    }
  }
};
</script>