<template>
  <div 
    ref="dropZone" 
    class="dnd-zone"
  >
    <div 
      v-show="!cropperOpen" 
      class="drag-info"
    >
      <div 
        v-if="imagePreview" 
        class="preview"
      >
        <img 
          :class="bordered ? 'bordered' : ''"
          :src="imagePreview"
        >
      </div>
      <div>
        <p>
          Drag Image Here
          <span>or</span>
        </p>
        <label
          v-show="!imagePreview"
          class="btn btn-primary pick-avatar"
        >Choose Image to Upload</label>
        <div 
          v-show="imagePreview" 
          class="buttons-wrap"
        >
          <button
            v-if="canRemove"
            type="button"
            class="btn btn-primary remove-image"
            @click.prevent="removeImage"
          >
            Remove Image
          </button>
          <button 
            type="button"
            class="btn btn-primary change-image pick-avatar"
          >
            Change Image
          </button>
        </div>
      </div>
    </div>

    <avatar-cropper
      :key="cropperKey"
      :trigger="trigger"
      :drop-file="dropFile"
      :labels="{
        submit: 'Submit',
        cancel: 'Cancel'
      }"
      :cropper-options="cropperOptions"
      @cropping="cropperHandler"
      @submit="handleCroppedImage"
      @filename="getFileName"
    />
  </div>
</template>

<style scoped>
.bordered {
  border-radius: 100%;
}
</style>

<script>
import AvatarCropper from './AvatarCropper';

// @group Components
export default {
  name: 'ImagePicker',
  components: {
    AvatarCropper
  },
  props: {
    // @vuese
    // Image in Blob or Base64
    image: {
      type: [Object, Blob, String],
      default: null,
    },
    // @vuse
    // Image preview
    imagePreview: {
      type: String,
      default: '',
    },
    // @vuse
    // Filename
    filename: {
      type: String,
      default: '',
    },
    // @vuse
    // Whether image is boarder or not
    bordered: {
      type: Boolean,
      default: true,
    },
    // @vuese
    // Whether used can remove image or not
    canRemove: {
      type: Boolean,
      default: true,
    },
    // @vuese
    // Cropper option
    cropperOptions: {
      type: Object,
      default() {
        return {
          aspectRatio: 1,
          autoCropArea: 1,
          viewMode: 1,
          movable: false,
          zoomable: true
        };
      }
    }
  },
  data() {
    return {
      cropperOpen: false,
      dragAndDropCapable: false,
      dropFile: null,
      trigger: '',
      cropperKey: 0,
    };
  },
  mounted() {
    this.initDragAndDrop();
    this.$nextTick(() => {
      this.trigger = this.$el.querySelectorAll('.pick-avatar');
      this.cropperKey++;
    });
  },
  methods: {
    // @vuese
    // Used to pass parrent cropped image
    handleCroppedImage(value) {
      this.$emit('image', value);
      this.$emit('image-preview', URL.createObjectURL(value));
    },
    // @vuese
    // used to emit filename to parent
    getFileName(filename) {
      this.$emit('filename', filename);
    },
    // @vuese
    // Cropper handler
    cropperHandler(value) {
      this.cropperOpen = value;
    },
    // @vuse
    // Used to check if browser support drag and drop
    determineDragAndDropCapable(){
      /*
        Create a test element to see if certain events
        are present that let us do drag and drop.
      */
      var div = document.createElement('div');

      /*
        Check to see if the `draggable` event is in the element
        or the `ondragstart` and `ondrop` events are in the element. If
        they are, then we have what we need for dragging and dropping files.

        We also check to see if the window has `FormData` and `FileReader` objects
        present so we can do our AJAX uploading
      */
      return ( ( 'draggable' in div )
              || ( 'ondragstart' in div && 'ondrop' in div ) )
              && 'FormData' in window
              && 'FileReader' in window;
    },
    // @vuese
    // Used to tell parent that image was removed
    removeImage() {
      this.$emit('image', null);
      this.$emit('image-preview', '');
    },
    // @vuese
    // used to init darag and drop element
    initDragAndDrop() {
      this.dragAndDropCapable = this.determineDragAndDropCapable();
      /*
        If drag and drop capable, then we continue to bind events to our elements.
      */

      if( this.dragAndDropCapable ) {
        /*
          Listen to all of the drag events and bind an event listener to each
          for the fileform.
        */
        [
          'drag', 
          'dragstart', 
          'dragend', 
          'dragover', 
          'dragenter', 
          'dragleave', 
          'drop'
        ].forEach(evt => {
          /*
            For each event add an event listener that prevents the default action
            (opening the file in the browser) and stop the propagation of the event (so
            no other elements open the file in the browser)
          */
          this.$refs.dropZone.addEventListener(evt, e =>{
            e.preventDefault();
            e.stopPropagation();
          }, false);
        });

        /*
          Add an event listener for drop to the form
        */
        this.$refs.dropZone.addEventListener('drop', e =>{
          /*
            Capture the files from the drop event and add them to our local files
            array.
          */
          this.dropFile = e.dataTransfer.files[0];
        });
      }
    }
  }
}
</script>