<!-- Contact.vue -->
<template>
  <div>
    <h2 class="main-privacy-heading">
      Contact Us
    </h2>
    <div id="hubspot-form" />
  </div>
</template>

<script>
export default {
  mounted() {
    // Load the HubSpot form script with HTTPS
    const script = document.createElement('script');
    script.charset = 'utf-8';
    script.type = 'text/javascript';
    script.src = 'https://js.hsforms.net/forms/embed/v2.js';
    script.onload = this.loadForm;
    document.body.appendChild(script);
  },
  methods: {
    loadForm() {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '23306491',
          formId: '87ce3425-a7cd-49ed-a630-4d5a9486df1c',
          target: '#hubspot-form'
        });
      }
    }
  }
};
</script>

<style scoped>
#hubspot-form {
  padding-left: 20%;
  padding-right: 20%;
}
.main-privacy-heading {
  text-align: center;
  margin: 4rem;
}
</style>
