import { RegimenService } from '../../services/api.service'; 
import {
  GET_PRESET_REGIMENS,
  GET_REGIMEN,
} from './actions.types';
import {
  SET_REGIMENS,
} from './mutations.types';
// import { 
// } from './initialObject';

const initialState = {
  regimens: [],
};

const state = Object.assign({}, initialState);

const getters = {
  regimens(state) {
    return state.regimens;
  },
};

const actions = {
  [GET_PRESET_REGIMENS](context, params) {
    return new Promise((resolve, reject) => {
      RegimenService.get(params)
        .then(resp => {
          context.commit(SET_REGIMENS, resp.data.regimens);
          resolve(resp);
        })
        .catch(err => reject(err));
    });
  },
  [GET_REGIMEN](context, id) {
    return new Promise((resolve, reject) => {
      RegimenService.getRegimen(id)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => reject(err));
    });
  }
};

const mutations = {
  [SET_REGIMENS](state, regimens) {
    state.regimens = regimens;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};