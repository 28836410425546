<template>
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      @click="$emit('hide-modal-window')"
    >
      <i
        class="fa fa-times"
        aria-hidden="true"
      />
    </button>
    <div>
      <div class="text-center">
        <i
          class="fas fa-exclamation-triangle fa-3x"
          style="color: #f7df8c; margin-bottom: 20px"
        />
      </div>

      <h3
        class="modal-title text-center"
      >
        {{ title }}
      </h3>

      <p
        class="text-center"
        style="margin: 10px auto"
      >
        {{ text }}
      </p>

      <div>
        <div
          v-for="product of products"
          :key="product.id"
          class="row"
        >
          <div
            class="col-md-12"
            style="display: flex; justify-content: spase-between"
          >
            <div class="col-sm-3 image-box">
              <img
                v-if="product.image"
                :src="product.image"
                alt="image"
              >
            </div>
            <div class="col-md-9">
              <p
                style="margin-bottom: 7px;"
              >
                <b>Product Name</b>
              </p>
              <p style="width: max-content;">
                {{ product.title }}
              </p>
            </div>
          </div>
          <hr style="border: 1px solid #d8d8d8; width: 100%;">
        </div>
      </div>

      <a
        class="btn btn-primary"
        data-dismiss="modal"
        aria-label="Close"
        @click="$emit('hide-modal-window')"
      >
        OKAY
      </a>
    </div>
  </div>
</template>

<script>
// @group Patinet
// Out of stock product modal
export default {
  name: 'OutOfStockProduct',
  props: {
    // @vuese
    // Out of stock products
    products: {
      type: Array,
      default() {
        return [{}];
      }
    },
    // @vuese
    // Content
    text: {
      type: String,
      default: ''
    },
    // @vuese
    // Modal title
    title: {
      type: String,
      default: ''
    }
  }
};
</script>