<template>
  <div>
    <div class="row no-gutters justify-content-between mb-1 mt-md-4 pb-2">
      <h4>Sales Detail</h4>

      <div class="d-flex flex-wrap">
        <div class="filter-group my-1">
          <label for="filter_data">View sales for:</label>
          <custom-select
            v-model="filterDate"
            :items="filterDateOption"
            class="select-xs filter-date"
          />
        </div>
        <div class="filter-group my-1">
          <label for="compare_to">Compare To:</label>
          <custom-select 
            v-model="compareToDate"
            :items="compareToDateOption"
            class="select-xs compare-to-date"
          />
        </div>
        <div class="filter-group my-1">
          <label for="filter_view">View:</label>
          <select-physican
            v-model="physician"
            title="Entire Practice"
            :items="valuesForSelect(physicians)"
            :total="physiciansTotal"
            :loading="isPhysiciansLoading"
            :name="'sales-detail-physician-select'"
            :filters="filters"
            @load-more="loadMorePhysicians"
            @click.native="patinetClick"
          />
        </div>
      </div>
    </div>

    <div class="d-box with-lpad">
      <div class="stats-grid">
        <div class="stat-sale stat-sale-dark col-lg-3 col-md-4 col-sm-6 ml-auto">
          <span class="stat-price">${{ formatedPrice }}</span>
          <span class="stat-text">Total</span>
        </div>
      </div>
      <sales-line-chart
        :key="key"
        :labels="labels"
        :quarter="filterDateChartOptions"
        :quarter-label="filterDateLabel"
        :compare-quarter="compareToDateChartOptions"
        :compare-quarter-label="compareToDateLabel"
      />
    </div>
  </div>
</template>

<script>
import { encodeQueryData, calcDelta } from '@/helper';
import CustomSelect from '@/views/components/CustomSelect';
import { ReportService } from '@/services/api.service';
import dateOptions from '@/mixins/dateOptions';
import SelectPhysican from '@/views/components/Select';
import physician from '@/mixins/physician';
import SalesLineChart from './SalesLineChart';
import moment from 'moment';

// @group Reports
export default {
  name: 'SalesDetail',

  components: {
    CustomSelect,
    SelectPhysican,
    SalesLineChart
  },

  mixins: [dateOptions, physician],

  props: {
    practiceCreatedAt: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      filterDate: '',
      filterDateLabel: '',
      filterDateOption: [],
      compareToDate: '',
      compareToDateLabel: '',
      compareToDateOption: [],
      filterDateChartOptions: {},
      compareToDateChartOptions: {},
      physician: '',
      weeksRange: [],
      monthsRange: [],
      compareWeeksRange: [],
      labels: [],
      total_price: 0.0,
      loadOnInit: false,
      physiciansLoaded: false,
      key: 0
    };
  },

  computed: {
    formatedPrice() {
      return Math.round(this.total_price).toLocaleString('en-US');
    }
  },

  watch: {
    physician() {
      this.getInfo();
    },

    filterDate: {
      handler() {
        const option = this.$el.querySelector('.filter-date option:checked');
        this.filterDateLabel = option ? option.innerHTML.trim() : this.filterDateOption[this.filterDateOption.length - 1].title;
        if (this.filterDate && this.compareToDate)
          this.getInfo();
      }
    },

    compareToDate: {
      handler() {
        setTimeout(() => {
          this.compareToDateLabel = this.$el.querySelector('.compare-to-date option:checked').innerHTML.trim();
        });
        this.getInfo();
      }
    },

    practiceCreatedAt: {
      immediate: true,
      handler() {
        if (!this.practiceCreatedAt) return;
        const dataOption = this.buildYearOptions(this.practiceCreatedAt);
        this.filterDateOption = dataOption;
        this.compareToDateOption = dataOption;

        this.filterDate = this.filterDateOption[this.filterDateOption.length - 1].value.join(',');
        this.filterDateLabel = this.filterDateOption[this.filterDateOption.length - 1].title;

        setTimeout(() => {
          let prevYear = this.compareToDateOption[this.compareToDateOption.length - 2];
          this.compareToDate = prevYear ? prevYear.value.join(',') : this.compareToDateOption[0].value.join(',');
          this.compareToDateLabel = prevYear ? prevYear.title : this.compareToDateOption[0].title;
        });

        this.monthsRange = this.getArrayOfMonths();
      }
    }
  },

  methods: {
    // @vuese
    // Used to convert object to get string
    // @arg Query object
    encodeQueryData,
    // @vuese
    // Used to calculate delta
    // @arg Current perriod value
    // @arg / Previous period value
    calcDelta,

    // @vuese
    // Used to get sales detail data
    getInfo() {
      ReportService.getSalesDetail(this.buildQueryObj())
        .then(resp => {
          if(resp.data.sales.total_price)
            this.total_price = resp.data.sales.total_price;
          else this.total_price = 0.0;

          this.filterDateChartOptions = this.prepareData(resp.data.sales.new_orders, this.monthsRange);
          this.compareToDateChartOptions = this.prepareData(resp.data.sales.previous_orders, this.monthsRange);
          this.labels = this.monthsRange;
          this.key++;
        });
    },

    // @vuese
    // Used to prepare data for chart
    // @arg Data 
    // @arg Month range
    prepareData(data, range) {
      const output = {};
      range.forEach((date) => {
        const month = moment(new Date(date)).format('MMM');

        output[month] = 0;
        data.forEach(saleObj => {
          const saleDate = Object.keys(saleObj)[0];
          const saleMonth = moment(saleDate).format('MMM');
          if (saleMonth == month)
            output[month] = parseFloat(saleObj[saleDate]);
        });
      });

      Object.keys(output).forEach(key => {
        output[key] = Number.isInteger(output[key]) ? output[key] : Number(output[key].toFixed(2));
      });

      return output;
    },

    prepareLabels(firstRange, lastRange) {
      const output = [];
      firstRange.forEach((el, idx) => {
        const lastEl = lastRange[idx] ? lastRange[idx] : '-';
        output.push(`${el}#${lastEl}`);
      });
      if (output.length < lastRange.length) {
        lastRange.slice(output.length).forEach(el => {
          output.push(`-#${el}`);
        });
      }
      return output;
    },

    // @vuese
    // Convert object to query string
    buildQueryObj() {
      return this.encodeQueryData({
        filters: {
          current_period: this.filterDate.split(',').map(dateItem => {
            return new Date(dateItem).toISOString();
          }),
          previous_period: this.compareToDate.split(',').map(dateItem => {
            return new Date(dateItem).toISOString();
          }),
          physician: this.physician,
        }
      });
    },

    // @vuese
    // Load physican only on click
    patinetClick() {
      if (!this.physiciansLoaded) {
        this.loadMorePhysicians({type: 'search'});
        this.physiciansLoaded = true;
      }
    }
  }
};
</script>