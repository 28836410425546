import ApiService, { PhysiciansService } from '@/services/api.service';
import { encodeQueryData } from '@/helper';

// mixin to load and search physican on practice
export default {
  props: {
    // @vuese
    // To load by practice for super admin user
    allowFilterByPractice: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      physicians: [],
      physiciansTotal: 0,
      selectedPhysicians: [],
      physiciansListVisible: false,
      isPhysiciansLoading: false,
      loadOnInit: true,
      filters: {}
    };
  },
  mounted() {
    if (this.loadOnInit)
      this.loadMorePhysicians({type: 'search'});
  },
  methods: {
    // @vuese
    // Used to convert object to get string
    // @arg Query object
    encodeQueryData,

    // @vuese
    // Prepare data for select options
    // @arg Data to change
    // @arg / what data we get from object
    valuesForSelect(resources, map={title: 'name', value: 'id'}) {
      return resources.map(item => { return {value: item[map.value], title: item[map.title] }; });
    },
    
    // @vuese
    // Used to load physican 
    // @arg Query object
    loadMorePhysicians(queryObj) {
      this.isPhysiciansLoading = true;

      const type = queryObj.type;
      delete queryObj.type;

      if (this.isRole('super_admin') && 
          ApiService.practiceId &&
          this.allowFilterByPractice
      ) {
        queryObj.filters = {};
        queryObj.filters.practice_id = ApiService.practiceId.replace('/', '');
      }

      return PhysiciansService.list(
        this.encodeQueryData(queryObj)
      ).then(
        ({ data }) => {
          if (type == 'search')
            this.physicians = data.physicians.items;
          else
            this.physicians = [...this.physicians, ...data.physicians.items];

          this.physiciansTotal = data.physicians.total_items;
        }
      ).finally(() => {
        this.isPhysiciansLoading = false;
      });
    }
  }
};