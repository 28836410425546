<template>
    <div class="wrap-table border-table">
      <h3>Regimen Logic Results</h3>
      <div v-if="quizResults">
        <div v-if="!canDoctorView">
          This client was recommended a regimen based on their RegimenLogic® assessment and chose to not share it with the practice.
        </div>
        <div v-else>
          <h4>Scores</h4>
          <div v-if="sortedScores" v-for="(score, concern) in sortedScores" :key="concern">
            <p class="concern-title"> {{ concernMap.get(concern) }} - {{ score }}</p>
            <div class="skillBar">
                <div class="skillBarContainer">
                  <div class="skillBarValue" :style="`width: ${score}%;background:${hexCodeMap.get(concern)}`"></div>
                </div>
            </div>
          </div>
          <h4>Client Information</h4>
          <div v-if="formattedAnswers" v-for="(answer, key) in formattedAnswers" :key="key">
              <p>{{ key }} {{ clientMap.get(answer) || answer }}</p>
          </div>

          <PatientViewRegimen
            :patient="patient"
            :allowEdit="false"
            :allowDelete="false"
            :useSkinCareProfessionalRecommendation="false"
          />

        </div>
      </div>
      <div v-else>
        This client has not completed RegimenLogic®.
      </div>
    </div>
  </template>

  <script>
  import { PatientsService } from '@/services/api.service';
  import PatientViewRegimen from './PatientViewRegimen';

  export default {
    name: 'PatientViewQuizResults',
    components: {
      PatientViewRegimen
    },
    props: {
      patient_id: Number,
      patient: {
        type: Object,
        default() {
          return {};
      }
    }
    },


    data() {
      return {
        quizResults: null, // Add an empty object to store the quiz results
        hexCodeMap: new Map([
          ["skinHealth", "#472c88"],
          ["skinAge","#f6958b"],
          ["acne", "#2a99d7"],
          ["redness", "#ff2e00"],
          ["ageSpots", "#01c0fe"],
          ["moisture", "#23d9e3"],
          ["firmness", "#fc85fe"],
          ["texture", "#cd86f2"],
          ["wrinkles", "#70dc5b"],
          ["wrinkles_firmness", "#fc85fe"],
          ["radiance_texture", "#aabbcd"],
          ["oiliness_pore", "#ff8b0c"],
          ["ageSpots", "#87CA6F"],
          ["radiance", "#aabbcd"],
          ["pore", "#8bb810"],
          ["darkCirclesV2_darkCircles", "#fd9183"],
          ["oiliness", "#ff8b0c"],
          ["droopyUpperEyelid_droopyLowerEyelid_eyeBags", "#cd5675"]
        ]),
        concernMap: new Map([
          ["acne","Acne"],
          ["redness","Redness"],
          ["ageSpots","Discoloration"],
          ["moisture","Dryness"],
          ["wrinkles_firmness","Wrinkles + Firmness"],
          ["radiance_texture","Radiance + Texture"],
          ["oiliness_pore","Oiliness + Pores"],
          ["texture","Texture"],
          ["wrinkles","Wrinkles"],
          ["firmness","Firmness"],
          ["eyeBags","Eye Bags"],
          ["radiance","Radiance"],
          ["pore","Pores"],
          ["darkCirclesV2_darkCircles","Dark Circles"],
          ["darkCircles","Dark Circles"],
          ["oiliness","Oiliness"],
          ["droopyUpperEyelid_droopyLowerEyelid_eyeBags","Eye Bags"],
          ["skinHealth", "Skin Score"],
          ["skinAge", "Skin Age"]
        ]),
        clientMap: new Map([
          ["acne","Acne"],
          ["redness","Redness"],
          ["firmness","Firmness + Wrinkles"],
          ["radiant","Radiance + Texture"],
          ["spots","Discoloration"],
          ["moisturizer","Dryness"],
          ["oiliness","Oiliness + Pores"]
        ]),
        canDoctorView: false,
      };
    },
    methods: {
      async getPatientQuizResults(patientId) {
        try {
          const response = await PatientsService.getPatientQuizResults(patientId);
          if (response.status === 200 && response.headers['content-type'] && response.headers['content-type'].includes('application/json')){
            this.canDoctorView = response.data.canDoctorView;
            return response.data.resultData;
          }
        } catch (error) {
          console.error(error);
          return null;
        }
      },
      async fetchQuizResults() {
        let fetchedQuizResults = await this.getPatientQuizResults(this.patient_id);
        if(fetchedQuizResults){
          if(fetchedQuizResults.answers){
              delete fetchedQuizResults.answers.doctor
              fetchedQuizResults.answers['Additional Products'] = fetchedQuizResults.answers['Additional Products'].map(product => {
                return product.replace(/^Rls/, '');
              });
            }
          if(fetchedQuizResults.user_responses){
              delete fetchedQuizResults.user_responses.doctor
              if (fetchedQuizResults?.user_responses?.['Additional Products']?.answer) {
                  fetchedQuizResults.user_responses['Additional Products'].answer = fetchedQuizResults.user_responses['Additional Products'].answer.map(product => {
                    return product.replace(/^Rls/, '');
                  });
              }

          }

        }
        // Call the method with the patient ID
        return fetchedQuizResults;
      },
      getPatientData(){
        let params = {
            id: this.patient_id,
        }
        PatientsService.getPatient(params).then((response) => {
            console.log(response.data);
        });
      },

    },
    computed: {
        sortedScores() {
            if(this.quizResults){

                delete this.quizResults.scores.droopyUpperEyelid;
                delete this.quizResults.scores.droopyLowerEyelid;
                delete this.quizResults.scores.darkCirclesV2;

                const scores = this.quizResults.scores;
                return Object.entries(scores)
                    .sort((a, b) => a[1] - b[1]) // Sort by score value (ascending)
                    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}); // Convert back to object
            }
        },
        formattedDate(){
            if(this.quizResults){
                // const date = new Date(this.quizResults.created_at);
                const date = new Date();
                const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                timeZoneName: "short"
                };

                const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

                return formattedDate;
            }
        },
        formattedAnswers(){
          if(this.quizResults){
            if(this.quizResults.answers){
              const answers = this.quizResults.answers;
              let formattedAnswersMap = {};
              for (const [key, value] of Object.entries(answers)) {
                if(Array.isArray(value)){
                  answers[key] = value.join(", ");
                  formattedAnswersMap[key] = answers[key];
                } else {
                  formattedAnswersMap[key] = value;
                }
              }
              return formattedAnswersMap;
            }
            if(this.quizResults.user_responses){
              const responses = this.quizResults.user_responses;
              let formattedAnswersMap = {};
              for (const [key, value] of Object.entries(responses)) {
                if(Array.isArray(value.answer)){
                  responses[value.question] = value.answer.join(", ");
                  formattedAnswersMap[value.question] = responses[value.question];
                } else {
                  formattedAnswersMap[value.question] = value.answer;
                }
              }
              return formattedAnswersMap;
            }
        }
      }
    },
    watch: {
      patient_id: {
        immediate: true,
        async handler() {
          if (this.patient_id) { // Use this.patient_id instead of patient_id
            this.quizResults = await this.fetchQuizResults(); // Call fetchQuizResults directly
          }
        }
      },
    },

  }
  </script>

<style>
  .skillBarContainer {
    width: 500px;
    min-width: 350px;
    height:26px;
    height: 1.66em;
    background: rgba(21, 22, 23, 0.2);
    overflow: hidden;
    border-radius:5px;
  }
  .skillBarValue {
    height: 1.66em;
    float: left;
    background: linear-gradient(45deg, #ebe9f1, #dfc5fe,  #472C88);
  }
  .skillBar{
    margin-bottom: 10px;
    display: flex;
  }

  .concern-title {
    margin-bottom: 0;
  }
</style>