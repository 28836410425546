<template>
  <div class="content-wrap">
    <Notifications />

    <h2>Clients</h2>

    <PatientsFilter
      @filter-physician="filterPhysician"
      @filter-status="filterStatus"
      @filter-search="filterSearch"
    />

    <hr>

    <Toolbar  
      :key="toolbarKey"
      :select-all="selectAll"
      :selected-count="selected.length"
      :download-orders-csv="downloadOrdersCsv"
      :load-csv="loadCSV"
      @show-add-patient="showModal('add')" 
      @show-remove-patient="showModal('remove')" 
      @show-reassign-patient="showModal('reassign')" 
    />

    <table class="table table-box table-striped patient-table">
      <thead>
        <tr>
          <th />
          <th 
            :class="orders[orderTypes.name] ? 
              `sort-${orders[orderTypes.name]}` : 
              'sortable'"
            @click="changeOrder(orderTypes.name)"
          >
            Client Name
          </th>
          <th 
            :class="orders[orderTypes.email] ? 
              `sort-${orders[orderTypes.email]}` : 
              'sortable'"
            @click="changeOrder(orderTypes.email)" 
          >
            Email Address
          </th>
          <th 
            :class="orders[orderTypes.physician] ? 
              `sort-${orders[orderTypes.physician]}` : 
              'sortable'"
            @click="changeOrder(orderTypes.physician)"
          >
            Skincare Pro
          </th>
          <th
            v-if="isRole('super_admin')"
          >
            Practice
          </th>
          <th
            :class="orders[orderTypes.date] ?
              `sort-${orders[orderTypes.date]}` :
              'sortable'"
            @click="changeOrder(orderTypes.date)"
          >
            Date Added
          </th>
          <th 
            :class="orders[orderTypes.status] ? 
              `sort-${orders[orderTypes.status]}` : 
              'sortable'"
            @click="changeOrder(orderTypes.status)"
          >
            Status
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        <router-link 
          v-for="patient in patients.items" 
          :key="patient.id"
          :to="
            patient.status == 'pending' ? 
              {
                name: 'PatientCreateRegimen',
                params: {
                  id: patient.id
                }
              }
              :
              {
                name: 'PatientView',
                params: {
                  id: patient.id
                }
              }"
          tag="tr"
          class="hover-btn"
        >
          <td @click.stop>
            <div class="box-checkbox">
              <input 
                :id="`check-${patient.id}`" 
                v-model="patient.selected"
                name="check" 
                type="checkbox" 
                @change="countSelected"
              >
              <label
                class="check-label"
                :for="`check-${patient.id}`"
              />
            </div>
          </td>
          <td>
            <span class="text-bold">{{ patient.name }}</span>
          </td>
          <td>{{ patient.email }}</td>
          <td>{{ patient.physician && patient.physician.name ? patient.physician.name : '' }}</td>
          <td
            v-if="isRole('super_admin')"
          >
            {{ getPracticeName(patient) }}
          </td>
          <td>{{ patient.date_added }}</td>
          <td>{{ capitalize(patient.status) }}</td>
          <td
            align="right"
          >
            <router-link 
              v-if="patient.status == 'completed'" 
              :to="{
                name: 'PatientView',
                params: {
                  id: patient.id
                }
              }"
              tag="a"
              class="link link_action"
            >
              View
            </router-link>
            <router-link 
              v-if="patient.status == 'pending'" 
              :to="{
                name: 'PatientCreateRegimen',
                params: {
                  id: patient.id
                }
              }"
              class="link link_action"
            >
              Continue
            </router-link>
          </td>
        </router-link>
      </tbody>
    </table>
    <!-- /. table-box -->

    <Pagination 
      :total-rows="patients.total_items"
      :total-pages="patients.total_pages"
      :current-page="currentPage"
      :per-page="perPage"
      @change-per-page="changePerPage"
      @change-page="changePage"
    />

    <Modal 
      :show-modal="showAddModal" 
      modal-content-class="overflow-visible"
      modal-id="add_patients"
      @hide-modal-window="hideModal('add')"
    >
      <AddPatientModal 
        :show-modal="showAddModal"
        :allow-filter-by-practice="false"
        @add-patient="hideModal('add')"
        @hide-modal-window="hideModal('add')"
      />
    </Modal>

    <Modal
      :show-modal="showRemoveModal"
      modal-id="remove_patients"
      @hide-modal-window="hideModal('remove')"
    >
      <RemovePatientsModal 
        :key="toolbarKey"
        :selected="selected"
        @patiens-removed="patientsRemoved"
        @hide-modal-window="hideModal('remove')"
      />
    </Modal>

    <Modal 
      :show-modal="showReassignModal" 
      modal-id="reassign_patients"
      modal-content-class="overflow-visible"
      @hide-modal-window="hideModal('reassign')"
    >
      <ReassignPatiensModal
        :key="toolbarKey"
        :selected="selected"
        @patiens-reassigned="patientsReassigned"
        @hide-modal-window="hideModal('reassign')"
      />
    </Modal>

    <div 
      v-if="showAddModal || showRemoveModal || showReassignModal" 
      class="modal-backdrop fade show" 
    />
  </div>
</template>

<script>
import PatientsFilter from './PatientsFilter';
import Toolbar from './Toolbar';
import AddPatientModal from './AddPatientModal';
import RemovePatientsModal from './RemovePatientsModal';
import ReassignPatiensModal from './ReassignPatiensModal';
import Modal from '../partials/Modal';
import Pagination from '../partials/Paginations';
import { mapGetters } from 'vuex';
import { capitalize, encodeQueryData } from '@/helper';
import { GET_PATIENTS } from '@/store/patients/actions.types';
import fileDownloader from '@/helper/fileDownloader';
import { PatientsService } from '@/services/api.service';

// @vuese
// Patient listing page
// @group Patinets
export default {
  name: 'Patients',

  components: {
    PatientsFilter,
    Toolbar,
    Modal,
    AddPatientModal,
    RemovePatientsModal,
    ReassignPatiensModal,
    Pagination,
  },

  props: {
    // Varable to get practice ID for super admin user
    practiceId: {
      type: [Number, String],
      // Default value is: `''`
      default: '',
    },
    // Araay of all practices loaded for super admin user
    practices: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      // inficator to show if data is loading
      sending: false,
      showAddModal: false,
      showRemoveModal: false,
      showReassignModal: false,
      loadCSV: false,
      selected: [],
      currentPage: 1,
      perPage: 20,
      toolbarKey: 0,
      orders: {
        date_added: 'desc',
      },
      filters: {},
      search: '',
      searchTimeout: null,
      orderTypes: {
        name: 'name',
        email: 'email',
        physician: 'physician',
        date: 'date_added',
        status: 'status',
      }
    };
  },

  computed: {
    ...mapGetters([
      'physicians',
      'patients',
    ])
  },

  beforeMount() {
    this.loadPatients(this.buildPatientsParams());
    if(document.patient){
      if(document.patient.goToLater){
        delete document.patient.goToLater;
        this.flashSuccess('Patient has been saved');
      }
    }
  },

  methods: {
    // @vuese
    // Used to create temp div pass download data to it and click on it
    // @arg base64File
    // @arg name of attribute
    fileDownloader,

    // @vuese 
    // Used to show modal
    // @arg Argument to find witch modal should be shown
    showModal(key) {
      switch (key) {
      case 'add':
        this.showAddModal = true;
        break;
      case 'remove':
        this.showRemoveModal = true;
        break;
      case 'reassign':
        this.showReassignModal = true;
        break;
      }
    },
    // @vuese 
    // Used to hide modal
    // @arg Argument to find witch modal should be hidden
    hideModal(key) {
      switch (key) {
      case 'add': 
        this.showAddModal = false;
        break;
      case 'remove':
        this.showRemoveModal = false;
        break;
      case 'reassign':
        this.showReassignModal = false;
        break;
      }
    },
    // @vuese 
    // Used to capitalize string
    // @arg String
    capitalize(val) {
      return capitalize(val);
    },
    // @vuese
    // Used count selected patinets row
    countSelected() {
      this.selected = this.patients.items.filter(patient => patient.selected);
      this.forceToolbarRerender();
    },
    // @vuese
    // Used to update toolbar view by increasing key
    forceToolbarRerender() {
      this.toolbarKey++;
    },
    // @vuese
    // Used to selcet all users loaded on page
    // @arg `true` or `false` param is used to show is patient should be selected or unselected
    selectAll(select) {
      this.patients.items.map(patient => {
        patient.selected = select;
        return patient;
      });

      this.countSelected();
    },
    // @vuese
    // Used to load patients to view
    loadPatients(params) {
      if (this.sending) return;

      this.selected = [];
      this.sending = true;

      return this.$store.dispatch(GET_PATIENTS, encodeQueryData(params))
        .finally(() => {
          this.sending = false;
        });
    },
    // @vuese
    // Used to bild patients query params
    buildPatientsParams() {
      return {
        per: this.perPage,
        orders: this.orders,
        filters: this.filters,
        page: this.currentPage,
        search: this.search
      };
    },
    // @vuese
    // Used to change per page in pagination and fetch info
    // @arg Per page value `Number`
    changePerPage(value) {
      this.perPage = value;
      this.currentPage = 1;
      this.loadPatients(this.buildPatientsParams());
    },
    // @vuese
    // Used to change page in pagination
    // @arg Page number
    changePage(value) {
      this.currentPage = value;
      this.loadPatients(this.buildPatientsParams());
    },
    // @vuese
    // Used to change order of products
    // @arg Param that show is what patients should be orderd
    changeOrder(type) {
      let { orders } = this;

      if (orders[type] && orders[type] == 'desc') {
        orders[type] = 'asc';
      } else if (orders[type] && orders[type] == 'asc') {
        orders[type] = 'desc';
      } else {
        orders = {};
        orders[type] = 'desc';
      }
      this.orders = orders;
      this.loadPatients(this.buildPatientsParams());
    },
    // @vuese
    // Used to filter by physicans
    filterPhysician(value) {
      this.currentPage = 1;
      if (value) this.filters.physician = value;
      else delete this.filters.physician;

      this.loadPatients(this.buildPatientsParams());
    },
    // @vuese
    // Used to filter by status
    filterStatus(status) {
      if (status) this.filters.status = status;
      else delete this.filters.status;
      this.currentPage = 1;

      this.loadPatients(this.buildPatientsParams());
    },
    // @vuese
    // Used to filter by search input
    filterSearch(value) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.search = value;
        this.currentPage = 1;
        this.loadPatients(this.buildPatientsParams());
      }, 500);
    },
    // @vuese
    // Used to load users after reassign
    patientsReassigned() {
      this.showReassignModal = false;
      this.loadPatients(this.buildPatientsParams());
    },
    // @vuese
    // Used to load patients after deletion
    patientsRemoved() {
      this.showRemoveModal = false;
      this.currentPage = 1;
      this.loadPatients(this.buildPatientsParams());
    },
    // @vuese
    // Used to get users by current selection and download CSV
    downloadOrdersCsv() {
      if (this.loadCSV) return;
      this.loadCSV = true;
      this.forceToolbarRerender();
      const queryData = this.buildPatientsParams();
      if (this.isRole('super_admin') && this.practiceId)
        queryData.filters.practice = this.practiceId;

      return PatientsService.listCsv(
        encodeQueryData(queryData)
      ).then((response) => {
        this.fileDownloader(response.data, this.buildFilename());
      }).finally(() => {
        this.loadCSV = false;
        this.forceToolbarRerender();
      });
    },
    // @vuese
    // Used to build CSV filename
    buildFilename() {
      return `patients-${(new Date).toDateString()}.csv`;
    },
    // @vuese
    // Used to get practice name for users when current user is super admin
    getPracticeName(patient) {
      let practiceName = '';
      if (patient.practice && patient.practice.name) {
        practiceName = patient.practice.name;
      } else if (Array.isArray(this.practices) && this.practiceId) {
        const practice = this.practices.find(p => p.id == this.practiceId);
        if (practice) practiceName = practice.name;
      }
      return practiceName;
    }
  }
};
</script>