import { ADD_STRIPE_PUBLIC_KEY } from './actions.type';
import { SET_STRIPE_PUBLIC_KEY } from './mutations.type';


const state = {
  stripePublicKey: ''
};

const getters = {
  stripePublicKey(state) {
    return state.stripePublicKey;
  }
};

const actions = {
  [ADD_STRIPE_PUBLIC_KEY]({ commit }, publicKey) {
    commit(SET_STRIPE_PUBLIC_KEY, publicKey);
  }
};

const mutations = {
  [SET_STRIPE_PUBLIC_KEY](state, publicKey) {
    state.stripePublicKey = publicKey;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
