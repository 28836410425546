import {
  SET_NOTIFICATION,
  REMOVE_NOTIFICATION
} from './actions.types';

import { 
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION
} from './mutations.types';
import { notification } from './initialObject';

const initialState = {
  notificationObj: {
    type: '',
    message: '',
  },
  notification: {...notification}
};

const state = Object.assign({}, initialState);

const getters = {
  notification(state) {
    return state.notificationObj;
  }
};

const actions = {
  [SET_NOTIFICATION]({commit}, notification) {
    commit(ADD_NOTIFICATION, notification);
  }, 
  [REMOVE_NOTIFICATION]({commit}) {
    commit(DELETE_NOTIFICATION);
  }
};

const mutations = {
  [ADD_NOTIFICATION](state, notification_data) {
    setTimeout(() => {
      let notification = Object.assign({}, notification_data, state.notification[notification_data.type !== undefined ? notification_data.type : 'error']);
      state.notificationObj = notification;
      window.scrollTo(0, 0);
    });
    // clearTimeout(notificationTimeout);

    // notificationTimeout = setTimeout(() => {
    //   state.notificationObj.message = '';
    // }, 5000)
  },
  [DELETE_NOTIFICATION](state) {
    state.notificationObj = {
      type: '',
      message: '',
    };
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};