import { PatientsService } from '../../services/api.service';
import {
  ADD_PATIENT,
  GET_PATIENTS,
  GET_PATIENT,
  UPDATE_PATIENT,
  REASSIGN_PATIENT,
  COMPLETE_REGISTRATION,
  UPDATE_PATIENT_ADDRESS,
  UPDATE_PATIENT_BILLING_ADDRESS,
  ADD_PATIENT_BILLING_ADDRESS,
  ADD_PATIENT_ADDRESS,
  REMOVE_PATIENTS,
  UPDATE_PHYSICIAN,
  ADD_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD,
  SEND_REGIMEN
} from './actions.types';
import { 
  SET_PATIENTS,
  SET_PATIENT,
  SORT_REGIMEN
} from './mutations.types';
import { AsYouType } from 'libphonenumber-js';
import { address } from './initialObject';

const initialState = {
  patients: [],
  total_items: 0,
  total_pages: 0,
};

const state = Object.assign({}, initialState);

const getters = {
  patients(state) {
    return {
      items: state.patients,
      total_items: state.total_items,
      total_pages: state.total_pages,
    };
  }

};

const actions = {
  [GET_PATIENTS](context, params) {
    return new Promise((resolve, reject) => {
      PatientsService.get(params)
        .then(resp => {
          context.commit(SET_PATIENTS, resp.data.patients);
          resolve(resp);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  [GET_PATIENT](context, params) {
    return new Promise((resolve, reject) => {
      PatientsService.getPatient(params)
        .then(resp => {
          const patient = resp.data.patient;
          if (patient.physician && patient.physician.id) 
            patient.user_id = patient.physician.id;
          
          if (patient.phone) 
            patient.phone = new AsYouType('US').input(patient.phone);
          
          if (!patient.shipping_address) 
            patient.shipping_address = {...address};

          if (!patient.billing_address)
            patient.billing_address = {...address};
          
          // context.commit(SORT_REGIMEN, resp.data.patient);
          
          resolve(resp);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  [ADD_PATIENT](context, data) {
    return new Promise((resolve, reject) => {
      PatientsService.create(data, data.practiceId)
        .then(resp => {
          context.commit(SET_PATIENT, resp.data.patient);
          resolve(resp);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  [UPDATE_PATIENT](context, data) {
    return new Promise((resolve, reject) => {
      PatientsService.update(data, data.id, data.practiceId)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  [REASSIGN_PATIENT](contenxt, data) {
    return new Promise((resolve, reject) => {
      PatientsService.reassignPatient(data.id, data)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  [COMPLETE_REGISTRATION](contenxt, data) {
    return new Promise((resolve, reject) => {
      PatientsService.compleateRegistration(data, data.id, data.practiceId)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  [ADD_PATIENT_ADDRESS](context, data) {
    return new Promise((resolve, reject) => {
      PatientsService.createAddress(data, data.id, data.practiceId)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  [UPDATE_PATIENT_ADDRESS](context, data) {
    return new Promise((resolve, reject) => {
      PatientsService.updateAddress(data, data.id, data.practiceId)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  [ADD_PATIENT_BILLING_ADDRESS](contenxt, data) {
    return new Promise((resolve, reject) => {
      PatientsService.createBillingAddress(data, data.id, data.practiceId)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  [UPDATE_PATIENT_BILLING_ADDRESS](context, data) {
    return new Promise((resolve, reject) => {
      PatientsService.updateBillingAddress(data, data.id, data.practiceId)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  [REMOVE_PATIENTS](context, params) {
    return new Promise((resolve, reject) => [
      PatientsService.removePatients(params)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err.response);
        })
    ]);
  },
  [UPDATE_PHYSICIAN](context, data) {
    return new Promise((resolve, reject) => {
      PatientsService.updatePhysician(data)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  [ADD_PAYMENT_METHOD](contenxt, params) {
    return new Promise((resolve, reject) => {
      PatientsService.createPaymentMethod(params.data, params.id, params.practiceId)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  [UPDATE_PAYMENT_METHOD](contenxt, params) {
    return new Promise((resolve, reject) => {
      PatientsService.updatePaymentMethod(params.data, params.id, params.practiceId)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  [SEND_REGIMEN](contenxt, params) {
    return new Promise((resolve, reject) => {
      PatientsService.sendRegimen(params.id, params.practiceId)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  }
};

const mutations = {
  [SET_PATIENTS](state, patientData) {
    state.patients = patientData.items.map(patient => {
      patient.selected = false;
      return patient;
    });
    state.total_items = patientData.total_items;
    state.total_pages = patientData.total_pages;
  },
  [SET_PATIENT](state, patient) {
    patient.selected = false;
    state.patients.unshift(patient);
  },
  [SORT_REGIMEN](state, patient){
    if(patient.regimen.products.length > 0){
      patient.regimen.products.sort(function(a, b){
        return a.position - b.position;
      });
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};