<template>
  <div class="bg-login">
    <div class="container">
      <Notifications />
      <div class="box-login">
        <ValidationObserver
          ref="observer"
          v-slot="{ invalid }"
          class="ogin-form validate-form"
          tag="form"
          @submit.prevent=""
        >
          <div class="logo-img">
            <img
              :src="logo"
              alt="Logo"
            >
          </div>
          <h2 class="text-center mb-3">
            Choose a New Password
          </h2>
          <div class="reset-input">
            <div class="box-input validate-input mb-0">
              <label>Password</label>
              <i
                :class="pass.pass_visible ? 'show-pass fas fa-eye' : 'show-pass fas fa-eye-slash'"
                @click="pass.pass_visible = !pass.pass_visible"
              />
              <input
                id="pass0"
                v-model="pass.password"
                placeholder="Password"
                :type="pass.pass_visible ? 'text' : 'password'"
                autocomplete="new-password"
                name="reset"
              >
            </div>

            <div class="char-content">
              <p :class="validatePass()['length'] ? 'char checked' : 'char no-checked' ">
                At least 8 characters
              </p>
              <p :class="validatePass()['specialChar'] ? 'char checked' : 'char no-checked' ">
                At least one special
                character (e.g. !, &amp;, #, etc.)
              </p>
              <p :class="validatePass()['haveNumber'] ? 'char checked' : 'char no-checked' ">
                At least one number
              </p>
            </div>
          </div>
          <div class="reset-input">
            <div class="box-input validate-input mb-0">
              <label>Confirm password</label>
              <i
                :class="pass.confirm_pass_visible ? 'show-pass fas fa-eye' : 'show-pass fas fa-eye-slash'"
                @click="pass.confirm_pass_visible = !pass.confirm_pass_visible"
              />
              <input
                id="confirm_pass"
                v-model="pass.confirm_password"
                placeholder="Confirm Password"
                :type="pass.confirm_pass_visible ? 'text' : 'password'"
                autocomplete="new-password"
                name="reset"
              >
            </div>
            <div class="char-content">
              <p :class="passEqual() ? 'char checked' : 'char no-checked' ">
                Password matches
              </p>
            </div>
          </div>
          <button 
            href="./login.html" 
            class="btn btn-primary"
            @click.prevent="action" 
          >
            Set New Password
          </button>
        </ValidationObserver>
      </div>
    </div>
    <ContactUs />
  </div>
</template>

<script>
import { ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import store from '../../store';
import logo from '../../../images/logo-primary.png';
import { LOGIN, RESET_PASSWORD } from '../../store/login/actions.types';
import ContactUs from '../components/ContactUs';

extend('required', required);

email['message'] = 'Please enter a valid email address';
extend('email', email);

// @group Login
export default {
  name: 'ResetPassword',
  components: {
    ContactUs,
    ValidationObserver
  },
  data() {
    return {
      logo: logo,
      pass: {
        pass_visible: false,
        confirm_pass_visible: false,
        edit: false,
        password: '',
        confirm_password: ''
      }
    };
  },
  methods: {
    // @vuese
    /// Used to validate form and send reset email to user
    async action(){
      let valid = this.validatePass();

      if(this.$route.query.reset_password_token != '' && valid.haveNumber && valid.length && valid.specialChar && this.passEqual() && this.pass.confirm_password != "" && this.pass.password != ""){
        store.dispatch(RESET_PASSWORD,{
          user: {
            reset_password_token: this.$route.query.reset_password_token,
            password: this.pass.password,
            password_confirmation: this.pass.confirm_password
          }
        })
          .then((resp) => {
            this.$store.dispatch(LOGIN, {
              user: {
                email: resp.data.user.email,
                password: this.pass.password,
              }
            })
              .then(resp => {
                this.$router.push({name: 'userdashboard'});
              });
          });
      }
    },
    // @vuese
    // Used to validate password
    validatePass() {
      return {
        length: this.pass.password.length >= 8,
        specialChar: /[^a-zA-Z0-9\-\/]/.test(this.pass.password),
        haveNumber: /[0-9]/g.test(this.pass.password)
      }
    },
    // @vuese
    // used to check if password is equal
    passEqual() {
      return this.pass.password != '' && this.pass.password === this.pass.confirm_password;
    }
  }
};
</script>