<template>
  <div
    class="filter-search"
    :class="classes"
  >
    <search-icon />
    <input
      :id="id"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('input', $event.target.value)"
    >
  </div>
</template>

<script>
import SearchIcon from './SearchIcon';
// @group Components
export default {
  name: 'SearchInput',
  components: {
    SearchIcon
  },

  props: {
    // @vuese
    // Input Value
    value: {
      type: String,
      default: ''
    },
    // @vuese
    // Element ID
    id: {
      type: String,
      default: 'search'
    },
    // @vuese
    // Input type
    type: {
      type: String,
      default: 'text'
    },
    // @vuese
    // Elemet name attr
    name: {
      type: String,
      default: 'search'
    },
    // @vuese
    // Elemet placeholder attr
    placeholder: {
      type: String,
      default: 'Search'
    },
    // @vuese
    // Elemet class attr
    classes: {
      type: String,
      default: ''
    }
  },
};
</script>
