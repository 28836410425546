<template>
  <header class="main-header">
    <div class="container container-md-lg">
      <div class="header-wrap wrap-lg">
        <div class="header-logo">
          <router-link 
            :to="{
              name: 'userdashboard'
            }"
          >
            <img 
              :src="logo" 
              alt="Logo" 
            >
          </router-link>
        </div>
        <div class="header-right">
          <div class="header-center">
            <p v-if="patient.name">
              Client Name:
              <strong>
                {{ patient.first_name }} {{ patient.last_name }}
              </strong>
            </p>
            <p v-if="patient.physician">
              Skincare Pro Name:
              <strong>{{ patient.physician.name }}</strong>
            </p>
          </div>
          <p class="text-soft pt-1">
            Please complete the following to set up your client's RegimenPro<sup>®</sup> account:
          </p>
        </div>
        <div class="header-right-link">
          <a 
            href="#" 
            @click.prevent="goToEdit"
          >Edit Client</a>
        </div>
        <div class="header-right-link">
          <a 
            href="#" 
            @click.prevent="goToLater"
          >Save Client for Later</a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import logo from '../../../../images/logo-light.png';

// @group Patinet
// Patient header
export default {
  name: 'PatientHeader',
  props: {
    // @vuese
    // Patient object
    patient: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      logo
    };
  },
  
  methods: {
    // @vuese
    // Used to go to patient page
    goToEdit() {
      document.patient = {
        goToEdit: true
      };
      this.$router.push({name: 'PatientView', params: { id: this.$route.params.id } });
    },

    // @vuese
    // Used to go to patient page
    goToLater() {
      document.patient = {
        goToLater: true
      };
      this.$router.push({name: 'Patients'});
    }
  }
};
</script>