<template>
  <div class="content-table">
    <table class="table orders-table table-box table-striped">
      <thead>
        <tr>
          <th 
            :class="getSortClass('created_at')"
            @click="setSorting('created_at')"
          >
            Order Placed
          </th>
          <th 
            :class="getSortClass('number')"
            @click="setSorting('number')"
          >
            Order Number
          </th>
          <th 
            :class="getSortClass('patient_name')"
            @click="setSorting('patient_name')"
          >
            Client
          </th>
          <th 
            :class="getSortClass('physician_name')"
            @click="setSorting('physician_name')"
          >
            Skincare Pro
          </th>
          <th 
            :class="getSortClass('total_price')"
            @click="setSorting('total_price')"
          >
            Total
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr 
          v-for="(order, index) in orders" 
          :key="index"
        >
          <td>
            <span class="text-bold" v-if="order.shopify">
              {{ order.shopify.created_at }}
            </span>
          </td>
          <td>
            <router-link
              v-if="order.status === 'completed' && order.shopify"
              class="link_action"
              :to="{
                name: 'ShowOrder',
                params: {
                  id: order.id
                }
              }"
            >
              #{{ order.shopify.order_number }}
            </router-link>
            <span v-else>-</span>
          </td>
          <td>
            <span v-if="order.patient">
              {{ order.patient.name }}
            </span>
          </td>
          <td>
            <span v-if="order.physician">
              {{ order.physician.name }}
            </span>
          </td>
          <td>
            <span>{{ order.total_price }}</span>
          </td>
          <td>
            <router-link
              class="link_action"
              :to="{
                name: 'ShowOrder',
                params: {
                  id: order.id
                }
              }"
            >
              <span
                v-if="order.status === 'completed' && order.shopify !== null"
              >
                View
              </span>
              <span
                v-else-if="order.status === 'failed'"
              >
                Failed
              </span>
              <div
                v-else
                class="head-tooltip"
              >
                <span 
                  @mouseover="hover(`order-view-${order.id}`)" 
                  @mouseleave="hoverOff(`order-view-${order.id}`)"
                >
                  Confirmed
                  <i class="fas fa-question-circle fa-xs" />
                </span>
                <div 
                  :data-tooltip-type="`order-view-${order.id}`" 
                  class="tooltip-black order-tooltip"
                >
                  Order details will be available once the manufacturer receives the order. Any additional orders placed today for this client will show under the same order number.
                </div>
              </div>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- /. Recent Orders -->
</template>

<script>
import { mapGetters } from 'vuex';
import { encodeQueryData } from '@/helper';
import { OrdersService } from '@/services/api.service';

// Component used to show recent orders from company
// @group Dashboard main
export default {
  name: 'RecentOrders',
  data() {
    return {
      sending: false,
      pagination: {
        totalPages: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 10
      },
      filteringParams: {
        filters: {}
      },
      sortingParams: {
        orders: {
          created_at: 'desc'
        }
      },
      orders: []
    };
  },


  computed: {
    ...mapGetters(['currentUser', 'practice'])
  },

  watch: {
    sortingParams() {
      this.fetchOrders();
    }
  },

  beforeMount() {
    this.fetchOrders();
  },

  methods: {
    // @vuese 
    // Used to get orders
    fetchOrders() {
      if (this.sending) return;

      this.sending = true;

      return OrdersService.list(
        this.orderParams()
      ).then(this.setOrders)
        .finally(() => this.sending = false);
    },

    // @vuese 
    // Used to prepare query params
    orderParams() {
      return encodeQueryData(
        this.buildFilterObject()
      );
    },

    // @vuese 
    // Used to create query param object
    buildFilterObject() {
      return {
        ...this.filteringParams,
        ...this.sortingParams,
        page: this.pagination.currentPage,
        per: this.pagination.perPage
      };
    },

    // @vuese 
    // Used to set order to state
    setOrders({ data }) {
      this.orders = data.orders.items;
      this.pagination.totalPages = data.orders.total_pages;
      this.pagination.totalRows = data.orders.total_items;
    },

    // @vuese 
    // Used to sort orders
    setSorting(by, direction= 'desc') {
      if (this.sortingParams['orders'][by] && this.sortingParams['orders'][by] == 'desc')
        direction = 'asc';

      this.sortingParams['orders'] = { [`${by}`]: direction };
      this.fetchOrders();
    },

    // @vuese
    // Used to show tooltip
    // @arg HTML element data type
    hover(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.add('open');
    },

    // @vuese
    // Used to hide tooltip
    // @arg HTML element data type
    hoverOff(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.remove('open');
    },

    // @vuese
    // Used to get order table tab class
    getSortClass(by) {
      return this.sortingParams['orders'][by] ? `sort-${this.sortingParams['orders'][by]}` : 'sortable';
    }
  }
};
</script>