import Vue from 'vue';
import Vuex from 'vuex';

import app from './app';
import login from './login';
import practice from './practice';
import patients from './patients';
import notifications from './notifications';
import regimen from './regimen';
import topPicks from './top-picks';

Vue.use(Vuex);

let store = new Vuex.Store({
  modules: {
    app,
    login,
    practice,
    patients,
    notifications,
    regimen,
    topPicks,
  }
});

export default store;
