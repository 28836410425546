import { mapGetters } from 'vuex';
import {
  ADD_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD
} from '@/store/patients/actions.types';
import notifications from './notifications';

export default {
  mixins: [notifications],
  computed: {
    ...mapGetters([
      'stripePublicKey'
    ])
  },
  data() {
    return {
      cardArgs: {},
      stripe: null,
      card: null,
      elements: null,
      paymentId: null,
      userCard: null,
      shouldCreateToken: false,
      cardError: '',
      cardExpiry: '',
      cardCvc: '',
      style: {
        base: {
          fontSize: '17px',
          color: '#4a4a4a',
        },
        invalid: {
          color: '#e5424d',
          ':focus': {
            color: '#303238',
          },
        },
      },
    };
  },

  watch: {
    patient: {
      immediate: true,
      deep: true,
      handler() {
        if(this.patient && this.patient.payment_method !== null) {
          this.cardArgs = {
            cardPlaceholder: `**** **** **** ${this.patient.payment_method ? 
              this.patient.payment_method.card_number : '****'}`,
            datePlaceholder: this.patient.payment_method ? this.patient.payment_method.exp_date : 'MM / YY',
            cvcPlaceholder: this.patient.payment_method ? '***' : 'CVC',
          };
        }
        // this.$nextTick(() => {
        //   this.initStripe();
        // });
      }
    }
  },

  methods: {
    // @vuese
    // Used to init stripe and stripe elements
    initStripe() {
      this.stripe = new Stripe(this.stripePublicKey);
      this.elements = this.stripe.elements();

      this.card = this.elements.create('cardNumber', { 
        style: this.style,
        placeholder: this.cardArgs.cardPlaceholder ? this.cardArgs.cardPlaceholder : '1234 1234 1234 1234',
      });
      this.cardExpiry = this.elements.create('cardExpiry', { 
        style: this.style,
        placeholder: this.cardArgs.datePlaceholder ? this.cardArgs.datePlaceholder : 'MM / YY',
      });
      this.cardCvc = this.elements.create('cardCvc', { 
        style: this.style,
        placeholder: this.cardArgs.cvcPlaceholder ? this.cardArgs.cvcPlaceholder : 'CVC',
      });

      this.card.mount('#card-element');
      this.cardExpiry.mount('#card-expiry-element');
      this.cardCvc.mount('#card-cvc-element');

      this.card.on('change', event => {
        // if not empty create card
        this.shouldCreateToken = !event.empty;
      });
    },
    // @vuese
    // Used to create stripe token
    createToken() {
      return this.stripe.createToken(this.card)
        .then( result => {
          if(result.error) {
            this.flashError(result.error.message);
            this.cardError = true;
            return false;
          }
          this.stripeToken = result.token.id;
        })
        .catch(err => {
          console.log(err);
          this.cardError = true;
        });
    },
    // @vuese
    // Used to create payment method
    createPaymentMethod() {
      this.cardError = false;
      return this.stripe.createPaymentMethod('card', this.card)
        .then(result => {
          if (!result.paymentMethod) {
            this.cardError = true;
            return Promise.reject({data: result.error});
          }
          this.paymentId = result.paymentMethod.id;
          this.userCard = result.paymentMethod.card;
        })
        .catch(err => {
          if(err.data && err.data.message) {
            this.flashError(err.data.message);
          } else if(err.response && err.response.data) {
            this.flashError(JSON.stringify(err.response.data));
          } else {
            this.flashError('Error happened, please fix it.');
          }
          this.cardError = true;
        });
    },
    //  @vuese
    // Used to save patient stripe token
    // @arg Patient ID
    async saveToken(patientId) {
      let handler = '';
      if (this.patient.payment_method) 
        handler = UPDATE_PAYMENT_METHOD;
      else 
        handler = ADD_PAYMENT_METHOD;

      return await this.$store.dispatch(handler, {
        id: patientId,
        data: {
          payment_id: this.paymentId,
          card: this.userCard,
        },
        practiceId: this.getRequestPracticeId()
      });
    },
    // @vuese
    // Used to get practice ID for super admin user
    getRequestPracticeId() {
      return this.isRole('super_admin') ? `/${this.patient.practice.id}` : '';
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (document.getElementById('card-element'))
        this.initStripe();
    });
  }
};
