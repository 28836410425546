const TOKEN_KEY = 'jwt_token';
const TOKEN_KEY_INV = 'inv_token';

export default {};
export const getToken = () => {
  return window.localStorage.getItem(TOKEN_KEY);
};
export const saveToken = (token) => {
  window.localStorage.setItem(TOKEN_KEY, token);
};
export const destroyToken = () => {
  window.localStorage.removeItem(TOKEN_KEY);
};

export const getInvToken = () => {
  return window.localStorage.getItem(TOKEN_KEY_INV);
};
export const saveInvToken = (token) => {
  window.localStorage.setItem(TOKEN_KEY_INV, token);
};
export const destroyInvToken = () => {
  window.localStorage.removeItem(TOKEN_KEY_INV);
};