<template>
  <router-view 
    :regimen="regimen" 
  />
</template>

<script>
import { GET_REGIMEN } from '@/store/regimen/actions.types';

// @group Preset Regimen
export default {
  name: 'Regimen',
  data() {
    return {
      regimen: {
        name: '',
        description: '',
        tags: [],
        products: [],
        regimen_type: 'preset'
      },
    };
  },
  mounted() {
    this.fetchRegimen();
  },
  methods: {
    // @vuese
    // Used to fetch regimen
    fetchRegimen() {
      const id = this.$route.params.id;
      if (!id) return;

      this.$store
        .dispatch(GET_REGIMEN, id)
        .then(resp => {
          this.regimen = resp.data.regimen;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>