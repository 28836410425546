import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'currentUser',
    ])
  },
  methods: {
    // @vuese
    // Used ti check user role
    isRole(roleType) {
      return this.currentUser && this.currentUser.roles && this.currentUser.roles.includes(roleType);
    }
  }
};