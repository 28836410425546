<template>
  <div>
    <div
      class="row no-gutters justify-content-lg-end"
    >
      <div class="filter-group my-1">
        <label for="filter_data">Filter Data:</label>
        <custom-select
          v-model="filterDate"
          :items="filterDateOption"
          class="select-xs"
        />
      </div>
      <div class="filter-group my-1">
        <label for="compare_to">Compare To:</label>
        <custom-select 
          v-model="compareToDate"
          :items="compareToDateOption"
          class="select-xs"
        />
      </div>
      <div class="filter-group my-1">
        <label for="filter_view">View:</label>
        <select-physican
          v-model="physician"
          title="Entire Practice"
          :items="valuesForSelect(physicians)"
          :total="physiciansTotal"
          :loading="isPhysiciansLoading"
          :name="'order-placed-physician-select'"
          :filters="filters"
          @load-more="loadMorePhysicians"
          @click.native="patinetClick"
        />
      </div>
    </div>
    <!-- /. Filters box -->
    <h4>Key Metrics</h4>
    <div
      class="d-box with-pad"
    >
      <div class="row metrics-grid">
        <div class="metric-box col-md-3 col-sm-6 py-md-2 py-3">
          <p class="mb-lg-3 mb-2">
            New Clients
          </p>
          <div class="metric-card">
            <p class="stat-num">
              {{ patients.new_current_period }}
            </p>
            <p>Clients Added</p>
            <p class="percent">
              <span 
                :class="getReportDeltaClass(patients.patinets_added)"
              >
                {{ patients.patinets_added }}%
              </span>
            </p>
          </div>
        </div>
        <div class="metric-box col-md-3 col-sm-6 py-md-2 py-3">
          <p class="mb-lg-3 mb-2">
            New Orders Without Subscription
          </p>
          <div class="metric-card">
            <div class="two-cards">
              <div class="metric-card">
                <p class="stat-num">
                  {{ orders.new_orders_without_subscription.placed_current_period }}
                </p>
                <p>Orders Placed</p>
                <p class="percent">
                  <span
                    :class="getReportDeltaClass(orders.new_orders_without_subscription.placed_delta)"
                  >
                    {{ orders.new_orders_without_subscription.placed_delta }}%
                  </span>
                </p>
              </div>
              <div class="metric-card">
                <p class="stat-num">
                  ${{ orders.new_orders_without_subscription.average_current_period }}
                </p>
                <p>Average Order Value</p>
                <p class="percent">
                  <span
                    :class="getReportDeltaClass(orders.new_orders_without_subscription.average_delta)"
                  >
                    {{ orders.new_orders_without_subscription.average_delta }}%
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="metric-box col-md-3 col-sm-6 py-md-2 py-3">
          <p class="mb-lg-3 mb-2">
            New Orders With Subscription
          </p>
          <div class="metric-card">
            <div class="two-cards">
              <div class="metric-card">
                <p class="stat-num">
                  {{ orders.new_orders_with_subscription.placed_current_period }}
                </p>
                <p>Orders Placed</p>
                <p class="percent">
                  <span
                    :class="getReportDeltaClass(orders.new_orders_with_subscription.placed_delta)"
                  >
                    {{ orders.new_orders_with_subscription.placed_delta }}%
                  </span>
                </p>
              </div>
              <div class="metric-card">
                <p class="stat-num">
                  ${{ orders.new_orders_with_subscription.average_current_period }}
                </p>
                <p>Average Order Value</p>
                <p class="percent">
                  <span
                    :class="getReportDeltaClass(orders.new_orders_with_subscription.average_delta)"
                  >
                    {{ orders.new_orders_with_subscription.average_delta }}%
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="metric-box col-md-3 col-sm-6 py-md-2 py-3">
          <p class="mb-lg-3 mb-2">
            Repeat Orders With Subscription
          </p>
          <div class="metric-card">
            <div class="two-cards">
              <div class="metric-card">
                <p class="stat-num">
                  {{ orders.repeat_orders_from_subscriptions.placed_current_period }}
                </p>
                <p>Orders Placed</p>
                <p class="percent">
                  <span
                    :class="getReportDeltaClass(orders.repeat_orders_from_subscriptions.placed_delta)"
                  >
                    {{ orders.repeat_orders_from_subscriptions.placed_delta }}%
                  </span>
                </p>
              </div>
              <div class="metric-card">
                <p class="stat-num">
                  ${{ orders.repeat_orders_from_subscriptions.average_current_period }}
                </p>
                <p>Average Order Value</p>
                <p class="percent">
                  <span
                    :class="getReportDeltaClass(orders.repeat_orders_from_subscriptions.average_delta)"
                  >
                    {{ orders.repeat_orders_from_subscriptions.average_delta }}%
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /. metric-grid -->
    </div>
  </div>
</template>

<script>
import { encodeQueryData, calcDelta, getReportDeltaClass } from '@/helper';
import CustomSelect from '@/views/components/CustomSelect';
import { ReportService } from '@/services/api.service';
import dateOptions from '@/mixins/dateOptions';
import SelectPhysican from '@/views/components/Select';
import physician from '@/mixins/physician';

// Compoent that show current practice reports on Dashboard page
// Includes: New patients, New Orders Without Subscription,
// New Orders With Subscription and Repeated Orders With Subscription
// @group Dashboard main
export default {
  name: 'DashboardKeyMetrics',

  components: {
    CustomSelect,
    SelectPhysican
  },

  mixins: [dateOptions, physician],

  props: {
    // Practice creted date
    practiceCreatedAt: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      filterDate: '',
      filterDateOption: [],
      compareToDate: '',
      compareToDateOption: [],
      physician: '',
      loadOnInit: false,
      physiciansLoaded: false,
      patients: {
        new_current_period: 0,
        new_previous_period: 0
      },
      orders: {
        new_orders_with_subscription: {
          average_current_period: 0,
          average_previous_period: 0,
          average_delta: 0,
          placed_current_period: 0,
          placed_previous_period: 0,
          placed_delta: 0
        },
        new_orders_without_subscription: {
          average_current_period: 0,
          average_previous_period: 0,
          average_delta: 0,
          placed_current_period: 0,
          placed_previous_period: 0,
          placed_delta: 0
        },
        repeat_orders_from_subscriptions: {
          average_current_period: 0,
          average_previous_period: 0,
          average_delta: 0,
          placed_current_period: 0,
          placed_previous_period: 0,
          placed_delta: 0
        }
      }
    };
  },

  watch: {
    physician() {
      this.getInfo();
      this.getPatientsInfo();
    },

    filterDate: {
      handler() {
        if (this.filterDate && this.compareToDate) {
          this.getInfo();
          this.getPatientsInfo();
        }
      }
    },

    compareToDate: {
      handler() {
        this.getInfo();
        this.getPatientsInfo();
      }
    },

    practiceCreatedAt: {
      immediate: true,
      handler() {
        if (!this.practiceCreatedAt) return;
        const dataOption = this.buildMonthOptions(this.practiceCreatedAt);
        this.filterDateOption = dataOption;
        this.compareToDateOption = dataOption;

        this.filterDate = this.filterDateOption[this.filterDateOption.length - 1].value.map((el) => {
          return el.toString();
        }).join(',');

        setTimeout(() => {
          this.compareToDate = this.compareToDateOption[0].value.map((el) => {
            return el.toString();
          }).join(',');
        });
      }
    }
  },

  methods: {
    // @vuese
    // Change object to GET query string
    // @arg query object
    encodeQueryData,
    // @vuese
    // Used to calculate delta
    // @arg Current perriod value
    // @arg / Previous period value
    calcDelta,
    // @vuese
    // Used to get report minus or plus class, based on delta value
    // @arg Delta value
    getReportDeltaClass,

    // @vuese
    // Get Order placed info
    getInfo() {
      ReportService.getOrdersPlaced(this.buildQueryObj())
        .then(resp => {
          this.orders = resp.data.orders_placed;
          this.addDelta();
        });
    },

    // @vuese
    // Get patients reports data
    getPatientsInfo() {
      ReportService.getPatientsInfo(this.buildQueryObj())
        .then(resp => {
          if (resp.data.patients) {
            this.patients = resp.data.patients;
            this.patients.patinets_added = this.calcDelta(
              resp.data.patients.new_current_period,
              resp.data.patients.new_previous_period
            );
          }
        });
    },

    // @vuese
    // Calculate delta value for reports
    addDelta() {
      this.orders.new_orders_with_subscription.placed_delta = this.calcDelta(
        this.orders.new_orders_with_subscription.placed_current_period,
        this.orders.new_orders_with_subscription.placed_previous_period
      );

      this.orders.new_orders_with_subscription.average_delta = this.calcDelta(
        this.orders.new_orders_with_subscription.average_current_period,
        this.orders.new_orders_with_subscription.average_previous_period
      );

      this.orders.new_orders_without_subscription.placed_delta = this.calcDelta(
        this.orders.new_orders_without_subscription.placed_current_period,
        this.orders.new_orders_without_subscription.placed_previous_period
      );

      this.orders.new_orders_without_subscription.average_delta = this.calcDelta(
        this.orders.new_orders_without_subscription.average_current_period,
        this.orders.new_orders_without_subscription.average_previous_period
      );

      this.orders.repeat_orders_from_subscriptions.placed_delta = this.calcDelta(
        this.orders.repeat_orders_from_subscriptions.placed_current_period,
        this.orders.repeat_orders_from_subscriptions.placed_previous_period
      );

      this.orders.repeat_orders_from_subscriptions.average_delta = this.calcDelta(
        this.orders.repeat_orders_from_subscriptions.average_current_period,
        this.orders.repeat_orders_from_subscriptions.average_previous_period
      );

      this.orders.canceled_subscriptions.delta = this.calcDelta(
        this.orders.canceled_subscriptions.current_period,
        this.orders.canceled_subscriptions.previous_period
      );
    },

    // @vuese
    // Convert object to query string
    buildQueryObj() {
      return this.encodeQueryData({
        filters: {
          current_period: this.filterDate.split(',').map(dateItem => {
            return new Date(dateItem).toISOString();
          }),
          previous_period: this.compareToDate.split(',').map(dateItem => {
            return new Date(dateItem).toISOString();
          }),
          physician: this.physician,
        }
      });
    },
    // @vuese
    // Load physican only on click
    patinetClick() {
      if (!this.physiciansLoaded) {
        this.loadMorePhysicians({type: 'search'});
        this.physiciansLoaded = true;
      }
    }
  }
};
</script>