<template>
  <div class="top-filters">
    <div class="filter-tags">
      <span>All Clients</span>
    </div>
    <div class="row">
      <div class="col-md-6 filter-l">
        <div class="filter-search">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            xmlns:xlink="http://www.w3.org/1999/xlink" 
            width="29" 
            height="29" 
            viewBox="0 0 29 29"
          >
            <metadata><?xpacket begin=" " id="W5M0MpCehiHzreSzNTczkc9d"?>
              <x:xmpmeta 
                xmlns:x="adobe:ns:meta/" 
                x:xmptk="Adobe XMP Core 5.6-c140 79.160451, 2017/05/06-01:08:21"
              >
                <rdf:RDF xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                  <rdf:Description rdf:about />
                </rdf:RDF>
              </x:xmpmeta><?xpacket end="w"?>
            </metadata>
            <image 
              width="29" 
              height="29" 
              xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAdCAQAAAD8mq+EAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfjCQIKKCCQuWLCAAACVUlEQVQ4y53UTWgTQRQH8H/GJoYkTWOJMY1axESL4heFUAl4kapBSsCIevASqgcR8eBBrYhSRIIeBEFBkGAvHopECSoJRDyZitUiWlD6EaSUNsZYNWnz1bh5HkbJbnazEGcub97Ob/ftzsxqCNL2y/Jy74edafuSyfbNMbfnlXuUVaHcSNSnXceGWyqSFNnSt86V9AR5F4U3z2srUJpDzuTHHfK0hhdMrD80FOB1dE32PXNNtS6lbaM9UW/ODADGQvjwgZhiwQNBHmz5HPWK77xoujq4sgwCGfPj2xQKjnn5yBdZNMkLG/GszoBAm6bKujoqsO3jIJAnofw5CAkPf/Lts3U04gOBtJXkRmVIIFy+BgJ1pKpMQgMPQKCT9xtDQs5szINAI55ajgHx/QBw5BFUWmvOGwOAeG8tx4il7ADgfgfV5n4LALPrRVRgVQZof6/6oU7XfAWAtF1E+Q6ttBQN6pRvDUNBQttyADDtUqeTXQDgmBdRwD0KAE/71OnzgwDgSUg24p0zINC62aK+8eKE/SCQvrjQLlnXjNWcBYEGgo3gT4szCQL1h2R7+PolPhoKKMG8YV+cH4AvG2S0pO95w8cXbhQMUvhpa/cYD0/fVTzq847OGR6unRu8MtadNQssZY/4jj9cIfybq62E/Yp/iZR992vxTTWimJHlL37sV6CEkj54sS0rf9ld7xOeic0dqXpcN2+h/d4pb9Sa0RCICc7kidCLXn5FjhssR1n33Sowaa4eq5xReZfipqgUN0lr+Ohw05TjQ0+Wdf9BCTOdyzrCHxW1lyJMyp75AAAAAElFTkSuQmCC"
            />
          </svg>
          <input
            id="search"
            v-model="search"
            type="text"
            placeholder="Search"
            name="search"
            @input="filterSearch"
          >
        </div>
      </div>
      <div class="col-md-6 filter-r">
        <label for="physicians">FILTER BY</label>
        <select-physican
          v-model="physiciansVal"
          title="All Skincare Pros"
          :items="valuesForSelect(physicians)"
          :total="physiciansTotal"
          :loading="isPhysiciansLoading"
          :name="'physician-select'"
          @load-more="loadMorePhysicians"
        />
        <select 
          id="status"
          v-model="status"
          class="select-md"
          name="filter-view"
          @change="filterStatus"
        >
          <option 
            value 
            selected
          >
            All Statuses
          </option>
          <option 
            value="pending"
          >
            Pending
          </option>
          <option 
            value="completed"
          >
            Completed
          </option>
        </select>
      </div>
    </div>
  </div>
  <!-- /. top-filters -->
</template>


<script>
import { mapGetters } from 'vuex';
import physician from '@/mixins/physician';
import SelectPhysican from '@/views/components/Select';

// Component used to filter by patients
// @group Patinets
export default {
  name: 'PatientsFilter',
  components: {
    SelectPhysican
  },
  mixins: [physician],
  data() {
    return {
      selected: [],
      physiciansVal: '',
      status: '',
      search: '',
    };
  },
  computed: {
    ...mapGetters([
      'patients',
    ])
  },

  watch: {
    physiciansVal() {
    // @vuese
    // Emit physican value and trigger search in parent component
      this.$emit('filter-physician', this.physiciansVal);
    }
  },

  activated() {
    if (this.search) {
      this.search = '';
      this.filterSearch();
    }
  },

  mounted() {
    this.$emit('filter-physician', this.physiciansVal);
  },
  methods: {
    filterStatus() {
      // Emit status value and trigger search in parent component
      this.$emit('filter-status', this.status);
    },
    filterSearch() {
      // Emit search value and trigger search in parent component
      this.$emit('filter-search', this.search);
    },
  }
};
</script>