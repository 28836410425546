import { SET_NOTIFICATION, REMOVE_NOTIFICATION } from '@/store/notifications/actions.types';
import { notification } from '@/store/notifications/initialObject';

export default {

  methods: {
    // @vuese
    // Used to show error notification
    // @arg Message
    flashError(message) {
      return this.$store.dispatch(SET_NOTIFICATION, {
        message: message,
        type: notification.error.type,
      });
    },

    // @vuese
    // Used to show warning notification
    // @arg Message
    flashWarning(message) {
      return this.$store.dispatch(SET_NOTIFICATION, {
        message: message,
        type: notification.warning.type,
      });
    },

    // @vuese
    // Used to show successs notification
    // @arg Message
    flashSuccess(message) {
      return this.$store.dispatch(SET_NOTIFICATION, {
        message: message,
        type: notification.success.type,
      });
    },

    // @vuese
    // Used to remove notification
    removeNotification() {
      return this.$store.dispatch(REMOVE_NOTIFICATION);
    }
  }
};