<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap mb-1 pb-2">
      <h4>Royalties Overview</h4>
      <div class="filter-group">
        <label for="filter_data">View:</label>
        <select-physican
          v-model="physician"
          title="Entire Practice"
          :items="valuesForSelect(physicians)"
          :total="physiciansTotal"
          :loading="isPhysiciansLoading"
          :name="'кoyalties-щverview-physician-select'"
          :filters="filters"
          @load-more="loadMorePhysicians"
          @click.native="patinetClick"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-sm-6 mb-4">
        <div class="d-box with-pad mb-0 h-100">
          <div class="metrics-grid">
            <p>Month to Date Royalties</p>
            <div class="metric-card">
              <p class="stat-num">
                {{ royalties.current_month_value }}
              </p>
              <p class="text-soft">
                MTD
              </p>
            </div>
            <p class="mb-0">
              Display Sales from {{ date.format('MMM') }} 1-present
            </p>
          </div>
        </div>
      </div>
      <!-- /. card 1 -->
      <div class="col-md-4 col-sm-6 mb-4">
        <div class="d-box with-pad mb-0 h-100">
          <div class="metrics-grid">
            <p>Year to Date Royalties</p>
            <div class="metric-card">
              <p class="stat-num">
                {{ royalties.current_year_value }}
              </p>
              <p class="text-soft">
                YTD
              </p>
            </div>
            <p class="mb-0">
              Display Sales from Jan 1-present
            </p>
          </div>
        </div>
      </div>
      <!-- /. card 2 -->
      <div class="col-md-4 col-sm-6 mb-4">
        <div class="d-box with-pad mb-0 h-100">
          <div class="metrics-grid">
            <p>All Time Royalties</p>
            <div class="metric-card">
              <p class="stat-num">
                {{ royalties.all_time }}
              </p>
              <p class="text-soft">
                Total
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- /. card 3 -->
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { encodeQueryData, getReportDeltaClass } from '@/helper';
import { ReportService } from '@/services/api.service';
import SelectPhysican from '@/views/components/Select';
import physician from '@/mixins/physician';

// @group Reports
export default {
  name: 'RoyaltiesOverview',

  components: {
    SelectPhysican
  },

  mixins: [physician],

  data() {
    return {
      physician: '',
      date: moment(),
      loadOnInit: false,
      physiciansLoaded: false,
      royalties: {
        all_time: '$0.00',
        current_month_value: '$0.00',
        current_year_value: '$0.00',
      }
    };
  },

  watch: {
    physician: {
      immediate: true,
      handler() {
        this.getInfo();
      }
    }
  },

  methods: {
    // @vuese
    // Used to convert object to get string
    // @arg Query object
    encodeQueryData,
    // @vuese
    // Used to get report minus or plus class, based on delta value
    // @arg Delta value
    getReportDeltaClass,

    // @vuese
    // Used to get royalties overview data
    getInfo() {
      ReportService.getRoyaltiesOverview(this.buildQueryObj())
        .then(resp => {
          this.royalties = resp.data.royalties;
        });
    },

    // @vuese
    // Convert object to query string
    buildQueryObj() {
      return this.encodeQueryData({
        filters: {
          physician: this.physician,
        }
      });
    },

    // @vuese
    // Load physican only on click
    patinetClick() {
      if (!this.physiciansLoaded) {
        this.loadMorePhysicians({type: 'search'});
        this.physiciansLoaded = true;
      }
    }
  }
};
</script>