<template>
  <div>
    <button 
      type="button" 
      class="close" 
      data-dismiss="modal" 
      aria-label="Close"
      @click="hideModal"
    >
      <i 
        class="fa fa-times" 
        aria-hidden="true" 
      />
    </button>
    <h4 class="modal-title text-center">
      Reassign Client(s)
    </h4>
    <div
      v-if="isRole('super_admin') && superAdminWarning"
      class="notification message warning mt-2"
    >
      <p class="icon">
        {{ superAdminWarning }}
      </p>
    </div>
    <p class="text-center">
      Select a skincare pro in your practice to reassign your {{ selected.length }} clients.
    </p>
    <div class="form-group">
      <label for="patients">Reassign Client(s)</label>
      <select-physican
        v-model="physician"
        title="Select from other practice physicians"
        :items="valuesForSelect(physicians)"
        :total="physiciansTotal"
        :loading="isPhysiciansLoading"
        :name="'reaisign-patinet-select'"
        :filters="filters"
        @load-more="loadMorePhysicians"
      />
      <p 
        v-if="physicianError" 
        class="validation error mt-2"
      >
        {{ physicianError }}
      </p>
    </div>
    <div class="pt-sm-4">
      <a 
        :class="sending ? `btn btn-primary btn-cta dissabled` : `btn btn-primary btn-cta`"
        @click="reassignPhysician" 
      >Reassign</a>
    </div>
  </div>
</template>


<style lang="scss">
  #reassign_patients .content-down {
    width: 100%;
    margin-right: 0;
    .title {
      width: 100%;
    }
    .multi-select {
      width: 100%;
    }
    .multi-select {
      .multi-select-list {
        height: 80px;
      }
    }
    input[type="text"] {
      width: 100%;
    }
  }
</style>


<script>
import { UPDATE_PHYSICIAN } from '../../../store/patients/actions.types';
import physician from '@/mixins/physician';
import SelectPhysican from '@/views/components/Select';

// Used to reassign patients to diffrent physican
// @group Patinets
export default {
  name: 'Reasign',
  components: {
    SelectPhysican
  },
  mixins: [physician],
  props: {
    // Array of selected users
    selected: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      sending: true,
      physician: '',
      physicianError: '',
    };
  },
  watch: {
    selected: {
      immediate: true,
      handler() {
        if (this.isRole('super_admin') && 
          this.selected.length && 
          !this.isUsersFromSamePractice()
        ) {
          this.superAdminWarning = 'You are about to reassign clients that are from different practices all to the same practice';
        } else {
          this.superAdminWarning = '';
        }
      }
    }
  },
  methods: {
    // @vuese
    // Used to close modal
    hideModal() {
      // Hide modal
      this.$emit('hide-modal-window');
    },
    // @vuese
    // Used to check if physican selected
    isValid() {
      let valid = true;
      if (!this.physician) {
        valid = false;
        this.physicianError = 'Please select a physician';
      } else {
        this.physicianError = '';
      }
      return valid;
    },
    // @vuese
    // Used to check if users from same practice
    isUsersFromSamePractice() {
      let practiceId = this.selected[0].practice ? this.selected[0].practice.id : null;
      return this.selected.every(patient => {
        let patientPracticeId = patient.practice ? patient.practice.id : null;
        return practiceId == patientPracticeId;
      });
    },
    // @vuese
    // Used to send request to reassign physican
    reassignPhysician() {
      if (!this.isValid()) return;
      this.sending = true;
      this.$store.dispatch(UPDATE_PHYSICIAN, {
        patients: {
          ids: this.selected.map(patient => patient.id),
          user_id: this.physician
        }
      })
        .then(() => {
          this.flashSuccess(`${this.selected.length} clients have been reassigned`);
        })
        .catch(() => {
          this.flashError('Error happend. Please reload and try again.');
        })
        .finally(() => {
          // Tell parent component that we done with reassigning patients
          this.$emit('patiens-reassigned');
          this.sending = false;
        });
    }
  }
};
</script>