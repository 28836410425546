<template>
  <div class="bg-login set-pwd">
    <div class="container">
      <Notifications />
      <div class="row no-gutters">
        <div class="dark-left col-md-5">
          <div class="container-detail-pwd">
            <div class="logo-img">
              <img
                :src="logo"
                alt="Logo"
              >
            </div>
            <div class="content-dsc-set-pwd">
              <p v-if="!isRole(user, 'super_admin')">
                Hi <strong>{{ user.first_name }}</strong>, welcome to RegimenMD! You've been invited to join your practice's account.
              </p>
              <p
                v-else
              >
                You’ve been invited to join RegimenMD
              </p>
              <p v-if="!isRole(user, 'super_admin')">
                Name<br>{{ user.first_name }} {{ user.last_name }}
              </p>
              <p v-if="!isRole(user, 'super_admin')">
                Email Address<br>{{ user.email }}
              </p>
              <p v-if="!isRole(user, 'super_admin')">
                Practice Name<br>{{ practice.name }}
              </p>
              <p
                v-if="!isRole(user, 'super_admin')"
                class="mb-0"
              >
                Account Type
                <br>
                <span v-if="isRole(user, 'practice_admin')">Practice Admin</span>
                <span v-if="isRole(user, 'physician')">Physician</span>
                <span v-if="isRole(user, 'staff')">Staff</span>
                <span v-if="isRole(user, 'super_admin')">Super Admin</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div class="box-login">
            <ValidationObserver
              ref="observer"
              v-slot="{ invalid }"
              class="reset-form validate-form"
              tag="form"
            >
              <h2 class="mb-3 text-center">
                Set your password
              </h2>

              <div class="reset-input">
                <div class="box-input validate-input mb-0">
                  <label>Password</label>
                  <i
                    :class="pass.pass_visible ? 'show-pass fas fa-eye' : 'show-pass fas fa-eye-slash'"
                    @click="pass.pass_visible = !pass.pass_visible"
                  />
                  <input
                    id="pass0"
                    v-model="pass.password"
                    placeholder="Password"
                    :type="pass.pass_visible ? 'text' : 'password'"
                    autocomplete="set-password"
                    name="reset"
                  >
                </div>

                <div class="char-content">
                  <p :class="validatePass()['length'] ? 'char checked' : 'char no-checked' ">
                    At least 8 characters
                  </p>
                  <p :class="validatePass()['specialChar'] ? 'char checked' : 'char no-checked' ">
                    At least one special
                    character (e.g. !, &amp;, #, etc.)
                  </p>
                  <p :class="validatePass()['haveNumber'] ? 'char checked' : 'char no-checked' ">
                    At least one number
                  </p>
                </div>
              </div>
              <div class="reset-input">
                <div class="box-input validate-input mb-0">
                  <label>Confirm password</label>
                  <i
                    :class="pass.confirm_pass_visible ? 'show-pass fas fa-eye' : 'show-pass fas fa-eye-slash'"
                    @click="pass.confirm_pass_visible = !pass.confirm_pass_visible"
                  />
                  <input
                    id="confirm_pass"
                    v-model="pass.confirm_password"
                    placeholder="Confirm Password"
                    :type="pass.confirm_pass_visible ? 'text' : 'password'"
                    autocomplete="new-password"
                    name="reset"
                  >
                </div>
                <div class="char-content">
                  <p :class="passEqual() ? 'char checked' : 'char no-checked' ">
                    Password matches
                  </p>
                </div>
              </div>
              <Preload
                v-if="loading"
                style="margin-left: 35%"
              />
              <button
                class="btn btn-primary"
                @click.prevent="setPassword"
              >
                SET NEW PASSWORD
              </button>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <a
        class="contact-btn"
        href="#"
        @click.prevent="action"
      >Contact Us</a>
    </div>
  </div>
</template>

<script>
import logo from '@images/logo-primary.png';
import { ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { GET_USER, SET_PASSWORD } from '@/store/login/actions.types';
import { LOGIN, LOGOUT } from '@/store/login/actions.types';
import {
  getInvToken,
  getToken,
  destroyToken,
  destroyInvToken
} from '@/services/jwt.service';
import { isRole } from '@/helper';
import Preload from '../components/Preload';

extend('required', required);

email['message'] = 'Please enter a valid email address';
extend('email', email);

// @group Login
export default {
  name: 'SetPassword',
  components: {
    Preload,
    ValidationObserver
  },
  data() {
    return {
      logo: logo,
      loading: false,
      pass: {
        pass_visible: false,
        confirm_pass_visible: false,
        edit: false,
        password: '',
        confirm_password: '',
      },
      user: {
        roles: []
      },
      practice: {},
      invitationToken: ''
    };
  },
  beforeMount() {
    this.logout();

    if(this.$route.query.token){
      this.invitationToken = this.$route.query.token;
    }else{
      this.invitationToken = getInvToken();
    }

    if(this.invitationToken != '')
      this.$store
        .dispatch(GET_USER, {
          token: this.invitationToken
        })
        .then(resp => {
          if (resp.success) {
            this.practice = resp.practice;
            this.user = resp.user;
          }
        });
    else
      this.$router.push({name: 'LoginFirstTime'});
  },
  methods: {
    // @vuse
    // Used to load user practice and check practice compleation
    isRole,

    // @vuese
    // Used to logout user
    async logout() {
      if (getToken()) {
        destroyToken();
        await this.$store.dispatch(LOGOUT);
      }
    },
    // @vuese
    // Used to set password
    setPassword() {
      if (!this.validated()) return;
      const {
        password,
        confirm_password
      } = this.pass;

      this.loading = true;
      this.$store.dispatch(SET_PASSWORD, {
        user: {
          invitation_token: this.invitationToken,
          password: password,
          password_confirmation: confirm_password,
        }
      }).then(
        resp => {
          this.$store.dispatch(LOGIN, {
            user: {
              email: this.user.email,
              password: password,
            }
          })
            .then(resp => {
              destroyInvToken();
              if(isRole(this.user, 'authorized_principal'))
                this.$router.push({name: 'Affiliate'});
              else
                this.$router.push({name: 'userdashboard'});
            });
        },
        reject => {
          this.flashError('Something went wrong, please <a href="https://regimenpro.com/pages/affiliate-contact-us">contact us</a> for additional information')
        }
      ).finally(()=>{
        this.loading=false;
      });
    },
    // @vuese
    // Used to validate for
    validated() {
      const validatePass = this.validatePass();
      let passValidated = true;

      Object.keys(validatePass).forEach(passPatternKey => {
        if (!validatePass[passPatternKey]) passValidated = false;
      });

      return this.passEqual() && passValidated;
    },
    // @vuese
    // Used to validate password
    validatePass() {
      return {
        length: this.pass.password.length >= 8,
        specialChar: /[^a-zA-Z0-9\-\/]/.test(this.pass.password),
        haveNumber: /[0-9]/g.test(this.pass.password)
      };
    },
    // @vuese
    // Used to check if passwords equal
    passEqual() {
      return (
        this.pass.password != '' &&
        this.pass.password === this.pass.confirm_password
      );
    }
  }
};
</script>