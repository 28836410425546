<template>
  <div class="policy">
    <h2 class="main-privacy-heading">
      PRIVACY POLICY
    </h2>
    <div class="privacy-details">
      <div
        v-for="(section, index) in policySections"
        :key="index"
      >
        <h3>{{ section.title }}</h3>
        <ul v-if="section.bulletPoints && section.bulletPoints.length > 0">
          <li
            v-for="(point, pointIndex) in section.bulletPoints"
            :key="pointIndex"
          >
            <span v-html="formatBulletPoint(point)" />
          </li>
        </ul>
        <p v-else>{{ section.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Policy',
  data() {
    return {
      policySections: [
        {
          title: 'Last Updated: April 30, 2024',
          content: 'RegimenMD, Inc. (“RegimenPro®,” “our,” “us,” “we” or the “Company”) is committed to protecting the privacy and security of the information of our guests, registered users (“Registered Users”), and dermatologist, esthetician, plastic surgeon, or other skin care professional (“SkinCare Professional” and together with guests and Registered Users, “you”).  We want you to understand how we collect and use information from our website and related services (collectively, the “Services”) so you can interact with us with confidence.  By using the Services, you accept and agree to the practices this Privacy Policy describes.  If you do not agree to any of the terms in this Privacy Policy, you should not use the Services.'
        },
        {
          title: 'What Information Do We Collect?',
          content: 'When you use our Services, we may collect, store, and process various kinds of data, including Personal Information about you.  “Personal Information” refers to information about you that can be used to identify you, such as your name, address, telephone number, e-mail address, IP address, device information, credit card information, or transaction information.  If you take our skin care quiz, Personal Information will also include photographs of your face and skin. Specifically, we may collect, store, or process information when you:'
        },
        {
          bulletPoints: [
            'Fill in forms on the Services, including when you register to use the Services;',
            'Contact us with inquiries, including via email;',
            'Take our skin care quiz;',
            'Subscribe to our newsletter or otherwise sign up to receive communications;',
            'Otherwise interact with or contract RegimenPro®;',
            'Voluntarily provide information.'
          ]
        },
        {
          content: 'If you use or otherwise interact with our RegimenLogic® skin care quiz, we will use artificial intelligence to scan and take a photograph of your face (“Biometric Information”).  The purpose of collecting and storing this Biometric Information is so that we can recommend products to you.  We do not sell, lease, or trade from our use of Biometric Information.'
        },
        {
          content: 'We may collect details of your visit to our Services and the pages you access, including, but not limited to, traffic data, location data, weblogs and other communication data, including your IP address, browser type, the date and time you accessed or left the Services, and which pages you viewed.  Some parts of our Services use cookies and other technologies to collect this information about your general internet usage.  See the section below called “How We Use Tracking Technologies” to learn more.'
        },
        {
          title: 'How We Use Tracking Technologies',
          content: 'We use cookies (small text files stored in your browser) and other techniques such as web beacons (small, clear picture files used to follow your online activities) (“Tracking Technologies”). Tracking Technologies collect information that tells us how you use our Services. The use of Tracking Technologies does not give us access to the rest of your computer.'
        },
        {
          content: 'This, in turn, helps us make our Services relevant to your interests and needs.  We use a number of different Tracking Technologies, including functional, performance, advertising, and social media or content cookies.'
        },
        {
          title: 'Why We Collect Information',
          content: 'We use your information for the following purposes:'
        },
        {
          bulletPoints: [
            'To present our Services and products to you;',
            'To perform Services requested by you;',
            'To personalize your experience;',
            'To improve customer service by being able to respond to your requests and support needs more efficiently;',
            'To personalize user experience by using aggregated information and your feedback to understand how to improve the Services;',
            'To send periodic emails to you;',
            'To help us establish and verify the identity of users;',
            'To maintain the security and integrity of our systems; or ',
            'To fulfill any other purposes at your direction or with your consent.'
          ]
        },
        {
          title: 'How Do We Share Your Personal Information?',
          content: 'We may disclose your Personal Information as follows:'
        },
        {
          bulletPoints: [
            'With a SkinCare Professional to provide product recommendations for the purposes of supporting SkinCare Professional’s services to Registered Users;',
            'With affiliates, contractors, service providers, and other third parties we use to support our business;',
            'To fulfill the purpose for which you provide it;',
            'For any other purpose disclosed by us when you provide the information;',
            'With your consent;',
            'With affiliates if you opt-in to receiving communications from affiliates;',
            'To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which Personal Information held by us about the Services’ users is among the assets transferred;',
            'To enforce any agreement, including any applicable terms of service;',
            'To establish or exercise our right to defend against legal claims;',
            'To law enforcement and other government authorities such as legislatures, courts, agencies and litigants if we reasonably believe that such action is necessary to: (a) comply with the law and the reasonable requests of governmental authorities; (b) comply with legal process; (c) respond to requests from public or government authorities, including public or government authorities outside your country of residence; (d) protect the security or integrity of the Services’ information systems; and/or (e) exercise or protect our rights, privacy, safety or those of affiliates, clients, you or others; and',
            'If we reasonably believe disclosure is necessary or appropriate to protect the rights, property, or safety of RegimenPro® or others.'
          ]
        },
        {
          title: 'Choices About How We Use and Disclose Your Information',
          content: 'We strive to provide you with choices regarding the Personal Information you provide to us. We have created mechanisms to provide you with the following control over your information:'
        },
        {
          bulletPoints: [
            'Review, Correction, and Deletion. You may review, edit, and/or delete the information we collected about you at any time by contacting us at the addresses listed at the end of this privacy policy.  If your information has been shared with a third party, as described elsewhere in this Privacy Policy, then that third party has received its own copy of your data.  If you have been contacted by one of these third parties and wish to correct or delete your information, please contact them directly.',
            'Tracking Technologies. Most browsers accept cookies automatically.  You may be able to set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. For instructions, check your browser’s technical information. If you disable or refuse cookies, please note that some parts of the Services may then be inaccessible or not function properly.  However, you can still place orders over the telephone or by contacting customer service.  Please note that cookie-based opt outs must be performed on each device and browser that you wish to have opted out.',
            'Promotional Offers from the Company. If you do not wish to have your e-mail address/contact information used by the Company to promote our own or third parties’ products or services, you can opt-out by clicking the unsubscribe link in the promotional email. This opt out does not apply to information provided to the Company as a result of a product purchase, warranty registration, product service experience or other transactions.',
            'Targeted or Interest-Based Advertising. We may work with third-party advertising companies that collect and use information about your online activities across websites or applications over time, in order to deliver more relevant advertising elsewhere on the Internet.  This practice is known as interest-based advertising.  The online advertising industry also provides websites from which you may opt-out of receiving targeted ads from our advertising partners that participate in self-regulatory programs. You can access these, and also learn more about targeted advertising and consumer choice and privacy at:  www.networkadvertising.org/managing/opt_out.asp, www.youronlinechoices.eu/, or www.youradchoices.ca/, and www.aboutads.info/choices/.  You can also opt-out of targeted advertising directly on the advertising platform by visiting https://www.facebook.com/settings/?tab=ads, https://www.google.com/settings/ads/anonymous, and https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads. Note that electing to opt out will not stop advertising from appearing in your browser or applications.  It may make the ads you see less relevant to your interests.  If you delete, block, or otherwise restrict cookies or use a different computer or Internet browser, you may need to renew your opt-out choice.  ',
            'Google Analytics.  We may use Google analytics to improve the Services’ functions.  You can choose not to be included in Google analytics by visiting https://tools.google.com/dlpage/gaoptout.'
          ]
        },
        {
          title: '“Do Not Track” Requests',
          content: 'The Services currently do not respond to “do not track” or similar signals.'
        },
        {
          title: 'Payment Information',
          content: 'When you make a payment on our Services, you may be taken to the website of one of our third-party service providers.  This third-party will either collect or have access to your first and last name, zip code, postal address, email address, payment amount, billing information, and payment card information.  Please see our Terms of Use for more information.'
        },
        {
          title: 'How Do We Protect Your Information?',
          content: 'To protect your Personal Information, we take commercially reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered, or destroyed. However, no data protection procedures are entirely infallible. As a result, while we strive to protect your Personal Information, we cannot guarantee that it will be 100% secure. Your transmission of your information to our Platform thus is done entirely at your own risk.'
        },
        {
          title: 'Third-Party Websites and Social Media',
          content: 'Our Services may contain links to other websites, including those of third parties or partners.  While we seek to link only to Services that share our high standards and respect for privacy, we cannot be responsible for the privacy practices other websites use.  By accessing other third-party websites or applications through our Services, you are consenting to the terms and privacy policies of those websites.  It is possible that other parties may collect personally identifiable information about your online activities over time and across different websites when you use our Services. '
        },
        {
          content: 'Our Services may include social media features, such as Facebook “Likes” or “Recommend” buttons, Pinterest, Twitter or “X”, Tumblr, and YouTube.  These social media features may require cookies to be set to function properly.  These features may also collect Personal Information such as your IP address.  These features are governed by the Privacy Policy of the social media platform.  Please review the Privacy Policy of the social media platforms to learn how they protect your information.'
        },
        {
          title: 'International Visitors',
          content: 'Our Services are hosted and operated in the United States (“US”).  By using the Services, you are consenting to the transfer of your Personal Information to the US.  If you are accessing our Services from outside the US, please be advised that US law may not offer the same privacy protections as the law of your jurisdiction. '
        },
        {
          title: 'California Visitors',
          content: 'California’s “Shine the Light” law (Civil Code Section § 1798.83) permits users of our Services that are California residents to request certain information regarding our disclosure of Personal Information to third parties for their direct marketing purposes. To make such a request, please send an email to customerservice@regimenpro.com.'
        },
        {
          title: 'Users Only of Legal Age of Majority',
          content: 'Our Services are not intended for anyone under 18 years of age. We do not knowingly collect Personal Information from anyone under 18. If you are under 18, do not use or provide any information on the Services or on or through any of its features/register on the Services or provide any information about yourself to us, including your name, address, telephone number, e-mail address or any screen name or username you may use. If we learn we have collected or received Personal Information from anyone under 18, we will delete that information. If you believe we might have any information from or about anyone under 18, please contact us at customerservice@regimenpro.com.  Services are offered and available to users who are 18 years of age or older.  By using the Services, you represent and warrant that you are of legal age to form a binding contract with RegimenPro® and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Services.'
        },
        {
          title: 'Privacy Policy Updates',
          content: 'We update the Privacy Policy from time to time, so please review it regularly.  If we materially change our Privacy Policy, we will notify you by contacting you through your provided e-mail address or by posting a notice on our Services. Your continued use of our Services will be deemed your agreement that your information may be used in accordance with the new policy.  If you do not agree with the changes, then you should stop using the Services and notify us that you do not want your information used in accordance with the changes.'
        },
        {
          title: 'Contacting Us',
          content: 'We welcome any questions, comments, or complaints you may have regarding this Privacy Policy.  Please do not hesitate to contact us by email at customerservice@regimenpro.com or by writing to us:'
        },
        {
          content: '295 Main Street'
        },
        {
          content: 'Tilton, New Hampshire 03276'
        }
      ]
    };
  },
  created() {
    this.parsePolicySections();
  },
  methods: {
    formatBulletPoint(point) {
      const boldText = {
        'Review, Correction, and Deletion.': '<b>Review, Correction, and Deletion.</b>',
        'Tracking Technologies': '<b>Tracking Technologies</b>',
        'Promotional Offers from the Company': '<b>Promotional Offers from the Company</b>',
        'Targeted or Interest-Based Advertising': '<b>Targeted or Interest-Based Advertising</b>',
        'Google Analytics': '<b>Google Analytics</b>'
      };

      let formattedPoint = point;
      for (const text in boldText) {
        if (Object.prototype.hasOwnProperty.call(boldText, text)) {
          formattedPoint = formattedPoint.replace(new RegExp(text, 'g'), boldText[text]);
        }
      }
      return formattedPoint;
    },
    parsePolicySections() {
      this.policySections.forEach(section => {
        if (section.bulletPoints && section.bulletPoints.length > 0) {
          section.bulletPoints = section.bulletPoints.map(point => this.parseText(point));
        } else {
          section.content = this.parseText(section.content);
        }
      });
    },
    parseText(text) {
      text = text.replace(/(?:https?|ftp):\/\/[^\s]+|(?:www\.)[^\s]+/g, function(match) {
        return match.startsWith('http') ? `<a href="${match}" target="_blank">${match}</a>` : `<a href="http://${match}" target="_blank">${match}</a>`;
      });
      text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

      return text;
    }
  }
};
</script>

<style scoped>
a {
  color: blue;
  text-decoration: underline;
}

.privacy-details {
  margin-top: 5rem;
}

.main-privacy-heading {
  text-align: center;
  margin: 4rem;
}

.policy {
  color: #333;
  padding-left: 15%;
  padding-right: 18%;
}

.policy h2 {
  font-size: 32px;
  margin-bottom: 10px;
}

.policy h3 {
  font-size: 18px;
  margin-bottom: 8px;
}

.policy ul {
  list-style-type: disc;
  margin-left: 20px;
}

.policy p {
  margin-bottom: 16px;
}
</style>
