<template>
  <date-range-picker 
    ref="datePickerCustom"
    :date-range="dateRange"
    :locale-data="locale"
    :opens="opens"
    :show-dropdowns="true"
    @update="log"
    @toggle="toggleRDP"
  >
    <!--Optional scope for the input displaying the dates -->
    <div 
      slot="input" 
      slot-scope="picker"
    >
      {{ formatDate(picker.startDate) }} - {{ formatDate(picker.endDate) }}
    </div>
  </date-range-picker>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';
//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

// @group Components
export default {
  components: { DateRangePicker },
  data() {
    return {
      dateRange: { 
        startDate: new Date(Date.now() - 30 * 86400000),
        endDate: new Date(),
      },
      opens: 'left',//which way the picker opens, default "center", can be "left"/"right"
      locale: {
        direction: 'ltr', //direction of text
        format: 'MMMM DD, YYYY', //fomart of the dates displayed
        separator: ' - ', //separator between the two ranges
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 1, //ISO first day of week - see moment documenations for details
        showWeekNumbers: true //show week numbers on each row of the calendar
      },
      ranges: { //default value for ranges object (if you set this to false ranges will no be rendered)
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'This month': [moment().startOf('month'), moment().endOf('month')],
        'This year': [moment().startOf('year'), moment().endOf('year')],
        'Last week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
        'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      }
    };
  },

  methods: {
    toggleRDP() {
      var target = document.querySelector('.vue-daterange-picker');
      const config = {
        childList: true,
        subtree: true
      }; 
      const callback = (mutationsList, observer) => {
        for (let mutation of mutationsList) {
          if(mutation.addedNodes.length > 0){
            if(mutation.addedNodes[0].nodeType == 8)
              observer.disconnect();
            let lis = this.$refs.datePickerCustom.$el.querySelectorAll('.daterangepicker.dropdown-menu ul li');
            if(lis.length > 0){
              lis.forEach((element) => {
                element.addEventListener('click', this.setApply);
              });
              observer.disconnect();
            }
          }
        }
      };
      const observer = new MutationObserver(callback);
      observer.observe(target, config);
    },
    setApply() {
      this.$refs.datePickerCustom.$el.querySelectorAll('.daterangepicker.dropdown-menu ul li').forEach((element) => {
        element.removeEventListener('click', this.setApply);
      });
      this.$refs.datePickerCustom.$el.querySelector('.daterangepicker .drp-buttons .btn.applyBtn.btn-success').click();
    },

    log(value) {
      this.$emit('input', [value.startDate, value.endDate]);
    },
    formatDate(value) {
      return moment(value).format(this.locale.format);
    }
  }
};
</script>