<template>
  <div class="front-wrapper">
    <header class="main-header">
      <div class="container container-md-lg">
        <div class="header-wrap wrap-lg">
          <div class="header-logo">
            <router-link 
              :to="{
                name: 'userdashboard'
              }"
            >
              <img
                :src="logo"
                alt="Logo"
              >
            </router-link>
          </div>
          <div class="header-right lg-wrap">
            <div class="row justify-content-between mx-0">
              <p>
                Client Name:
                <strong>{{ patient.name }}</strong>
              </p>
              <p>
                Email Address:
                <strong>{{ patient.email }}</strong>
              </p>
              <p v-if="patient.physician">
                Skincare Pro Name:
                <strong>{{ patient.physician.name }}</strong>
              </p>
            </div>
            <p v-if="patient.shipping_address">
              Shipping Address:
              <strong>{{ bulidAddressStr(patient.shipping_address) }},</strong>
            </p>
            <p>
              Payment Method:
              <strong v-if="patient.payment_method && patient.payment_method.card_number">{{ patient.payment_method.card_type }} ending in {{ patient.payment_method.card_number }}</strong>
              <strong v-else>None</strong>
            </p>
          </div>
          <div class="header-right-link">
            <router-link
              :to="{
                name: 'PatientView',
                params: {
                  id: patient.id
                }
              }"
            >
              Leave Without Saving
            </router-link>
          </div>
        </div>
      </div>
    </header>

    <div class="container mt-3">
      <Notifications />
    </div>

    <div class="frontend-main">
      <div class="container container-md-lg">
        <h2>Client's Order</h2>
        <div class="row">
          <div class="col-md-7">
            <h4 class="mb-4">
              Shipping Address
            </h4>
            <div class="d-box with-lpad pb-4">
              <div class="form form-gray">
                <ValidationObserver 
                  v-if="patient.shipping_address"
                  ref="shipping_address" 
                  v-slot="{ invalid }" 
                  tag="form"
                >
                  <div class="row">
                    <div class="col-sm-6 form-group">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Shipping first name"
                        rules="required"
                        class="form-group"
                      >
                        <label for="sh_f_name">First Name</label>
                        <input
                          id="sh_f_name"
                          v-model="patient.shipping_address.first_name"
                          type="text"
                          :class="errors.length ? 'error' : ''"
                        >
                        <p
                          v-for="(err, errKey) in errors"
                          :key="errKey"
                          class="validation error mt-2"
                        >
                          {{ err }}
                        </p>
                      </validation-provider>
                    </div>
                    <div class="col-sm-6 form-group">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Shipping last name"
                        rules="required"
                        class="form-group"
                      >
                        <label for="sh_l_name">Last Name</label>
                        <input
                          id="sh_l_name"
                          v-model="patient.shipping_address.last_name"
                          type="text"
                          :class="errors.length ? 'error' : ''"
                        >
                        <p
                          v-for="(err, errKey) in errors"
                          :key="errKey"
                          class="validation error mt-2"
                        >
                          {{ err }}
                        </p>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Street Address"
                      rules="required"
                      class="form-group"
                    >
                      <label for="sa_street_address">Street Address</label>
                      <input
                        id="sa_street_address"
                        v-model="patient.shipping_address.address1"
                        type="text"
                        :class="errors.length ? 'error' : ''"
                        placeholder="123 Main St"
                      >
                      <p
                        v-for="(err, errKey) in errors"
                        :key="errKey"
                        class="validation error mt-2"
                      >
                        {{ err }}
                      </p>
                    </validation-provider>
                  </div>

                  <div class="form-group">
                    <label for="sa_city_unit">Unit or Suite Number (Optional)</label>
                    <input
                      id="sa_city_unit"
                      v-model="patient.shipping_address.address2"
                      type="text"
                      placeholder="Suite 600"
                    >
                  </div>
                  <div class="row gutter-22">
                    <div class="col-md-6 form-group">
                      <label for="sa_city">City</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="City"
                        rules="required"
                      >
                        <input
                          id="sa_city"
                          v-model="patient.shipping_address.city"
                          type="text"
                          :class="errors.length ? 'error' : ''"
                          placeholder="Chicago"
                        >
                        <p 
                          v-for="(err, errKey) in errors"
                          :key="errKey"
                          class="validation error mt-2"
                        >
                          {{ err }}
                        </p>
                      </validation-provider>
                    </div>
                    <div class="col-md-6 form-group">
                      <label for="sa_state">State</label>
                      <StateSelect 
                        id="sa_state"
                        v-model="patient.shipping_address.state"
                        @change="validZip('shipping_address')"
                      />
                    </div>

                    <div class="col-md-6 form-group">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Zip code"
                        rules="required"
                        class="form-group"
                      >
                        <label for="sa_zip">Zip Code</label>
                        <input 
                          id="sa_zip"
                          v-model="patient.shipping_address.zip"
                          :class="errors.length ? 'error' : ''"
                          type="text"
                          placeholder="12345"
                          @input="validZip('shipping_address')"
                        >
                        <p 
                          v-for="(err, errKey) in errors"
                          :key="errKey"
                          class="validation error mt-2"
                        >
                          {{ err }}
                        </p>
                        <p
                          v-if="shippingZipMessage"
                          class="validation error mt-2"
                        >
                          {{ shippingZipMessage }}
                        </p>
                      </validation-provider>
                    </div>
                    <div class="col-md-6 form-group">
                      <label for="sa_country">Country</label>
                      <input
                        id="sa_country"
                        v-model="patient.shipping_address.country"
                        type="text"
                        placeholder="United States"
                        readonly
                      >
                    </div>

                    <div class="col-md-6 form-group">
                      <div class="head-tooltip form-group">
                        <label for="sa_phone">
                          Phone Number
                          <i 
                            class="fas fa-question-circle" 
                            @mouseover="hover('sa_phone')" 
                            @mouseleave="hoverOff('sa_phone')"
                          />
                        </label>
                        <div 
                          data-tooltip-type="sa_phone" 
                          class="tooltip-black"
                        >
                          Phone numbers are used by delivery drivers to reach out if they have delivery issues.
                        </div>
                      </div>
                      
                      <input 
                        id="sa_phone"
                        v-model="patient.shipping_address.phone"
                        type="tel"
                        maxlength="16"
                        placeholder="(555) 555 - 5555"
                        @input="enterNumber()"
                      >
                      <p 
                        v-for="(err, errKey) in shippingPhoneErrors"
                        :key="errKey"
                        class="validation error mt-2"
                      >
                        {{ err }}
                      </p>
                    </div>
                  </div>
                </ValidationObserver>
              </div>
            </div>
          </div>

          <div class="col-md-5">
            <h4 class="mb-4">
              Order Review
            </h4>
            <div class="wrap-table border-table">
              <div 
                v-if="lineItems.length == 0"
                class="added-products"
              >
                <p>Please add products</p>
              </div>
              <div 
                v-for="lineItem in lineItems"
                :key="lineItem.id"
                class="row row-group"
              >
                <div class="col-10 pr-0">
                  <div class="row-large">
                    <div class="col-lg-3">
                      <img
                        v-if="lineItem.image"
                        :src="lineItem.image"
                        alt="Product 1"
                        class="product-img"
                      >
                    </div>
                    <div class="col-lg-9 product-actions">
                      <p class="mb-1">
                        <strong v-html="lineItem.title" />
                      </p>
                      <p class="text-soft mb-1">
                        {{ lineItem.vendor }}
                        <span class="pl-3">${{ formatMoney(calcLineItemPrice(lineItem)) }}</span>
                      </p>
                      <p
                        v-if="lineItem.frequency"
                        class="text-soft mb-1"
                      >
                        Frequency:
                        <span class="pl-1">
                          {{ lineItem.frequency != 'One time' ? `Every ${lineItem.frequency} Days` : lineItem.frequency }}
                        </span>
                      </p>
                      <p 
                        :class="!lineItem.changeQty ? 'mb- quanty-action' : 'mb-0 quanty-action d-none'"
                      >
                        <span class="text-soft">Quantity: <span>{{ lineItem.quantity }}</span></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row py-3 text-right">
                <div class="col-lg-9 col-6">
                  <strong>Tax</strong>
                </div>
                <div class="col-lg-3 col-6">
                  <strong>-</strong>
                </div>
                <div class="col-lg-9 col-6">
                  <strong>Subtotal</strong>
                </div>
                <div class="col-lg-3 col-6">
                  <strong>${{ lineItems.length > 0 ? formatMoney(calcSubtotal()) : '0' }}</strong>
                </div>
              </div>
              <div class="pt-1">
                <button
                  v-if="!sending"
                  class="btn btn-primary"
                  @click.prevent="saveShipping"
                >
                  Next
                </button>
                <button
                  v-else
                  class="btn btn-primary"
                  disabled
                >
                  Processing...
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/. frontend-main -->
  </div>
  <!--/. frontend-wrap -->
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import logo from '@images/logo-light.png';
import {
  formatMoney,
  bulidAddressStr,
  validPhone
} from '@/helper';
import { AsYouType } from 'libphonenumber-js';

import {
  UPDATE_PATIENT_ADDRESS,
  ADD_PATIENT_ADDRESS
} from '@/store/patients/actions.types';

import patientStateValidation from '@/mixins/patientStateValidation';
import StateSelect from '@/views/components/StateSelect';
import googleAutocompleate from '@/mixins/googleAutocompleate';
import { CheckoutService, PatientsService } from '@/services/api.service';

// @group Patinet
export default {
  name: 'PatientOrderAddShipping',

  components: {
    ValidationProvider,
    ValidationObserver,
    StateSelect,
  },

  mixins: [patientStateValidation, googleAutocompleate],

  props: {
    patient: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    },
    selectedProducts: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  data() {
    return {
      selectedItems: [],
      charge: null,
      sending: false,
      skipConfirm: false,
      logo,
      patientId: null,
      lineItems: [],
      shippingPhoneErrors: '',
      updateShipping: false,
      reChargeShippingAddress: {}
    };
  },

  watch: {
    'patient.shipping_address': {
      deep: true,
      handler() {
        this.updateShipping = true;
      }
    }
  },

  beforeMount() {
    this.shippingComponent.forEach(el => {
      if (el.element == 'state')
        el.type.administrative_area_level_1 = 'long_name';
    });
    this.$nextTick(() => {
      if (this.patient.shipping_address) {
        setTimeout(() => {
          this.initAutocomplete('sa_street_address', this.shippingComponent, 'patient.shipping_address');
        });
      }
    });
  },

  mounted() {
    this.selectedItems = this.selectedProducts;
    if(this.hasNoLineItems()) {
      this.skipConfirm = true;
      this.$router.push({ name: 'PatientCreateOrder' });
    }
    this.lineItems = this.buildLineItems();
    let nextChargeScheduledAt;
    let params = {};
    if(this.selectedItems[0].next_charge_scheduled_at) {
      nextChargeScheduledAt = this.selectedItems[0].next_charge_scheduled_at.format('YYYY-MM-DD');
      params['next_charge_scheduled_at'] = nextChargeScheduledAt;
    }

    CheckoutService.getCharge(params, this.patient.id, this.getRequestPracticeId()
    ).then(({ data }) => {
      const { charge } = data;

      if(charge !== null && charge !== undefined) {
        this.charge = charge;
        charge.line_items.forEach((lineItem) => {
          this.selectedItems.forEach((selectedProduct) => {
            if(selectedProduct.discount_variant_id === parseInt(lineItem.shopify_variant_id)) {
              selectedProduct.skip = true;
              selectedProduct.quantity = lineItem.quantity;
            }
          });
        });
      }
    });

    PatientsService.getReChargeShippingAddress({
      id: this.patient.id
    }, this.getRequestPracticeId()).then(
      ({ data }) => {
        this.reChargeShippingAddress = data.shipping_address;
        this.reChargeShippingAddress.discount = data.discount;
      }
    );

    if (this.patient.shipping_address && this.patient.shipping_address.phone) {
      this.patient.shipping_address.phone = this.getPhone();
    }
  },

  methods: {
    // @vuese
    // Used format money
    // @arg Price value
    formatMoney,
    // @vuese
    // Used to convert address object to string, if object have empty  props
    // return word `None`
    // @arg address object
    bulidAddressStr,
    // @vuese
    // Used to validate phone
    // @arg Phone value
    validPhone,

    // @vuese
    // Used to check if shipping form valid
    async shippingValid() {
      return await this.$refs.shipping_address.validate() &&
        this.shippingPhoneErrors.length == 0;
    },
    // @vuese
    // Used to fill phone in shipping, billing address and to patient object if
    // value empty
    getPhone() {
      let phone;
      if (this.patient && this.patient.phone)
        phone = this.patient.phone;
      if (this.patient.shipping_address && this.patient.shipping_address.phone)
        phone = this.patient.shipping_address.phone;
      if (this.patient.billing_address && this.patient.billing_address.phone)
        phone = this.patient.billing_address.phone;
      return phone;
    },

    // @vuese
    // Used to save shipping, load taxes and redirect to next page
    async saveShipping() {
      if (this.sending) return;
      let error = false;
      let checkoutResp;

      if (await this.shippingValid()) {
        this.sending = true;
        if(this.updateShipping) {
          error = await this.processShipping();
        }

        if (!error) {
          try {
            checkoutResp = await this.createCheckout();
          } catch (e) {}
        }

        if (!error) {
          let params = {
            shippingAddress: this.reChargeShippingAddress,
            chargeProp: this.charge,
            selectedProducts: this.selectedItems
          };
          if (checkoutResp) {
            params.checkout = checkoutResp.data.checkout;
            params.checkoutData = checkoutResp.data.checkout;
          }

          this.$router.push({
            name: 'PatientConfirmOrderCheckout',
            params: params
          });
        }
        this.sending = false;
      }
    },

    // @vuese
    // Used to update or create shipping if it is not created yet
    async processShipping() {
      let error = false;
      let handler = UPDATE_PATIENT_ADDRESS;
      if (!this.patient.shipping_address.id) 
        handler = ADD_PATIENT_ADDRESS;

      try {
        await this.$store.dispatch(handler, {
          id: this.$route.params.id,
          shipping_address: this.patient.shipping_address,
          practiceId: this.getRequestPracticeId()
        });
      } catch (e) {
        if (e.data.errors && e.data.errors.phone)
          this.shippingPhoneErrors = e.data.errors.phone;
        else this.flashError('Error updating shipping address');

        error = true;
      }
      return error;
    },

    // @vuese
    // Used to check if we need to use new checkout
    isNewCheckout() {
      return !this.patient.payment_method || this.patient.payment_method.payment_gateway == 'checkout';
    },

    // @vuese
    // Used to create Shopify checkout
    async createCheckout() {
      const data = {
        email: this.patient.email,
        shipping_address: this.patient.shipping_address,
        line_items: this.buildCheckoutLineItems()
      };

      // if( this.patient.practice &&
      //       this.patient.practice.active_promotion &&
      //       this.patient.practice.active_promotion.discount_code !== ''
      // ) {
      //   data.discount_code = this.patient.practice.active_promotion.discount_code;
      // }

      if (this.patient.employee)
        data.discount_code = this.employeeDiscount;

      if(this.charge !== null && this.charge.discount_codes.length > 0)
        data.discount_code = this.charge.discount_codes[0].code;

      if(this.reChargeShippingAddress.discount)
        data.discount_code = this.reChargeShippingAddress.discount.code;

      return await CheckoutService.createCheckout(
        this.patient.id, data, this.getRequestPracticeId());
    },

    // @vuese
    // Used to build line items for ReCharge
    buildLineItems() {
      return this.selectedItems.map(product => Object.assign({}, product, { stage: '' }));
    },

    // @vuese
    // Used to build line items for Shopify
    buildCheckoutLineItems() {
      return this.lineItems.map((product) => {
        const lineItem = {};
        lineItem.variant_id = product.variant_id;
        lineItem.quantity = product.quantity;
        if (product.frequency && product.frequency.toLowerCase() != 'one time') {
          lineItem.order_interval_frequency = product.frequency;
          lineItem.charge_interval_frequency = product.frequency;
          lineItem.order_interval_unit = 'day';
        }
        return lineItem;
      });
    },

    // @vuese
    // Used to check we have at least one product
    hasNoLineItems() {
      return this.selectedItems.length === 0;
    },

    // @vuese
    // Used to calculate price of all line items
    calcLineItemPrice(lineItem) {
      return parseFloat(lineItem.price) * lineItem.quantity;
    },

    // @vuese
    // Calc total price of all products
    calcSubtotal() {
      return this.lineItems.reduce((sum, lineItem) => {
        return sum + this.calcLineItemPrice(lineItem);
      }, 0);
    },

    // @vuese
    // Used to enter number, validate, and convert it
    enterNumber() {
      let number = this.patient.shipping_address.phone;
      if (!this.validPhone(number)) {
        this.shippingPhoneErrors = ['Please enter valid phone number'];
      } else {
        this.shippingPhoneErrors = [];
      }

      let count_numbers = 0, last_number = 0, max_numbers = 10;
      if(number[0] == 1)
        max_numbers += 1;

      for (let i = 0; i < number.length; i++) {
        if(/^\d+$/.test(number[i])){
          count_numbers +=1;
          if(count_numbers > max_numbers && last_number == 0)
            last_number = i;
        }
      }

      if(last_number != 0){
        number = number.slice(0,last_number);
      }

      number = new AsYouType('US').input(number);

      if(event.inputType && event.inputType.indexOf('deleteContent') == -1){
        this.patient.shipping_address.phone = number;
      }
    },

    // @vuese
    // Used to show tooltip
    // @arg HTML element data type
    hover(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.add('open');
    },

    // @vuese
    // Used to hide tooltip
    // @arg HTML element data type
    hoverOff(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.remove('open');
    },

    // @vuese
    // Used to get practice ID for super admin user
    getRequestPracticeId() {
      return this.isRole('super_admin') ? `/${this.patient.practice.id}` : '';
    },
  }
};
</script>

<style lang="scss" scoped>
  .btn-primary:disabled {
    cursor: not-allowed;
  }
</style>
