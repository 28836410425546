<template>
  <div>
    <select
      v-if="!isRole('super_admin')"
      id="creators"
      v-model="selectedCreators"
      style="display: block"
      name="creators"
      class="title hover-btn"
    >
      <option
        value=""
        selected
      >
        All Creators
      </option>
      <option
        v-if="practice"
        :value="practice.id"
      >
        Practice Created
      </option>
      <option value="superadmin">
        RegimenPro® Created
      </option>
    </select>
    <SelectPractice
      v-else
      v-model="selectedCreators"
      title="All Creators"
      :items="valuesForSelect(practices)"
      :total="practicesTotal"
      :loading="isPracticesLoading"
      :name="'creators-select'"
      :filters="filters"
      @load-more="loadMorePractices"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { tags } from '@/store/regimen/initialObject';
import SelectPractice from '@/views/components/Select';
import practices from '@/mixins/practices';
import { PracticeService } from '@/services/api.service';

// @group Preset Regimen
export default {
  name: 'PresetRegimenFilters',
  components: {
    SelectPractice
  },
  mixins: [practices],
  data() {
    return {
      creators: [],
      concerns: [],
      regimenTags: tags,
      selectedTags: [],
      selectedCreators: '',
      showTags: false,
      selectedTagsString: 'All Concerns',
      loadOnInit: false,
      superCreatorsOptions: [
        {
          id: '',
          name: 'All Creators',
        },
        {
          id: 'superadmin',
          name: 'RegimenPro® Created'
        }
      ]
    };
  },
  computed: {
    ...mapGetters([
      'physicians',
      'patients',
      'practice'
    ])
  },
  watch: {
    selectedTags() {
      if (this.selectedTags.length) {
        this.selectedTagsString = this.selectedTags.map(selectedTag => {
          return this.regimenTags.find(tag => tag.name == selectedTag).value;
        }).join(', ');
      } else {
        this.selectedTagsString = 'All Concerns';
      }
    },
    selectedCreators() {
      // @vuese
      // Tell parent that we need to filter by creators and pass selected creators
      // @arg Selected creators
      this.$emit('filter-creators', this.selectedCreators);
    }
  },
  beforeMount() {
    if (this.isRole('super_admin')) {
      this.selectedCreators = 'superadmin';
      this.loadOnInit = true;
    }
  },
  mounted() {
    this.initCloseEvents();
  },
  beforeDestroy() {
    this.removeCloseEvent();
  },
  methods: {
    // @vuese
    //used to emit filter tags
    filterTags() {
      // @vuese
      // Tell parent that we need to filter by tags and pass selected tags
      // @arg Selected tags
      this.$emit('filter-tags', this.selectedTags);
    },
    // @vuese
    // Used to clear selected tags and clear selected creator
    // and emit to parent that we emit to parent that we want to reset filters
    resetAll() {
      this.selectedTags = [];
      this.selectedCreators = '';
      // @vuese
      // Tell parent that we need to reset all filters
      this.$emit('reset-all');
    },
    // @vuese
    // Used to handle change on tags checkbox
    // @arg Tag key
    handleTag(tagValue) {
      const tagIndex = this.selectedTags.findIndex(tag => {
        return tag == tagValue;
      });
      if (tagIndex != -1) {
        this.selectedTags.splice(tagIndex, 1);
      } else {
        this.selectedTags.push(tagValue);
      }

      this.filterTags();
    },
    // @vuese
    // Used to check if tag is checked
    // @arg Tag key
    tagChacked(tagValue) {
      return this.selectedTags.find(tag => tag == tagValue);
    },
    // @vuese
    // Used to clear tags
    clearTags() {
      this.selectedTags = [];
      this.filterTags();
    },
    // @vuese
    // Used to show or hide tags select
    toggleTags() {
      this.showTags = !this.showTags;
    },
    // @vuese
    // Used to check if we need to close tags select
    closeEvent(e) {
      if (!e.target.closest('.filter-dropdown1')) {
        this.showTags = false;
      }
    },
    // @vuese
    // Used to init close event
    initCloseEvents() {
      document.addEventListener('click', this.closeEvent);
    },
    // @vuese
    // Used to remove close events
    removeCloseEvent() {
      document.removeEventListener('click', this.closeEvent);
    },
    // @vuese
    // Used to load more practices
    // @arg Query object
    loadMorePractices(queryObj) {
      this.isPracticesLoading = true;

      const type = queryObj.type;
      delete queryObj.type;
      return PracticeService.getPractice(
        this.encodeQueryData(queryObj)
      ).then(
        ({ data }) => {
          if (type == 'search')
            this.practices = [...this.superCreatorsOptions, ...data.practices.items];
          else
            this.practices = [...this.practices, ...data.practices.items];

          this.practicesTotal = data.practices.total_items;
        }
      ).finally(() => {
        this.isPracticesLoading = false;
      });
    }
  }
};
</script>