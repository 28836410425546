<template>
  <div class="container container-md-sm">
    <div class="steps-wrap">
      <div class="step passed">
        <p>Set Password</p>
        <span class="step-marker" />
      </div>
      
      <div class="step passed">
        <p>Add Practice Info</p>
        <span class="step-marker" />
      </div>
      
      <div class="step passed">
        <p>Add Practice Addresses</p>
        <span class="step-marker" />
      </div>
        
      <div class="step passed">
        <p>Add Skincare Pros</p>
        <span class="step-marker" />
      </div>
      
      <div class="step current">
        <p>Add Practice Staff</p>
        <span class="step-marker" />
      </div>
      
      <div class="step">
        <p>Review</p>
        <span class="step-marker" />
      </div>
    </div><!-- /. steps-wrap -->

    <div class="container">
      <Notifications />
    </div>

    <div class="steps-content">
      <div class="top-title">
        <h3>Add Staff to Your Practice (optional)</h3>
        <a 
          class="right-link" 
          href="#"
          @click.prevent="submit(true)"
        >
          Save for Later
        </a>
      </div>
      <ValidationObserver
        ref="form"
        v-slot="{ invalid }"
        tag="form"
        @submit.prevent="submit(false)"
      >
        <div class="row row-create-account">
          <div class="col-md-8">
            <p class="mb-2">
              Staff will be able to add clients and manage their regimen. 
              They can do everything a physician can, but they won't be associated with a client.
              The information entered here will not be shown to clients.
            </p>
            <div class="f-box form form-white">
              <div 
                v-for="(staffObj, index) in staff" 
                :key="index"
              >
                <div class="form-group">
                  <label :for="`name-${index}`">Staff name</label>
                  <input 
                    :id="`name-${index}`"
                    v-model="staffObj.first_name"
                    placeholder="Leslee"
                    type="text"
                    name="name"
                  >
                </div>
                <div class="form-group">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="`Staf email ${index}`"
                    rules="email"
                    mode="passive"
                  >
                    <label :for="`email-${index}`">Staff email</label>
                    <input
                      :id="`email-${index}`"
                      v-model="staffObj.email"
                      placeholder="lesleemoss@practice.com"
                      type="text"
                      :class="errors.length || staffObj.emailErrors.length ? 'error' : ''"
                      name="email"
                    >
                    <p class="validation error mt-2">
                      {{ errors[0] }}
                    </p>
                    <p 
                      v-for="(err, errIdx) in staffObj.emailErrors"
                      :key="errIdx"
                      class="validation error mt-2"
                    >
                      {{ err }}
                    </p>
                  </validation-provider>
                </div>
                <a 
                  v-if="index != 0 && !staffObj.id" 
                  class="hover-btn minus-physician mb-4 d-block" 
                  @click="removeStaff(index)"
                >
                  <div class="form-options">
                    <i class="fas fa-minus" /> Remove Staff Member
                  </div>
                </a>
                <hr>
              </div>
              <div class="form-options pt-sm-2">
                <a 
                  class="hover-btn" 
                  @click="addStaff"
                >
                  <i class="fas fa-plus" />
                  Add Another Staff Member
                </a>
              </div>
            </div>
            <!-- /. First Group -->
          </div>

          <div class="col-md-4">
            <Sidebar />

            <button 
              class="btn btn-primary" 
              :disabled="sending" 
              type="submit"
            >
              Continue
            </button>
            <router-link 
              class="btn btn-bordered mt-3" 
              :to="{
                name: 'CreateAccountS4',
              }" 
              tag="a"
            >
              Back
            </router-link>
          </div>
        </div>
      </ValidationObserver>
    </div><!-- /. row-create-account -->
    <ContactUs />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Sidebar from './partials/Sidebar';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { staff } from '@/store/practice/initialObject';
import { ADD_STAFF, UPDATE_STAFF } from '@/store/practice/actions.types';
import ContactUs from '../components/ContactUs';

// @vuese
// @group Create Account
export default {
  name: 'CreateAccountS5',
  components: {
    Sidebar,
    ValidationProvider,
    ValidationObserver,
    ContactUs,
  },
  data() {
    return {
      sending: false,
    };
  },
  computed: {
    ...mapGetters([
      'staff',
    ])
  },
  watch: {
    staff: function (newStaff) {
      if (!newStaff.length) this.addStaff();
    }
  },
  beforeMount() {
    if (this.staff.length == 0) this.addStaff();
  },
  methods: {
    // @vuese
    // Used to store physicans and store Alergan data
    // @arg Save for later
    async submit(saveForLater) {
      const valid = await this.isValid();
      if (valid) {
        this.sending = true;
        const data = [];
        this.staff.forEach(staffObj => {
          if (staffObj.first_name && staffObj.email) {
            data.push(staffObj);
          }
        });
        if (data.length) {
          const staffPromises = [];
          data.forEach(staffData => {
            if (staffData.id)
              staffPromises.push(this.$store.dispatch(UPDATE_STAFF, staffData));
            else
              staffPromises.push(this.$store.dispatch(ADD_STAFF, staffData));
          });

          Promise.all(staffPromises)
            .then(responses => {
              responses.forEach(resp => {
                if (resp.data && resp.data.staff) {
                  this.staff.forEach(staffObj => {
                    if (staffObj.email == resp.data.staff.email) {
                      staffObj.id = resp.data.staff.id;
                    }
                  });
                }
              });
              if(saveForLater)
                this.flashSuccess('Practice staff has been saved');
              this.$router.push({name: 'CreateAccountS6'});
            })
            .catch(err => {

              const errors = err.data.errors;
              const data = JSON.parse(err.config.data);
              const errEmail = data.email;

              if (errors.email) {
                this.staff.forEach(stafObj => {
                  if (stafObj.email == errEmail) {
                    stafObj.emailErrors = errors.email;
                  }
                });
              } else {
                this.flashError('Error updating practice staff');
              }
            })
            .finally(() => {
              this.sending = false;
            });
        } else {
          this.$router.push({name: 'CreateAccountS6'});
        }
      }
    },

    // @vuese
    // Used to check if form valid
    isValid() {
      return this.$refs.form.validate();
    },
    // @vuese
    // Used to add staff object to staff array
    addStaff() {
      this.staff.push({...staff});
    },
    // @vuese
    // Used to remove staff object to staff array
    // @arg physican index
    removeStaff(index) {
      this.staff.splice(index, 1);
    }
  }
};
</script>