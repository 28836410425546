<template>
  <div class="avatar-cropper">
    <div
      v-if="dataUrl"
      class="avatar-cropper-overlay"
    >
      <div class="avatar-cropper-mark">
        <a
          class="avatar-cropper-close"
          href="javascript:;"
          @click="destroy"
        >&times;</a>
      </div>
      <div class="avatar-cropper-container">
        <div class="avatar-cropper-image-container">
          <img
            ref="img"
            :src="dataUrl"
            alt
            @load.stop="createCropper"
          >
        </div>
        <div class="avatar-cropper-footer btn-primary">
          <button
            class="avatar-cropper-btn"
            @click.stop.prevent="destroy"
            v-text="labels.cancel"
          >
            Cancel
          </button>
          <button
            class="avatar-cropper-btn"
            @click.stop.prevent="submit"
            v-text="labels.submit"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <input
      ref="input"
      :accept="mimes"
      class="avatar-cropper-img-input"
      type="file"
    >
  </div>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

// @group Components
export default {
  props: {
    // @vuese
    // Element or elements that trigger pickup image
    trigger: {
      type: [String, Element, NodeList],
      required: true
    },
    // @vuese
    // Upload handler
    uploadHandler: {
      type: Function
    },
    // @vuese
    // Upload url
    uploadUrl: {
      type: String,
      default: ''
    },
    // @vuese
    // Request url
    requestMethod: {
      type: String,
      default: 'POST'
    },
    // @vuese
    // Upload headers
    uploadHeaders: {
      type: Object,
      default() {
        return {};
      }
    },
    // @vuese
    // Upload form name
    uploadFormName: {
      type: String,
      default: 'file'
    },
    // @vuese
    // Form data
    uploadFormData: {
      type: Object,
      default() {
        return {};
      }
    },
    // @vuese
    // Image crop option
    cropperOptions: {
      type: Object,
      default() {
        return {
          aspectRatio: 1,
          autoCropArea: 1,
          viewMode: 1,
          movable: false,
          zoomable: false
        };
      }
    },
    // @vuese
    // Drop file
    dropFile: {
      default: null,
    },
    outputOptions: {
      type: Object,
      default() {
        return {};
      }
    },
    outputMime: {
      type: String,
      default: null
    },
    outputQuality: {
      type: Number,
      default: 0.9
    },
    mimes: {
      type: String,
      default: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon'
    },
    labels: {
      type: Object,
      default() {
        return {
          submit: '提交',
          cancel: '取消'
        };
      }
    }
  },
  data() {
    return {
      cropper: undefined,
      dataUrl: undefined,
      filename: undefined
    };
  },
  watch: {
    dropFile() {
      let reader = new FileReader();
      reader.onload = e => {
        this.dataUrl = e.target.result;
      };

      reader.readAsDataURL(this.dropFile);
      this.filename = this.dropFile.name || 'unknown';
      this.mimeType = this.mimeType || this.dropFile.type;
      this.$emit('changed', this.dropFile, reader);
      this.$emit('filename', this.filename);
    }
  },
  mounted() {
    // listen for click event on trigger
    this.$nextTick(() => {
      let triggers = this.trigger;
      if (!triggers) {
        this.$emit('error', 'No avatar make trigger found.', 'user');
      } else {
        for (let i = 0; i < triggers.length; i++) {
          triggers[i].addEventListener('click', this.pickImage);
        }
      }

      // listen for input file changes
      let fileInput = this.$refs.input;
      fileInput.addEventListener('change', () => {
        if (fileInput.files != null && fileInput.files[0] != null) {
          let reader = new FileReader();
          reader.onload = e => {
            this.dataUrl = e.target.result;
          };

          reader.readAsDataURL(fileInput.files[0]);

          this.filename = fileInput.files[0].name || 'unknown';
          this.mimeType = this.mimeType || fileInput.files[0].type;
          this.$emit('changed', fileInput.files[0], reader);
          this.$emit('filename', this.filename.replace('.svg', '.png'));
        }
      });
    });
  },
  methods: {
    // @vuese
    // Used to destroy cropper box
    destroy() {
      this.cropper.destroy();
      this.$emit('cropping', false);
      this.$refs.input.value = '';
      this.dataUrl = undefined;
    },
    // @vuese
    // Used to get cropped image and pass it to parent
    submit() {
      this.cropper.getCroppedCanvas(this.outputOptions).toBlob(
        blob => {
          this.$emit('submit', blob);
        },
        this.outputMime,
        this.outputQuality
      );
      // if (this.uploadUrl) {
      //   this.uploadImage()
      // } else if (this.uploadHandler) {
      //   this.uploadHandler(this.cropper)
      // } else {
      //   this.$emit('error', 'No upload handler found.', 'user')
      // }
      this.destroy();
    },
    // @vuese
    // Used to trigger to pisck image
    // @arg Node event
    pickImage(e) {
      this.$refs.input.click();
      e.preventDefault();
      e.stopPropagation();
    },
    // @vuese
    // Used to create cropper instance and emit to parent that it was created
    createCropper() {
      this.$emit('cropping', true);
      this.cropper = new Cropper(this.$refs.img, this.cropperOptions);
    },
    // @vuese
    // Used to upload image
    uploadImage() {
      this.cropper.getCroppedCanvas(this.outputOptions).toBlob(
        blob => {
          let form = new FormData();
          let xhr = new XMLHttpRequest();
          let data = Object.assign({}, this.uploadFormData);

          for (let key in data) {
            form.append(key, data[key]);
          }

          form.append(this.uploadFormName, blob, this.filename);

          this.$emit('uploading', form, xhr);

          xhr.open(this.requestMethod, this.uploadUrl, true);

          for (let header in this.uploadHeaders) {
            xhr.setRequestHeader(header, this.uploadHeaders[header]);
          }

          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              let response = '';
              try {
                response = JSON.parse(xhr.responseText);
              } catch (err) {
                response = xhr.responseText;
              }
              this.$emit('completed', response, form, xhr);

              if ([200, 201, 204].indexOf(xhr.status) > -1) {
                this.$emit('uploaded', response, form, xhr);
              } else {
                this.$emit('error', 'Image upload fail.', 'upload', xhr);
              }
            }
          };
          xhr.send(form);
        },
        this.outputMime,
        this.outputQuality
      );
    }
  },
};
</script>

<style lang="scss">
.avatar-cropper {
  .avatar-cropper-overlay {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    // position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
  }

  .avatar-cropper-img-input {
    display: none;
  }

  .avatar-cropper-close {
    float: right;
    padding: 20px;
    font-size: 3rem;
    color: #fff;
    font-weight: 100;
    text-shadow: 0px 1px rgba(40, 40, 40, 0.3);
  }

  .avatar-cropper-mark {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
  }

  .avatar-cropper-container {
    background: #fff;
    z-index: 999;
    box-shadow: 1px 1px 5px rgba(100, 100, 100, 0.14);

    .avatar-cropper-image-container {
      position: relative;
      max-width: 400px;
      height: 300px;
    }
    img {
      max-width: 100%;
      height: 100%;
    }

    .avatar-cropper-footer {
      display: flex;
      align-items: stretch;
      align-content: stretch;
      justify-content: space-between;

      .avatar-cropper-btn {
        width: 50%;
        padding: 15px 0;
        cursor: pointer;
        border: none;
        background: #a989de;
        color: #fff;
        outline: none;
        &:hover {
          background-color: #936bd6;
          color: #fff;
        }
      }
    }
  }
}
</style>
