<template>
  <div class="bg-login">
    <div class="container">
      <Notifications />
      <div class="box-login">
        <ValidationObserver 
          ref="observer" 
          v-slot="{ invalid }" 
          class="login-form validate-form"
          tag="form" 
          @submit.prevent="firstTimeLogin"
        >
          <div class="logo-img">
            <img 
              :src="logo" 
              alt="Logo"
            >
          </div>
          <h2 class="text-center mb-3">
            First Time Logging In?
          </h2>
          <p class="pb-3 text-center">
            Please enter the email address associated with your new RegimenPro account.
          </p>
          <validation-provider 
            v-slot="{ errors }" 
            name="Email" 
            rules="email|required"
          >
            <div 
              class="box-input validate-input mb-5" 
              data-validate="Please enter a valid email address"
            >
              <label>Email address</label>
              <input 
                v-model="email" 
                placeholder="Email" 
                type="text" 
                :class="errors.length || invalidLogin == true ? 'error' : ''" 
                name="email"
                @input="invalidLogin = false"
              >
              <p class="validation error mt-2">
                {{ errors[0] }}
              </p>
              <p 
                v-if="invalidLogin == true" 
                class="validation error mt-2"
              >
                Please enter a valid email address
              </p>
            </div>
          </validation-provider>
          
          <button 
            href="#" 
            class="btn btn-primary"
          >
            Look Up Account
          </button>
          <router-link 
            class="link-logging" 
            :to="{
              name: 'Login'
            }" 
            tag="a"
          >
            back to login
          </router-link>
        </ValidationObserver>
      </div>
    </div>
    <ContactUs />
  </div>
</template>

<script>
import logo from '@images/logo-primary.png';
import ContactUs from '../components/ContactUs';

import { ValidationProvider, ValidationObserver, extend  } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { FIRST_TIME } from '../../store/login/actions.types';
import { isRole } from '@/helper';
import { LOGOUT } from '@/store/login/actions.types';
import {
  getToken,
  destroyToken
} from '@/services/jwt.service';


extend('required', required);

// extends('password', {
//   validate(value) {
//     return 
//   },
//   message: 'Incorrect password. Please try again.'
// })

email['message'] = 'Please enter a valid email address';
extend('email', email);

// @group Login
export default {
  name: 'LoginFirstTime',
  components: {
    ContactUs,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      logo: logo,
      email: '',
      invalidLogin: false
    };
  },
  beforeMount() {
    this.logout();
  }, 
  
  methods: {
    // @vuese
    // Used to check user role
    isRole,
    // @vuese
    // Used to logout user
    async logout() {
      if (getToken()) {
        destroyToken();
        await this.$store.dispatch(LOGOUT);
      }
    },
    // @vuese
    /// Used to send email and redirect user to Set password page or if 
    // it is practice admin and registration is not finisshed to Create account page
    async firstTimeLogin() {
      const { email } = this;
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.$store.dispatch(FIRST_TIME, {
          user: {
            email
          }
        })
          .then(
            resp => {
              if(resp.status == '200'){
                if(!this.isRole(resp.data, 'practice_admin')){
                  this.$router.push({name: 'SetPassword'});
                }else{
                  this.$router.push({ name: 'CreateAccount', query: {invitation_token: resp.data.invitation_token}});
                }
              }
            },reject => {
              if(reject.response.status == '404'){
                this.invalidLogin = true;
                this.flashError('Looks like this email address hasn\'t been invited to RegimenMD yet. Please contact your practice\'s administrator to get invited to RegimenMD');
              }
              if(reject.response.status == '422'){
                this.$router.push({name: 'Login'});
              }
            });
      }
    }
  }
};
</script>