import { PracticeService } from '../../services/api.service'; 
import {
  GET_PRACTICE,
  SUPER_ADMIN_GET_PRACTICE,
  SET_PRACTICE_CUSTOM,
  UPDATE_PRACTICE,
  UPDATE_PRACTICE_FORM,
  ADD_ADDRESS,
  UPDATE_ADDRESS,
  ADD_PHYSICIAN,
  UPDATE_PHYSICIAN,
  ADD_STAFF,
  UPDATE_STAFF,
  REMOVE_STAFF, 
  REASSIGN_REMOVE_PHYSICIAN,
  REASSIGN_ADMIN,
  UPDATE_ADMIN_INFO,
  REMOVE_PHYSICIAN,
  SUPER_ADMIN_PRACTICE
} from './actions.types';
import { 
  SET_PRACTICE,
  SET_ADDRESS,
  SET_PHYSICIANS,
  SET_STAFF,
  SET_ALLEGRAN,
  SET_SUPER_ADMIN_PRACTICE
} from './mutations.types';
import { 
  physician, 
  staff as staffObj, 
  phone,
  default_address,
  delivery_address, 
  allegran,
  socials,
} from './initialObject';

const initialState = {
  practice: {
    email: '',
    url: '',
    phones: [{...phone}],
    logo: {},
    social_media: [...socials],
    allegran: {
      soldto: '',
      shipto: ''
    },
    default_address: {...default_address},
    delivery_address: {...delivery_address},
    admin: {
      email: '',
      first_name: '',
      id: '',
      last_name: '',
      name: '',
      role: ''
    }
  },
  default_address: {...default_address},
  delivery_address: {...delivery_address},
  physicians: [{...physician}],
  allegran: {
    soldto: '',
    shipto: ''
  },
  superAdminPracticeId: '',
  staff: [{...staffObj}]
};

const state = Object.assign({}, initialState);

const getters = {
  practice(state) {
    return state.practice;
  },
  address(state) {
    return {
      default_address: state.default_address,
      delivery_address: state.delivery_address,
    };
  },
  physicians(state) {
    return state.physicians;
  },
  allegran(state) {
    return state.allegran;
  },
  staff(state) {
    return state.staff;
  },
  superAdminPracticeId(state) {
    return state.superAdminPracticeId;
  }
};

const actions = {
  [GET_PRACTICE](context) {
    return new Promise((resolve, reject) => {
      PracticeService.getPractice()
        .then(resp => {
          const practice = resp.data.practice || resp.data.practices.items;
          if (practice.phones && practice.phones.length == 0) {
            practice.phones.push({...phone});
          }

          if (!practice.default_address) {
            practice.default_address = {...default_address};
          }
          practice.default_address.type = 'default';
          context.commit(SET_ADDRESS, {
            type: 'default',
            default_address: practice.default_address
          });

          if (!practice.delivery_address) {
            practice.delivery_address = {...delivery_address};
          }
          practice.delivery_address.type = 'delivery';
          context.commit(SET_ADDRESS, { 
            type: 'delivery',
            delivery_address: practice.delivery_address 
          });

          if (practice.physicians){
            context.commit(SET_PHYSICIANS, practice.physicians);
          }

          if (practice.staff){
            context.commit(SET_STAFF, practice.staff);
          }

          if (practice.allegran) {
            context.commit(SET_ALLEGRAN, practice.allegran);
          }

          context.commit(SET_PRACTICE, practice);
          resolve(resp);
        })
        .catch(err => reject(err));
    });
  },
  [SUPER_ADMIN_GET_PRACTICE](context) {
    return new Promise((resolve, reject) => {
      PracticeService.superAdminGetPractice()
        .then(resp => {
          const practice = resp.data.practice;
          if (practice.phones && practice.phones.length == 0) {
            practice.phones.push({...phone});
          }
          if (!practice.default_address) {
            practice.default_address = {...default_address};
          }
          practice.default_address.type = 'default';
          context.commit(SET_ADDRESS, {
            type: 'default',
            default_address: practice.default_address
          });

          if (!practice.delivery_address) {
            practice.delivery_address = {...delivery_address};
          }
          practice.delivery_address.type = 'delivery';
          context.commit(SET_ADDRESS, { 
            type: 'delivery',
            delivery_address: practice.delivery_address 
          });

          if (practice.physicians){
            context.commit(SET_PHYSICIANS, practice.physicians);
          }

          if (practice.staff){
            context.commit(SET_STAFF, practice.staff);
          }

          if (practice.allegran) {
            context.commit(SET_ALLEGRAN, practice.allegran);
          }

          context.commit(SET_PRACTICE, practice);
          resolve(resp);
        })
        .catch(err => reject(err));
    });
  },
  [UPDATE_PRACTICE_FORM](context, formData) {
    return new Promise((resolve, reject) => {
      PracticeService.updateFormData(formData)
        .then(resp => {
          context.commit(SET_PRACTICE, resp.data.practice);
          resolve(resp);
        })
        .catch(err=> reject(err));
    });
  },
  [UPDATE_PRACTICE](context, data) {
    return new Promise((resolve, reject) => {
      PracticeService.updatePractice(data)
        .then(resp => {
          context.commit(SET_PRACTICE, resp.data.practice);
          resolve(resp);
        })
        .catch(err=> reject(err));
    });
  },
  [ADD_ADDRESS](context, params) {
    return new Promise((resolve, reject) =>  {
      PracticeService.addAddress(params.type, params)
        .then(resp => {
          resp.data.type = params.type;
          resp.data[`${params.type}_address`].type = params.type;
          context.commit(SET_ADDRESS, resp.data);
          resolve(resp);
        })
        .catch(err => reject(err));
    });
  },
  [UPDATE_ADDRESS](context, params) {
    return new Promise((resolve, reject) =>  {
      PracticeService.updateAddress(params.type, params)
        .then(resp => {
          resp.data.type = params.type;
          resp.data[`${params.type}_address`].type = params.type;
          context.commit(SET_ADDRESS, resp.data);
          resolve(resp);
        })
        .catch(err => reject(err));
    });
  },
  [ADD_PHYSICIAN](context, params) {
    return new Promise((resolve, reject) => {
      PracticeService.addPhysician(params.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  [UPDATE_PHYSICIAN](context, params) {
    return new Promise((resolve, reject) => {
      PracticeService.updatePhysician(params.data, params.id)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  [ADD_STAFF](context, params) {
    return new Promise((resolve, reject) => {
      PracticeService.addStaff(params)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  [UPDATE_STAFF](context, params) {
    return new Promise((resolve, reject) => {
      PracticeService.updateStaff(params, params.id)
        .then(resp => {
          resolve(resp);
        })
        .catch(err =>  {
          reject(err.response);
        });
    });
  },
  [REASSIGN_REMOVE_PHYSICIAN](context, params) {
    return new Promise((resolve, reject) => {
      PracticeService.reassignRemovePhysician(params)
        .then(resp => {
          resolve(resp);
        })
        .catch(err =>  {
          reject(err.response);
        });
    });
  },
  [REMOVE_STAFF](context, params) {
    return new Promise((resolve, reject) => {
      PracticeService.deleteStaff(params.id)
        .then(resp => {
          resolve(resp);
        })
        .catch(err =>  {
          reject(err.response);
        });
    });
  },
  [SET_PRACTICE_CUSTOM](context, practice) {
    return context.commit(SET_PRACTICE, practice);
  },
  [REASSIGN_ADMIN](context, data){
    return new Promise((resolve, reject) => {
      PracticeService.reassignAdmin(data)
        .then(resp => {
          resolve(resp);
        })
        .catch(err =>  {
          reject(err.response);
        });
    });
  },
  [UPDATE_ADMIN_INFO](context, data){
    return new Promise((resolve, reject) => {
      PracticeService.updateAdminInformation(data)
        .then(resp => {
          resolve(resp);
        })
        .catch(err =>  {
          reject(err.response);
        });
    });
  },
  [REMOVE_PHYSICIAN](context, data){
    return new Promise((resolve, reject) => {
      PracticeService.removePhysician(data.id)
        .then(resp => {
          resolve(resp);
        })
        .catch(err =>  {
          reject(err.response);
        });
    });
  },
  [SUPER_ADMIN_PRACTICE](context, practiceId){
    context.commit(SET_SUPER_ADMIN_PRACTICE, practiceId);
  }
};

const mutations = {
  [SET_PRACTICE](state, practice) {
    state.practice = practice;
  },
  [SET_ADDRESS](state, data) {
    if (data.type == 'default')
      state.default_address = data.default_address;
    else if (data.type == 'delivery')
      state.delivery_address = data.delivery_address;
  },
  [SET_PHYSICIANS](state, physicians) {
    state.physicians = physicians.map(physician => {
      if (!physician.allegran) {
        physician.allegran = {...allegran};
        physician.uniqueAllegran = false;
      } else if (physician.allegran.soldto && physician.allegran.shipto) {
        physician.uniqueAllegran = true;
      }

      physician.avatarPreview = physician.avatar.url;
      physician.avatarError = '';
      physician.avatarFilename = ' ';
      physician.emailErrors = [];
      return physician;
    });
  },
  [SET_ALLEGRAN](state, allegran) {
    state.allegran = allegran;
  },
  [SET_STAFF](state, staff) {
    state.staff = staff.map(staffObject => {
      staffObject.emailErrors = [];
      return staffObject;
    });
  },
  [SET_SUPER_ADMIN_PRACTICE](state, practiceId){
    state.superAdminPracticeId = practiceId;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};