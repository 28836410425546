<template>
  <div
    v-out-click="hideComponent"
    class="content-down"
  >
    <a
      class="title"
      href="#"
      @click.prevent="toggleListVisibility"
    >{{ title }}</a>
    <div
      v-show="isVisible"
      class="multi-select"
      style="display:block"
    >
      <div
        v-if="loading"
        class="preloader"
      >
        <i />
      </div>
      <div class="top-filters">
        <search-input
          v-if="searchBox"
          :id="`search${handleize(title)}`"
          v-model="query"
          type="text"
          :name="`search${handleize(title)}`"
          placeholder="Search"
          classes="content-filter"
        />
      </div>
      <p
        v-if="items.length === 0"
        class="m-2 text-center"
      >
        <strong>No items.</strong>
      </p>
      <div
        class="multi-select-list"
      >
        <div
          v-for="item in items"
          :key="item.value"
          class="form-check-box"
        >
          <input
            :id="handleize(item.title, item.value)"
            type="checkbox"
            :checked="isChecked(item.value)"
            :name="handleize(item.tite, item.value)"
            :value="item.value"
            @change="toggleItem(item.value)"
          >
          <label :for="handleize(item.title, item.value)"><span>{{ item.title }}</span></label>
        </div>
      </div>
      <div
        v-if="bottomBox"
        class="bottom-box"
      >
        <span>{{ value.length }} selected</span>
        <a
          href="#"
          class="clear-select"
          @click.prevent="clear"
        >Clear</a>
      </div>
    </div>
  </div>
</template>

<style scoped>
  body .row-filters label {
    text-transform: none;
  }
</style>

<script>
import SearchInput from './SearchInput';

// @group Components
export default {
  name: 'MultiSelect',

  components: {
    SearchInput
  },

  directives: {
    'out-click': {
      bind: function(el, binding, vNode) {
        const bubble = binding.modifiers.bubble;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;
        document.addEventListener('click', handler);
      },

      unbind: function(el, binding) {
        document.removeEventListener('click', el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      }
    }
  },

  props: {
    // @vuese
    // Selected value
    value: {
      type: Array,
      default: () => []
    },

    // @vuese
    // Select title
    title: {
      type: String,
      default: 'All'
    },

    // @vuese
    // Select items
    items: {
      type: Array,
      default: () => []
    },

    // @vuese
    // Selected items
    selectedItems: {
      type: Array,
      default: () => []
    },

    // @vuese
    // Used to show if more data loading
    loading: {
      type: Boolean,
      default: false
    },

    // @vuese
    // Used to show if need search box
    searchBox: {
      type: Boolean,
      default: true
    },

    // @vuese
    // Used to show if we need to show bottom box with clear button
    bottomBox: {
      type: Boolean,
      default: true
    },

    // @vuese
    // Time before we load more data
    waitBeforeCall: {
      type: Number,
      default: 500
    },

    // @vuese
    // Toatal amount of data
    total: {
      type: Number,
      default: 0,
    },

    // @vuese
    // New list
    newList: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      preloaderIsVisible: true,
      isVisible: false,
      query: '',
      per: 20,
      page: 1,
      type: 'search',
      enableSearch: true,
      callTimeout: null,
      allowLoadMore: true,
    };
  },

  watch: {
    query() {
      this.type = 'search';
      this.page = 1;
      this.allowLoadMore = true;
      this.loadMoreItems();
    },

    items(newValue) {
      this.hidePreloader();
      if (this.type == 'load')
        this.allowLoadMore = newValue.length < this.total;
    },

    newList(newVal){
      if(newVal){
        this.page = 1;
        this.$emit('reinited');
      }
    }
  },

  beforeDestroy() {
    this.removeScrollEvents();
  },

  mounted() {
    this.initScrollEvents();
  },

  methods: {
    // @vuese
    // Used to handlize value
    handleize(k, v='') {
      return `${k}${v}`.replace(/[^a-z0-9]/gi, '_');
    },

    // @vuese
    // Used to hide componet
    hideComponent() {
      this.isVisible = false;
    },

    // @vuese
    // Used to toggle list visibility
    toggleListVisibility() {
      this.isVisible = !this.isVisible;
    },

    // @vuese
    // Used to show preloader
    showPreloader() {
      this.preloaderIsVisible = true;
    },

    // @vuese
    // Used to check if value is checked
    // @arg Value
    isChecked(val) {
      return this.selectedItems.indexOf(val) != -1;
    },

    // @vuese
    // Used to hide preloader
    hidePreloader() {
      this.preloaderIsVisible = false;
    },

    // @vuese
    // Used to load more items
    loadMoreItems() {
      if(this.callTimeout !== null) clearTimeout(this.callTimeout);
      this.callTimeout = setTimeout(() => this.$emit('load-more', this.buildQuery()), this.waitBeforeCall);
    },

    // @vuese
    // Used to build query object
    buildQuery() {
      return {
        search: this.query,
        per: this.per,
        page: this.page,
        type: this.type,
      };
    },

    // @vuse
    // Used to toggle value in selected list
    toggleItem(value) {
      const index = this.value.indexOf(value);
      if(index === -1)
        this.value.push(value);
      else
        this.value.splice(index, 1);
    },

    // @vuse
    // Used to unselect all items
    clear() {
      while(this.value.length > 0) this.value.pop();
    },
    // @vuese
    // Used to init scroll events
    initScrollEvents() {
      this.$el.querySelector('.multi-select-list').addEventListener('scroll', this.searchEvents);
    },

    // @vuese
    // Used to load more data
    // @arg Node event
    searchEvents(e) {
      if (this.loading) return;
      if (!this.allowLoadMore)  return;
      const scrollTop = e.target.scrollTop;
      const offsetHeight =  e.target.offsetHeight;
      const scrollHeight = e.target.scrollHeight;
      if ((offsetHeight + scrollTop) > (scrollHeight - 0.5)) {
        this.type = 'load';
        this.page++;
        this.$emit('load-more', this.buildQuery());
      }
    },

    // @vuese
    // Used to remove scroll events
    removeScrollEvents() {
      this.$el.querySelector('.multi-select-list').removeEventListener('scroll', this.searchEvents);
    }
  }
};
</script>

<style lang="scss" scoped>
  .content-down {
    .multi-select {
      top-filters: {
        border-bottom: none;
      }
      height: initial;
      .multi-select-list {
        height: 186px;
        overflow: auto;
        padding: 2px 0;
      }
      .wrap-ckecks {
        max-height: 150px;
        overflow-y: auto;
      }
      .bottom-box {
        position: initial;
        padding-bottom: 0;
        width: calc(100% + 24px);
        margin-left: -12px;
      }
    }
  }
</style>
