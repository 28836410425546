export default {
  data() {
    return {
      shippingComponent: [
        {
          element: 'address1',
          type: [
            {
              street_number: 'long_name'
            },
            {
              route: 'long_name'
            }
          ]
        },
        {
          element: 'city',
          type: {
            locality: 'long_name'
          }
        },
        {
          element: 'state',
          type: {
            administrative_area_level_1: 'short_name'
          }
        },
        {
          element: 'zip',
          type: {
            postal_code: 'long_name'
          }
        }
      ]
    };
  },

  methods: {
    // @vuese
    // Used to init google autocomplete on elemet
    // @arg Node ID
    // @arg / Options that explaint what data we need to take from autompleate
    // @arg / Path to object in state
    initAutocomplete(id, options, path) {
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      const autocomplete = new google.maps.places.Autocomplete(
        document.getElementById(id), {
          types: ['geocode'],
          componentRestrictions: {country: 'us'}
        }
      );

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      autocomplete.setFields(['address_component']);
    
      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      autocomplete.addListener('place_changed', this.fillInAddress.bind(this, autocomplete, options, path));
    },

    // @vuese
    // Used to fill date in state object
    // @arg Autocompleate response
    // @arg / Options that explaint what data we need to take from autompleate
    // @arg / Path to object in state
    fillInAddress(autocomplete, options, path) {
      // Get the place details from the autocomplete object.
      var place = autocomplete.getPlace();

      if (!place) return;

      options.forEach(obj => {
        this.resolve(path, this)[obj.element] = '';
      });
    
      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        options.forEach(obj => {
          if (Array.isArray(obj.type)) {
            obj.type.forEach(type => {
              for (const prop in type) {
                if (prop == addressType)
                  this.resolve(path, this)[obj.element] += `${place.address_components[i][type[addressType]]} `;
              }
            });
          } else {
            for (const prop in obj.type) {
              if (prop == addressType)
                this.resolve(path, this)[obj.element] = place.address_components[i][obj.type[addressType]];
            }
          }
        });
      }
    },
    // @vuese
    // Used to get user location
    geolocate() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position) {
          var geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          var circle = new google.maps.Circle(
            {center: geolocation, radius: position.coords.accuracy});
          this.autocomplete.setBounds(circle.getBounds());
        });
      }
    },
    // @vuese
    // Used to get prop by path
    resolve(path, obj) {
      return path.split('.').reduce((prev, curr) => {
        return prev ? prev[curr] : null;
      }, obj || self);
    }
  }
};