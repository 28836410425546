<script>
import { Line } from 'vue-chartjs';
import moment from 'moment';

// @group Reports
export default {
  name: 'SalesLineChart',

  extends: Line,

  props: {
    // @vuese
    // Chart labels
    labels: {
      type: Array,
      default() {
        return [];
      }
    },
    // @vuese
    // Curernt date range data
    quarter: {
      type: Object,
      default() {
        return {};
      }
    },
    // @vuese
    // Cureant date range label label
    quarterLabel: {
      type: [String,Number],
      default: '',
    },
    // @vuese
    // Compare date range data
    compareQuarter: {
      type: Object,
      default() {
        return {};
      }
    },
    // @vuese
    // Compare date range label label
    compareQuarterLabel: {
      type: [String,Number],
      default: ''
    }
  },

  mounted() {
    this.renderChart({
      labels: this.getLabels(),
      datasets: [
        {
          label: this.quarterLabel,
          backgroundColor: 'rgba(68, 42, 112, 0.5)',
          borderColor: '#442a70',
          type: 'line',
          fill: true,
          // lineTension: 0,
          data: Object.values(this.quarter)
        },
        {
          label: this.compareQuarterLabel,
          backgroundColor: 'rgba(132, 104, 179, 0.5)',
          borderColor: '#8468b3',
          type: 'line',
          fill: true,
          // lineTension: 0,
          data: Object.values(this.compareQuarter)
        }
      ]
    }, {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: 'index',
        intersect: true,
        callbacks: {
          label: function(tooltipItems, data) {
            const dataSetLabel = data.datasets[tooltipItems.datasetIndex].label;

            return `${dataSetLabel}: $${tooltipItems.yLabel.toString()}`;
          }
        }
      },
      scales: {
        // xAxes: [
        //   {
        //     display: true,
        //     tipe: 'time',
        //     scaleLabel: {
        //       display: true,
        //       labelString: this.quarterLabel
        //     },
        //     id: 'x-axis-1',
        //     time: {
        //       displayFormats: {
        //         day: 'MMM DD',
        //         week: 'MMM DD',
        //         month: 'MMM DD',
        //         quarter: 'MMM DD',
        //         year: 'MMM DD'
        //       }
        //     },
        //     ticks: {
        //       callback: function(label) {
        //         const labelText = label.split('#')[0];
        //         return labelText != '-' ? moment(new Date(labelText)).format('MMM/D/YY') : '-';
        //       }
        //     }
        //   },
        //   {
        //     display: true,
        //     tipe: 'time',
        //     id: 'x-axis-2',
        //     scaleLabel: {
        //       display: true,
        //       labelString: this.compareQuarterLabel
        //     },
        //     ticks: {
        //       callback: function(label) {
        //         const labelText = label.split('#')[1];
        //         return labelText != '-' ? moment(new Date(labelText)).format('MMM/D/YY') : '-';
        //       }
        //     }
        //   }
        // ],
        yAxes: [{
          ticks: {
            beginAtZero: true,
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Total'
            },
            callback(label) {
              return label.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              });
            },
            
          },
        }]
      }
    });
  },

  methods: {
    // @vuese
    // Used to get labes in month format
    getLabels() {
      return this.labels.map(date => {
        return moment(date).format('MMM');
      });
    }
  }
};
</script>